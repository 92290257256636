import React from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { DeliveryLine } from 'services/action';

import { Print } from 'ui/components';
import { formatDate } from 'utilities/common';

import logoBlack from 'ui/assets/logo_jpl.png';
import _ from 'underscore';
import { currencyFormat } from 'utilities/common';

const PrintReportDailyWH = () => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.session);
  const StateTable = useSelector(
    (state) => state?.Table?.data['delivery_line']
  );

  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [pages, setPage] = React.useState(null);
  const [firstPage] = React.useState(12);
  const [limit] = React.useState(17);
  const [tcv, setTcv] = React.useState(0);

  const $onLoad = async () => {
    let filter = StateTable?.filter;
    filter.limit = 100000000000;
    const result = await Dispatch(DeliveryLine.$get(filter));
    if (result?.message === 'success') {
      setData(result?.data);
    }
  };

  React.useEffect(() => {
    if (data) {
      let pages = _.chunk(data, firstPage);
      if (data?.length > firstPage) {
        let first = pages[0];
        let last = _.rest(data, first?.length);
        pages = _.chunk(last, limit);
        pages.unshift(first);
      } else {
        setPage(pages);
      }

      let tcv = 0;
      _.each(data, function (i) {
        tcv += i?.total_package;
      });

      setTcv(tcv);
      setPage(pages);
      setLoaded(true);
    }
  }, [data]);

  React.useEffect(() => {
    setLoaded(false);
    $onLoad();
  }, []);

  if (data === null || !loaded) return null;

  return (
    <div>
      <Print
        title={`Daily Report Warehouse`}
        size="A4"
        content={renderToString(
          pages?.map((page, id) => (
            <section
              className="sheet page-break"
              style={{ padding: '20px 60px' }}
              key={id}
            >
              {id === 0 && (
                <>
                  <div
                    className="d-flex flex-row justify-content-between"
                    style={{ height: 132 }}
                  >
                    <div className="" style={{ width: '100%' }}>
                      <div
                        className="d-flex align-items-center "
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderBottomWidth: 3,
                          borderBottomColor: '#202020',
                          borderBottomStyle: 'solid',
                        }}
                      >
                        <div style={{ alignSelf: 'initial', width: '20%' }}>
                          <img
                            src={logoBlack}
                            alt=""
                            style={{ maxHeight: 80, maxWidth: '100%' }}
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: 20,
                            textAlign: 'left',
                            width: '60%',
                          }}
                        >
                          <h2 className="bolder uppercase ">
                            rekap bast gudang harian
                          </h2>
                          <h4 className="bolder uppercase ">
                            bantuan pangan nasional 2023
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <table className="body border">
                    <tr className="border">
                      <td className="border" style={{ width: '20%' }}>
                        <p className="mb-0 text-left">Tanggal</p>
                      </td>
                      <td className="border" style={{ width: '30%' }}>
                        <p className="bolder mb-0 text-left">
                          {formatDate(new Date(), 'DD MMMM YYYY')}
                        </p>
                      </td>
                      <td className="border" style={{ width: '20%' }}>
                        <p className="mb-0 text-left">Gudang</p>
                      </td>
                      <td className="border" style={{ width: '30%' }}>
                        <p className="bolder mb-0 text-left">
                          {Session?.warehouse?.name}
                        </p>
                      </td>
                    </tr>
                    <tr className="border">
                      <td className="border" style={{ width: '20%' }}>
                        <p className="mb-0 text-left">Periode</p>
                      </td>
                      <td className="border" style={{ width: '30%' }}>
                        <p className="bolder mb-0 text-left">
                          {formatDate(
                            new Date(StateTable?.filter?.dispatched_at),
                            'DD MMMM YYYY'
                          )}
                        </p>
                      </td>
                      <td className="border" style={{ width: '20%' }}>
                        <p className="mb-0 text-left">Provinsi</p>
                      </td>
                      <td className="border" style={{ width: '30%' }}>
                        <p className="bolder mb-0 text-left">
                          {page[0]?.area?.village?.district?.regency?.kanwil
                            ?.name || '-'}
                        </p>
                      </td>
                    </tr>
                  </table>
                </>
              )}

              <div style={{ marginTop: 30 }}>
                <table className="body border">
                  <thead>
                    <tr style={{ verticalAlign: 'center' }}>
                      <td
                        className="center  border"
                        style={{
                          padding: 5,
                          width: '5%',
                        }}
                      >
                        <p className="bold mb-0" style={{ fontSize: 12 }}>
                          No.
                        </p>
                      </td>
                      <td className="left border" style={{ width: '30%' }}>
                        <p className="bold mb-0" style={{ fontSize: 12 }}>
                          No. Resi
                        </p>
                      </td>
                      <td className="left border" style={{ width: '30%' }}>
                        <p className="bold mb-0" style={{ fontSize: 12 }}>
                          DOC-OUT
                        </p>
                      </td>
                      <td className="left border" style={{ width: '10%' }}>
                        <p className="bold mb-0" style={{ fontSize: 12 }}>
                          Kab. / Kota
                        </p>
                      </td>
                      <td
                        className="left border"
                        style={{ width: '10%', padding: 5 }}
                      >
                        <p className="bold mb-0" style={{ fontSize: 12 }}>
                          Kecamatan
                        </p>
                      </td>
                      <td
                        className="left border"
                        style={{ width: '10%', padding: 5 }}
                      >
                        <p className="bold mb-0" style={{ fontSize: 12 }}>
                          Kelurahan
                        </p>
                      </td>
                      <td
                        className="right border"
                        style={{ width: '5%', padding: 5 }}
                      >
                        <p className="bold mb-0" style={{ fontSize: 12 }}>
                          Jumlah Paket
                        </p>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {page?.map((d, idx) => (
                      <tr style={{ verticalAlign: 'center' }} key={idx}>
                        <td
                          className="border-bottom center border"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          <p className="mb-0">
                            {id > 1
                              ? limit + (firstPage * (id - 1) + idx + 1)
                              : firstPage * id + idx + 1}
                          </p>
                        </td>
                        <td
                          className="border-bottom left border"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{ wordBreak: 'break-all' }}
                          >
                            {d?.manifest}
                          </p>
                        </td>
                        <td
                          className="border-bottom left border"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{ wordBreak: 'break-all' }}
                          >
                            {d?.delivery?.doc_outs?.join(',\n') || '-'}
                          </p>
                        </td>
                        <td
                          className="border-bottom left border"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          <p className="mb-0">
                            {d?.area?.village?.district?.regency?.name}
                          </p>
                        </td>
                        <td
                          className="border-bottom left border"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingRight: 5,
                          }}
                        >
                          <p className="mb-0">
                            {d?.area?.village?.district?.name}
                          </p>
                        </td>
                        <td
                          className="border-bottom left border"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingRight: 5,
                          }}
                        >
                          <p className="mb-0">{d?.area?.village?.name}</p>
                        </td>
                        <td
                          className="border-bottom right border"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingRight: 5,
                          }}
                        >
                          <p className="mb-0">
                            {currencyFormat(d?.total_package)}
                          </p>
                        </td>
                      </tr>
                    ))}
                    {id + 1 === pages?.length && (
                      <tr style={{ verticalAlign: 'center' }}>
                        <td
                          className="right border-bottom center border"
                          colSpan={6}
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                            width: '3%',
                          }}
                        >
                          <p className="bold mb-0" style={{ fontSize: 15 }}>
                            Subtotal
                          </p>
                        </td>
                        <td
                          className="border-bottom right border"
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingRight: 5,
                          }}
                        >
                          <p className="bold mb-0 " style={{ fontSize: 15 }}>
                            {currencyFormat(tcv)}
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {id + 1 === pages?.length && (
                <div style={{ marginTop: 30 }}>
                  <table className="body border" style={{ marginTop: 20 }}>
                    <tr className="border">
                      <td className="border" style={{ width: '33%' }}>
                        <p className="bold mb-0">PIC Gudang</p>
                      </td>
                      <td className="border" style={{ width: '33%' }}>
                        <p className="bold mb-0">PIC Transporter</p>
                      </td>
                    </tr>
                    <tr className="border">
                      <td
                        className="border"
                        style={{ width: '33%', height: 80 }}
                      ></td>
                      <td
                        className="border"
                        style={{ width: '33%', height: 80 }}
                      ></td>
                    </tr>
                    <tr className="border">
                      <td className="border" style={{ width: '33%' }}>
                        <p className="bold mb-0">Tanggal :</p>
                      </td>
                      <td className="border" style={{ width: '33%' }}>
                        <p className="bold mb-0">Tanggal :</p>
                      </td>
                    </tr>
                  </table>
                </div>
              )}
            </section>
          ))
        )}
      />
    </div>
  );
};

export default PrintReportDailyWH;
