import cn from 'classnames';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { User } from 'services/action';
import { Form, Select } from 'ui/components';
import { SkeletonsForm } from 'ui/components/skeleton';

const initialState = {
  isLoading: true,
  usergroup: null,
  warehouse: null,
  name: '',
  phone: '',
  email: '',
  username: '',
  password: '',
  confirm_password: '',
};

export const FormUser = ({ data, onSuccess, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const dummy = Array.from({ length: 7 }, (v, i) => i);

  const [
    {
      name,
      username,
      password,
      confirm_password,
      usergroup,
      warehouse,
      isLoading,
    },
    setState,
  ] = React.useState(initialState);

  const $handleSubmit = async (c) => {
    const payload = {
      name,
      username,
      password,
      confirm_password,
    };

    payload.warehouse_id = warehouse?.id || null;
    payload.usergroup_id = usergroup?.id || null;

    if (data) {
      const submit = await Dispatch(User.$update(data?.id, payload));
      if (submit?.message === 'success') {
        onSuccess();
      }
    } else {
      const submit = await Dispatch(User.$create(payload));
      if (submit?.message === 'success') {
        onSuccess();
      }
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  React.useEffect(() => {
    if (!data) return;

    setState((prevState) => ({
      ...prevState,
      name: data?.name,
      phone: data?.phone,
      email: data?.email,
      username: data?.username,
      password: data?.password,
      confirm_password: data?.confirm_password,
      usergroup: { id: data?.usergroup_id },
      warehouse: { id: data?.warehouse_id },
    }));
  }, [data]);

  if (isLoading) {
    return (
      <Modal.Body>
        <div className="row m-0">
          {dummy?.map((d, i) => (
            <div className="col-md-6 col-12" key={i}>
              <SkeletonsForm count={1} />
            </div>
          ))}
        </div>
      </Modal.Body>
    );
  }

  return (
    <Form autocomplete="off" onSubmit={$handleSubmit}>
      <Modal.Body>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-3 ">
              <Select.Remote
                label="Pilih Usergroup"
                placeholder=""
                initialValue={usergroup?.id}
                url="/usergroup"
                UseOnClear
                onClear={() => {
                  setState((prevState) => ({
                    ...prevState,
                    usergroup: null,
                  }));
                }}
                row={(d) => {
                  return {
                    value: d.id,
                    label: d.name,
                  };
                }}
                onSelected={(c) => {
                  setState((prevState) => ({
                    ...prevState,
                    usergroup: c,
                  }));
                }}
                errors={StateForm?.errors?.usergroup_id}
                keys={usergroup?.id}
              />
            </div>
            <Form.Input
              value={name || ''}
              required
              type="text"
              label="Nama"
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  name: e?.target?.value,
                }))
              }
              error={StateForm?.errors?.name}
            />

            <Form.Input
              value={username || ''}
              required
              disabled={data ? true : false}
              type="text"
              label="Username"
              autocomplete="off"
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  username: e?.target?.value,
                }))
              }
              error={StateForm?.errors?.username}
            />
          </div>

          <div className="col-12 col-md-6">
            {usergroup?.name === 'Gudang' && (
              <div className="mb-3">
                <Select.Remote
                  required
                  label="Pilih Warehouse"
                  placeholder=""
                  url="/warehouse"
                  initialValue={warehouse?.id}
                  row={(d) => {
                    return { value: d.id, label: d.name };
                  }}
                  onSelected={(c) => {
                    setState((prevState) => ({
                      ...prevState,
                      warehouse: c,
                    }));
                  }}
                  errors={StateForm?.errors?.warehouse_id}
                />
              </div>
            )}
            <div className="form-group mb-3 ">
              <Form.InputAddon
                value={password || ''}
                required={!data}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    password: e?.target?.value,
                  }))
                }
                label="Password"
                type="password"
                placeholder=""
                autocomplete="off"
                error={StateForm?.errors?.password}
              />
              <Form.InputAddon
                value={confirm_password || ''}
                required={!data}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    confirm_password: e?.target?.value,
                  }))
                }
                label="Konfirmasi Password"
                type="password"
                placeholder=""
                error={StateForm?.errors?.confirm_password}
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button
              type="button"
              className="btn btn-light w-100"
              onClick={() => onClose()}
            >
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className={cn(
                StateForm?.loading ? 'btn-loading' : '',
                'btn btn-primary w-100'
              )}
              disabled={StateForm?.loading}
              onClick={$handleSubmit}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Form>
  );
};
