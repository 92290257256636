import { useSelector } from 'react-redux';

import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';

import { Page } from 'ui/components';
import tableConfig from './table.config';

const UsergroupScreen = () => {
  const UsergroupTable = useTable('usergroup', tableConfig);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );

  return (
    <Page className="col-12 col-md-8 bordered">
      <Page.Header pretitle="" title="Usergroup" isLoading={StateLoading} />

      <Page.Navigation toolbar />
      <Page.Body className="row m-0 p-0">
        <div className="col-md-12 m-0 p-0">
          {StateLoading ? (
            <SkeletonTable headerCount={6} bodyCount={10} />
          ) : (
            <UsergroupTable.Render />
          )}
        </div>
      </Page.Body>

      <Page.Footer>
        {StateLoading ? <SkeletonPageFooter /> : <UsergroupTable.Pagination />}
      </Page.Footer>
    </Page>
  );
};

export default UsergroupScreen;
