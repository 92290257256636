import config from 'services/table/const';
import Action from './action';

let table = { ...config };
table.url = '/region/village';
table.columns = {
  kanwil: {
    title: 'Kanwil',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.district?.regency?.kanwil?.name}</div>
        </div>
      );
    }
  },

  regency: {
    title: 'Kota/Kab',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.district?.regency?.name}</div>
        </div>
      );
    }
  },

  district: {
    title: 'Kecamatan',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.district?.name}</div>
        </div>
      );
    }
  },

  kelurahan: {
    title: 'Kelurahan',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.name}</div>
        </div>
      );
    }
  },

  sorting_dusun: {
    title: 'Sorting Dusun',
    class: 'text-center',
    classHeader: 'text-center',
    width: 70,
    component: (row) => {
      return <Action data={row} />;
    },
  },
};

export default table;
