import React from 'react';
import { useSelector } from 'react-redux';
import { Page } from 'ui/components';

import * as Section from './component';
import CardCounter from './component/card.kanwil';
import ChartBar from './component/chart';

const MonitoringScreen = () => {
  const Session = useSelector((state) => state?.Auth?.session);

  React.useEffect(() => {}, [Session]);

  return (
    <Page className="col-11">
      <Page.Body className="bg-light p-2  mt-2 mt-md-0 py-4 ">
        <div className="row mb-3">
          <div className="col-md-7">
            <Section.CardMap />
          </div>
          <div className="col-md-5">
            <Section.CardRtt />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <CardCounter />
          </div>
          <div className="col-md-6">
            <ChartBar />
          </div>
        </div>
      </Page.Body>
    </Page>
  );
};

export default MonitoringScreen;
