import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rtt } from 'services/action';
import { Page } from 'ui/components';
import { PODImage, SectionInfo, SectionLine } from './component/detail';
import { Action } from './component/detail/action';

const initialState = {
  data: null,
};

const DetailScreen = ({ match }) => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Rtt.Show']
  );
  const [{ data }, setState] = React.useState(initialState);

  const $onLoad = async (id) => {
    const req = await Dispatch(Rtt.$show(id));

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        data: req?.data,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data: null,
      }));
    }
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, [match]);

  return (
    <Page className="col-md-10 col-sm-12 bordered bg-white">
      <Page.Header
        pretitle="RTT"
        title={data?.code}
        status={data?.document_status}
        backTo="/rtt"
        className="bg-white"
      >
        <Action data={data} onSubmit={() => $onLoad(match?.params?.id)} />
      </Page.Header>
      <Page.Body className="bg-light mt-3 mt-md-0 bordered">
        <div className="p3">
          <div className="row">
            <div className="col-md-8">
              <SectionInfo data={data} loading={StateLoading} />
              <SectionLine data={data?.lines} loading={StateLoading} />
            </div>
            <div className="col-md-4">
              <PODImage data={data} type='recap_rtt' />

              <PODImage data={data} type='recap_receive' />
            </div>
          </div>
        </div>
      </Page.Body>
    </Page>
  );
};

export default DetailScreen;
