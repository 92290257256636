import { $done, $processing } from 'services/activity/action';
import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';
import { $failure, $requesting, $success } from '../form/action';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const MODULE = 'Report';

export const $kanwil = ActionCreator.Async(MODULE, 'Kanwil', (payload) => {
  return async (dispatch) => {
    dispatch($processing($kanwil.OPERATION));

    return await Rest.GET(`${API_URL}/report/kanwil`, payload)
      .then((response) => {
        dispatch($kanwil.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($kanwil.OPERATION)));
  };
});

export const $province = ActionCreator.Async(MODULE, 'Province', (payload) => {
  return async (dispatch) => {
    dispatch($processing($province.OPERATION));

    return await Rest.GET(`${API_URL}/report/province`, payload)
      .then((response) => {
        dispatch($province.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($province.OPERATION)));
  };
});

export const $regency = ActionCreator.Async(MODULE, 'Regency', (payload) => {
  return async (dispatch) => {
    dispatch($processing($regency.OPERATION));

    return await Rest.GET(`${API_URL}/report/regency`, payload)
      .then((response) => {
        dispatch($regency.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($regency.OPERATION)));
  };
});

export const $district = ActionCreator.Async(MODULE, 'District', (payload) => {
  return async (dispatch) => {
    dispatch($processing($district.OPERATION));

    return await Rest.GET(`${API_URL}/report/district`, payload)
      .then((response) => {
        dispatch($district.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($district.OPERATION)));
  };
});

export const $village = ActionCreator.Async(MODULE, 'Village', (payload) => {
  return async (dispatch) => {
    dispatch($processing($village.OPERATION));

    return await Rest.GET(`${API_URL}/report/village`, payload)
      .then((response) => {
        dispatch($village.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($village.OPERATION)));
  };
});

export const $showVillage = ActionCreator.Async(
  MODULE,
  'Show.Village',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($processing($showVillage.OPERATION));

      return await Rest.GET(`${API_URL}/report/village/${id}`, payload)
        .then((response) => {
          dispatch($showVillage.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($showVillage.OPERATION)));
    };
  }
);

export const $getSPMCount = ActionCreator.Async(
  MODULE,
  'Get.SPM.Count',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($getSPMCount.OPERATION));

      return await Rest.GET(`${API_URL}/report/spm/count`, payload)
        .then((response) => {
          dispatch($getSPMCount.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getSPMCount.OPERATION)));
    };
  }
);

export const $reconProvince = ActionCreator.Async(
  MODULE,
  'Recon.Province',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($reconProvince.OPERATION));

      return await Rest.GET(`${API_URL}/report/recon/province`, payload)
        .then((response) => {
          dispatch($reconProvince.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($reconProvince.OPERATION)));
    };
  }
);

export const $reconKanwil = ActionCreator.Async(
  MODULE,
  'Recon.Kanwil',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($reconKanwil.OPERATION));

      return await Rest.GET(`${API_URL}/report/recon/kanwil`, payload)
        .then((response) => {
          dispatch($reconKanwil.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($reconKanwil.OPERATION)));
    };
  }
);

export const $reconRegency = ActionCreator.Async(
  MODULE,
  'Recon.Regency',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($reconRegency.OPERATION));

      return await Rest.GET(`${API_URL}/report/recon/regency`, payload)
        .then((response) => {
          dispatch($reconRegency.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($reconRegency.OPERATION)));
    };
  }
);

export const $accept = ActionCreator.Async(MODULE, 'Accept', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/report/village/${id}/accept`, payload)
      .then((response) => {
        dispatch($accept.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $reject = ActionCreator.Async(MODULE, 'Reject', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/report/village/${id}/reject`, payload)
      .then((response) => {
        dispatch($reject.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $updateRTT = ActionCreator.Async(
  MODULE,
  'UpdateRTT',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.POST(`${API_URL}/report/regency/${id}/rtt`, payload)
        .then((response) => {
          dispatch($updateRTT.success({ response: response }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $dailyUploaded = ActionCreator.Async(
  MODULE,
  'DailyUploaded',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($dailyUploaded.OPERATION));

      return await Rest.GET(`${API_URL}/report/daily/uploaded`, payload)
        .then((response) => {
          dispatch($dailyUploaded.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($dailyUploaded.OPERATION)));
    };
  }
);

export const $dailySPM = ActionCreator.Async(MODULE, 'DailySPM', (payload) => {
  return async (dispatch) => {
    dispatch($processing($dailySPM.OPERATION));

    return await Rest.GET(`${API_URL}/report/daily/spm`, payload)
      .then((response) => {
        dispatch($dailySPM.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($dailySPM.OPERATION)));
  };
});

export const $villageByRTT = ActionCreator.Async(
  MODULE,
  'VillageByRTT',
  (id) => {
    return async (dispatch) => {
      dispatch($processing($villageByRTT.OPERATION));

      return await Rest.GET(`${API_URL}/report/village/${id}/rtt`)
        .then((response) => {
          dispatch($villageByRTT.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($villageByRTT.OPERATION)));
    };
  }
);

export const $chart = ActionCreator.Async(MODULE, 'Chart', () => {
  return async (dispatch) => {
    dispatch($processing($chart.OPERATION));

    return await Rest.GET(`${API_URL}/report/chart`)
      .then((response) => {
        dispatch($chart.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($chart.OPERATION)));
  };
});
