import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import { Provider as StateProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import { getStore, setStore } from './services/store';

import { setConfig } from './utilities/config';

import App from './app';
import bootstrap from './boot';

import 'react-medium-image-zoom/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'ui/themes/styles.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

setConfig();
setStore();

const AppContainer = () => {
  return (
    <StateProvider store={getStore()}>
      <Router>
        <App />
        <ToastContainer limit={1} />
      </Router>
    </StateProvider>
  );
};

ReactDOM.render(<AppContainer />, document.getElementById('root'));

bootstrap();

serviceWorker.unregister();
