import React from 'react';
import { useDispatch } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';
import _ from 'underscore';

import { Select } from 'services/action';

const RemoteCreateable = ({
  name,
  url,
  initialValue,
  row,
  params,
  onSelected,
  placeholder,
  errors,
  label,
  required,
  keys = null,
  disabled = false,
  isMulti,
  isSearch = false,
  hint,
  create,
  onClear,
  horizontal,
  UseOnClear = false,
  privilege,
}) => {
  const Dispatch = useDispatch();
  const [value, setValue] = React.useState(null);

  const customeStyle = {
    option: (styles, { data }) => ({
      ...styles,
      backgroundColor:
        data?.value === 'create' ? 'rgba(42,255,0,0.3 )' : undefined,
    }),
  };

  const loadOptions = async (inputValue, callback) => {
    const p = {
      ...params,
      search: inputValue ? inputValue : params?.search ? params?.search : '',
      page: params?.page || 1,
      limit: params?.limit || 100,
    };

    if (isSearch === true) {
      if (inputValue) {
        const resp = await Dispatch(Select.$get(url, p));

        let needValue = false;
        setValue(null);

        if (
          typeof initialValue !== 'undefined' &&
          typeof initialValue !== 'object' &&
          initialValue !== null
        ) {
          needValue = true;
        }

        let result = [];
        if (resp?.message === 'success') {
          result = _.map(resp?.data, function (d) {
            const no = { ...row(d), original: d };

            if (needValue) {
              if (d?.id === initialValue) {
                setValue(no);
              } else if (d?.village_id === initialValue) {
                setValue(no);
              } else if (d?.regency_id === initialValue) {
                setValue(no);
              }
            }
            return no;
          });
        }
        return result;
      }
    } else {
      const resp = await Dispatch(Select.$get(url, p));

      let needValue = false;
      setValue(null);

      if (
        typeof initialValue !== 'undefined' &&
        typeof initialValue !== 'object' &&
        initialValue !== null
      ) {
        needValue = true;
      }

      let result = [];
      if (resp?.message === 'success') {
        result = _.map(resp?.data, function (d) {
          const no = { ...row(d), original: d };

          if (needValue) {
            if (d?.id === initialValue) {
              setValue(no);
            } else if (d?.village_id === initialValue) {
              setValue(no);
            } else if (d?.regency_id === initialValue) {
              setValue(no);
            }
          }
          return no;
        });
        if (privilege) {
          result?.push({ value: 'create', label: '+ Create New' });
        }
      }

      if (initialValue === 'create') {
        setValue({ value: 'create', label: '+ Create New' });
      }
      return result;
    }
  };

  const handleInputChange = (newValue, { action }) => {
    setValue(newValue);
    onSelected(newValue?.original || newValue);

    if (UseOnClear) {
      if (action === 'clear') {
        setValue(null);
        onSelected(null);
        onClear();
      }
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [keys]);

  return (
    <div className={horizontal ? 'row mb-3' : 'mb-3'}>
      {label ? (
        <label
          className={
            required
              ? 'form-label required'
              : horizontal
              ? 'form-label col-3 col-form-label'
              : 'form-label'
          }
        >
          {label}
        </label>
      ) : null}
      <div className={horizontal ? 'col' : null}>
        <AsyncCreatableSelect
          name={name}
          classNamePrefix={errors ? 'is-invalid' : 'select'}
          cacheOptions
          placeholder={placeholder}
          value={value}
          style={customeStyle}
          loadOptions={loadOptions}
          defaultOptions
          onChange={handleInputChange}
          isClearable
          isSearchable
          className="bg-white"
          isDisabled={disabled}
          key={keys}
          isMulti={isMulti}
        />
        {hint ? <small className="form-hint pt-1">{hint}</small> : null}
        {errors ? (
          <div className="invalid-feedback d-block">{errors}</div>
        ) : null}
      </div>
    </div>
  );
};

export default RemoteCreateable;
