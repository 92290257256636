import config from 'services/table/const';
import Action from './table.action';
import HubStatus from './table.status';

let table = { ...config };

table.url = '/warehouse';
table.columns = {
  code: {
    title: 'Kode',
    width: 100,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
          {row?.code}
        </div>
      );
    },
  },
  name: {
    title: 'Nama',
    sortable: false,
    width: 350,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.name}
          </div>
          {/* <small className="text-ellipsis info fw-semibold text-uppercase">
            {`${row?.region?.district_name}, ${row?.region?.regency_name}, ${row?.region?.kanwil_name}`}
          </small> */}
          <small className="text-ellipsis info fw-semibold text-uppercase">
            {row?.address}
          </small>
        </div>
      );
    },
  },
  kanwil_id: {
    title: 'Area',
    width: 100,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
          {row?.kanwil?.name}
        </div>
      );
    },
  },

  is_active: {
    title: 'Status',
    class: 'text-center',
    classHeader: 'text-center',
    width: 90,
    component: (row) => {
      return <HubStatus data={row} />;
    },
  },
  action: {
    title: '',
    width: 40,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};

export default table;
