import CreateScreen from './create';
import DetailScreen from './detail';
import SpmScreen from './index';
import DeliveryLines from './lines';
import DetailLineScreen from './lines/detail';
import DeliveryTroubleScreen from './trouble';
import UpdateScreen from './update';

const routes = [
  {
    path: '/spm',
    component: SpmScreen,
    usergroup: [
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
  },
  {
    path: '/spm/create',
    component: CreateScreen,
    usergroup: [
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
  },
  {
    path: '/spm/line',
    component: DeliveryLines,
    usergroup: [
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '7ce651f7-5df9-424a-8a82-7d87ffd45107',
      '4df4c118-ed27-40a4-865e-270930637e82'
    ],
  },
  {
    path: '/spm/line/:id',
    component: DetailLineScreen,
    usergroup: [
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '7ce651f7-5df9-424a-8a82-7d87ffd45107',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '8465c143-a4e3-478e-b019-cb4e1035e886',
      '004850c5-ebee-2f68-5cd9-4eb2405cfb5e',
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
    ],
  },
  {
    path: '/spm/trouble',
    component: DeliveryTroubleScreen,
    usergroup: [
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
    ],
  },
  {
    path: '/spm/:id',
    component: DetailScreen,
    usergroup: [
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '8465c143-a4e3-478e-b019-cb4e1035e886',
      '004850c5-ebee-2f68-5cd9-4eb2405cfb5e',
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
    ],
  },
  {
    path: '/spm/:id/update',
    component: UpdateScreen,
    usergroup: [
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
  },
];

export default routes;
