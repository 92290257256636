import React, { Fragment } from 'react';

import cn from 'classnames';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'services/action';
import { Page } from 'ui/components';
import * as Section from './component';
import Breadcrumb from './component/breadcrumbs';
import Table from './component/table';
import DashboardUserWarehouse from './user.national';

const DashboardScreen = () => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.session);
  const [area, setArea] = React.useState(null);
  const [tabKey, setTabKey] = React.useState('kanwil');

  const [kanwil, setKanwil] = React.useState(null);
  const [regency, setRegency] = React.useState(null);
  const [district, setDistrict] = React.useState(null);
  const [keys, setKeys] = React.useState('regency');

  const isFixed = window.innerWidth > 770 ? true : false;

  const $show = async () => {
    const req = await Dispatch(User.$getArea(Session?.user?.id));

    if (req?.message === 'success') {
      setKeys('regency');
      setArea(req?.data);
      if (req?.data) {
        setKanwil(req?.data[0]);
        setRegency(null);
        setDistrict(null);
      }
    }
  };

  const $handleKanwil = async (e) => {
    setKeys('regency');
    setKanwil(e);
    setRegency(null);
    setDistrict(null);
  };

  const $handleRegency = async (e) => {
    setKeys('district');
    setRegency(e);
    setDistrict(null);
  };

  const $handleDistrict = async (e) => {
    setKeys('village');
    setDistrict(e);
  };

  React.useEffect(() => {
    $show();
  }, [Session]);

  if (area) {
    return (
      <Page className="fixed bg-white">
        <Breadcrumb
          kanwil={kanwil}
          regency={regency}
          district={district}
          onKanwilChange={$handleKanwil}
          onRegencyChange={$handleRegency}
          onDistrictChange={$handleDistrict}
        />
        <Page.Body className="bg-light mt-2 mt-md-0">
          <Table
            keys={keys}
            kanwil={kanwil}
            regency={regency}
            district={district}
            onRegencySelected={$handleRegency}
            onDistrictSelected={$handleDistrict}
          />
        </Page.Body>
      </Page>
    );
  }

  return (
    <Fragment>
      <Page className={cn(isFixed ? 'detail-fixed bg-white' : '')}>
        <Page.Body className="bg-light mt-2 mt-md-0">
          <DashboardUserWarehouse />
        </Page.Body>
        <Tabs
          id="controlled-tab-example"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
        >
          <Tab eventKey="kanwil" title="Berdasarkan Kanwil">
            <Section.CardCounter />
          </Tab>
          <Tab eventKey="provinsi" title="Berdasarkan Provinsi">
            <Section.CardCounterProvince />
          </Tab>
          {Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && (
            <Tab eventKey="document" title="Berdasarkan Rekon">
              <Section.CardCounterDocument />
            </Tab>
          )}

        </Tabs>
      </Page>
    </Fragment>
  );
};

export default DashboardScreen;
