import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Report } from 'services/action';
import { SkeletonTable } from 'ui/components/skeleton';
import _ from 'underscore';
import { currencyFormat, decimalFormat } from 'utilities/common';
import CountUpComponent from '../countup';

const TableRegencyRecon = ({ keys, onSelected }) => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const Bansos = useSelector((state) => state?.Auth?.project);
  const [data, setData] = React.useState(null);
  const [recap, setRecap] = React.useState(null);

  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Report.Regency']
  );

  const $getData = async () => {
    if (!keys) return;

    const filters = {
      bansos_id: Bansos?.id,
      kanwil_id: keys?.kanwil_id,
      page: 1,
      limit: 1000,
    };

    const req = await Dispatch(Report.$reconRegency(filters));

    if (req?.message === 'success') {
      setData(req?.data);

      var total_kpm = _.reduce(
        req?.data,
        function (num, i) {
          return i?.total_kpm + num;
        },
        0
      );

      var total_uploaded = _.reduce(
        req?.data,
        function (num, i) {
          return i?.total_uploaded + num;
        },
        0
      );

      var total_approved = _.reduce(
        req?.data,
        function (num, i) {
          return i?.total_approved + num;
        },
        0
      );

      setRecap({
        total_kpm,
        total_uploaded,
        total_approved,
        percent: (total_uploaded / total_kpm) * 100,
      });
    }
  };

  React.useEffect(() => {
    $getData();
  }, [keys]);

  return (
    <div className="bg-white">
      {StateLoading ? (
        <SkeletonTable headerCount={9} bodyCount={9} />
      ) : (
        <div>
          <div className="row">
            <div
              className="col-md-4 col-12 border border-1 border-white text-center text-white align-items-center justify-content-center d-flex flex-column"
              style={{
                background: `linear-gradient(90deg,  ${
                  recap?.percent > 70
                    ? 'rgba(47,179,68,1)'
                    : 'rgba(246,103,7,1)'
                } 0%, ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(152,139,36,1)'
                    : 'rgba(231,81,31,1)'
                } ${recap?.percent}%, ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(238,106,9,1)'
                    : 'rgba(216,59,55,1)'
                } ${(recap?.percent + 6).toFixed(0)}%,  ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(246,103,7,1)'
                    : 'rgba(214,57,57,1)'
                } 100%)`,
              }}
            >
              <CountUpComponent
                starting={0}
                ending={decimalFormat(recap?.percent || 0)}
                fs={80}
                surfix=" %"
                decimals={2}
                className="percentage mt-n3 text-white"
              />
            </div>
            <div
              className="col-md-4 col-12 border border-1 border-white text-center text-white align-items-center justify-content-center d-flex flex-column"
              style={{
                background: `linear-gradient(90deg,  ${
                  recap?.percent > 70
                    ? 'rgba(47,179,68,1)'
                    : 'rgba(246,103,7,1)'
                } 0%, ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(152,139,36,1)'
                    : 'rgba(231,81,31,1)'
                } ${recap?.percent}%, ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(238,106,9,1)'
                    : 'rgba(216,59,55,1)'
                } ${(recap?.percent + 6).toFixed(0)}%,  ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(246,103,7,1)'
                    : 'rgba(214,57,57,1)'
                } 100%)`,
              }}
            >
              <h3 className="text-white">Total Uploaded</h3>
              <span className="d-flex align-items-baseline justify-content-center ">
                <CountUpComponent
                  starting={0}
                  ending={recap?.total_uploaded}
                  fs={35}
                />{' '}
                <span
                  style={{
                    fontSize: 18,
                    lineHeight: '20px',
                    fontWeight: 'bold',
                    paddingLeft: 0,
                  }}
                >
                  {' '}
                  / {currencyFormat(recap?.total_kpm)} PBP
                </span>
              </span>
            </div>
            <div
              className="col-md-4 col-12 border border-1 border-white text-center text-white align-items-center justify-content-center d-flex flex-column"
              style={{
                background: `linear-gradient(90deg,  ${
                  recap?.percent > 70
                    ? 'rgba(47,179,68,1)'
                    : 'rgba(246,103,7,1)'
                } 0%, ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(152,139,36,1)'
                    : 'rgba(231,81,31,1)'
                } ${recap?.percent}%, ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(238,106,9,1)'
                    : 'rgba(216,59,55,1)'
                } ${(recap?.percent + 6).toFixed(0)}%,  ${
                  recap?.percent > 70
                    ? recap?.percent === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(246,103,7,1)'
                    : 'rgba(214,57,57,1)'
                } 100%)`,
              }}
            >
              <h3 className="text-white">Total Disetujui</h3>
              <span className="d-flex align-items-baseline justify-content-center ">
                <CountUpComponent
                  starting={0}
                  ending={recap?.total_approved}
                  fs={35}
                />{' '}
                <span
                  style={{
                    fontSize: 18,
                    lineHeight: '20px',
                    fontWeight: 'bold',
                    paddingLeft: 0,
                  }}
                >
                  {' '}
                  / {currencyFormat(recap?.total_kpm)} PBP
                </span>
              </span>
            </div>
          </div>

          <div
            className="table-responsive m-0 table-overflow"
            style={{ minHeight: '60vh' }}
          >
            <table
              width="100%"
              className="table table-hover table-vcenter card-table datatable"
            >
              <thead>
                <tr>
                  <th width={300}>Kota/Kabupaten</th>
                  <th className="text-end">PBP</th>
                  <th className="text-end">Uploaded</th>
                  <th className="text-end">Disetujui</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row, i) => (
                  <tr key={i} onClick={() => History.push(`/report/recon/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency?.id}`)}>
                    <td>
                      <div className="table-col">
                        <div className="text-ellipsis">
                          {row?.regency?.name}
                        </div>
                        <small className="info text-ellipsis">
                          {row?.regency?.kanwil?.name}
                        </small>
                      </div>
                    </td>
                    <td className="text-end">
                      <div className="table-col">
                        {currencyFormat(row?.total_kpm)}
                      </div>
                    </td>
                    <td className="text-end">
                      <div className="table-col">
                        <span className="fw-semibold">
                          {currencyFormat(row?.total_uploaded)}
                        </span>
                        {` (${(
                          (row?.total_uploaded / row?.total_kpm) *
                          100
                        ).toFixed(2)}%)`}
                      </div>
                    </td>
                    <td className="text-end">
                      <div className="table-col">
                        <span className="fw-semibold">
                          {currencyFormat(row?.total_approved)}
                        </span>
                        {` (${(
                          (row?.total_approved / row?.total_kpm) *
                          100
                        ).toFixed(2)}%)`}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableRegencyRecon;
