import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Recon, Report } from 'services/action';
import { Page } from 'ui/components';
import Datagrid from 'ui/components/data/grid';
import { currencyFormat } from 'utilities/common';
import ActionApprove from './component/table/accept';
import ActionUpload from './component/table/upload';
import ActionReport from './component/table/report'
import ActionSorting from './component/table/sorting';
import ActionDeletePOD from './component/table/delete_pod';

const initialState = {
  area: null,
  data: [],
  report: null,
};
const ReconDetailScreen = ({ match }) => {
  const Dispatch = useDispatch();

  const Bansos = useSelector((state) => state?.Auth?.project);
  const Session = useSelector((state) => state.Auth?.session);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Report.Show.Village']
  );

  const [{ data, report, area }, setState] = React.useState(initialState);

  const $onLoad = async () => {
    let query = {
      bansos_id: Bansos?.id,
      kanwil_id: report?.village?.district?.regency?.kanwil?.id,
      regency_id: report?.village?.district?.regency?.id,
      district_id: report?.village?.district?.id,
      village_id: report?.village?.id,
      order_by: '-line:alias_id'
    };

    const req = await Dispatch(Recon.$get(query));

    if (req?.message === 'success') {
      setState((prevState) => ({ ...prevState, data: req?.data }));
    }
  };

  const $refresh = async (e) => {
    const res = await Dispatch(Report.$showVillage(e));

    if (res?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        report: res?.data,
        area: {
          kanwil: {
            kanwil: res?.data?.village?.district?.regency?.kanwil,
          },
          regency: { regency: res?.data?.village?.district?.regency },
          district: { district: res?.data?.village?.district },
          village: res?.data,
        },
      }));
    }
  };

  const $refreshVillageLine = async () => {
    $refresh(area?.village?.id);
    $onLoad();
  };

  React.useEffect(() => {
    $refresh(match?.params?.id);
  }, [match]);

  React.useEffect(() => {
    if (area?.village) {
      $onLoad();
    }
  }, [area]);

  return (
    <Page className="">
      <Page.Header
        title="Recon"
        isLoading={StateLoading}
        backTo="/report/distribusi/village"
      />
      <div className="row bg-white px-4 py-3">
        <div className="col-8">
          <div className="row">
            <div className="col-6">
              <Datagrid
                loading={StateLoading}
                title="Kanwil"
                value={report?.village?.district?.regency?.kanwil?.name}
              />
            </div>
            <div className="col-6">
              <Datagrid
                loading={StateLoading}
                title="Kab./ Kota"
                value={report?.village?.district?.regency?.name}
              />
            </div>
            <div className="col-6">
              <Datagrid
                loading={StateLoading}
                title="Kecamatan"
                value={report?.village?.district?.name}
              />
            </div>
            <div className="col-6">
              <Datagrid
                loading={StateLoading}
                title="Kelurahan"
                value={report?.village?.name}
              />
            </div>
          </div>
          {/* <ActionArcon data={report} onClose={() => $refreshVillageLine()} /> */}
        </div>
        <div className="col-4">
          <div className="row p-2 mb-3 mb-md-0  justify-content-center">
            <div className="rounded-2 border bg-success-lt text-center p-2 col-6 col-md-12 mb-3">
              <div className="fs-5 fw-semibold text-left text-secondary">
                {report?.total_kpm || 0}
              </div>
              <div className="page-pretitle text-left">total pbp</div>
            </div>
            <div className="rounded-2 border bg-light-lt text-center p-2 col-6 col-md-6 mb-3">
              <div className="fs-5 fw-semibold text-left text-secondary">
                {report?.total_shipped || 0}
              </div>
              <div className="page-pretitle text-left">TOTAL DIKIRIM</div>
            </div>
            <div className="rounded-2 border bg-light-lt text-center p-2 col-6 col-md-6 mb-3">
              <div className="fs-5 fw-semibold text-left text-secondary">
                {report?.total_delivered || 0}
              </div>
              <div className="page-pretitle text-left">TOTAL TERKIRIM</div>
            </div>
          </div>
        </div>
      </div>
      <Page.Body className="row m-0 p-0">
        {StateLoading ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
            </div>
          </div>
        ) : data?.length === 0 || !data ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
              <p className="empty-title">Belum ada pengiriman</p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-6">
              <div className="table-responsive bg-white">
                <table
                  width="100%"
                  className="table table-hover table-vcenter card-table datatable border"
                >
                  <thead className="bg-light">
                    <tr className="th">
                      <td style={{ whiteSpace: 'nowrap', width: '30%' }}></td>
                      <td
                        style={{ whiteSpace: 'nowrap', width: '30%' }}
                        className="text-center"
                      >
                        Total Paket
                      </td>
                      <td
                        style={{ whiteSpace: 'nowrap', width: '30%' }}
                        className="text-center"
                      >
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((d, i) => (
                      <>
                        <tr key={i} className="border-bottom-0 border-white">
                          <td>
                            <div className="table-col ps-2">
                              <div className="row">
                                <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
                                  {d?.line?.manifest}
                                </div>
                                <small className="info text-ellipsis">
                                  {d?.village?.name},{' '}
                                  {d?.village?.district?.name},{' '}
                                  {d?.village?.district?.regency?.name},{' '}
                                  {d?.village?.district?.regency?.kanwil?.name}
                                </small>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Lihat SPM Berdasarkan Tujuan
                                </Tooltip>
                              }
                            >
                              <Link
                                className={`btn ${d?.line?.status === 'new' ? 'btn-default' : 'btn-success'} px-5`}
                                role="button"
                                to={`/spm/line/${d?.line?.id}`}
                              >
                                <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
                                  {currencyFormat(d?.line?.total_package)}
                                </div>
                              </Link>
                            </OverlayTrigger>
                          </td>
                          {i === 0 && (
                            <td className="text-center" >
                              <ActionReport
                                data={report}
                                type="receive"
                                accepted={report?.stt_status === 'accepted'}
                                rejected={report?.stt_status === 'rejected'}
                                uploaded={report?.upload_stt}
                                onClose={() => $refreshVillageLine()}
                              />

                              {report?.stt_reason && (
                                <div className='border-top mt-2 text-start'>
                                  <span className='info fs-9'>
                                    Alasan: <p className='mb-0'>{report?.stt_reason}</p>
                                  </span>
                                </div>
                              )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td className="justify-content-end" colSpan={3}>
                            <ActionUpload
                              data={d}
                              type="receive"
                              isLine
                              received={report?.stt_status !== ''}
                              status={report?.stt_status}
                              onClose={() => $refreshVillageLine()}
                            />
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6">
              <div className="table-responsive bg-white">
                <table
                  width="100%"
                  className="table table-hover card-table datatable border"
                >
                  <thead className="bg-light">
                    <tr className="th">
                      <td
                        style={{ whiteSpace: 'nowrap', width: '20%' }}
                        className="text-center"
                      >
                        BAST
                      </td>
                      <td
                        style={{ whiteSpace: 'nowrap', width: '20%' }}
                        className="text-center"
                      >
                        SPTJM
                      </td>
                      <td
                        style={{ whiteSpace: 'nowrap', width: '20%' }}
                        className="text-center"
                      >
                        BNBA
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <ActionReport
                          data={report}
                          type="bast"
                          accepted={report?.bast_status === 'accepted'}
                          rejected={report?.bast_status === 'rejected'}
                          uploaded={report?.upload_bast}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                      <td className="text-center">
                        <ActionReport
                          data={report}
                          type="sptjm"
                          accepted={report?.sptjm_status === 'accepted'}
                          rejected={report?.sptjm_status === 'rejected'}
                          uploaded={report?.upload_sptjm}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                      <td className="text-center">
                        <ActionReport
                          data={report}
                          type="rtt"
                          accepted={report?.rtt_status === 'accepted'}
                          rejected={report?.rtt_status === 'rejected'}
                          uploaded={report?.upload_rtt}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <ActionUpload
                          data={report}
                          type="bast"
                          received={report?.bast_status !== ''}
                          status={report?.bast_status}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                      <td className="text-center">
                        <ActionUpload
                          data={report}
                          type="sptjm"
                          received={report?.sptjm_status !== ''}
                          status={report?.sptjm_status}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                      <td className="text-center">
                        <ActionUpload
                          data={report}
                          type="rtt"
                          received={report?.rtt_status !== ''}
                          status={report?.rtt_status}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                    </tr>
                    {report?.upload_rtt && (
                      <>
                        <tr className='border-bottom-0 border-white'>
                          <td colSpan={3}>
                            <ActionSorting
                              type="rtt"
                              data={report}
                              onClose={$refreshVillageLine}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <ActionDeletePOD
                              type="rtt"
                              data={report}
                              received={report?.rtt_status !== ''}
                              status={report?.rtt_status}
                              onClose={$refreshVillageLine}
                            />
                          </td>
                        </tr>
                      </>
                    )}

                    {(report?.bast_reason || report?.sptjm_reason || report?.rtt_reason) && (
                      <tr>
                        <td className="text-left border-end">
                          {report?.bast_reason && (
                            <span className='info fs-9'>
                              Alasan: <p className='mb-0'>{report?.bast_reason}</p>
                            </span>
                          )}
                        </td>
                        <td className="text-left border-end">
                          {report?.sptjm_reason && (
                            <span className='info fs-9'>
                              Alasan: <p className='mb-0'>{report?.sptjm_reason}</p>
                            </span>
                          )}
                        </td>
                        <td className="text-left">
                          {report?.rtt_reason && (
                            <span className='info fs-9'>
                              Alasan: <p className='mb-0'>{report?.rtt_reason}</p>
                            </span>
                          )}
                        </td>
                      </tr>
                    )}

                    {Session?.usergroup?.id ===
                      '302d772c-77db-47ea-ab5a-83b6be4d3787' && (
                      <tr>
                        <td colSpan={3}>
                          <ActionApprove
                            data={report}
                            type="arcon"
                            accepted={Session?.usergroup?.id !==
                              '302d772c-77db-47ea-ab5a-83b6be4d3787'}
                            onClose={() => $refreshVillageLine()}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Page.Body>
    </Page>
  );
};

export default ReconDetailScreen;
