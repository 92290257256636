import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from 'services/action';

const initialState = {
  isAccept: false,
};

const docType = {
  bast: 'BAST',
  sptjm: 'SPTJM',
  rtt: 'BNBA / DTT',
  receive: 'STT / SPM',
};

const Accept = ({ data, type, onClose, accepted, rejected }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const Session = useSelector((state) => state.Auth?.session);
  const [{ isAccept }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({ ...prevState, isAccept: false }));
  };

  const $handleAccept = async () => {
    const payload = {
      type: type,
    };

    const result = await Dispatch(Report.$accept(data?.id, payload));
    if (result?.message === 'success') {
      $handleClose();
      onClose();
    }
  };

  if (accepted) {
    return null
  }

  return (
    <>
      {type === 'arcon' ? (
        <>
          {!data?.is_arcon ? (
            <button
              className="btn btn-sm btn-info rounded-2 col-12"
              onClick={() =>
                setState((prevState) => ({ ...prevState, isAccept: true }))
              }
            >
              <Fi.FiCheck size={15} className="m-1" />
              Terima
            </button>
          ) : (
            <button
              className="btn btn-sm btn-danger rounded-2 col-12"
              onClick={() =>
                setState((prevState) => ({ ...prevState, isAccept: true }))
              }
            >
              <Fi.FiX size={15} className="m-1" />
              Batal Terima
            </button>
          )}
        </>
      ): (
        <>
          {(Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2') && (
            <button
              className="btn btn-sm btn-info rounded-2 mt-2"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isAccept: true,
                }))
              }
            >
              <Fi.FiCheck size={15} className={rejected? 'me-1': ''}  />
              {rejected ? 'Setujui Data': ''}
            </button>
          )}
        </>
      )}

      {/* modal accept */}
      <Modal
        show={isAccept}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-info" />
          <Fi.FiAlertTriangle className="mb-2 text-info" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            {type === 'arcon' ?
              !data?.is_arcon ? 'Akan menkonfirmasi bahwa Anda telah menerima dokumen BAST, BNBA, SPTJM' : 'Akan menkonfirmasi bahwa Anda belum menerima Dokumen BAST, BNBA, SPTJM'
              : `Akan menkonfirmasi bahwa Anda telah menerima dan memvalidasi Dokumen ${docType[type]}`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="info"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleAccept}
                disabled={StateForm?.loading}
              >
                Konfirmasi
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Accept;
