import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Skeletons } from 'ui/components/skeleton';

const Maps = React.lazy(() => import('ui/components/mapbox/map.indonesia_v2'));

const CardMap = ({ isLoading }) => {
  const Session = useSelector((state) => state?.Auth?.project);

  return isLoading ? (
    <Skeletons height="450px" />
  ) : (
    <div className="card p-3 rounded-2 bg-white h-100">
      <Suspense fallback={<Skeletons height="450px" />}>
        <Maps bansos_id={Session?.id} />
      </Suspense>
    </div>
  );
};

export default CardMap;
