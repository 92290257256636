import config from 'services/table/const';
import { currencyFormat } from 'utilities/common';
import Action from './action';

let table = { ...config };
table.url = '/report/regency';
table.columns = {
  code: {
    title: 'Kota/Kabupaten',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.regency?.name}</div>
          <small className="info text-ellipsis">
            {row?.regency?.kanwil?.name}
          </small>
        </div>
      );
    },
    url: (row) => {
      return `/report/distribusi/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },

  total_kpm: {
    title: 'Target',
    titleChild: '1',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return <div className="table-col">{currencyFormat(row?.total_kpm)}</div>;
    },
    url: (row) => {
      return `/report/distribusi/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },

  total_shipped: {
    title: 'Realisasi',
    titleChild: '2 = 2/1',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_shipped)}</span>
          {` (${((row?.total_shipped / row?.total_kpm) * 100).toFixed(2)}%)`}
        </div>
      );
    },
    url: (row) => {
      return `/report/distribusi/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },

  total_delivered: {
    title: 'Diterima',
    titleChild: '3 = 3/1',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_delivered)}</span>
          {` (${((row?.total_delivered / row?.total_kpm) * 100).toFixed(2)}%)`}
        </div>
      );
    },
    url: (row) => {
      return `/report/distribusi/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },

  total_delivered_realisasi: {
    title: 'Realisasi Diterima',
    titleChild: '4 = 4/2',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_delivered)}</span>
          {row?.total_shipped > 0 ? ` (${((row?.total_delivered / row?.total_shipped) * 100).toFixed(2)}%)`: ' (0%)'}
        </div>
      );
    },
    url: (row) => {
      return `/report/distribusi/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },

  actions: {
    title: 'Dokumen Pendukung',
    width: 100,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};

export default table;
