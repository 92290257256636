import TransferScreen from './index';

const routes = [
  {
    path: '/transfer',
    component: TransferScreen,
    usergroup: [
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
    ],
  },
];

export default routes;
