/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import MenuRoute from "./menu.route";

const Menu = ({ fluid }) => {
  const History = useHistory();
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <div className="navbar-expand-md d-none d-md-block">
      <div className="navbar-collapse collapse" id="navbar-menu">
        <div className="navbar navbar-light">
          <div className={fluid ? "container-fluid" : "container-xl"}>
            <ul className="navbar-nav">
              {MenuRoute.map((menu, index) => {
                return (
                  <li key={index} className="nav-item">
                    {!menu?.isDropdown ? (
                      <NavLink
                        to={menu.path}
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) => {
                          const splitLocation = location.pathname.split("/");
                          if (splitLocation[1] === menu.isActive) {
                            return true;
                          }
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            {menu.icon}
                          </span>
                          <span className="nav-link-title">{menu?.title}</span>
                        </div>
                      </NavLink>
                    ) : (
                      <Nav>
                        <NavDropdown
                          id="nav-dropdown-dark-example"
                          title={
                            <div className="d-flex justify-content-center">
                              <span className="nav-link-icon d-md-none d-lg-inline-block">
                                {menu?.icon}
                              </span>
                              <span className="nav-link-title">
                                {menu?.title}
                              </span>
                            </div>
                          }
                        >
                          {menu?.child.map((c, index) => {
                            return (
                              <NavDropdown.Item
                                key={index}
                                onClick={() => History.push(c?.path)}
                                active={splitLocation[1] === c?.isActive}
                              >
                                {c?.title}
                              </NavDropdown.Item>
                            );
                          })}
                        </NavDropdown>
                      </Nav>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
