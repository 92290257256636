import config from 'services/table/const';

let table = { ...config };
table.url = '/usergroup';
table.columns = {
  name: {
    title: 'Nama Usergroup',
    sortable: true,
    component: (row) => {
      return <div className="text-ellipsis">{row?.name}</div>;
    },
  },
};

export default table;
