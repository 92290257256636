import React from 'react';
import Select from 'react-select';

const Options = ({
  option,
  label,
  required,
  error,
  placeholder,
  onSelected,
  initialValue,
  isMulti,
}) => {
  const [value, setValue] = React.useState(null);

  const capitalizeLabel = (e) => {
    return e.charAt(0).toUpperCase() + e.slice(1);
  };

  const op = () => {
    let opt = [];
    if (option) {
      option.forEach((item) => {
        opt.push({ value: item, label: capitalizeLabel(item) });
      });
    }
    return opt;
  };

  const handleInputChange = (newValue) => {
    setValue(newValue);
    if (isMulti) {
      onSelected({ ...value, ...(newValue || null) });
    } else {
      onSelected(newValue?.value || null);
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, []);

  return (
    <>
      {label ? (
        <label className={required ? 'form-label required' : 'form-label'}>
          {label}
        </label>
      ) : null}
      <Select
        classNamePrefix={error ? 'is-invalid' : 'select'}
        placeholder={placeholder}
        options={op()}
        value={value}
        onChange={handleInputChange}
        defaultOptions
        isClearable
        isSearchable
        className="bg-white"
        isMulti={isMulti}
      />
      {error ? <div className="invalid-feedback d-block">{error}</div> : null}
    </>
  );
};

export default Options;
