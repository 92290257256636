import React from 'react';
import { Skeletons } from 'ui/components/skeleton';
import Chart from 'react-apexcharts';

const CardPie = ({ data }) => {
  const [options, setOptions] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const defaultOption = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '14px',
            color: '#202020',
            offsetY: 5,
            fontFamily: 'Inter',
            fontWeight: 'bold',
          },
          value: {
            show: false,
          },
        },
        track: {
          background: '#F8F8F8',
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    colors: ['#f16821'],
  };

  React.useEffect(() => {
    if (!data) return null;

    setLoading(true);
    let x = ((data?.total_uploaded_rtt / data?.total_kpm) * 100).toFixed(2);
    let opt = defaultOption;
    opt.labels = [`${parseFloat(x)} %`];
    setOptions(opt);
  }, [data]);

  React.useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 1000);
  }, [loading]);

  return loading || !data ? (
    <Skeletons height="150px" />
  ) : (
    <div id={`id-${data?.id}`}>
      <Chart
        options={options}
        series={[
          ((data?.total_uploaded_rtt / data?.total_kpm) * 100).toFixed(2),
        ]}
        type="radialBar"
        height={180}
      />
    </div>
  );
};

export default CardPie;
