import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Delivery } from 'services/action';
import { Button, Page } from 'ui/components';
import { FormDeliveryLines, FormGeneral } from './component/form';

const initialState = {
  data: null,
  general: null,
  region: null,
  lines: null,
};

const UpdateScreen = ({ match }) => {
  const History = useHistory();
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state?.Form);
  const Session = useSelector((state) => state?.Auth);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Delivery.Show']
  );

  const [{ general, region, lines, data }, setState] = React.useState(initialState);

  const $handleSubmit = async () => {
    const payload = {
      ref_code: general?.ref_code,
      driver: general?.driver,
      warehouse_id: general?.warehouse?.id,
      doc_outs: general?.doc_outs,
      lines,
      region,
      dispatch_at: general?.dispatch_at?.formatted,
    };

    if (Session?.project) {
      payload.bansos_id = Session?.project?.id;
    }

    if (Session?.session?.warehouse) {
      payload.warehouse_id = Session?.session?.warehouse?.id;
    }

    const req = await Dispatch(Delivery.$update(data?.id, payload));

    if (req?.message === 'success') {
      History.push(`/spm/${req?.data?.id}`);
    }
  };

  const $onLoad = async (id) => {
    const req = await Dispatch(Delivery.$show(id, { load_kpm: true }));

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        data: req?.data,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data: null,
      }));
    }
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, [match]);

  return (
    <Page
      type="form"
      className="detail-fixed bg-white"
      onSubmit={$handleSubmit}
    >
      <Page.Header title="Perbaharui SPM" className="bg-light" backTo="/spm" />
      <Page.Body className="row px-3">
        <div
          className="col-12 col-md-4 position-md-fixed "
          style={{ paddingRight: 10 }}
        >
          <FormGeneral
            onInputChange={(e) =>
              setState((prevState) => ({ ...prevState, general: e }))
            }
            isLoading={StateLoading}
            data={data}
            lines={lines}
          />
        </div>
        <div className="col-md-8 col-12 offset-md-4 border-start border-sm-none ps-0 pe-0 position-relative">
          <div className="py-md-3 ps-md-3 ">
            <FormDeliveryLines
              onFormChange={(e) => {
                setState((prevState) => ({ ...prevState, lines: e }));
              }}
              onRegionChange={(e) => {
                setState((prevState) => ({ ...prevState, region: e }));
              }}
              data={data}
            />
          </div>
        </div>
      </Page.Body>
      <div className="justify-content-center gap-2 pt-3 border-top  d-md-flex d-block mb-3 ">
        <Button
          type="button"
          className="btn-secondary px-4 py-3 mb-3 mb-md-0"
          onClick={() => {
            History.goBack();
          }}
        >
          Batal &amp; Kembali
        </Button>
        <Button
          type="button"
          loading={StateForm?.loading}
          className="btn-primary px-4 py-3"
          onClick={$handleSubmit}
        >
          Simpan
        </Button>
      </div>
    </Page>
  );
};

export default UpdateScreen;
