export const SectionLine = ({ data }) => {
  return (
    <div className="p-0 p-md-3 mb-3 mb-md-0">
      <div className="card rounded-2 bg-white">
        <div className="card-header-gray p-3 border-bottom">
          <h3 className="card-title dx-card-title m-0 p-0">Daftar Kecamatan</h3>
        </div>
        <div className="card-body p-3 pt-3">
          <div className="table-responsive bg-white">
            <table
              width="100%"
              className="table table-hover table-vcenter card-table datatable border"
            >
              <thead className="bg-light">
                <tr className="th">
                  <td style={{ whiteSpace: 'nowrap', width: '5%' }}>No</td>
                  <td style={{ whiteSpace: 'nowrap', width: '15%' }}>Kecamatan</td>
                  <td style={{ whiteSpace: 'nowrap', width: '15%' }}>PBP</td>
                </tr>
              </thead>

              <tbody>
                {data?.map((d, i) => (
                  <tr
                    key={i}
                  >
                    <td>{i + 1}</td>
                    <td>
                      <span className="d-block fs-8 fw-semibold">
                        {d?.report_district?.district?.name}
                      </span>
                    </td>
                    <td>
                      <span className="d-block fs-8 fw-semibold">
                        {d?.report_district?.total_kpm}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
