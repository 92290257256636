import { connect } from 'react-redux';

import Authorized from './ui/screens/authorized/router';
import Unauthorized from './ui/screens/unauthorized/router';

import 'react-medium-image-zoom/dist/styles.css';
import SplashScreen from './ui/screens/splash';

const App = ({ appReady, isAuthenticated }) => {
  const Ready = () => {
    if (!appReady) return <SplashScreen />;

    return isAuthenticated ? <Authorized /> : <Unauthorized />;
  };

  return <Ready />;
};

const mapStateToProps = (state) => {
  return {
    appReady: state.App.isReady,
    isAuthenticated: state.Auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(App);
