import React from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { Report, Rtt } from 'services/action';

import bapang from 'ui/assets/badanpangan.png';
import bulog from 'ui/assets/logo_bulog.png';
import logoBlack from 'ui/assets/logo_jpl.png';
import { Print } from 'ui/components';
import { currencyFormat } from 'utilities/common';

import _ from 'underscore';

const initialState = {
  code: '',
  kpm: '',
  districts: null,
  villages: null,
  data: null
};

const PrintRecap = ({ match, location }) => {
  const Dispatch = useDispatch();
  const Project = useSelector((state) => state?.Auth?.project);

  const [loading, setLoading] = React.useState(null)
  const [vnos, setVnos] = React.useState(null);
  const [vpages, setVpages] = React.useState(null);
  const [dpages, setDpages] = React.useState(null);
  const [vfp] = React.useState(23);
  const [vlimit] = React.useState(23);
  const [dfp] = React.useState(20);
  const [dlimit] = React.useState(20);
  const [{ kpm, villages, districts, data }, setState] = React.useState(initialState);

  const $onLoad = async (id) => {
    const detail = await Dispatch(Rtt.$show(id));
    if (detail?.message === 'success') {
      appendDistrict(detail?.data);

      $villageByRTT(id);
    }
  }

  const $villageByRTT = async(id) => {
    const detail = await Dispatch(Report.$villageByRTT(id));
    if (detail?.message === 'success') {
      setState((prevState) => ({...prevState, villages: detail?.data}));
    }
  }

  const appendDistrict = async(data) => {
    let newdistricts = [];
    _.forEach(data?.lines, function(i) {
      newdistricts.push(i.report_district)
    })

    setState((prevState) => ({...prevState, districts: newdistricts, data: data}));
  }

  const $pagesVillage = async () => {
    if (villages) {
      let vpages = _.chunk(villages, vfp);
      if (villages?.length > vfp) {
        let first = vpages[0];
        let last = _.rest(villages, first?.length);
        vpages = _.chunk(last, vlimit);
        vpages.unshift(first);
      } else {
        setVpages(vpages);
      }

      const vlgs = villages.reduce((group, rv) => {
        const { village } = rv;
        group[village?.district?.id] = 1;
        return group;
      }, {});

      let no = 1;
      _.each(vlgs, function(i, k) {
        vlgs[k] = no
        no++
      })

      setVnos(vlgs);
      setVpages(vpages);
    }
  }

  const $pagesDistrict = async () => {
    if (districts) {
      let dpages = _.chunk(districts, dfp);
      if (districts?.length > dfp) {
        let first = dpages[0];
        let last = _.rest(districts, first?.length);
        dpages = _.chunk(last, dlimit);
        dpages.unshift(first);
      } else {
        setDpages(dpages);
      }

      let kpm = 0;
      _.each(districts, function(i, k) {
        kpm += i?.total_kpm
      })

      setState((prevState) => ({
        ...prevState,
        kpm,
      }));

      setDpages(dpages);
    }
  }

  React.useEffect(() => {
    setLoading(true);
    $onLoad(match?.params?.id);
  }, [match]);

  React.useEffect(() => {
    if (villages) {
      $pagesVillage();
    }

    if (districts) {
      $pagesDistrict();
    }
  }, [villages, districts])


  React.useEffect(() => {
    if(vpages && dpages) {
      setLoading(false);
    }

  }, [vpages, dpages])

  if (loading || districts === null || villages === null) return null;

  const alokasi = {
    'aa93be9f-5841-4fb2-93f4-1e2ba8dba54a': 'Maret',
    '2acec690-a2a2-4257-b44a-9fb7b4e37fcf': 'April',
    'a7330f07-1505-4e0a-bf9f-4af347bc8389': 'Mei',
  };

  return (
    <div>
      {!districts || districts === null ? null : (
        <Print
          title={`REKAP BAST - ${data?.region?.regency_name} (${data?.code})`}
          size="A4"
          content={renderToString(
            <>
              {dpages?.map((page, id) => (
                <section
                  className=" sheet page-break"
                  style={{ padding: '30px 40px', position: 'relative' }}
                >
                  {id === 0 && (
                    <>
                      <table className="body">
                        <tr className="">
                          <td className="left" style={{ width: '33%' }}>
                            <img
                              src={bapang}
                              alt=""
                              style={{ maxHeight: 70, maxWidth: '100%' }}
                            />
                          </td>
                          <td className="center" style={{ width: '33%' }}>
                            <img
                              src={bulog}
                              alt=""
                              style={{ maxHeight: 50, maxWidth: '100%' }}
                            />
                          </td>
                          <td className="right" style={{ width: '33%' }}>
                            <img
                              src={logoBlack}
                              alt=""
                              style={{ maxHeight: 50, maxWidth: '100%' }}
                            />
                          </td>
                        </tr>
                      </table>
                      <div
                        className="d-flex flex-row justify-content-between"
                        style={{ paddingBottom: 10, marginTop: 10 }}
                      >
                        <div style={{ width: '100%' }}>
                          <h2 className="mb-0 uppercase text-center">
                            rekapitulasi bast
                          </h2>
                          <h2 className="mb-0  uppercase text-center">
                            pelaksanaan pendistribusian
                          </h2>
                          <h2 className="mb-0  uppercase text-center">
                            program bantuan pangan - cbp 2023
                          </h2>
                        </div>
                      </div>

                      <table className="body">
                        <tr className="">
                          <td className="" style={{ width: '25%' }}></td>
                          <td className="" style={{ width: '50%' }}>
                            <table className="body">
                              <tr>
                                <td className="" style={{ width: '40%' }}>
                                  <p
                                    className="mb-0 text-left uppercase bolder"
                                    style={{ fontSize: 12 }}
                                  >
                                    kabupaten/kota
                                  </p>
                                </td>
                                <td className="" style={{ width: '5%' }}>
                                  <p
                                    className="mb-0 text-left uppercase bolder"
                                    style={{ fontSize: 12 }}
                                  >
                                    :
                                  </p>
                                </td>
                                <td className="" style={{ width: '55%' }}>
                                  <p
                                    className="mb-0 text-left uppercase bolder"
                                    style={{ fontSize: 12 }}
                                  >
                                    {data?.region?.regency_name}
                                  </p>
                                </td>
                              </tr>
                              <tr className="">
                                <td className="" style={{ width: '40%' }}>
                                  <p
                                    className="mb-0 text-left uppercase bolder"
                                    style={{ fontSize: 12 }}
                                  >
                                    ALOKASI
                                  </p>
                                </td>
                                <td className="" style={{ width: '5%' }}>
                                  <p
                                    className="mb-0 text-left uppercase bolder"
                                    style={{ fontSize: 12 }}
                                  >
                                    :
                                  </p>
                                </td>
                                <td className="" style={{ width: '55%' }}>
                                  <p
                                    className="mb-0 text-left uppercase bolder"
                                    style={{ fontSize: 12 }}
                                  >
                                    {alokasi[Project?.id]} 2023
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td className="" style={{ width: '25%' }}></td>
                        </tr>
                      </table>

                      <table className="body" style={{ marginTop: 15 }}>
                        <tr>
                          <td>
                            <p
                              className="mb-0 text-left"
                              style={{ fontSize: 12, lineHeight: 2 }}
                            >
                              Berdasarkan Berita Acara Serah Terima Program Bantuan
                              Pangan - CBP 2023 Kepada Penerima Bantuan Pangan (PBP),
                              telah didistribusikan Beras sebanyak{' '}
                              <span className="bolder">
                                {currencyFormat(
                                  parseFloat(kpm) * Project?.weight_packages
                                )}{' '}
                                Kg
                              </span>
                              , untuk{' '}
                              <span className="bolder">
                                {currencyFormat(parseFloat(kpm))} PBP
                              </span>{' '}
                              dengan rincian sebagai berikut:
                            </p>
                          </td>
                        </tr>
                      </table>
                    </>
                  )}

                  <table className='body text-center' style={{marginTop: 20, fontSize: 11}}>
                    <thead>
                      <tr className='border'>
                        <td className='border' rowSpan={2} style={{ width: '5%' }}>
                          <p className='mb-0 bolder'>No</p>
                        </td>
                        <td className='border' rowSpan={2} style={{ width: '20%' }}>
                          <p className='mb-0 bolder'>Kecamatan</p>
                        </td>
                        <td className='border' style={{ width: '15%' }}>
                          <p className='mb-0 bolder'>Jumlah</p>
                        </td>
                        <td className='border' style={{ width: '10%' }}>
                          <p className='mb-0 bolder'>Kuantum</p>
                        </td>
                        <td className='border' rowSpan={2} style={{ width: '10%' }}>
                          <p className='mb-0 bolder'>Keterangan</p>
                        </td>
                      </tr>
                      <tr className='border'>
                        <td className='border' style={{ width: '15%' }}>
                          <p className='mb-0 bolder'>PBP</p>
                        </td>
                        <td className='border' style={{ width: '10%' }}>
                          <p className='mb-0 bolder'>(KG)</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {page?.map((p, k) => (
                        <tr className='border' key={k}>
                          <td className='border'>
                            <p className='mb-0'>
                              {id > 1
                              ? dlimit + (dfp * (id - 1) + k + 1)
                              : dfp * id + k + 1}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {p?.district?.name}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {currencyFormat(parseFloat(p?.total_kpm))}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {currencyFormat(
                                parseFloat(p?.total_kpm) * Project?.weight_packages
                              )} {' '}
                              KG
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              &nbsp;
                            </p>
                          </td>
                        </tr>
                      ))}
                      {dpages?.length === id + 1 && (
                        <tr className='border'>
                          <td colSpan={2} className='border'>
                            <p className='mb-0 bolder'>
                              Jumlah
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0 bolder'>
                              {currencyFormat(parseFloat(kpm))}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0 bolder'>
                              {currencyFormat(
                                parseFloat(kpm) * Project?.weight_packages
                              )} {' '}
                              KG
                            </p>
                          </td>
                          <td className='border'>
                            &nbsp;
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {dpages?.length === id + 1 && (
                    <table className="body" style={{ marginTop: 30 }}>
                      <tr className="">
                        <td className="" colSpan={2} style={{ width: '5%' }}></td>
                        <td className="right" style={{ width: '40%' }}>
                          <p
                            className="mb-0 text-end"
                            style={{ fontSize: 12, lineHeight: 2 }}
                          >
                            .............................................. 2023
                          </p>
                        </td>
                        <td className="" style={{ width: '5%' }}></td>
                      </tr>
                      <tr className="border">
                        <td className="" style={{ width: '5%' }}></td>
                        <td
                          className="border"
                          style={{ height: 80, padding: 10, width: '40%' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <div style={{ marginBottom: 100 }}>
                              <p className="mb-0 bold" style={{ fontSize: 10 }}>
                                Mengetahui,
                              </p>
                              <p className="mb-0 bold" style={{ fontSize: 10 }}>
                                KANWIL/KANCAB/KANCAPEM...........
                              </p>
                              <p className="mb-0 bold" style={{ fontSize: 10 }}>
                                PEMIMPIN/KEPALA
                              </p>
                            </div>
                            <p className="mb-0 bold" style={{ fontSize: 10 }}>
                              (Nama Jelas, TTD & Stempel)
                            </p>
                          </div>
                        </td>
                        <td className="border" style={{ width: '40%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p className="mb-0 bold" style={{ fontSize: 10 }}>
                              Dibuat oleh,
                            </p>
                            <p
                              className="bold"
                              style={{
                                marginBottom: 120,
                                fontSize: 10,
                              }}
                            >
                              TRANSPORTER,
                            </p>
                            <p className="mb-0 bold" style={{ fontSize: 10 }}>
                              (Nama Jelas, TTD & Stempel)
                            </p>
                          </div>
                        </td>
                        <td className="" style={{ width: '5%' }}></td>
                      </tr>
                    </table>

                  )}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      right: 20,
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{
                        fontSize: 9,
                      }}
                    >
                      RTT: {data?.code}{' '}
                    </p>
                  </div>
                </section>
              ))}

              {vpages?.map((page, id) => (
                <section
                  className="sheet page-break"
                  style={{ padding: '50px', position: 'relative'}}
                >
                  {id === 0 && (
                    <>
                      <div style={{paddingBottom: 50}}>
                        <h2 className="mb-0 text-center uppercase">
                          SERAH TERIMA BERKAS
                        </h2>
                      </div>
                      <div>
                        <p className='mb-0 text-left' style={{fontSize: 13, lineHeight: 2, textIndent: '20px'}}>
                          Dengan ini saya yang bertanda tangan dibawah menyerahkan berkas meliputi Wilayah {' '}
                          <span className="bolder">
                          {districts[0]?.district?.regency?.name}{' '}
                          </span> dengan rincian sebagai berikut :
                        </p>
                      </div>
                    </>
                  )}

                  <table className='body text-center' style={{marginTop: 30, fontSize: 11,}}>
                    <thead>
                      <tr className='border'>
                        <td className='border' style={{ width: '5%' }}>
                          <p className='mb-0 bolder'>No</p>
                        </td>
                        <td className='border' style={{ width: '20%' }}>
                          <p className='mb-0 bolder'>Kecamatan</p>
                        </td>
                        <td className='border' style={{ width: '15%' }}>
                          <p className='mb-0 bolder'>Kelurahan</p>
                        </td>
                        <td className='border' style={{ width: '10%' }}>
                          <p className='mb-0 bolder'>SPM</p>
                        </td>
                        <td className='border' style={{ width: '10%' }}>
                          <p className='mb-0 bolder'>BAST</p>
                        </td>
                        <td className='border' style={{ width: '20%' }}>
                          <p className='mb-0 bolder'>SPTJM / BAST PENGGANTI</p>
                        </td>
                        <td className='border' style={{ width: '10%' }}>
                          <p className='mb-0 bolder'>BNBA</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {page?.map((p, k) => (
                        <tr className='border' key={k}>
                          <td className='border'>
                            <p className='mb-0'>
                              {page?.[k-1]?.village?.district?.name === p?.village?.district?.name ? '': vnos[p?.village?.district?.id]}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {page?.[k-1]?.village?.district?.name === p?.village?.district?.name ? '': p?.village?.district?.name}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {p?.village?.name}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {p?.stt_status === 'accepted' ? 'V': ''}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {p?.bast_status === 'accepted' ? 'V': ''}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {p?.sptjm_status === 'accepted' ? 'V': ''}
                            </p>
                          </td>
                          <td className='border'>
                            <p className='mb-0'>
                              {p?.rtt_status === 'accepted' ? 'V': ''}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {vpages?.length === id + 1 && (
                    <table className='body' style={{marginTop: 30}}>
                      <tr className='border'>
                        <td style={{width: '5%'}}></td>
                        <td className='border' style={{ height: 80, padding: 10}}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 120,
                                fontSize: 10,
                              }}
                            >
                              Yang Menyerahkan,
                              <br />
                              Transporter**
                            </p>

                            <p className="mb-0 bold" style={{ fontSize: 10 }}>
                              (Nama Jelas, TTD & Stempel)***
                            </p>
                          </div>
                        </td>
                        <td className='border' style={{ height: 80, padding: 10}}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 120,
                                fontSize: 10,
                              }}
                            >
                              Yang Menerima,
                              <br />
                              KANWIL/KANCAB/KANCABPEM*
                              <br/>PEMIMPIN/ KEPALA
                            </p>

                            <p className="mb-0 bold" style={{ fontSize: 10 }}>
                              (Nama Jelas, TTD & Stempel)***
                            </p>
                          </div>
                        </td>
                        <td className="" style={{ width: '5%' }}></td>
                      </tr>
                    </table>
                  )}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      right: 20,
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{
                        fontSize: 9,
                      }}
                    >
                      RTT: {data?.code}{' '}
                    </p>
                  </div>
                </section>
              ))}
            </>
          )}
        />
      )}
    </div>
  );
};

export default PrintRecap;
