/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { GrFormNext, GrFormPrevious, GrFormDown } from 'react-icons/gr';
import { Button, NavDropdown } from 'react-bootstrap';

const TablePagination = ({
  name,
  onChangePage,
  onChangeLimit,
  pageLimit,
}: Props) => {
  const StateLimit = useSelector(
    (state) => state?.Table?.data[name]?.limitPerPage
  );
  const StateTotal = useSelector((state) => state?.Table?.data[name]?.total);
  const StateCurrentPage = useSelector(
    (state) => state?.Table?.data[name]?.currentPage
  );

  const [pageLinks, setPageLinks] = React.useState(0);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [diff, setDiff] = React.useState(1);
  const [showPrev, setShowPrev] = React.useState(false);
  const [showNext, setShowNext] = React.useState(false);

  const changedPage = (i) => {
    if (StateCurrentPage === i) return false;

    onChangePage(i);
  };

  const changedLimit = (i) => {
    if (StateLimit === i) return false;

    onChangeLimit(i);
  };

  const range = () => {
    let first_num = (StateCurrentPage - 1) * StateLimit + 1;
    let last_num = StateTotal;

    if (StateTotal > StateCurrentPage * StateLimit) {
      last_num = StateCurrentPage * StateLimit;
    }

    return `${first_num} - ${last_num}`;
  };

  React.useEffect(() => {
    const numberOfPages = () => {
      const result = Math.ceil(StateTotal / StateLimit);
      return result < 1 ? 1 : result;
    };

    const pageLinks = () => {
      let result = StateLimit;
      const limit = 7;
      const nop = numberOfPages();

      if (StateCurrentPage > nop) {
        changedPage(1);
      }

      setShowPrev(false);
      setShowNext(false);

      // If less pages than limit just show this pages
      if (nop <= limit) {
        return nop;
      }

      // If somehere in the middle show just limit - 4 links in the middle
      // and one button on the left with "..." and on button on the right preceeded with "..."
      if (
        StateCurrentPage >= limit - 2 &&
        StateCurrentPage <= nop - limit + 2
      ) {
        setDiff(StateCurrentPage - 1);
        setShowPrev(true);
        setShowNext(true);

        result = limit - 4;
      } else {
        // If at the beggining of the list or at the end show full number of pages within limit - 2
        // -2 is reserves space for two buttons: "..." and "first/last button"
        if (StateCurrentPage <= limit - 2) {
          setShowNext(true);

          result = limit - 2;
        }

        // At the end of the range
        if (StateCurrentPage > nop - limit + 2) {
          setDiff(nop - limit + 2);
          setShowPrev(true);

          result = limit - 1;
        }
      }

      return result;
    };

    setNumberOfPages(numberOfPages());
    setPageLinks(pageLinks());
  }, [name, StateLimit, StateTotal, StateCurrentPage]);

  return (
    <div className="m-0 row align-items-center justify-content-between w-100">
      <div className="col-md-6 col-12 order-2 order-md-0 mt-3 mt-md-0 p-0">
        <p className="m-0 text-muted ">
          Showing {range()} of <span>{StateTotal}</span> results
        </p>
      </div>
      <div className="col-md-6 col-12 d-flex justify-content-md-end p-0">
        <div className="d-flex flex-row align-items-center justify-content-md-center">
          <ul className="pagination align-items-center justify-content-center m-0">
            <li
              className={cn(
                'page-item',
                StateCurrentPage === 1 ? 'disabled' : ''
              )}
            >
              <a
                className="page-link"
                href="#"
                onClick={
                  StateCurrentPage === 1
                    ? null
                    : () => changedPage(StateCurrentPage - 1)
                }
              >
                <GrFormPrevious
                  size={20}
                  color={StateCurrentPage === 1 ? '#636363' : '#202020'}
                />
              </a>
            </li>

            {showPrev ? (
              <>
                <li
                  className={cn(
                    'page-item',
                    StateCurrentPage === 1 ? 'active' : ''
                  )}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => changedPage(1)}
                  >
                    1
                  </a>
                </li>
                <li className="page-item disabled">
                  <a className="page-link" href="#">
                    ...
                  </a>
                </li>
              </>
            ) : null}

            {Array(pageLinks)
              .fill(1)
              .map((el, i) => (
                <li
                  key={i}
                  className={cn(
                    'page-item',
                    i + diff === StateCurrentPage ? 'active' : '',
                    i + diff !== StateCurrentPage ? 'hidden-xs-down' : ''
                  )}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => changedPage(i + diff)}
                  >
                    {i + diff}
                  </a>
                </li>
              ))}

            {showNext ? (
              <>
                <li className="page-item disabled">
                  <a className="page-link" href="#">
                    ...
                  </a>
                </li>
                <li
                  className={cn(
                    'page-item',
                    numberOfPages === StateCurrentPage ? 'active' : ''
                  )}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => changedPage(numberOfPages)}
                  >
                    {numberOfPages}
                  </a>
                </li>
              </>
            ) : null}

            <li
              className={cn(
                'page-item',
                StateCurrentPage === numberOfPages ? 'disabled' : ''
              )}
            >
              <a
                className="page-link"
                href="#"
                onClick={
                  StateCurrentPage === numberOfPages
                    ? null
                    : () => changedPage(StateCurrentPage + 1)
                }
              >
                <GrFormNext size={20} />
              </a>
            </li>
          </ul>
          <NavDropdown
            title={
              <Button variant="outline-secondary">
                {`${StateLimit} /page`}
                <GrFormDown size={15} className="ms-2" />
              </Button>
            }
            className="no-carret m-n2"
          >
            {pageLimit.map((i) => (
              <NavDropdown.Item
                key={i}
                onClick={() => changedLimit(i)}
                style={{}}
              >
                <div style={{ whiteSpace: 'normal' }}>{`${i} /page`}</div>
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </div>
      </div>
    </div>
  );
};

TablePagination.defaultProps = {
  pageLimit: [25, 50, 100, 200],
};

export default TablePagination;
