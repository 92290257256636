/* eslint-disable no-unused-vars */
import moment from 'moment';
import Geocode from 'react-geocode';
import { App, Auth } from './services/action';
import { getStore } from './services/store';
import { Rest } from './utilities/rest';
var idLocale = require('moment/locale/id');

moment.locale('id', idLocale);

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const POD_URL = `${process.env.REACT_APP_POD_SYSTEM_URL}`;

export default async function bootstrap() {
  const { dispatch, getState } = getStore();
  const session = getState().Auth;

  Geocode.setApiKey(GOOGLE_KEY);
  Geocode.setLanguage('en');
  Geocode.setLocationType('ROOFTOP');
  Geocode.enableDebug();

  if (session.isAuthenticated) {
    // should read new profile
    const loggedin = await dispatch(Auth.$fetchProfile());
    if (!loggedin) {
      await dispatch(Auth.$logout());
    }
  }

  if (window?.location?.pathname?.split('/')[1] === 'pod') {
    window.open(
      `${POD_URL}/pod?type=receive&line_id=${
        window?.location?.pathname?.split('/')[2]
      }`,
      '_self'
    );
  }

  Rest.events.on('unauthorized', async (errors) => {
    await dispatch(Auth.$logout());
  });

  await dispatch(App.$appReady());
}
