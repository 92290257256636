import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bansos } from 'services/action';
import { Form, Select } from 'ui/components';
import { currencyFormat } from 'utilities/common';

const initialState = {
  bansos_area: null,
  area: null,
  isInput: false,
};

export const FormDeliveryLines = ({ errors, onRegionChange, onFormChange, data }) => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Bansos.Area']
  );

  const Project = useSelector((state) => state?.Auth?.project);

  const [{ bansos_area, area }, setState] = React.useState(initialState);

  const $handleItemsChange = async (i, e) => {
    let newItems = [...bansos_area];

    if (e) {
      let { valueNumber, min, max } = e?.target;
      valueNumber = Math.max(Number(min), Math.min(Number(max), valueNumber));
      newItems[i]['qty'] = valueNumber;

      const m =
        parseFloat(newItems[i]['total_kpm']) - parseFloat(newItems[i]['qty']);

      newItems[i]['kpm_left'] = m;
    }

    setState((prevState) => ({ ...prevState, bansos_area: newItems }));
  };

  const queryArea = () => {
    let query = {
      page: 1,
      limit: 100,
    };

    if (area?.kanwil?.kanwil?.id) {
      query.kanwil_id = area?.kanwil?.kanwil?.id;
    }

    if (area?.regency?.regency?.id) {
      query.regency_id = area?.regency?.regency?.id;
    }

    if (area?.district?.district?.id) {
      query.district_id = area?.district?.district?.id;
    }

    return query;
  };

  const $setupArea = async () => {
    const res = await Dispatch(Bansos.$area(Project?.id, queryArea()));

    if (res && res?.message === 'success') {
      let newData = [];

      let x = data?.lines;

      await res?.data?.forEach((data) => {
        let m = parseFloat(data?.total_kpm) - parseFloat(data?.total_shipped);
        const payload = {
          village: data?.village,
          kpm_left: m,
          total_kpm: m,
          qty: 0,
          id: data?.id,
          bansos_id: data?.bansos_id,
        };

        let ts = data?.total_shipped

        x?.map((y) => {
          if (data?.id === y?.area_id) {
            payload.line_id = y?.id;
            payload.qty = y?.total_package
            payload.total_kpm += y?.total_package
            ts -= y?.total_package
          }
          return true;
        });

        payload.isInput = (data?.total_kpm - ts) > 0 ? true : false;

        newData?.push(payload);
      });
      setState((prevState) => ({ ...prevState, bansos_area: newData }));
    }
  };

  const $handleKanwil = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        kanwil: e,
      },
    }));
  };

  const $handleRegency = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        ...prevState?.area,
        regency: e,
        district: null,
      },
    }));
  };

  const $handleDistrict = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        ...prevState?.area,
        district: e,
      },
    }));
  };

  React.useEffect(() => {
    if (area?.kanwil && area?.regency) {
      $setupArea();
    } else {
      setState((prevState) => ({ ...prevState, bansos_area: [] }));
    }
  }, [area]);

  React.useEffect(() => {
    let newLines = [];

    if (bansos_area) {
      bansos_area?.forEach((line) => {
        if (line?.qty > 0 && line?.isInput) {
          newLines.push({
            id: line?.line_id,
            area_id: line?.id,
            total_package: line?.qty ? parseFloat(line?.qty) : 0,
          });
        }
      });
    }

    onFormChange(newLines);
  }, [bansos_area]);

  React.useEffect(() => {
    let region = {kanwil_id: "", regency_id: "", district_id: ""};

    if (area?.kanwil) {
      region.kanwil_id = area?.kanwil?.kanwil?.id
    }

    if (area?.regency) {
      region.regency_id = area?.regency?.regency?.id
    }

    if (area?.district) {
      region.district_id = area?.district?.district?.id
    }

    onRegionChange(region);
  }, [area]);

  React.useEffect(() => {
    if (data) {
      let area = {kanwil: null, regency: null, district: null}

      if (data?.region?.kanwil_id) {
        area.kanwil = {kanwil: {id: data?.region?.kanwil_id, name: data?.region?.kanwil_name}}
      }

      if (data?.region?.regency_id) {
        area.regency = {regency: {id: data?.region?.regency_id, name: data?.region?.regency_name}}
      }

      if (data?.region?.district_id) {
        area.district = {district: {id: data?.region?.district_id, name: data?.region?.district_name}}
      }

      setState((prevState) => ({
        ...prevState,
        area: area,
      }));
    }
  }, [data]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
            Informasi Pengiriman
          </div>
        </div>
        {/* <div className="col-3">
          <div className="d-flex flex-row-reverse pt-1">
            <button
              type="button"
              className="btn btn-xs btn- "
              onClick={() => $addMoreFields()}
            >
              <Fi.FiPlus /> Tambah Item
            </button>
          </div>
        </div> */}
      </div>

      <div className="row">
        <div className="col-4">
          <div className="mb-3">
            <Select.Remote
              required
              label="Pilih Kanwil"
              placeholder=""
              url="/report/kanwil"
              initialValue={area?.kanwil?.kanwil?.id}
              row={(d) => {
                return { value: d?.kanwil?.id, label: d?.kanwil?.name };
              }}
              params={{
                bansos_id: Project?.id,
              }}
              onSelected={(c) => {
                $handleKanwil(c);
              }}
              keys={area?.kanwil?.kanwil?.id}
              errors={errors?.kanwil_id}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <Select.Remote
              required
              label="Pilih Kota/Kab"
              placeholder=""
              url={`/report/regency`}
              initialValue={area?.regency?.regency?.id}
              params={{
                kanwil_id: area?.kanwil?.kanwil?.id,
                bansos_id: Project?.id,
              }}
              row={(d) => {
                return { value: d?.regency?.id, label: d?.regency?.name };
              }}
              onSelected={(c) => {
                $handleRegency(c);
              }}
              keys={area?.kanwil?.kanwil?.id}
              disabled={!area?.kanwil}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <Select.Remote
              required
              label="Pilih Kecamatan"
              placeholder=""
              url={`/report/district`}
              initialValue={area?.district?.district?.id}
              row={(d) => {
                return { value: d?.district?.id, label: d?.district?.name };
              }}
              params={{
                bansos_id: Project?.id,
                regency_id: area?.regency?.regency?.id,
              }}
              onSelected={(c) => {
                $handleDistrict(c);
              }}
              keys={area?.regency?.regency?.id}
              disabled={!area?.regency}
            />
          </div>
        </div>
      </div>
      <div className="col-md-12 col-12 border-top">
        {StateLoading ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
            </div>
          </div>
        ) : !bansos_area ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
              <p className="empty-title">Hasil tidak ditemukan</p>
              <p className="empty-subtitle text-muted">
                Coba sesuaikan pencarian atau filter Anda untuk menemukan apa
                yang Anda cari.
                <br /> Atau mungkin belum ada datanya!
              </p>
            </div>
          </div>
        ) : (
          <div className="table-responsive m-0">
            <table
              width="100%"
              className="table table-hover table-vcenter card-table datatable border"
            >
              <thead className="bg-light">
                <tr className="th">
                  <td style={{ width: '20px' }}></td>
                  <td
                    style={{
                      whiteSpace: 'initial',
                      width: '200px',
                    }}
                  >
                    Kelurahan
                  </td>
                  <td style={{ width: '50px' }}>Sisa KPM</td>
                  <td style={{ width: '50px' }}>Qty Pengiriman</td>
                  <td style={{ width: '20px' }}></td>
                </tr>
              </thead>
              <tbody>
                {bansos_area?.map((b, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{`${area?.district ? '': `${b?.village?.district?.name} -`} ${b?.village?.name}`}</td>
                    <td>{currencyFormat(b?.kpm_left)}</td>
                    {b?.isInput && (
                      <>
                        <td>
                          <Form.Input
                            mb0
                            value={b?.qty}
                            name="qty"
                            type="number"
                            onChange={(e) => $handleItemsChange(i, e)}
                            min={0}
                            max={b?.total_kpm}
                          />
                        </td>

                        <td>
                          <button
                            type="button"
                            className="btn btn-xs btn-info rounded p-2 "
                            style={{ minHeight: 30, minWidth: '100%' }}
                            onClick={() =>
                              $handleItemsChange(i, {
                                target: {
                                  name: 'qty',
                                  valueNumber: b?.total_kpm,
                                  min: 0,
                                  max: b?.total_kpm,
                                },
                              })
                            }
                            disabled={b?.kpm_left <= 0}
                          >
                            Kirim Semua
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {errors ? (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {errors}
          </div>
        ) : null}
      </div>
    </div>
  );
};
