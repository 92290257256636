import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { currencyFormat } from 'utilities/common';

const Table = ({ data }) => {
  if (!data) {
    return (
        <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
              <p className="empty-title">Belum ada uploaded</p>
            </div>
        </div>
    )
  }

  return (
    <div className="table-responsive m-0 table-overflow">
      <table
        className="table table-hover table-vcenter card-table datatable"
        width="100%"
      >
        <thead>
          <tr>
            <th
              className="text-start border-end"
              style={{ whiteSpace: 'initial', width: '13%' }}
            >
              Kanwil
            </th>
            <th
              className="text-center border-end"
              style={{ whiteSpace: 'initial', width: '5%' }}
            >
              Total PBP
            </th>
            {data?.labels?.map((d, i) => (
              <th key={i} className="text-center border-end">
                {d}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white graph">
          {data?.statistic?.map((d, i) => (
            <tr key={`${i}-${i}-${i}`}>
              <td style={{width: '13%'}} className="border-end">
                {d?.kanwil}
              </td>
              <td style={{width: '5%'}} className="text-end border-end">
                {currencyFormat(d?.total_pbp)}
              </td>
              {d?.data?.map((s, ii) => (
                <>
                    <td
                      key={`${i}-${ii}`}
                      className="text-end border-end "
                    >
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>Total PBP</Tooltip>
                          }
                        >
                          <div>{currencyFormat(s)}</div>
                        </OverlayTrigger>
                      </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;