import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Fi from 'react-icons/fi';

import cn from 'classnames';

import { DeliveryLine, Uploader, POD } from 'services/action';
import { Skeletons } from 'ui/components/skeleton';

const initialState = {
  isLoading: true,
  docs: []
};

export const FormReceive = ({ data, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);

  const [{ docs, isLoading }, setState ] = React.useState(initialState);
  const inputRef = React.useRef(null);

  const $handleSubmit = async (c) => {
    const payload = {
      total_receive: data?.total_package,
      is_session: true
    };

    const submit = await Dispatch(DeliveryLine.$receive(data?.id, payload));
      if (submit?.message === 'success') {
        onClose();
      }
  }

  const $savePOD = async () => {
    if (docs.length === 0) {
      $handleSubmit()

      return
    }

    const payload = {
      document: {
        type: 'receive',
        bansos_id: data?.delivery?.bansos_id,
        delivery_id: data?.delivery?.id,
        line_id: data?.id,
        village_id: data?.area?.village_id,
      },
    };

    if (docs) {
      const str = [];

      docs?.forEach((doc) => str?.push(doc?.data));

      payload.images = str;
    }

    const req = await Dispatch(POD.$upload(payload));
    if (req?.message === 'success') {
      $handleSubmit()
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleFiles = async (e) => {
    let selectedFile = e;
    let file = null;
    let fileName = '';

    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      let fileToLoad = selectedFile[0];
      fileName = fileToLoad.name;
      // FileReader function for read the file.
      let fileReader = new FileReader();
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        file = fileLoadedEvent.target.result;
        // Print data in console
        const fi = {
          file,
          fileName,
        };

        $upload(fi);
      };
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
    }
  };

  const $removeDoc = async (i) => {
    let newImg = [...docs];
    newImg.splice(i, 1);
    setState((prevState) => ({ ...prevState, docs: newImg }));
  };

  const $upload = async (e) => {
    let newData = [...docs];

    const payload = {
      file: e?.file,
      document: {
        type: 'receive',
        bansos_id: data?.delivery?.bansos_id,
        village_id: data?.area?.village_id,
      }
    };

    const req = await Dispatch(Uploader.$upload(payload));
    if (req?.message === 'success') {
      let x = {
        data: req?.data,
        filename: e?.fileName,
      };
      newData?.push(x);
    }

    setState((prevState) => ({ ...prevState, docs: newData }));
  };

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  React.useEffect(() => {
    if (!data) return;

    setState((prevState) => ({
      ...prevState,
      warehouse: data?.warehouse,
    }));
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <div className="row m-0">
          <Skeletons height="350px" />
        </div>
      </div>
    );
  }

  return (
    <>
      {data?.status === 'new' ? (
        <Modal.Body>
          <div className="row">
            <div className="col-5">
              <form
                id="form-file-upload"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={false}
                  onChange={handleChange}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                >
                  <div>
                    <p>Drag and drop your file here or</p>
                    <button className="upload-button" onClick={onButtonClick}>
                      Upload a file
                    </button>
                  </div>
                </label>
              </form>
            </div>
            <div className="col-7">
              {docs?.map((doc, i) => (
                <div className="row border-bottom py-2 align-items-end" key={i}>
                  <div className="col-2 text-start ">
                    <img
                      src={doc?.data}
                      alt=""
                      style={{ width: 40, height: 40 }}
                      className="rounded-3"
                    />
                  </div>
                  <div className="col-9 text-start overflow-hidden">
                    {doc?.filename}
                  </div>
                  <div className="col-1 text-start">
                    <div
                      className=" btn-action btn-sm cursor-pointer"
                      onClick={() => $removeDoc(i)}
                    >
                      <Fi.FiTrash color="red" size={14} />
                    </div>
                  </div>
                </div>
              ))}
              </div>
          </div>
          <div class="form-group mb-3 mt-3 text-center">
            <span className="form-control py-3 fw-semibold">{`${data?.total_package} Paket / ${data?.total_package * 10} Kg`}</span>
            <p className="text-danger fw-semibold mt-3 me-4">
              *Data yang sudah diterima tidak bisa dipulihkan, Apabila ada selisih silakan hubungi Gudang / Korwil.
            </p>
          </div>
        </Modal.Body>
      ): (
        <Modal.Body>
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
        </Modal.Body>
      )}
      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button
              type="button"
              className='btn btn-light w-100'
              onClick={() => onClose()}
            >
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className={cn(
                StateForm?.loading ? 'btn-loading' : '',
                  'btn btn-primary w-100'
                )}
              disabled={StateForm?.loading}
              onClick={$savePOD}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};
