import * as Action from './action';

const defineInitialState = () => ({
  isReady: false,
  isKeyboardVisible: false,
  statusBar: {
    backgroundColor: 'transparent',
    barStyle: 'dark-content',
    isHidden: true,
  },
  isNew: true,
  routeHistory: null,
});

export function reducer(state = defineInitialState(), action) {
  switch (action.type) {
    case Action.$appReady.ACTION:
      return {
        ...state,
        statusBar: {
          ...state.statusBar,
          isHidden: false,
        },
        isReady: true,
      };
    case Action.$keyboardVisible.ACTION:
      return {
        ...state,
        isKeyboardVisible: action.keyboardVisible,
      };
    case Action.$statusBar.ACTION:
      return {
        ...state,
        statusBar: action.statusBar,
      };
    case Action.$finishOnboarding.ACTION:
      return {
        ...state,
        isNew: action.isNew,
      };
    case Action.$routeChanged.ACTION:
      if (state?.routeHistory?.to === action?.path) {
        return state;
      }

      let newHistory = null;
      // check for loop route
      if (action?.path === state?.routeHistory?.from) {
        newHistory = {
          from: null,
          to: action?.path,
        };
      } else {
        newHistory = {
          from: state?.routeHistory?.to || null,
          to: action?.path,
        };
      }

      return {
        ...state,
        routeHistory: newHistory,
      };
    default:
      return state;
  }
}

export function persister({ isKeyboardVisible, routeHistory }) {
  return {
    isKeyboardVisible,
    routeHistory,
  };
}
