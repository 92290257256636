import React from 'react';
import { Button, ButtonGroup, DropdownButton, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeliveryLine, Table } from 'services/action';
import { FormReceive, FormUpdate } from '../form';

const initialState = {
  isShow: false,
  isReceive: false,
  isUpdate: false
};

const Action = ({ data }) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.session);
  const StateTable = useSelector(
    (state) => state?.Table?.data['delivery_line']
  );
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['DeliveryLine.Delete']
  );
  const [{ isShow, isReceive, isUpdate }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({ ...prevState, isShow: false, isUpdate: false }));
  };

  const $handleDelete = async () => {
    const del = await Dispatch(DeliveryLine.$delete(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      Dispatch(Table.$reload('delivery_line', StateTable));
    }
  };

  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        variant="transparent"
        size="md"
        className="no-carret tbl-action"
        title={<Fi.FiMoreVertical size={13} color="gray" />}
      >
        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={`/spm/line/${data?.id}`}
        >
          <Fi.FiClipboard size={15} color="#202020" className="me-2" />
          Detil
        </Link>

        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={`/print/linebast/${data?.id}`}
          target="_blank"
        >
          <Fi.FiPrinter size={15} color="#202020" className="me-2" />
          Print BAST / STT
        </Link>
        {data?.status === 'new' && Session?.usergroup?.id !== '6c5128cc-e5e8-4dbe-8204-cd532a7e229c' && (
          <>
            <button
              className="dropdown-item d-flex align-items-center"
              onClick={() =>
                setState((prevState) => ({ ...prevState, isUpdate: true }))
              }
            >
              <Fi.FiEdit size={15} color="#202020" className="me-2" />
              Perbaharui
            </button>

            <button
              className="dropdown-item d-flex align-items-center"
              onClick={() =>
                setState((prevState) => ({ ...prevState, isShow: true }))
              }
            >
              <Fi.FiTrash2 size={15} className="me-2" />
              Hapus
            </button>
          </>
        )}

        {(Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2' || Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82') && (
          <button
            className="dropdown-item d-flex align-items-center"
            onClick={() =>
              setState((prevState) => ({ ...prevState, isReceive: true }))
            }
          >
            {data?.status === 'received' ? (
              <>
                <Fi.FiXCircle size={15} className="me-2" />
                Batalkan Penerimaan
              </>
            ): (
              <>
                <Fi.FiCheckCircle size={15} className="me-2" />
                Terima
              </>
            )}
          </button>
        )}
      </DropdownButton>

      {/* modal delete */}
      <Modal
        show={isShow}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang sudah dihapus tidak bisa dipulihkan
          </p>

          {/* {isError ? (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {isError?.id}
            </div>
          ) : null} */}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateLoading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleDelete}
                disabled={StateLoading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal receive */}
      <Modal
        show={isReceive}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{data?.status === 'new' ? 'Terima Barang': 'Batalkan Penerimaan'}</Modal.Title>
        </Modal.Header>
        <FormReceive data={data} onClose={() => {
          $handleClose()
          Dispatch(Table.$reload('delivery_line', StateTable));
        }}/>
      </Modal>

      {/* modal update */}
      <Modal
        show={isUpdate}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Perbaharui QTY Pengiriman</Modal.Title>
        </Modal.Header>
        <FormUpdate data={data} onClose={() => {
          $handleClose()
          Dispatch(Table.$reload('delivery_line', StateTable));
        }}/>
      </Modal>
    </>
  );
};

export default Action;
