import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { POD } from 'services/action';

const initialState = {
  showDelete: false
};

// delete pod all
const DeletePOD = ({ data, type, received, status, onClose }) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.session);
  const StateForm = useSelector((state) => state.Form);

  const [{showDelete}, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      showDelete: false,
    }));
  };

  const $handleDelete = async() => {
    const payload = {
      type: type,
      bansos_id: data?.bansos_id,
      village_id: data?.village_id,
      deleted_by: Session?.user?.name,
    };

    const result = await Dispatch(POD.$deleteByVillage(payload));
    if (result?.message === 'success') {
      $handleClose();
      onClose();
    }
  }

  if (Session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' || Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' || Session?.usergroup?.id === '302d772c-77db-47ea-ab5a-83b6be4d3787') return null

  if (!((!received && (Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && Session?.usergroup?.id !== '302d772c-77db-47ea-ab5a-83b6be4d3787')) ||
      (received && (Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805')) ||
      (status === 'rejected' && Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82'))) return null



  return (
    <>
      <button
        className="btn btn-sm btn-danger rounded-2 col-3 float-end"
        onClick={() =>
          setState((prevState) => ({ ...prevState, showDelete: true }))
        }
      >
        <Fi.FiTrash size={15} className="m-1" />
        Hapus Dokumen
      </button>

      <Modal
        show={showDelete}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Akan menghapus semua file BNBA, data yang sudah dihapus tidak bisa dipulihkan
          </p>

          {/* {isError ? (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {isError?.id}
            </div>
          ) : null} */}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleDelete}
                disabled={StateForm?.loading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeletePOD;
