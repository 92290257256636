import cn from 'classnames';
import React from 'react';
import * as Fi from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { DateRangePicker, Select } from 'ui/components';

import { formatDate } from 'utilities/common';

const initialState = {
  receivingStatus: null,
  schedule: null,
};

const receivingStatusOptions = [
  { value: 'new', label: 'Baru' },
  { value: 'active', label: 'Aktif' },
  { value: 'completed', label: 'Selesai' },
];


const Filter = ({ show, onClose, table, setCount }) => {
  const Session = useSelector((state) => state?.Auth?.session);
  const [{ schedule, receivingStatus }, setState] = React.useState(initialState);

  const $onLoad = async () => {
    let date = [];

    const receivingStatus = table?.State?.filter?.receiving_status === 'new'
      ? receivingStatusOptions[0]
      : table?.State?.filter?.receiving_status === 'active'
      ? receivingStatusOptions[1]
      : table?.State?.filter?.receiving_status === 'completed'
      ? receivingStatusOptions[2]
      : null

    if (table.State?.filter?.start_at && table.State?.filter?.end_at) {
      const x = table.State?.filter?.start_at;

      date.push(new Date(x));

      const y = table.State?.filter?.end_at;
      date.push(new Date(y));
    } else {
      date = null
    }

    setState((prevState) => ({
      ...prevState,
      schedule: date,
      receivingStatus: receivingStatus
    }));
  };

  const handleFilter = async (e) => {
    if (table?.State?.filter) {
      let x = [];
      Object.keys(table?.State?.filter).forEach((k) => {
        if (table?.State?.filter[k] && k !== 'task' && k !== 'start_at' && k !== 'bansos_id') {
          x.push(k);
        }
      });
      setCount(x);
    }
  };

  const $filterReceivingStatus = (e) => {
    setState((prevState) => ({
      ...prevState,
      receivingStatus: e,
    }));

    table?.filter('receiving_status', e?.value);
    handleFilter();
  };

  React.useEffect(() => {
    $onLoad();
  }, []);

  React.useEffect(() => {
    handleFilter();
  }, []);

  return (
    <>
      <div
        className={cn(
          show
            ? 'col-md-3 d-none d-md-block py-3 px-3 m-0 border-start'
            : 'd-none'
        )}
      >
        <div className="mb-3 border-bottom">
          <div className="d-flex align-items-center justify-content-between">
            <div className="page-pretitle fs-7 text-dark">Filter</div>
            <div className="mt-n1">
              <button className="btn btn-action-close " onClick={onClose}>
                <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
              </button>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-secondary mb-2">
            Status Penerimaan
          </div>
          <Select
            placeholder=""
            classNamePrefix="select"
            isClearable
            value={receivingStatus}
            initialValue={receivingStatus}
            options={receivingStatusOptions}
            onChange={$filterReceivingStatus}
          />
        </div>

        {!Session?.warehouse && (
          <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">Gudang</div>
            <Select.Remote
              placeholder=""
              url="/warehouse"
              initialValue={table.State?.filter?.warehouse_id}
              row={(d) => {
                return {
                  value: d.id,
                  label: `${d.code} - ${d.name}`,
                };
              }}
              onSelected={(c) => {
                table.filter('warehouse_id', c?.id);
                handleFilter();
              }}
            />
          </div>
        )}

        <div className="mb-3">
          <div className="page-pretitle text-dark mb-2">Jadwal Pengiriman</div>
          <DateRangePicker
            value={schedule}
            placeholder=""
            onChange={(date) => {
              setState((prevState) => ({
                ...prevState,
                schedule: date,
              }));
              let fm = [];
              date?.forEach((data) => fm?.push(formatDate(data, 'YYYY-MM-DD')));
              table.filter('date_range', fm);
              handleFilter();
            }}
          />
        </div>
      </div>

      <>
        <div
          className={cn('filter-overlay', show ? 'd-block d-md-none' : '')}
          onClick={onClose}
        />
        <div
          className={cn(
            'filter-modal d-block d-md-none py-3',
            show ? 'filter-show' : ''
          )}
        >
          <div className="mb-3 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-pretitle fs-7 text-dark">Filter</div>
              <div className="mt-n1">
                <button className="btn btn-action-close " onClick={onClose}>
                  <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
                </button>
              </div>
            </div>
          </div>

          {/* <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">
              Status
            </div>
            <Select
              placeholder=""
              classNamePrefix="select"
              isClearable
              value={document_status}
              initialValue={document_status}
              options={statusOptions}
              onChange={$filterStatus}
            />
          </div> */}

          <div className="mb-3">
            <div className="page-pretitle text-secondary mb-2">
              Status Penerimaan
            </div>
            <Select
              placeholder=""
              classNamePrefix="select"
              isClearable
              value={receivingStatus}
              initialValue={receivingStatus}
              options={receivingStatusOptions}
              onChange={$filterReceivingStatus}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-dark mb-2">
              Jadwal Pengiriman
            </div>
            <DateRangePicker
              value={schedule}
              placeholder=""
              onChange={(date) => {
                setState((prevState) => ({
                  ...prevState,
                  schedule: date,
                }));
                let fm = [];
                date?.forEach((data) =>
                  fm?.push(formatDate(data, 'YYYY-MM-DD'))
                );
                if (fm?.length > 0) {
                  table.filter('start_at', fm[0]);
                  table.filter('end_at', fm[1]);
                  handleFilter();
                } else {
                  table.filter('start_at', null);
                  table.filter('end_at', null);
                  handleFilter();
                }
              }}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default Filter;
