import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { currencyFormat } from 'utilities/common';
import { ListItem } from '..';

const TableCardRender = ({ name, columns }) => {
  const StateTable = useSelector((state) => state?.Table?.data[name]?.data);
  const StateEmpty = useSelector((state) => state?.Table?.data[name]?.isEmpty);

  const Content = ({ field, column, data }) => {
    return (
      <ListItem
        title={column?.title}
        content={<Data column={column} field={field} data={data} />}
      />
    );
  };

  const Data = ({ field, column, data }) => {
    if (column?.visible === false) return null;

    const c = cn(column?.class);

    if (column?.component && React.isValidElement(column?.component(data))) {
      return <div className={c}>{column?.component(data)}</div>;
    }

    const value = () => {
      if (column?.format_number) {
        return currencyFormat(data[field]);
      }

      return data[field];
    };

    return <div className={c}>{value()}</div>;
  };

  const EmptyData = () => {
    if (!StateEmpty) return null;

    return (
      <div className="row">
        <div className="empty col-lg-12 truck">
          <div className="truck-wrapper">
            <div className="truck">
              <div className="truck-container"></div>
              <div className="glases"></div>
              <div className="bonet"></div>
              <div className="base"></div>
              <div className="base-aux"></div>
              <div className="wheel-back"></div>
              <div className="wheel-front"></div>
              <div className="smoke"></div>
            </div>
          </div>
          <p className="empty-title">Hasil tidak ditemukan</p>
          <p className="empty-subtitle text-muted">
            Coba sesuaikan pencarian atau filter Anda untuk menemukan apa yang
            Anda cari.
            <br /> Atau mungkin belum ada datanya!
          </p>
        </div>
      </div>
    );
  };

  const HasData = () => {
    if (StateEmpty) return <EmptyData />;
    return (
      <div className="row mx-1">
        {StateTable.map((d, i) => (
          <div className="col-md-4 col-lg-3 col-sm-12" key={i}>
            <div className="p-3 mx-2 my-3 border shadow-sm position-relative rounded">
              {d?.status ? (
                d?.status === 'new' ? (
                  <div className="card-status-top bg-danger"></div>
                ) : d?.status === 'active' ? (
                  <div className="card-status-top bg-yellow"></div>
                ) : (
                  <div className="card-status-top bg-success"></div>
                )
              ) : null}

              {Object.keys(columns).map((i, c) =>
                columns[i].title !== '' ? (
                  <Content column={columns[i]} field={i} key={c} data={d} />
                ) : (
                  <div className="position-absolute top-0 end-0  me-3 mt-3">
                    <Data column={columns[i]} field={c} data={d} />
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return <HasData />;
};

export default TableCardRender;
