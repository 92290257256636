import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Warehouse } from 'services/action';

import { Page, Select, Button } from 'ui/components';
import { FormSpm } from './component/spm';

const initialState = {
  origin: null,
  destination: null
};

const TransferScreen = () => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const [{ origin, destination }, setState] = React.useState(initialState);
  const StateForm = useSelector((state) => state?.Form);

  const $handleSubmit = async () => {
    const payload = {
      origin_id: origin?.id,
      destination_id: destination?.id,
    };

    const req = await Dispatch(Warehouse.$change(payload));

    if (req?.message === 'success') {
      History.push(`/master/warehouse`);
    }
  };


  return (
    <Page className="col-md-11 col-sm-12 bordered bg-white">
      <Page.Header title="Pindahkan Gudang" className="bg-light" />
      <Page.Body className="row border-top">
        <div className="sub-page">
          <div className="sub-page-body">
            <div className="p-3">
              <div className="row">
                <div className="col-md-3 col-12">
                  <div className='mb-3'>
                    <Select.Remote
                      required
                      label="Pilih Warehouse Awal"
                      placeholder=""
                      url="/warehouse"
                      initialValue={origin}
                      row={(d) => {
                        return { value: d.id, label: d?.name };
                      }}
                      onSelected={(e) => {
                        setState((prevState) => ({
                          ...prevState,
                          origin: e,
                        }))
                      }}
                      errors={StateForm?.errors?.origin_id}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className='mb-3'>
                    <Select.Remote
                      required
                      label="Pilih Tujuan Warehouuse"
                      placeholder=""
                      url="/warehouse"
                      initialValue={destination}
                      params={{
                        is_bulog: 'active',
                        exclude: origin?.id
                      }}
                      row={(d) => {
                        return { value: d.id, label: d?.name };
                      }}
                      onSelected={(e) => {
                        setState((prevState) => ({
                          ...prevState,
                          destination: e,
                        }))
                      }}
                      key={origin?.id}
                      errors={StateForm?.errors?.destination_id}
                    />
                  </div>
                </div>
                <div className='col-md-6 col-12'>
                  <div className="gap-2 pt-3 d-md-flex d-block mb-3">
                    <Button
                      type="button"
                      className="btn-secondary mb-3"
                      onClick={() => {
                        History.push('/master/warehouse');
                      }}
                    >
                      Batal &amp; Kembali
                    </Button>
                    <Button
                      type="button"
                      loading={StateForm?.loading}
                      className="btn-primary mb-3"
                      onClick={$handleSubmit}
                    >
                      Simpan
                    </Button>
                  </div>
                </div>

              <p className='fw-semibold mt-1'>*Data SPM dan User akan dipindahkan dari Warehouse Awal ke Warehouse Tujuan</p>
              </div>
            </div>
            {origin && (
              <div className="col-12 ">
                <div className="pb-md-3 px-3 ">
                  <FormSpm
                    warehouseId={origin?.id}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Page.Body>{' '}
    </Page>
  );
};

export default TransferScreen;
