import cn from 'classnames';
import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Page } from 'ui/components';

import RegionRegency from './regency';
import RegionVillage from './village';

const routes = [
  {
    path: '/region/regency',
    component: RegionRegency,
    menu: 'Berdasarkan Kota/Kab',
  },
  {
    path: '/region/village',
    component: RegionVillage,
    menu: 'Berdasarkan Kelurahan',
  },
];

const Navbar = () => {
  const [myRoute, setMyRoute] = React.useState(null);
  const match = useRouteMatch();

  const $getPriviledge = async () => {
    setMyRoute(routes);
  };

  React.useEffect(() => {
    $getPriviledge();
  }, []);

  return (
    <div className="list-group list-group-flush">
      {myRoute?.map((r, i) => {
        let comp = (
          <Link
            key={i}
            to={r?.path}
            className={cn(
              'list-group-item list-group-item-action',
              match?.url === r?.path ? 'active' : ''
            )}
          >
            {r?.menu}
          </Link>
        );

        return comp;
      })}
    </div>
  );
};

const Switcher = () => {
  const [myRoute, setMyRoute] = React.useState(null);

  const $getPriviledge = async () => {
    setMyRoute(routes);
  };

  React.useEffect(() => {
    $getPriviledge();
  }, []);

  return (
    <Switch>
      {myRoute?.map((r, i) => (
        <Route key={i} exact path={r?.path} component={r.component} />
      ))}
    </Switch>
  );
};

const RegionScreen = () => {
  React.useEffect(() => {}, []);

  return (
    <Page className="col-md-11 col-sm-12 bordered bg-white">
      <Page.Header title="Sorting Dusun" className="bg-light" />
      <Page.Body className="row border-top">
        <div className="col-4 col-md-2 m-0 p-0">
          <Navbar />
        </div>
        <div className="col-8 col-md-10 border-start p-0">
          <Switcher />
        </div>
      </Page.Body>{' '}
    </Page>
  );
};

export default RegionScreen;
