import config from 'services/table/const';
import { currencyFormat } from 'utilities/common';

let table = { ...config };
table.url = '/report/document/village';
table.columns = {
  code: {
    title: 'Kelurahan',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.village?.name}</div>
          <small className="info text-ellipsis">
            {row?.village?.district?.name},{' '}
            {row?.village?.district?.regency?.name},{' '}
            {row?.village?.district?.regency?.kanwil?.name}
          </small>
        </div>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  total_document: {
    title: 'Dokumen Keluar',
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return <div className="table-col">{currencyFormat(row?.total_document)}</div>;
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  total_uploaded: {
    title: 'Dokumen POD',
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_uploaded)}</span>
        </div>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  status: {
    title: 'Dokumen Status',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 120,
    component: (row) => {
      return (
        <span className={`badge status-${row?.status}-lt ms-auto`}>
          {row?.status?.replace('_', ' ')?.replace('new', 'Baru')}
        </span>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },
};

export default table;
