import * as Fi from 'react-icons/fi';
import config from 'services/table/const';
import { currencyFormat } from 'utilities/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Action from './action';

let table = { ...config };
table.url = '/report/village';
table.columns = {
  code: {
    title: 'Kelurahan',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.village?.name}</div>
          <small className="info text-ellipsis">
            {row?.village?.district?.name},{' '}
            {row?.village?.district?.regency?.name},{' '}
            {row?.village?.district?.regency?.kanwil?.name}
          </small>
        </div>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  total_kpm: {
    title: 'Target',
    titleChild: '1',
    width: 100,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return <div className="table-col">{currencyFormat(row?.total_kpm)}</div>;
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  total_shipped: {
    title: 'Realisasi',
    titleChild: '2 = 2/1',
    width: 100,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_shipped)}</span>
          {` (${((row?.total_shipped / row?.total_kpm) * 100).toFixed(2)}%)`}
        </div>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  total_delivered: {
    title: 'Diterima',
    titleChild: '3 = 3/1',
    width: 100,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_delivered)}</span>
          {` (${((row?.total_delivered / row?.total_kpm) * 100).toFixed(2)}%)`}
        </div>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  total_delivered_realisasi: {
    title: 'Realisasi Diterima',
    titleChild: '4 = 4/2',
    width: 120,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_delivered)}</span>
          {row?.total_shipped > 0 ? ` (${((row?.total_delivered / row?.total_shipped) * 100).toFixed(2)}%)`: ' (0%)'}
        </div>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  bast: {
    title: 'BAST',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.bast_status === '' ? '' :
      row?.bast_status === 'accepted' ? <Fi.FiCheck size={20} color="#28ba00" /> : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Alasan: <br/>{row?.bast_reason}</Tooltip>}
        >
          <div>
            <Fi.FiX size={20} color="#d63939"/>
          </div>
        </OverlayTrigger>
      )
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
    visible: 'ex 302d772c-77db-47ea-ab5a-83b6be4d3787'
  },

  sptjm: {
    title: 'SPTJM',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.sptjm_status === '' ? '' :
      row?.sptjm_status === 'accepted' ? <Fi.FiCheck size={20} color="#28ba00" /> : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Alasan: <br/>{row?.sptjm_reason}</Tooltip>}
        >
          <div>
            <Fi.FiX size={20} color="#d63939"/>
          </div>
        </OverlayTrigger>
      )
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
    visible: 'ex 302d772c-77db-47ea-ab5a-83b6be4d3787'
  },

  rtt: {
    title: 'BNBA / DTT',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.rtt_status === '' ? '' :
      row?.rtt_status === 'accepted' ? <Fi.FiCheck size={20} color="#28ba00" /> : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Alasan: <br/>{row?.rtt_reason}</Tooltip>}
        >
          <div>
            <Fi.FiX size={20} color="#d63939"/>
          </div>
        </OverlayTrigger>
      )
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
    visible: 'ex 302d772c-77db-47ea-ab5a-83b6be4d3787'
  },

  stt: {
    title: 'SPM / STT',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.stt_status === '' ? '' :
      row?.stt_status === 'accepted' ? <Fi.FiCheck size={20} color="#28ba00" /> : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Alasan: <br/>{row?.stt_reason}</Tooltip>}
        >
          <div>
            <Fi.FiX size={20} color="#d63939"/>
          </div>
        </OverlayTrigger>
      )
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
    visible: 'ex 302d772c-77db-47ea-ab5a-83b6be4d3787'
  },

  is_arcon: {
    title: 'Document',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.is_arcon ? <Fi.FiCheck size={20} color="#28ba00" /> : '';
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
    visible: '302d772c-77db-47ea-ab5a-83b6be4d3787'
  },

  actions: {
    title: '',
    width: 50,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};

export default table;
