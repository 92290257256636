import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FiAlertTriangle, FiCheckCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';

export const useModal = (type) => {
  const [isShow, setIsShow] = React.useState(false);
  const StateForm = useSelector((state) => state?.Form);
  const StateLoading = useSelector((state) => state?.Activity?.processing);

  const $handleClose = () => {
    setIsShow(false);
  };

  const Render = ({ title, footer, children, className, fullscreen }) => {
    return (
      <Modal
        show={isShow}
        onHide={$handleClose}
        backdrop="static"
        centered
        className={className}
        fullscreen={fullscreen}
        size="small"
      >
        {type !== 'dialog' ? (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        ) : null}
        <Modal.Body>{children}</Modal.Body>
        {footer ? <Modal.Footer>{footer}</Modal.Footer> : null}
      </Modal>
    );
  };

  const Dialog = ({ title, onClick, children, variant }) => {
    return (
      <Modal
        show={isShow}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose}></div>
          {variant === 'confirm' ? (
            <>
              <div className="modal-status bg-primary"></div>
              <FiCheckCircle className="mb-2 text-primary" size={90} />
              <h3>Anda yakin?</h3>
            </>
          ) : (
            <>
              <div className="modal-status bg-danger"></div>
              <FiAlertTriangle className="mb-2 text-danger" size={90} />
              <h3>Anda yakin?</h3>
            </>
          )}
          {children}
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100">
            <div className="row">
              <div className="col">
                <Button
                  variant="white"
                  className="w-100"
                  onClick={$handleClose}
                >
                  Batal
                </Button>
              </div>
              <div className="col">
                {variant === 'confirm' ? (
                  <Button
                    variant="primary"
                    className={
                      StateForm?.loading ? ' btn-loading w-100' : 'w-100'
                    }
                    onClick={onClick}
                    disabled={StateForm?.loading}
                  >
                    {title}
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    className={StateLoading ? ' btn-loading w-100' : 'w-100'}
                    onClick={onClick}
                    disabled={StateLoading}
                  >
                    {title}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  return {
    show(): void {
      setIsShow(true);
    },
    hide(): void {
      setIsShow(false);
    },
    Render,
    Dialog,
  };
};
