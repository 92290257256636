import * as ActionCreator from 'utilities/action.creator';

const MODULE = 'Activity';

export const $reset = ActionCreator.Default(MODULE, 'reset', () => $reset.action());

export const $processing = ActionCreator.Default(MODULE, 'processing', (operation) =>
  $processing.action({ operation }),
);

export const $done = ActionCreator.Default(MODULE, 'done', (operation) => $done.action({ operation }));
