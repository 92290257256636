import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';

import { POD, Download } from 'services/action';
import { Uploader } from 'ui/components';
import PODViewer from 'ui/components/imageViewer';
import { Skeletons } from 'ui/components/skeleton';
import { aliasBansosName } from 'utilities/common';

const initialState = {
  url: '',
  isRemove: false,
  images: [],
  imgShow: 7,
  zoomImg: null,
  isShow: false,
};

export const PODImage = ({ data, onSubmit }) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state.Auth?.session);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['POD.Get.Line']
  );

  const dummy = Array.from({ length: 2 }, (v, i) => i);
  const [{ url, isRemove, images }, setState] = React.useState(initialState);

  const $handleDownload = async() => {
    const params = {
      bansos: aliasBansosName(data?.delivery?.bansos_id),
      type: 'receive',
      village: data?.area?.village?.name,
      district: data?.area?.village?.district?.name,
      regency: data?.area?.village?.district?.regency?.name,
      kanwil: data?.area?.village?.district?.regency?.kanwil?.name,
    }

    const result = await Dispatch(Download.$imagePdf(params));
    console.log(result, "hahah")
    if (result?.message === 'success') {
      setState((prevState) => ({ ...prevState, isRemove: true, url: result?.data }));
      window.open(result?.data, '_blank');
    } else {
      const message = 'Dokumen tidak tersedia.';
      toast.error(message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const $handleRemoveDownload = async() => {
    const payload = {
      url
    }

    const result = await Dispatch(Download.$remove(payload));
    if (result?.message === 'success') {
      setState((prevState) => ({ ...prevState, isRemove: false, url: null }));
    }
  }

  React.useEffect(() => {
    if (isRemove) {
      setTimeout(function () {
        $handleRemoveDownload()
      }, 5000)
    }
  }, [isRemove, url])

  const $postImage = async (e) => {
    let payload = {
      document: {
        bansos_id: data?.delivery?.bansos_id,
        delivery_id: data?.delivery?.id,
        line_id: data?.id,
        village_id: data?.area?.village_id,
        type: 'receive',
      },
      images: e,
    };

    const req = await Dispatch(POD.$upload(payload));

    if (req?.message === 'success') {
      onSubmit();
    }
  };

  const $getImage = async (e) => {
    const req = await Dispatch(POD.$line(e, {type: 'receive'}));

    if (req?.message === 'success' && req?.data?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        images: req?.data,
      }));
    }
  };

  React.useEffect(() => {
    if (data) {
      $getImage(data?.id);
    }
  }, [data]);

  console.log(images, "haha")

  return (
    <div className="p-0 p-md-3 mb-3 mb-md-0">
      <div className="card rounded-2 bg-white">
        <div className="card-header-gray p-3 border-bottom">
          <div className='row m-0 justify-content-between align-items-center'>
            <div className='col-auto'>
              <h3 className="card-title dx-card-title m-0 p-0">POD</h3>
            </div>
            <div className='col-auto'>
              {images?.length > 0 && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-top`}>Download PDF</Tooltip>}
                >
                  <button
                    className='btn btn-secondary px-2'
                    onClick={$handleDownload}
                  >
                    <Fi.FiDownload size="17" />
                  </button>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card-body bg-white p-3 pt-3">
        <div className="mt-2">
          {StateLoading ? (
            <div className="d-flex flex-wrap gap-2 align-items-center justify-content-start mt-n1">
              {dummy?.map((d, i) => (
                <Skeletons width="50px" height="50px" key={i} />
              ))}
              <Skeletons width="50px" height="50px" />
            </div>
          ) : (
            <div className="d-flex flex-wrap pb-2 gap-2 align-items-center justify-content-start mt-2">
              {(Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && Session?.usergroup?.id !== '302d772c-77db-47ea-ab5a-83b6be4d3787') && (
                <Uploader
                  medium
                  setData={(e) => $postImage(e)}
                  type='receive'
                  village_id={data?.area?.village_id}
                  bansos_id={data?.delivery?.bansos_id}
                />
              )}

              <PODViewer
                items={images}
                imgStyle={{ width: 80, height: 80 }}
                imgClass="img-hover  rounded-3 border"
                wrapperClass="d-flex flex-wrap pb-2 gap-2 align-items-center justify-content-start mt-2"
                onLoad={() => $getImage(data?.id)}
              />

              {/* {images?.slice(0, imgShow)?.map((d, i) => (
                <div key={i} style={{ width: 80, height: 80 }}>
                  <img
                    className="img-hover  rounded-3 border"
                    src={d?.image}
                    alt=""
                    style={{
                      width: 80,
                      height: 80,
                    }}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isShow: true,
                        zoomImg: d,
                      }));
                    }}
                  />
                </div>
              ))}

              {images?.length > 4 ? (
                imgShow < images?.length ? (
                  <div
                    className="d-flex flex-column justify-content-center align-items-center bg-secondary-lt text-uppercase fw-bold rounded-3 uploader "
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        imgShow: images?.length,
                      }))
                    }
                    style={{ cursor: 'pointer', height: 80, width: 80 }}
                  >
                    <div
                      className=""
                      style={{
                        textOrientation: 'upright',
                        writingMode: 'horizontal-tb',
                        textAlign: 'center',
                        fontSize: 10,
                      }}
                    >
                      Lihat semua <Fi.FiChevronsRight />
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex flex-column justify-content-center align-items-center bg-secondary-lt text-uppercase fw-bold rounded-3 uploader "
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        imgShow: 7,
                      }))
                    }
                    style={{ cursor: 'pointer', height: 80, width: 80 }}
                  >
                    <div
                      className=""
                      style={{
                        textOrientation: 'upright',
                        writingMode: 'horizontal-tb',
                        textAlign: 'center',
                        fontSize: 10,
                      }}
                    >
                      <Fi.FiChevronsLeft />
                      Tutup
                    </div>
                  </div>
                )
              ) : null}

              <Modal
                show={isShow}
                onHide={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isShow: false,
                  }))
                }
                backdrop="static"
                centered
                className="text-center"
                size="small"
              >
                <Modal.Body>
                  <div
                    className="btn-close"
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isShow: false,
                      }))
                    }
                  />
                  <div className="text-center mt-4" style={{ maxHeight: 800 }}>
                    <img
                      src={zoomImg?.image?.replace('_thumb', '')}
                      alt=""
                      className="img-fluid"
                      style={{ maxHeight: 800 }}
                    />
                  </div>
                </Modal.Body>
              </Modal> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
