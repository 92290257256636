import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';

const ListRoute = ({ iconOrigin, origin, iconDestination, destination }) => {
  return (
    <div className="row align-items-center justify-content-center my-2">
      <div className="col-1">
        <div className="mb-1">{iconOrigin}</div>
        <FaEllipsisV size="18" color="gray" />
        <div className="mt-1">{iconDestination}</div>
      </div>
      <div className="col-11 text-start">
        <div className="mb-1">{origin}</div>
        <div className="mt-2">{destination}</div>
      </div>
    </div>
  );
};

export default ListRoute;
