import React from 'react';
import { Button, ButtonGroup, DropdownButton, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import * as Fi from 'react-icons/fi';

import { Table, User } from 'services/action';
import { FormUser, FormCoverage } from 'ui/screens/authorized/_shared';

const initialState = {
  privilege: null,
  isShow: false,
  isUpdate: false,
  isCoverage: false,
};

const Action = ({ data }) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state.Auth?.session);
  const StateTable = useSelector((state) => state?.Table?.data['users']);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['User.Delete']
  );

  const [{ privilege, isShow, isUpdate, isCoverage }, setState] =
    React.useState(initialState);

  const $getPriviledge = async () => {
    const x = _.findWhere(Session?.privileges, {
      service: 'nvo.platform.user',
    });
    setState((prevState) => ({ ...prevState, privilege: x }));
  };

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      isShow: false,
      isUpdate: false,
      isCoverage: false,
    }));
  };

  const $handleDelete = async () => {
    const del = await Dispatch(User.$delete(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      Dispatch(Table.$reload('users', StateTable));
    }
  };

  const $handleSuccess = () => {
    Dispatch(Table.$reload('users', StateTable));
  };

  React.useEffect(() => {
    $getPriviledge();
  }, []);

  if (privilege?.action === 'manage' || !Session?.privileges) {
    return (
      <div className="row">
        <DropdownButton
          as={ButtonGroup}
          variant="transparent"
          size="md"
          className="no-carret tbl-action"
          title={<Fi.FiMoreVertical size={13} color="gray" />}
        >
          <button
            className="dropdown-item d-flex align-items-center"
            onClick={() =>
              setState((prevState) => ({ ...prevState, isUpdate: true }))
            }
          >
            <Fi.FiEdit size={15} color="#202020" className="me-2" />
            <span className="mt-1">Perbaharui</span>
          </button>

          {Session?.user?.id !== data?.id && (
            <button
              className="dropdown-item d-flex align-items-center"
              onClick={() =>
                setState((prevState) => ({ ...prevState, isShow: true }))
              }
            >
              <Fi.FiTrash2 size={15} className="me-2" />
              <span className="mt-1">Hapus</span>
            </button>
          )}

          <button
            className="dropdown-item d-flex align-items-center"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isCoverage: true,
              }))
            }
          >
            <Fi.FiSettings size={15} color="#202020" className="me-2" />
            <span className="pt-1">Atur Area Coverage</span>
          </button>
        </DropdownButton>

        {/* modal delete */}
        <Modal
          show={isShow}
          onHide={$handleClose}
          backdrop="static"
          centered
          className="text-center"
          size="small"
        >
          <Modal.Body>
            <div className="btn-close" onClick={$handleClose} />
            <div className="modal-status bg-danger" />
            <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
            <h3>Anda yakin?</h3>
            <p className="text-danger fw-semibold">
              Data yang sudah dihapus tidak bisa dipulihkan
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="row w-100">
              <div className="col">
                <Button
                  variant="white"
                  className="w-100"
                  onClick={$handleClose}
                >
                  Batal
                </Button>
              </div>
              <div className="col">
                <Button
                  variant="danger"
                  className={StateLoading ? ' btn-loading w-100' : 'w-100'}
                  onClick={$handleDelete}
                  disabled={StateLoading}
                >
                  Hapus
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isUpdate}
          onHide={$handleClose}
          backdrop="static"
          centered
          size="xl"
          enforceFocus={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Perbaharui User</Modal.Title>
          </Modal.Header>
          <FormUser
            onClose={$handleClose}
            onSuccess={$handleSuccess}
            data={data}
          />
        </Modal>

        <Modal
          show={isCoverage}
          onHide={$handleClose}
          backdrop="static"
          centered
          size="xl"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Atur Area Coverages</Modal.Title>
          </Modal.Header>
          <FormCoverage
            data={data}
            onClose={$handleClose}
            onSuccess={$handleSuccess}
          />
        </Modal>
      </div>
    );
  }

  return false;
};

export default Action;
