import cn from 'classnames';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { User } from 'services/action';
import { Select } from 'ui/components';
import { Skeletons, SkeletonsForm } from 'ui/components/skeleton';

const initialState = {
    isLoading: true,
    coverages: [],
    kanwil: null,
    regency: null,
    village: null,
};

export const FormCoverage = ({ data, onClose, onSuccess }) => {
    const Dispatch = useDispatch();
    const StateForm = useSelector((state) => state.Form);
    const dummy = Array.from({ length: 2 }, (v, i) => i);
    const [{ isLoading, coverages, kanwil, regency }, setState] = React.useState(initialState);

    const clearState = () => {
        setState({ ...initialState });
    };

    const $onLoad = async (id) => {
        const result = await Dispatch(User.$getArea(id));

        if (result?.message === 'success') {
            const converages = result?.data

            let kanwil = null
            let regency = null
            if (converages) {
                if (data?.usergroup_id === '302d772c-77db-47ea-ab5a-83b6be4d3787') {
                    kanwil = {id: converages[0].kanwil_id, name: converages[0].kanwil_name}
                }else if (data?.usergroup_id ===  '7ce651f7-5df9-424a-8a82-7d87ffd45107') {
                    kanwil = {id: converages[0].kanwil_id, name: converages[0].kanwil_name}
                    regency = {id: converages[0].regency_id, name: converages[0].regency_name}
                }
            }

            setState((prevState) => ({
                ...prevState,
                kanwil: kanwil,
                regency: regency,
                coverages: converages,
            }));
        }
    }

    const $handleSubmit = async () => {
        const payload = {
            areas: coverages,
        };

        const submit = await Dispatch(User.$updateCoverage(data?.id, payload));

        if (submit?.message === 'success') {
            clearState();
            onSuccess();
        }
    }

    const $handleRemove = (i) => {
        let newCoverage = [...coverages];
        newCoverage.splice(i, 1);
        setState((prevState) => ({
            ...prevState,
            coverages: newCoverage,
        }));
    }

    const $handleKanwilSelect = (v) => {
        let x = _.pluck(v, 'original');

        let original = []
        _.each(x, function(v) {
            original.push({
                kanwil_id: v?.id, kanwil_name: v?.name
            })
        })

        // should check the dupe if coverages already exists
        const pid = _.pluck(coverages, 'kanwil_id')
        original = _.reject(original, function (r) {
            var exists = _.find(pid, function (pi) {
                return r.kanwil_id === pi;
            });

            return exists;
        })

        let newCoverage = [];
        if (coverages) {
            newCoverage = [...coverages, ...original];
        } else {
            newCoverage = [...original];
        }

        setState((prevState) => ({
            ...prevState,
            coverages: newCoverage,
        }));
    }

    const $handleDistrictSelect = (v) => {
        let x = _.pluck(v, 'original');

        let original = []
        _.each(x, function(v) {
            original.push({
                kanwil_id: kanwil?.id, kanwil_name: kanwil?.name,
                regency_id: regency?.id, regency_name: regency?.name,
                district_id: v?.id, district_name: v?.name,
            })
        })

        // should check the dupe if coverages already exists
        const did = _.pluck(coverages, 'district_id')
        original = _.reject(original, function (r) {
            var exists = _.find(did, function (di) {
                return r.district_id === di;
            });

            return exists;
        })

        let newCoverage = [];
        if (coverages) {
            newCoverage = [...coverages, ...original];
        } else {
            newCoverage = [...original];
        }

        setState((prevState) => ({
            ...prevState,
            coverages: newCoverage,
        }));
    }

    React.useEffect(() => {
        setTimeout(() => {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
            }));
        }, 2000);
    }, []);

    React.useEffect(() => {
        if (!data) return;
        $onLoad(data?.id)
    }, [data]);

    if (isLoading) {
        return (
            <Modal.Body>
                <div className="row m-0">
                    {dummy?.map((d, i) => (
                        <div className="col-md-6">
                            <SkeletonsForm count={1} key={i} />
                        </div>
                    ))}
                    <div className="col-md-12">
                        <Skeletons height="350px" />
                    </div>
                </div>
            </Modal.Body>
        );
    }

     if (data?.usergroup_id === '4df4c118-ed27-40a4-865e-270930637e82' || data?.usergroup_id === '302d772c-77db-47ea-ab5a-83b6be4d3787' || data?.usergroup_id === '8465c143-a4e3-478e-b019-cb4e1035e886' || data?.usergroup_id === '004850c5-ebee-2f68-5cd9-4eb2405cfb5e') {
        return (
            <>
                <Modal.Body>
                    <div className="row m-0">
                        <div className="col-12 mt-3 mb-3">
                            <Select.Remote
                                required
                                label="Pilih Kanwil"
                                placeholder=""
                                url="/region/kanwil"
                                row={(d) => {
                                    return {
                                        value: d?.id,
                                        label: `${d?.name}`,
                                    };
                                }}
                                onSelected={$handleKanwilSelect}
                                isMulti
                            />
                        </div>
                        <div className="table-responsive mb-5">
                            <table
                                width="100%"
                                height="350"
                                className="table table-hover table-vcenter card-table datatable border"
                            >
                                <thead className="bg-light">
                                    <tr className="th">
                                        <td
                                            style={{
                                                whiteSpace: 'initial',
                                                textAlign: 'center',
                                            }}
                                        >
                                            No
                                        </td>
                                        <td
                                            style={{
                                                whiteSpace: 'initial',
                                                width: '600px',
                                            }}
                                        >
                                            Region
                                        </td>
                                        <td
                                            style={{
                                                whiteSpace: 'initial',
                                                textAlign: 'center',
                                            }}
                                        ></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coverages?.map((d, i) => (
                                        <tr key={i}>
                                            <td
                                                style={{
                                                    whiteSpace: 'initial',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {i + 1}
                                            </td>
                                            <td
                                                style={{
                                                    whiteSpace: 'initial',
                                                    width: '300px',
                                                }}
                                            >
                                                {`${d?.kanwil_name}`}
                                            </td>
                                            <td
                                                style={{
                                                    whiteSpace: 'initial',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id={`tooltip-top`}>Hapus</Tooltip>}
                                                >
                                                    <div onClick={() => $handleRemove(i)}>
                                                        <Fi.FiTrash2 color="red" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100">
                        <div className="col">
                            <Button
                                type="button"
                                className='btn btn-light w-100'
                                onClick={() => onClose()}
                            >
                                Batal
                            </Button>
                        </div>
                        <div className="col">
                            <Button
                                type="button"
                                className={cn(
                                    StateForm?.loading ? 'btn-loading' : '',
                                    'btn btn-primary w-100'
                                )}
                                disabled={StateForm?.loading}
                                onClick={$handleSubmit}
                            >
                                Simpan
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </>
        )
    }

    if (data?.usergroup_id === '7ce651f7-5df9-424a-8a82-7d87ffd45107') {
        return (
            <>
                <Modal.Body>
                    <div className="row m-0">
                        <div className="col-6">
                            <Select.Remote
                                required
                                label="Pilih Kanwil"
                                placeholder=""
                                url="/region/kanwil"
                                initialValue={kanwil?.id}
                                row={(d) => {
                                    return {
                                        value: d?.id,
                                        label: `${d?.name}`,
                                    };
                                }}
                                onSelected={(v) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        kanwil: v,
                                        regency: null,
                                        district: null,
                                        coverages: null
                                    }));
                                }}
                                keys={kanwil?.id}
                            />
                        </div>
                        <div className="col-6">
                            {kanwil && <Select.Remote
                                required
                                label="Pilih Kota/Kabupaten"
                                placeholder=""
                                url={`/region/regency`}
                                initialValue={regency?.id}
                                params={{
                                    kanwil_id: kanwil?.id || ''
                                }}
                                row={(d) => {
                                    return {
                                        value: d?.id,
                                        label: `${d?.name}`,
                                    };
                                }}
                                onSelected={(v) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        regency: v,
                                        district: null,
                                        coverages: null
                                    }));
                                }}
                                keys={kanwil?.id}
                            />}
                        </div>
                        <div className="col-12 mt-3 mb-3">
                            {regency && <Select.Remote
                                required
                                label="Pilih Kecamatan"
                                placeholder=""
                                url={`/region/${regency?.id}/district`}
                                row={(d) => {
                                    return {
                                        value: d?.id,
                                        label: `${d?.name}`,
                                    };
                                }}
                                onSelected={$handleDistrictSelect}
                                keys={regency?.id}
                                isMulti
                            />}
                        </div>

                        <div className="table-responsive mb-5">
                            <table
                                width="100%"
                                height="350"
                                className="table table-hover table-vcenter card-table datatable border"
                            >
                                <thead className="bg-light">
                                    <tr className="th">
                                        <td
                                            style={{
                                                whiteSpace: 'initial',
                                                textAlign: 'center',
                                            }}
                                        >
                                            No
                                        </td>
                                        <td
                                            style={{
                                                whiteSpace: 'initial',
                                                width: '600px',
                                            }}
                                        >
                                            Region
                                        </td>
                                        <td
                                            style={{
                                                whiteSpace: 'initial',
                                                textAlign: 'center',
                                            }}
                                        ></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coverages?.map((d, i) => (
                                        <tr key={i}>
                                            <td
                                                style={{
                                                    whiteSpace: 'initial',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {i + 1}
                                            </td>
                                            <td
                                                style={{
                                                    whiteSpace: 'initial',
                                                    width: '300px',
                                                }}
                                            >
                                                {`${d?.kanwil_name}, ${d?.regency_name}, ${d?.district_name}`}
                                            </td>
                                            <td
                                                style={{
                                                    whiteSpace: 'initial',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id={`tooltip-top`}>Hapus</Tooltip>}
                                                >
                                                    <div onClick={() => $handleRemove(i)}>
                                                        <Fi.FiTrash2 color="red" />
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100">
                        <div className="col">
                            <Button
                                type="button"
                                className='btn btn-light w-100'
                                onClick={() => onClose()}
                            >
                                Batal
                            </Button>
                        </div>
                        <div className="col">
                            <Button
                                type="button"
                                className={cn(
                                    StateForm?.loading ? 'btn-loading' : '',
                                    'btn btn-primary w-100'
                                )}
                                disabled={StateForm?.loading}
                                onClick={$handleSubmit}
                            >
                                Simpan
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </>
        )
    }

    return null

}
