import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeliveryLine } from 'services/action';
import { Page } from 'ui/components';
import { PODImage, SectionDelivery, SectionInfo } from './component/detail';
import { Action } from './component/detail/action';

const initialState = {
  data: null,
};

const DetailLineScreen = ({ match }) => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['DeliveryLine.Show']
  );
  const [{ data }, setState] = React.useState(initialState);

  const $onLoad = async (id) => {
    const req = await Dispatch(DeliveryLine.$show(id));

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        data: req?.data,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data: null,
      }));
    }
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, [match]);

  return (
    <Page className="col-md-8 col-sm-12 bordered bg-white">
      <Page.Header
        pretitle="SPM"
        title={data?.manifest}
        status={data?.status}
        backTo="/spm"
      >
        <Action data={data} onSubmit={() => $onLoad(match?.params?.id)} />
      </Page.Header>
      <Page.Body className="bg-light mt-3 mt-md-0 bordered">
        <div className="p3">
          <div className="row">
            <div className="col-md-8">
              <SectionInfo data={data} loading={StateLoading} />
              <SectionDelivery data={data?.delivery} loading={StateLoading} />
            </div>
            <div className="col-md-4">
              <PODImage
                data={data}
                onSubmit={() => $onLoad(match?.params?.id)}
              />
            </div>
          </div>
        </div>
      </Page.Body>
    </Page>
  );
};

export default DetailLineScreen;
