import React from 'react';
import cn from 'classnames';
// import { useSelector } from 'react-redux';

const Button = ({
  type,
  children,
  className,
  onClick,
  error,
  loading,
  disabled,
}) => {
  // const StateForm = useSelector((state) => state.Form);
  // const StateLoading = useSelector((state) => state?.Activity?.processing);

  const cl = cn('btn btn-action', className, loading ? 'btn-loading' : null);

  const err = cn('btn btn-danger py-3 btn-action', className);

  return (
    <>
      <button
        type={type}
        disabled={loading ? loading : disabled ? disabled : false}
        className={error ? err : cl}
        onClick={onClick}
      >
        {children}
      </button>
      {error ? <div className="invalid-feedback d-block">{error}</div> : null}
    </>
  );
};

export default Button;
