import React from "react";
import icon from "ui/assets/empty.png";

const Empty = ({ title, subtitle }) => {
  return (
    <div className="text-center">
      <img
        src={icon}
        alt=""
        className="img-fluid img-empty"
        style={{ height: 500 }}
      />
      <p className="empty-title">{title}</p>
      <p className="empty-subtitle text-muted">{subtitle}</p>
    </div>
  );
};

export default Empty;
