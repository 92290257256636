import config from 'services/table/const';
import { formatDate } from 'utilities/common';
import { currencyFormat } from 'utilities/common';
import Action from './action';

let table = { ...config };
table.url = '/rtt';
table.columns = {
  code: {
    title: 'Kode',
    sortable: true,
    width: 100,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold">{row?.code}</div>
        </div>
      );
    },
  },

  dispatched_at: {
    title: 'Tanggal RTT',
    sortable: false,
    width: 100,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {formatDate(row?.dispatched_at, 'DD/MM/YYYY')}
          </div>
        </div>
      );
    },
  },

  bansos: {
    title: 'Bansos',
    width: 100,
    sortable: false,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.bansos?.name}
          </div>
        </div>
      );
    },
  },

  dispatched_by: {
    title: 'Dibuat Oleh',
    sortable: false,
    width: 100,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.dispatched_by}
          </div>
          <small className="info">
            {formatDate(row?.created_at)}
          </small>
        </div>
      );
    },
  },

  region: {
    title: 'Kanwil - Kota / Kab',
    sortable: false,
    alias: 'region.kanwil_name',
    width: 180,
    component: (row) => {
      return (
        <div className="table-col ps-2">
          <div className="row">
            <div className="text-ellipsis fs-8 text-uppercase">
              {row?.region?.kanwil_name},{' '}
              {row?.region?.regency_name}
            </div>
          </div>
        </div>
      );
    },
  },

  total_kpm: {
    title: 'Total PBP',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 120,
    component: (row) => {
      return <div className="table-col">{currencyFormat(row?.total_kpm)}</div>;
    },
  },

  document_status: {
    title: 'Status',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 120,
    component: (row) => {
      return (
        <span className={`badge status-${row?.document_status}-lt ms-auto`}>
          {row?.document_status?.replace('new', 'Baru')}
        </span>
      );
    },
  },

  action: {
    title: '',
    width: 50,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};
export default table;
