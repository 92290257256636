import React from 'react';

import ActionAccept from './accept';
import ActionReject from './reject';

const ReportAction = ({ data, type, accepted, rejected, uploaded, onClose, approved, received }) => {
  if (!uploaded && !(accepted || rejected)) {
    return <span>&nbsp;</span>;
  }

  return (
    <>
        <div className='row'>
            {(accepted || rejected) && (
                <div className='col-12'>
                    <span className={`badge status-${accepted ? 'received': 'danger'}-lt ms-auto`}>
                        {accepted ? 'Disetujui' : 'Ditolak'}
                    </span>
                </div>
            )}
            {!(accepted || rejected) ? (
                <div className='gap-2 d-block d-flex justify-content-center'>
                    <ActionAccept data={data} type={type} accepted={accepted} rejected={rejected} onClose={onClose}/>
                    <ActionReject data={data} type={type} accepted={accepted} rejected={rejected} onClose={onClose}/>
                </div>
            ): (
                <>
                    <div className={(accepted || rejected) ? 'col-12': 'col-6'}>
                        <ActionAccept data={data} type={type} accepted={accepted} rejected={rejected} onClose={onClose}/>
                    </div>
                    <div className={(accepted || rejected) ? 'col-12': 'col-6'}>
                        <ActionReject data={data} type={type} accepted={accepted} rejected={rejected} onClose={onClose}/>
                    </div>
                </>
            )}
        </div>
    </>

  );
};

export default ReportAction;
