import UsergroupScreen from './index';

const routes = [
  {
    path: '/master/usergroup',
    component: UsergroupScreen,
    usergroup: ['6ca4fba3-b0a2-4266-909e-2471987025f2'],
  },
];

export default routes;
