import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { currencyFormat } from 'utilities/common';

const TableRender = ({ name, columns, onSorted }) => {
  const Session = useSelector((state) => state.Auth?.session);
  const StateTable = useSelector((state) => state?.Table?.data[name]?.data);
  const History = useHistory();
  const StateSorting = useSelector(
    (state) => state?.Table?.data[name]?.sorting
  );
  const StateEmpty = useSelector((state) => state?.Table?.data[name]?.isEmpty);

  const checkSorted = () => {
    let result = { field: StateSorting, sort: 'asc' };

    if (StateSorting.charAt(0) === '-') {
      result.sort = 'desc';
      result.field = result.field.substring(1);
    }

    return result;
  };

  const onFieldSorted = (field, column) => {
    if (!column?.sortable) return false;

    const sorting = checkSorted();

    let sortby = field;
    if (typeof column.alias !== 'undefined') {
      sortby = column.alias;
    }

    if (sortby === sorting.field) {
      if (sorting.sort === 'asc') {
        sortby = '-' + sortby;
      }
    }

    onSorted(sortby);
  };

  const Th = ({ field, column }) => {
    let visible = true;

    if (column?.visible) {
      const split = column?.visible?.split('ex ')

      if (split?.length > 1) {
        if (Session?.usergroup?.id === split[1]) {
          visible = false
        }
      }else{
        if (Session?.usergroup?.id !== split[0]) {
          visible = false
        }
      }
    }

    if (visible === false) return null;

    const sorting = checkSorted();

    const c = cn(
      column?.classHeader,
      column?.sortable ? 'sorting' : null,
      sorting.field === field || sorting.field === column?.alias
        ? 'sorting_' + sorting.sort
        : ''
    );

    const Sorting = () => {
      if (column?.sortable === false) return null;

      return <span className="sort" />;
    };

    return (
      <th
        className={c}
        width={column?.width}
        onClick={() => onFieldSorted(field, column)}
      >
        {column?.title}
        {(Session?.usergroup?.id === '6c5128cc-e5e8-4dbe-8204-cd532a7e229c' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2') && (
          <span className='d-block'>{column?.titleChild}</span>
        )}
        <Sorting />
      </th>
    );
  };

  const Td = ({ field, column, data }) => {
    let visible = true;

    if (column?.visible) {
      const split = column?.visible?.split('ex ')

      if (split?.length > 1) {
        if (Session?.usergroup?.id === split[1]) {
          visible = false
        }
      }else{
        if (Session?.usergroup?.id !== split[0]) {
          visible = false
        }
      }
    }

    if (visible === false) return null;

    const c = cn(column?.class);

    if (column?.component && React.isValidElement(column?.component(data))) {
      return (
        <td
          width={column?.width}
          className={c}
          onClick={() =>
            column?.url
              ? History.push(column?.url(data))
              : null
          }
        >
          {column?.component(data)}
        </td>
      );
    }

    const value = () => {
      if (column?.format_number === true) {
        return currencyFormat(data[field]);
      }

      return data[field];
    };

    return (
      <td className={c} width={column?.width}>
        {value()}
      </td>
    );
  };

  const EmptyData = () => {
    if (!StateEmpty) return null;

    return (
      <div className="row">
        <div className="empty col-lg-12 truck">
          <div className="truck-wrapper">
            <div className="truck">
              <div className="truck-container"></div>
              <div className="glases"></div>
              <div className="bonet"></div>
              <div className="base"></div>
              <div className="base-aux"></div>
              <div className="wheel-back"></div>
              <div className="wheel-front"></div>
              <div className="smoke"></div>
            </div>
          </div>
          <p className="empty-title">Hasil tidak ditemukan</p>
          <p className="empty-subtitle text-muted">
            Coba sesuaikan pencarian atau filter Anda untuk menemukan apa yang
            Anda cari.
            <br /> Atau mungkin belum ada datanya!
          </p>
        </div>
      </div>
    );
  };

  const HasData = () => {
    if (StateEmpty) return <EmptyData />;

    return (
      <div
        className="table-responsive m-0 table-overflow"
        style={{ minHeight: '60vh' }}
      >
        <table
          className="table table-hover table-vcenter card-table datatable"
          width="100%"
        >
          <thead>
            <tr>
              {Object.keys(columns).map((i, c) => (
                <Th column={columns[i]} field={i} key={c} />
              ))}
            </tr>
          </thead>
          <tbody>
            {StateTable.map((d, i) => (
              <tr key={i}>
                {Object.keys(columns).map((c, x) => (
                  <Td column={columns[c]} field={c} data={d} key={x} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return <HasData />;
};

export default TableRender;
