import React from 'react';

import FormRadio from 'react-bootstrap/Form';
import FormInputAddon from './form.input.addon';
import Checkbox from './form.checkbox';
import FormInputFile from './form.select.file';
import FormTextbox from './form.textbox';
import FormInputMasked from './form.mask';
import TimeInput from './form.time';

import type { FormEvents } from 'utilities/events';
import CustomCheckbox from './form.custom.checkbox';
import FormInput from './form.input';

export type Props = {|
  ...FormEvents,
  +children?: React.Node,
  +className?: string,
  +action?: string,
  +method?: string,
  +autoComplete: 'on' | 'off',
|};

function Form({
  className,
  children,
  action,
  method,
  onSubmit,
  autoComplete,
}: Props): React.Node {
  return (
    <form
      className={className}
      onSubmit={(e) => {
        onSubmit(e);
        e.preventDefault();
      }}
      action={action}
      method={method}
      autoComplete={autoComplete}
    >
      {children}
    </form>
  );
}

Form.defaultProps = {
  autoComplete: 'off',
  method: 'POST',
};

Form.Input = FormInputMasked;
Form.Textbox = FormTextbox;
Form.InputAddon = FormInputAddon;
Form.Checkbox = Checkbox;
Form.CustomCheckbox = CustomCheckbox;
Form.InputFile = FormInputFile;
Form.InputDefault = FormInput;
Form.Radio = FormRadio.Check;
Form.Time = TimeInput;

export default Form;
