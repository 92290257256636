import { $done, $processing } from 'services/activity/action';
import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';
import { $failure, $requesting, $success } from '../form/action';

export const MODULE = 'POD';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $upload = ActionCreator.Async(MODULE, 'Upload', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/pod`, payload)
      .then((response) => {
        dispatch($upload.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $delivery = ActionCreator.Async(
  MODULE,
  'Get.Delivery',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($processing($delivery.OPERATION));

      return await Rest.GET(`${API_URL}/pod/delivery/${id}`, payload)
        .then((response) => {
          dispatch($delivery.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($delivery.OPERATION)));
    };
  }
);

export const $line = ActionCreator.Async(MODULE, 'Get.Line', (id, payload) => {
  return async (dispatch) => {
    dispatch($processing($line.OPERATION));

    return await Rest.GET(`${API_URL}/pod/line/${id}`, payload)
      .then((response) => {
        dispatch($line.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($line.OPERATION)));
  };
});

export const $recon = ActionCreator.Async(
  MODULE,
  'Get.Recon',
  (bansos_id, village_id, payload) => {
    return async (dispatch) => {
      dispatch($processing($recon.OPERATION));

      return await Rest.GET(
        `${API_URL}/pod/bansos/${bansos_id}/village/${village_id}`,
        payload
      )
        .then((response) => {
          dispatch($recon.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($recon.OPERATION)));
    };
  }
);

export const $getRegency = ActionCreator.Async(
  MODULE,
  'Get.Regency',
  (bansos_id, regency_id, payload) => {
    return async (dispatch) => {
      dispatch($processing($getRegency.OPERATION));

      return await Rest.GET(`${API_URL}/pod/bansos/${bansos_id}/regency/${regency_id}`, payload)
        .then((response) => {
          dispatch($getRegency.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getRegency.OPERATION)));
    };
  }
);

export const $delete = ActionCreator.Async(MODULE, 'Delete', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.DELETE(`${API_URL}/pod/${id}`, payload)
      .then((response) => {
        dispatch($delete.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $update = ActionCreator.Async(MODULE, 'Update', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/pod/${id}`, payload)
      .then((response) => {
        dispatch($update.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $updateSorting = ActionCreator.Async(MODULE, 'Update.Sorting', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/pod/sorting`, payload)
      .then((response) => {
        dispatch($updateSorting.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $deleteByVillage = ActionCreator.Async(MODULE, 'Delete.Village', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.DELETE(`${API_URL}/pod/village`, payload)
      .then((response) => {
        dispatch($deleteByVillage.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $getRTT = ActionCreator.Async(
  MODULE,
  'Get.RTT',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($processing($getRTT.OPERATION));

      return await Rest.GET(`${API_URL}/pod/rtt/${id}`, payload)
        .then((response) => {
          dispatch($getRTT.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getRTT.OPERATION)));
    };
  }
);

export const $getKpm = ActionCreator.Async(
  MODULE,
  'Get.Kpm',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($processing($getKpm.OPERATION));

      return await Rest.GET(`${API_URL}/pod/kpm/${id}`, payload)
        .then((response) => {
          dispatch($getKpm.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($getKpm.OPERATION)));
    };
  }
);