import React from 'react';
import { useSelector } from 'react-redux';

import { Select } from 'ui/components';
import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';

import tableConfig from './table.config';

const initialState = {
  kanwil: null,
  province: null
};

const ReportRegency = ({ location }) => {
  const Bansos = useSelector((state) => state?.Auth?.project);
  const ServiceTable = useTable('report_document_regency', tableConfig, false);
  const StateTable = useSelector((state) => state?.Table?.data['report_document_regency']);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );
  const [{ kanwil, province }, setState] = React.useState(initialState);
  const paramKanwil = new URLSearchParams(location?.search).get('kanwil_id');
  const paramProvince = new URLSearchParams(location?.search).get('province_id');

  const $handleKanwil = async (e) => {
    setState((prevState) => ({
      ...prevState,
      kanwil: e,
    }));

    ServiceTable.filter('kanwil_id', e?.kanwil?.id);
  };

  const $handleProvince = async (e) => {
    setState((prevState) => ({
      ...prevState,
      province: e,
    }));

    ServiceTable.filter('province_id', e?.province?.id);
  };

  const $onLoad = async() => {
    const filters = {
      bansos_id: Bansos?.id,
    };

    let sk = null
    let sp = null

    if (paramKanwil) {
      filters.kanwil_id = paramKanwil;
      filters.province_id = null
      sk = paramKanwil
      sp = null
    }

    if (paramProvince) {
      filters.province_id = paramProvince
      filters.kanwil_id = null;
      sp = paramProvince
      sk = null
    }

    if (!paramKanwil && !paramProvince) {
      if (StateTable?.filter?.kanwil_id) {
        filters.kanwil_id = StateTable?.filter?.kanwil_id;
        sk = StateTable?.filter?.kanwil_id
      }
    }

    setState((prevState) => ({
      ...prevState,
      kanwil: {kanwil_id: sk},
      province: {province_id: sp},
    }));

    ServiceTable.boot(filters);
  }

  React.useEffect(() => {
    $onLoad();
  }, []);

  return (
    <div className="sub-page">
      <div className="p-3">
        <div className="row justify-content-between">
          {!paramProvince ? (
            <div className="col-md-4 col-12 order-2 order-md-1">
              <Select.Remote
                required
                label="Pilih Kanwil"
                placeholder=""
                url="/report/document/kanwil"
                initialValue={paramKanwil || kanwil?.kanwil_id}
                keys={paramKanwil || kanwil?.kanwil_id}
                params={{
                  bansos_id: Bansos?.id,
                }}
                row={(d) => {
                  return { value: d.kanwil, label: d?.kanwil?.name };
                }}
                onSelected={(c) => {
                  $handleKanwil(c);
                }}
              />
            </div>
          ): (
            <div className="col-md-4 col-12 order-2 order-md-1">
              <Select.Remote
                required
                label="Pilih Provinsi"
                placeholder=""
                url="/report/document/province"
                initialValue={paramProvince || province?.province_id}
                keys={paramProvince || province?.province_id}
                params={{
                  bansos_id: Bansos?.id,
                }}
                row={(d) => {
                  return { value: d.province, label: d?.province?.name };
                }}
                onSelected={(c) => {
                  $handleProvince(c);
                }}
              />
            </div>
          )}
          <div className="col-md-4 col-12 order-1 order-md-2">
            <ServiceTable.Tools downloadable />
          </div>
        </div>
      </div>
      <div className="sub-page-body border-bottom">
        {StateLoading ? (
          <SkeletonTable headerCount={6} bodyCount={10} />
        ) : (
          <ServiceTable.Render />
        )}
      </div>
      <div className="sub-page-footer">
        {StateLoading ? <SkeletonPageFooter /> : <ServiceTable.Pagination />}
      </div>
    </div>
  );
};

export default ReportRegency;
