import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Skeletons } from 'ui/components/skeleton';

const Maps = React.lazy(() => import('ui/components/mapbox/map.indonesia'));

const DashboardUserNational = () => {
  const Session = useSelector((state) => state?.Auth?.project);

  return (
    <div>
      <Suspense fallback={<Skeletons height="450px" />}>
        <Maps bansos_id={Session?.id} />
      </Suspense>
    </div>
  );
};

export default DashboardUserNational;
