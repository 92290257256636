import React from 'react';
import cn from 'classnames';
import Header from './section.header';

const MainBar = ({ children, className, config }) => {
  const SectionHeader = () => {
    if (!config?.title) return null;

    return (
      <Header
        title={config?.title}
        pretitle={config?.pretitle}
        action={config?.action}
        submenu={config?.submenu}
      />
    );
  };

  return (
    <div className="container-fluid p-0 m-0 overflow-hidden">
      <div className="row gx-lg-4 justify-content-center">
        <div className={cn(className ? className : 'col-lg-12', 'mainbar')}>
          <SectionHeader />
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainBar;
