import config from 'services/table/const';
import { formatDate } from 'utilities/common';
import Action from './table.action';
import ActiveStatus from './table.status';

let table = { ...config };
table.url = '/user';
table.filter = {related: true}
table.columns = {
  name: {
    title: 'Nama',
    sortable: true,
    format_number: false,
    width: 100,
    class: '',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.name}
          </div>
          <small className="info ">{row?.usergroup?.name}</small>
        </div>
      );
    },
  },
  username: {
    title: 'Username',
    sortable: true,
    format_number: false,
    width: 100,
    class: '',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.username}
          </div>
          {row?.warehouse && (
            <small className="info ">GD/WH: {row?.warehouse?.name}</small>
          )}
        </div>
      );
    },
  },
  registered_at: {
    title: 'Terdaftar Pada',
    sortable: false,
    format_number: false,
    width: 100,
    class: '',
    component: (row) => {
      return (
        <div className="table-col">
          <small className="info text-uppercase fw-semibold">
            {formatDate(row?.registered_at, 'DD/MM/YYYY - HH:mm')} WIB
          </small>
        </div>
      );
    },
  },
  last_login_at: {
    title: 'Aktifitas Terakhir',
    sortable: false,
    format_number: false,
    width: 100,
    class: '',
    component: (row) => {
      return (
        <div className="table-col">
          <small className="info text-uppercase fw-semibold">
            {row?.last_activity_at !== '0001-01-01T00:00:00Z'
              ? formatDate(row?.last_activity_at, 'DD MMM YYYY - HH:mm') +
                ' WIB'
              : '-'}
          </small>
        </div>
      );
    },
  },
  is_active: {
    title: 'Status',
    sortable: true,
    format_number: false,
    width: 50,
    class: '',
    component: (row) => {
      return <ActiveStatus data={row} />;
    },
  },
  action: {
    title: '',
    sortable: false,
    width: 50,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};

export default table;
