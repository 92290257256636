import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { POD } from 'services/action';
import pdf from 'ui/assets/pdf.png';
import _ from 'underscore';


const initialState = {
  images: [],
  isSorting: false,
  showPdf: null,
  showImg: null,
  zoomFile: null
};

const ActionSorting = ({ data, type, onClose, isLine }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const Project = useSelector((state) => state.Auth?.project);
  const Session = useSelector((state) => state?.Auth?.session);

  const [{isSorting, images, showPdf, showImg, zoomFile}, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      isSorting: false,
      images: null,
    }));
  };

  const $handleSorting = async () => {
    let changes = [];
    _.each(images, function(x) {
      changes.push({id: x?.id, serial_id: x?.serial_id})
    })

    const payload = {
      changes: changes
    }

    const result = await Dispatch(POD.$updateSorting(payload));
    if (result?.message === 'success') {
      $handleClose()
      onClose()
    }
  }

  const moveUp = (key) => {
    let me = images[key]
    let to = images[key-1]

    const mst = me.serial_id
    const tst = to.serial_id

    me.serial_id = tst
    to.serial_id = mst

    images.splice(key-1, 1, me)
    images.splice(key, 1, to)

    setState((prevState) => ({
      ...prevState,
      images: images,
    }));
  }

  const moveDown = (key) => {
    let me = images[key]
    let to = images[key+1]

    const mst = me.serial_id
    const tst = to.serial_id

    me.serial_id = tst
    to.serial_id = mst

    images.splice(key+1, 1, me)
    images.splice(key, 1, to)

    setState((prevState) => ({
      ...prevState,
      images: images,
    }));

  }

  const $getByVillage = async () => {
    const req = await Dispatch(
      POD.$recon(Project?.id, data?.village_id, { type: type })
    );

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        images: req?.data,
      }));
    }
  };

  const $getByLine = async () => {
    const req = await Dispatch(
      POD.$line(data?.line_id, {
        type: type,
        bansos_id: Project?.id,
        village_id: data?.village_id,
      })
    );

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        images: req?.data,
      }));
    }
  };

  const $loadImage = async () => {
    if (isLine) {
      $getByLine();
    } else {
      $getByVillage();
    }
  }

  React.useEffect(() => {
    if (isSorting) {
      $loadImage();
    }

  }, [isSorting])

  if (Session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' || Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' || Session?.usergroup?.id === '302d772c-77db-47ea-ab5a-83b6be4d3787') return null

  return (
    <>
      <button
        className="btn btn-sm btn-light rounded-2 col-3 float-end"
        onClick={() =>
          setState((prevState) => ({ ...prevState, isSorting: true }))
        }
      >
        <Fi.FiCheck size={15} className="m-1" />
        Urutkan Dokumen
      </button>

      {/* modal update */}
      <Modal
        show={isSorting}
        onHide={$handleClose}
        backdrop="static"
        centered
        size="fullscreen"
      >
        <Modal.Header closeButton>
          <Modal.Title>Urutkan Dokumen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {images?.map((d, i) => (
            <div className='border-bottom p-2' key={i}>
              <div className='row'>
                <div className="col-1 pe-0">
                  <div className="d-flex flex-column gap-2">
                    {i > 0 && (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary p-0"
                        onClick={() => moveUp(i)}
                      >
                        <Fi.FiChevronUp size={22} />
                      </button>
                    )}

                    {i !== (images?.length - 1) && (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary p-0"
                        onClick={() => moveDown(i)}
                      >
                        <Fi.FiChevronDown size={22} />
                      </button>
                    )}
                  </div>
                </div>
                <div className='col-11'>
                  <img
                    className='img-hover-sorting'
                    key={i}
                    src={`${d?.image?.includes('.pdf') ? pdf : d?.image}`}
                    alt=""
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        showPdf: d?.image?.includes('.pdf') ? true: false,
                        showImg: d?.image?.includes('.pdf') ? false: true,
                        zoomFile: d?.image,
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="secondary"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleSorting}
                disabled={StateForm?.loading}
              >
                Simpan
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal pdf */}
      <Modal
        show={showPdf}
        onHide={() =>
          setState((prevState) => ({ ...prevState, showPdf: false }))
        }
        className="text-center bg-transparent"
        contentClassName="bg-transparent "
        centered
        size="xl"
      >
        <Modal.Body className="bg-transparent" style={{ height: '95vh' }}>
          <div
            className="btn-close"
            onClick={() =>
              setState((prevState) => ({ ...prevState, showPdf: false }))
            }
          />

          <iframe
            title=" "
            src={zoomFile}
            style={{
              width: '100%',
              height: '100%',
            }}
            frameBorder="0"
          />
        </Modal.Body>
      </Modal>

      {/* modal image */}
      <Modal
        show={showImg}
        onHide={() =>
          setState((prevState) => ({...prevState, showImg: false }))
        }
        centered
        className="text-center"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>POD Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mt-4" style={{ maxHeight: 800 }}>
            <img
              src={zoomFile?.replace('_thumb', '')}
              alt=""
              className="img-fluid"
              style={{ maxHeight: 800 }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActionSorting;
