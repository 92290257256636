import React from 'react';
import { Button, ButtonGroup, DropdownButton, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Delivery, Table } from 'services/action';
import { FormPartial, FormWarehouse } from '../form';

const initialState = {
  isShow: false,
  isPublish: false,
  isUpdate: false,
  isWarehouse: false,
};

const Action = ({ data }) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.session);
  const StateTable = useSelector((state) => state?.Table?.data['spm']);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Delivery.Delete']
  );
  const [{ isShow, isPublish, isUpdate, isWarehouse }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({ ...prevState, isShow: false, isPublish: false, isUpdate: false, isWarehouse: false }));
  };

  const $handleDelete = async () => {
    const del = await Dispatch(Delivery.$delete(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      Dispatch(Table.$reload('spm', StateTable));
    }
  };

  const $handlePublish = async () => {
    const del = await Dispatch(Delivery.$publish(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      Dispatch(Table.$reload('spm', StateTable));
    }
  };

  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        variant="transparent"
        size="md"
        className="no-carret tbl-action"
        title={<Fi.FiMoreVertical size={13} color="gray" />}
      >
        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={`/spm/${data?.id}`}
        >
          <Fi.FiClipboard size={15} color="#202020" className="me-2" />
          Detil
        </Link>
        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={`/print/bast/${data?.id}`}
          target="_blank"
        >
          <Fi.FiPrinter size={15} color="#202020" className="me-2" />
          Print BAST / STT
        </Link>
        {Session?.usergroup?.id !== '6c5128cc-e5e8-4dbe-8204-cd532a7e229c' && (
          <>
            {data?.document_count?.received === 0 ? (
              <>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  role="button"
                  to={`/spm/${data?.id}/update`}
                >
                  <Fi.FiEdit size={15} color="#202020" className="me-2" />
                  Perbaharui
                </Link>

                <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, isUpdate: true }))
                  }
                >
                  <Fi.FiEdit size={15} className="me-2" />
                  Perbaharui Informasi
                </button>

                {(Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2') && (
                  <button
                    className="dropdown-item d-flex align-items-center"
                    onClick={() =>
                      setState((prevState) => ({ ...prevState, isWarehouse: true }))
                    }
                  >
                    <Fi.FiEdit size={15} className="me-2" />
                    Perbaharui Gudang
                  </button>
                )}

                <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, isShow: true }))
                  }
                >
                  <Fi.FiTrash2 size={15} className="me-2" />
                  Hapus
                </button>

                {/* <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, isPublish: true }))
                  }
                >
                  <Fi.FiCheckCircle size={15} className="me-2" />
                  Publish
                </button> */}
              </>
            ): (
              <>
                <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, isUpdate: true }))
                  }
                >
                  <Fi.FiEdit size={15} className="me-2" />
                  Perbaharui Informasi
                </button>

                {(Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2') && (
                  <button
                    className="dropdown-item d-flex align-items-center"
                    onClick={() =>
                      setState((prevState) => ({ ...prevState, isWarehouse: true }))
                    }
                  >
                    <Fi.FiEdit size={15} className="me-2" />
                    Perbaharui Gudang
                  </button>
                )}
              </>
            )}
          </>
        )}
      </DropdownButton>

      {/* modal delete */}
      <Modal
        show={isShow}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang sudah dihapus tidak bisa dipulihkan
          </p>

          {/* {isError ? (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {isError?.id}
            </div>
          ) : null} */}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateLoading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleDelete}
                disabled={StateLoading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal publish */}
      <Modal
        show={isPublish}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-primary" />
          <Fi.FiAlertTriangle className="mb-2 text-primary" size={90} />
          <h3>Anda yakin?</h3>
          {/* {isError ? (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {isError?.id}
            </div>
          ) : null} */}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="primary"
                className={StateLoading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handlePublish}
                disabled={StateLoading}
              >
                Publish
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal update */}
      <Modal
        show={isUpdate}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Perbaharui Informasi SPM</Modal.Title>
        </Modal.Header>
        <FormPartial onClose={() => {
          $handleClose();
          Dispatch(Table.$reload('spm', StateTable));
        }} data={data} />
      </Modal>

      {/* modal update warehouse */}
      <Modal
        show={isWarehouse}
        onHide={$handleClose}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Perbaharui Gudang SPM</Modal.Title>
        </Modal.Header>
        <FormWarehouse onClose={() => {
          $handleClose();
          Dispatch(Table.$reload('spm', StateTable));
        }} data={data} />
      </Modal>
    </>
  );
};

export default Action;
