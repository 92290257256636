import React from 'react';
import { BiAddToQueue, BiRevision } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';

import { Modal } from 'react-bootstrap';
import { Table } from 'services/action';
import { Page } from 'ui/components';
import _ from 'underscore';
import { FormWarehouse } from '../../_shared';
import tableConfig from './table.config';

const initialState = {
  privilege: null,
  isShow: false,
};

const WarehouseScreen = () => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state.Auth?.session);
  const HubTable = useTable('warehouse', tableConfig);
  const StateTable = useSelector((state) => state?.Table?.data['warehouse']);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );

  const [{ isShow, privilege }, setState] = React.useState(initialState);

  const $getPriviledge = async () => {
    const x = _.findWhere(Session?.privileges, {
      service: 'nvo.platform.user',
    });
    setState((prevState) => ({ ...prevState, privilege: x }));
  };

  React.useEffect(() => {
    $getPriviledge();
  }, [Session]);

  return (
    <Page className="col-12 col-md-8 bordered">
      <Page.Header pretitle="Daftar" title="Warehouse" isLoading={StateLoading}>
        {(!Session?.privileges || privilege?.action === 'manage') && (
          <>
            <button
              onClick={() =>
                setState((prevState) => ({ ...prevState, isShow: true }))
              }
              className="btn btn-primary py-1 ms-1"
            >
              <BiAddToQueue color="white" size="14" />
              <span className="d-none d-md-block ms-2">Tambah Warehouse</span>
            </button>

            <Link to='/transfer' className="btn btn-secondary py-1">
              <BiRevision color="white" size="14" />
              <span className="d-none d-md-block ms-1">Pindahkan Warehouse</span>
            </Link>
          </>
        )}
      </Page.Header>

      <Page.Navigation toolbar>
        <HubTable.Tools isLoading={StateLoading} downloadable />
      </Page.Navigation>
      <Page.Body className="row m-0 p-0">
        <div className="col-md-12 m-0 p-0">
          {StateLoading ? (
            <SkeletonTable headerCount={6} bodyCount={10} />
          ) : (
            <HubTable.Render />
          )}
        </div>

        <Modal
          show={isShow}
          onHide={() =>
            setState((prevState) => ({ ...prevState, isShow: false }))
          }
          backdrop="static"
          centered
          size="xl"
          enforceFocus={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Tambah Warehouse</Modal.Title>
          </Modal.Header>

          <FormWarehouse
            onClose={() => {
              setState((prevState) => ({ ...prevState, isShow: false }));
            }}
            onSuccess={() => {
              setState((prevState) => ({ ...prevState, isShow: false }));
              Dispatch(Table.$reload('warehouse', StateTable));
            }}
          />
        </Modal>
      </Page.Body>

      <Page.Footer>
        {StateLoading ? <SkeletonPageFooter /> : <HubTable.Pagination />}
      </Page.Footer>
    </Page>
  );
};

export default WarehouseScreen;
