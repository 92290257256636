import { $done, $processing } from 'services/activity/action';
import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';
import { $failure, $requesting, $success } from '../form/action';

export const MODULE = 'User';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $get = ActionCreator.Async(MODULE, 'Get', (payload) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}/user`, payload)
      .then((response) => {
        dispatch($get.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($get.OPERATION)));
  };
});

export const $create = ActionCreator.Async(MODULE, 'Create', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/user`, payload)
      .then((response) => {
        dispatch($create.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $show = ActionCreator.Async(MODULE, 'Show', (id) => {
  return async (dispatch) => {
    dispatch($processing($show.OPERATION));

    return await Rest.GET(`${API_URL}/user/${id}`)
      .then((response) => {
        dispatch($show.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($show.OPERATION)));
  };
});

export const $update = ActionCreator.Async(MODULE, 'Update', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/user/${id}`, payload)
      .then((response) => {
        dispatch($update.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $updateCoverage = ActionCreator.Async(MODULE, 'UpdateCoverage', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/user/${id}/coverage`, payload)
      .then((response) => {
        dispatch($updateCoverage.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $activated = ActionCreator.Async(
  MODULE,
  'Activated',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(`${API_URL}/user/${id}/activate`, payload)
        .then((response) => {
          dispatch($activated.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $deactivated = ActionCreator.Async(
  MODULE,
  'Deactivated',
  (id, payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      return await Rest.PUT(`${API_URL}/user/${id}/deactivate`, payload)
        .then((response) => {
          dispatch($deactivated.success({ response: response?.data }));
          dispatch($success.action());
          return response;
        })
        .catch((error) => {
          dispatch($failure({ error }));
          return false;
        });
    };
  }
);

export const $delete = ActionCreator.Async(MODULE, 'Delete', (id) => {
  return async (dispatch) => {
    dispatch($processing($delete.OPERATION));

    return await Rest.DELETE(`${API_URL}/user/${id}`)
      .then((response) => {
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($delete.OPERATION)));
  };
});

export const $getArea = ActionCreator.Async(MODULE, 'GetArea', (id) => {
  return async (dispatch) => {
    dispatch($processing($show.OPERATION));

    return await Rest.GET(`${API_URL}/user/${id}/area`)
      .then((response) => {
        dispatch($show.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($show.OPERATION)));
  };
});