import ReportScreen from './index';

const routes = [
  {
    path: '/report/document',
    component: ReportScreen,
    usergroup: [
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
  },
  {
    path: '/report/document/*',
    component: ReportScreen,
    usergroup: [
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
  },
];

export default routes;
