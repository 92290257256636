import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'ui/components';

import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';

import tableConfig from './table.config';

const initialState = {
  kanwil: null,
  regency: null,
  district: null
};

const ReportVillage = ({ location }) => {
  const Bansos = useSelector((state) => state?.Auth?.project);
  const ServiceTable = useTable('report_document_village', tableConfig, false);
  const StateTable = useSelector((state) => state?.Table?.data['report_document_village']);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );
  const [{ kanwil, regency, district }, setState] = React.useState(initialState);

  const paramKanwil = new URLSearchParams(location?.search).get('kanwil_id');
  const paramRegency = new URLSearchParams(location?.search).get('regency_id');
  const paramDistrict = new URLSearchParams(location?.search).get('district_id');

  const $handleKanwil = async (e) => {
    setState((prevState) => ({
      ...prevState,
      kanwil: e,
      regency: null,
      district: null
    }));

    let filters = [
      {
        label: 'district_id',
        value: null
      },
      {
        label: 'regency_id',
        value: null
      },
      {
        label: 'kanwil_id',
        value: e?.kanwil?.id
      }
    ]

    ServiceTable.filter(filters);
  };

  const $handleRegency = async (e) => {
    setState((prevState) => ({
      ...prevState,
      regency: e,
      district: null
    }));

    let filters = [
      {
        label: 'district_id',
        value: null
      },
      {
        label: 'regency_id',
        value: e?.regency?.id
      },
    ]

    ServiceTable.filter(filters);
  };

  const $handleDistrict = async (e) => {
    setState((prevState) => ({
      ...prevState,
      district: e
    }));

    ServiceTable.filter('district_id', e?.district?.id);
  };

  const $onLoad = async() => {
    const filters = {
      bansos_id: Bansos?.id,
    };

    if (paramKanwil && paramRegency && paramDistrict) {
      filters.kanwil_id = paramKanwil;
      filters.regency_id = paramRegency;
      filters.district_id = paramDistrict;

      setState((prevState) => ({
        ...prevState,
        kanwil: {kanwil_id: paramKanwil},
        regency: {regency_id: paramRegency},
        district: {district_id: paramDistrict}
      }));
    } else if (StateTable?.filter?.district_id) {
      filters.kanwil_id = StateTable?.filter?.kanwil_id;
      filters.regency_id = StateTable?.filter?.regency_id;
      filters.district_id = StateTable?.filter?.district_id;

      setState((prevState) => ({
        ...prevState,
        kanwil: {kanwil_id: StateTable?.filter?.kanwil_id},
        regency: {regency_id: StateTable?.filter?.regency_id},
        district: {district_id: StateTable?.filter?.district_id}
      }));
    }

    ServiceTable.boot(filters);
  }

  React.useEffect(() => {
    $onLoad();
  }, []);

  return (
    <div className="sub-page">
      <div className="px-3">
        <ServiceTable.Tools downloadable />
      </div>
      <div className="sub-page-body">
        <div className="p-3">
          <div className="row">
            <div className="col-md-4 col-12 mb-2 mb-md-0 mt-n3 mt-md-0">
              <Select.Remote
                required
                label="Pilih Kanwil"
                placeholder=""
                url="/report/document/kanwil"
                initialValue={kanwil?.kanwil_id}
                keys={kanwil?.kanwil_id}
                params={{
                  bansos_id: Bansos?.id,
                }}
                row={(d) => {
                  return { value: d.kanwil, label: d?.kanwil?.name };
                }}
                onSelected={(c) => {
                  $handleKanwil(c);
                }}
              />
            </div>
            <div className="col-md-4 col-12 mb-2 mb-md-0">
              <Select.Remote
                required
                label="Pilih Kota/Kab"
                placeholder=""
                url={`/report/document/regency`}
                initialValue={regency?.regency_id}
                params={{
                  kanwil_id: kanwil?.kanwil_id,
                  bansos_id: Bansos?.id,
                }}
                row={(d) => {
                  return { value: d?.regency, label: d?.regency?.name };
                }}
                onSelected={(c) => {
                  $handleRegency(c);
                }}
                keys={kanwil?.kanwil_id}
                disabled={!kanwil}
              />
            </div>
            <div className="col-md-4 col-12 mb-2 mb-md-0">
              <Select.Remote
                required
                label="Pilih Kecamatan"
                placeholder=""
                url={`/report/document/district`}
                initialValue={district?.district_id}
                row={(d) => {
                  return { value: d?.district, label: d?.district?.name };
                }}
                params={{
                  regency_id: regency?.regency_id,
                  bansos_id: Bansos?.id,
                }}
                onSelected={(c) => {
                  $handleDistrict(c);
                }}
                keys={regency?.regency_id}
                disabled={!regency}
              />
            </div>
          </div>
        </div>

        {StateLoading ? (
          <SkeletonTable headerCount={6} bodyCount={10} />
        ) : (
          <ServiceTable.Render />
        )}
      </div>
      <div className="sub-page-footer">
        {StateLoading ? <SkeletonPageFooter /> : <ServiceTable.Pagination />}
      </div>
    </div>
  );
};

export default ReportVillage;
