import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'services/action';
import _ from 'underscore';

const ActiveStatus = ({ data }) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.session);
  // const StateTable = useSelector((state) => state?.Table?.data['hub']);

  const [setting, setSetting] = React.useState(null);
  const [active, setActive] = React.useState(null);

  const $handleToogle = async (data) => {
    if (active) {
      await Dispatch(User.$deactivated(data?.id));
      setActive(false);
    } else {
      await Dispatch(User.$activated(data?.id));
      setActive(true);
    }
  };

  const $getPriviledge = async () => {
    const x = _.findWhere(Session?.privileges, {
      service: 'nvo.platform.location.hub',
    });
    setSetting(x);
  };

  React.useEffect(() => {
    $getPriviledge();

    setActive(data?.is_active);
  }, [data]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <label className="mb-1 form-switch text-center">
        <input
          defaultChecked={data?.is_active}
          className="form-check-input"
          type="checkbox"
          onChange={() => $handleToogle(data)}
          disabled={setting && setting?.action !== 'manage'}
        />
      </label>
      <div className="text-center page-pretitle">
        {active === true ? 'Aktif' : 'Tidak Aktif'}
      </div>
    </div>
  );
};

export default ActiveStatus;
