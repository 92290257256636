import cn from 'classnames';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Warehouse } from 'services/action';
import { Form, Select } from 'ui/components';
import { SkeletonsForm } from 'ui/components/skeleton';

const initialState = {
  isLoading: true,
  kanwil: null,
  name: '',
  code: '',
  address: '',
};

export const FormWarehouse = ({ data, onSuccess, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);

  const dummy = Array.from({ length: 7 }, (v, i) => i);

  const [{ name, kanwil, code, address, isLoading }, setState] =
    React.useState(initialState);

  const $handleSubmit = async (c) => {
    const payload = {
      name,
      kanwil_id: kanwil?.id,
      code,
      address,
    };

    if (data) {
      const submit = await Dispatch(Warehouse.$update(data?.id, payload));
      if (submit?.message === 'success') {
        onSuccess();
      }
    } else {
      const submit = await Dispatch(Warehouse.$create(payload));
      if (submit?.message === 'success') {
        onSuccess();
      }
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  React.useEffect(() => {
    if (!data) return;

    let kanwil = null
    if (data?.kanwil_id) {
      kanwil = { id: data?.kanwil_id }
    }

    setState((prevState) => ({
      ...prevState,
      name: data?.name,
      code: data?.code,
      address: data?.address,
      kanwil: kanwil,
    }));
  }, [data]);

  if (isLoading) {
    return (
      <Modal.Body>
        <div className="row m-0">
          {dummy?.map((d, i) => (
            <div className="col-md-6 col-12" key={i}>
              <SkeletonsForm count={1} />
            </div>
          ))}
        </div>
      </Modal.Body>
    );
  }

  return (
    <Form autocomplete="off" onSubmit={$handleSubmit}>
      <Modal.Body>
        <div className="row">
          <div className="col-12 col-md-6">
            <Form.Input
              value={code || ''}
              required
              type="text"
              label="Kode"
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  code: e?.target?.value,
                }))
              }
              error={StateForm?.errors?.code}
            />
            <Form.Input
              value={name || ''}
              required
              type="text"
              label="Nama"
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  name: e?.target?.value,
                }))
              }
              error={StateForm?.errors?.name}
            />
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group mb-3 ">
              <div className="mb-3">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <Select.Remote
                        required
                        label="Pilih Kanwil"
                        placeholder=""
                        url="/region/kanwil"
                        initialValue={kanwil?.id}
                        row={(d) => {
                          return { value: d.id, label: d.name };
                        }}
                        onSelected={(c) => {
                          setState((prevState) => ({
                            ...prevState,
                            kanwil: c,
                          }));
                        }}
                        errors={StateForm?.errors?.kanwil_id}
                        key={kanwil?.id}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Form.Textbox
                required
                value={address || ''}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    address: e.target.value,
                  }))
                }
                rows={4}
                label="Alamat Lengkap"
                error={StateForm?.errors?.address}
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button
              type="button"
              className="btn btn-light w-100"
              onClick={() => onClose()}
            >
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className={cn(
                StateForm?.loading ? 'btn-loading' : '',
                'btn btn-primary w-100'
              )}
              disabled={StateForm?.loading}
              onClick={$handleSubmit}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Form>
  );
};
