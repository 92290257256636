import config from 'services/table/const';
import { formatDate } from 'utilities/common';
import Action from './action';

const ModaDesc = ({ data }) => {
  return (
    <div className="table-col">
      <div className="text-ellipsis fs-8 fw-semibold">
        {data?.name} ({data?.phone})
      </div>
      <small className="info text-uppercase">
        {data?.vehicle_type} - {data?.vehicle_number}
      </small>
    </div>
  );
};

let table = { ...config };
table.url = '/delivery';
table.columns = {
  code: {
    title: 'Kode',
    sortable: true,
    width: 100,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold">{row?.code}</div>
          <div className="text-ellipsis fs-8">
            DOC-OUT: {row?.doc_outs?.join(',\n') || '-'}
          </div>
        </div>
      );
    },
  },

  dispatched_at: {
    title: 'Tanggal Pengiriman',
    sortable: false,
    width: 100,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {formatDate(row?.dispatched_at, 'DD/MM/YYYY')}
          </div>
        </div>
      );
    },
  },

  bansos: {
    title: 'Bansos',
    width: 100,
    sortable: false,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.bansos?.name}
          </div>
        </div>
      );
    },
  },

  warehouse: {
    title: 'Gudang/Hub',
    class: 'text-start',
    classHeader: 'text-start',
    width: 100,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.warehouse?.code} - {row?.warehouse?.name}
          </div>
          <small className="info">{row?.warehouse?.address}</small>
        </div>
      );
    },
  },

  driver: {
    title: 'Driver',
    sortable: false,
    width: 100,
    component: (row) => {
      return <ModaDesc data={row?.driver} />;
    },
  },

  dispatched_by: {
    title: 'Dibuat Oleh',
    sortable: false,
    width: 100,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {row?.dispatched_by}
          </div>
          <small className="info">
            {formatDate(row?.created_at)}
          </small>
        </div>
      );
    },
  },

  process: {
    title: 'Proses',
    sortable: false,
    class: 'text-center',
    classHeader: 'text-center',
    width: 80,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="fs-8 fw-semibold">
            {row?.document_count?.received} / {row?.document_count?.total}
          </div>
        </div>
      );
    },
  },

  action: {
    title: '',
    width: 50,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};
export default table;
