import { $done, $processing } from 'services/activity/action';
import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';
import { $failure, $requesting, $success } from '../form/action';

export const MODULE = 'Delivery';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $get = ActionCreator.Async(MODULE, 'Get', (payload) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}/delivery`, payload)
      .then((response) => {
        dispatch($get.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($get.OPERATION)));
  };
});

export const $create = ActionCreator.Async(MODULE, 'Create', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/delivery`, payload)
      .then((response) => {
        dispatch($create.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $show = ActionCreator.Async(MODULE, 'Show', (id, payload) => {
  return async (dispatch) => {
    dispatch($processing($show.OPERATION));

    return await Rest.GET(`${API_URL}/delivery/${id}`, payload)
      .then((response) => {
        dispatch($show.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($show.OPERATION)));
  };
});

export const $update = ActionCreator.Async(MODULE, 'Update', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/delivery/${id}`, payload)
      .then((response) => {
        dispatch($update.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $updatePartial = ActionCreator.Async(MODULE, 'UpdatePartial', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/delivery/${id}/partial`, payload)
      .then((response) => {
        dispatch($updatePartial.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $updateWarehouse = ActionCreator.Async(MODULE, 'UpdateWarehouse', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/delivery/${id}/warehouse`, payload)
      .then((response) => {
        dispatch($updateWarehouse.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});


export const $publish = ActionCreator.Async(MODULE, 'Publish', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/delivery/${id}/publish`, payload)
      .then((response) => {
        dispatch($publish.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $receive = ActionCreator.Async(MODULE, 'Receive', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/delivery/${id}/receive`, payload)
      .then((response) => {
        dispatch($receive.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $unreceive = ActionCreator.Async(MODULE, 'Unreceive', (id, payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.PUT(`${API_URL}/delivery/${id}/unreceive`, payload)
      .then((response) => {
        dispatch($unreceive.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $delete = ActionCreator.Async(MODULE, 'Delete', (id) => {
  return async (dispatch) => {
    dispatch($processing($delete.OPERATION));

    return await Rest.DELETE(`${API_URL}/delivery/${id}`)
      .then((response) => {
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($delete.OPERATION)));
  };
});
