/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FaBars } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Accordion, Nav, NavDropdown } from 'react-bootstrap';
import { Auth, Bansos } from 'services/action';
import { useModal } from 'ui/hooks';
import _ from 'underscore';
import MenuRoute from './menu.route';

const MenuSm = () => {
  const Dispatch = useDispatch();
  const session = useSelector((state) => state?.Auth?.session);
  const project = useSelector((state) => state?.Auth?.project);
  const History = useHistory();
  const Modal = useModal();
  const [data, setData] = React.useState(null);

  const $getProject = async () => {
    const req = await Dispatch(Bansos.$get());

    if (req?.message === 'success' && req?.data) {
      if (!project) {
        const p = _.first(req?.data);
        Dispatch(Auth.$project(p));
      }

      setData(req?.data);
    }
  };

  const $handleProjectChange = async (e) => {
    Dispatch(Auth.$project(e, true));

    setTimeout(function(){
      window.location.reload();
    }, 5000);
  };

  React.useEffect(() => {
    $getProject();
  }, []);

  return (
    <div className="d-block d-md-none">
      <FaBars color="white" size={25} onClick={() => Modal.show()} />

      <Modal.Render fullscreen title="Menu" className="text-center bg-dark">
        <ul className="navbar-nav ">
          <li className="nav-item mb-2 ">
            <Nav>
              <NavDropdown
                menuVariant="secondary"
                className="bg-secondary m-0 p-0 rounded-3 carret"
                style={{
                  minWidth: 150,
                }}
                title={
                  <div
                    className="page-title text-light text-start"
                    style={{ fontSize: 13, marginBottom: 0 }}
                  >
                    {project?.name}
                  </div>
                }
              >
                {data?.map((d, i) => (
                  <NavDropdown.Item
                    key={i}
                    onClick={() => $handleProjectChange(d)}
                  >
                    {d?.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
          </li>
          {MenuRoute?.map((menu, i) => {
            let link = null;

            if (menu?.hide !== 'sm') {
              if (session?.usergroup) {
                let x = _.contains(menu?.usergroup, session?.usergroup?.id);
                if (x) {
                  if (!menu?.isMaster) {
                    link = !menu?.isDropdown ? (
                      <li key={i} className="nav-item border-b-1">
                        <a
                          className="nav-link ps-0"
                          onClick={() => {
                            Modal.hide();
                            History.push(menu?.path);
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                              {menu?.icon}
                            </span>
                            <span className="nav-link-title">
                              {menu?.title}
                            </span>
                          </div>
                        </a>
                      </li>
                    ) : (
                      <li key={i} className="nav-item border-b-1">
                        <Accordion>
                          <Accordion.Item eventKey={menu?.title}>
                            <Accordion.Header className="b ">
                              <div className="nav-link d-flex justify-content-center px-0 mt-n1 ">
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                  {menu?.icon}
                                </span>
                                <span className="nav-link-title">
                                  {menu?.title}
                                </span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body
                              className="py-0"
                              style={{
                                paddingLeft: '1.5rem',
                                paddingRight: 0,
                              }}
                            >
                              <ul
                                className="nav-vertical nav border-start"
                                style={{ paddingLeft: '1rem' }}
                              >
                                {menu?.child.map((c, index) => {
                                  let child = [];
                                  let x = _.contains(
                                    c?.usergroup,
                                    session?.usergroup?.id
                                  );

                                  if (x) {
                                    child = (
                                      <li
                                        className="nav-item border-b-1"
                                        key={index}
                                      >
                                        <a
                                          onClick={() => {
                                            Modal.hide();
                                            History.push(c?.path);
                                          }}
                                          className="nav-link"
                                        >
                                          {c?.title}
                                        </a>
                                      </li>
                                    );
                                  }
                                  return child;
                                })}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </li>
                    );
                  } else {
                    link = (
                      <li key={i} className="nav-item border-b-1">
                        <Accordion>
                          <Accordion.Item eventKey={menu?.title}>
                            <Accordion.Header className="b ">
                              <div className="nav-link d-flex justify-content-center px-0 mt-n1 ">
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                  {menu?.icon}
                                </span>
                                <span className="nav-link-title">
                                  {menu?.title}
                                </span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body
                              className="py-0"
                              style={{
                                paddingLeft: '1.5rem',
                                paddingRight: 0,
                              }}
                            >
                              <ul
                                className="nav-vertical nav border-start"
                                style={{ paddingLeft: '1rem' }}
                              >
                                {menu?.child.map((c, index) => {
                                  let child = [];

                                  let x = _.contains(
                                    c?.usergroup,
                                    session?.usergroup?.id
                                  );

                                  if (x) {
                                    child = (
                                      <li
                                        className="nav-item border-b-1"
                                        key={index}
                                      >
                                        <a
                                          onClick={() => {
                                            Modal.hide();
                                            History.push(c?.path);
                                          }}
                                          className="nav-link"
                                        >
                                          {c?.title}
                                        </a>
                                      </li>
                                    );
                                  }
                                  return child;
                                })}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </li>
                    );
                  }
                }
              } else {
                if (!menu?.isMaster) {
                  link = !menu?.isDropdown ? (
                    <li key={i} className="nav-item border-b-1">
                      <a
                        className="nav-link ps-0"
                        onClick={() => {
                          Modal.hide();
                          History.push(menu?.path);
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            {menu?.icon}
                          </span>
                          <span className="nav-link-title">{menu?.title}</span>
                        </div>
                      </a>
                    </li>
                  ) : (
                    <li key={i} className="nav-item border-b-1">
                      <Accordion>
                        <Accordion.Item eventKey={menu?.title}>
                          <Accordion.Header className="b ">
                            <div className="nav-link d-flex justify-content-center px-0 mt-n1 ">
                              <span className="nav-link-icon d-md-none d-lg-inline-block">
                                {menu?.icon}
                              </span>
                              <span className="nav-link-title">
                                {menu?.title}
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body
                            className="py-0"
                            style={{
                              paddingLeft: '1.5rem',
                              paddingRight: 0,
                            }}
                          >
                            <ul
                              className="nav-vertical nav border-start"
                              style={{ paddingLeft: '1rem' }}
                            >
                              {menu?.child.map((c, index) => {
                                return (
                                  <li
                                    className="nav-item border-b-1"
                                    key={index}
                                  >
                                    <a
                                      onClick={() => {
                                        Modal.hide();
                                        History.push(c?.path);
                                      }}
                                      className="nav-link"
                                    >
                                      {c?.title}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </li>
                  );
                } else {
                  link = (
                    <li key={i} className="nav-item border-b-1">
                      <Accordion>
                        <Accordion.Item eventKey={menu?.title}>
                          <Accordion.Header className="b ">
                            <div className="nav-link d-flex justify-content-center px-0 mt-n1 ">
                              <span className="nav-link-icon d-md-none d-lg-inline-block">
                                {menu?.icon}
                              </span>
                              <span className="nav-link-title">
                                {menu?.title}
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body
                            className="py-0"
                            style={{
                              paddingLeft: '1.5rem',
                              paddingRight: 0,
                            }}
                          >
                            <ul
                              className="nav-vertical nav border-start"
                              style={{ paddingLeft: '1rem' }}
                            >
                              {menu?.child.map((c, index) => {
                                return (
                                  <li
                                    className="nav-item border-b-1"
                                    key={index}
                                  >
                                    <a
                                      onClick={() => {
                                        Modal.hide();
                                        History.push(c?.path);
                                      }}
                                      className="nav-link"
                                    >
                                      {c?.title}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </li>
                  );
                }
              }
            }

            return link;
          })}
        </ul>
      </Modal.Render>
    </div>
  );
};

export default MenuSm;
