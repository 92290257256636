import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from 'services/action';
import { Form } from 'ui/components';

const initialState = {
  isReject: false,
  reason: null
};

const docType = {
  bast: 'BAST',
  sptjm: 'SPTJM',
  rtt: 'BNBA / DTT',
  receive: 'STT / SPM',
};

const Reject = ({ data, type, onClose, accepted, rejected }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const Session = useSelector((state) => state.Auth?.session);
  const [{ isReject, reason }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({ ...prevState, isReject: false, reason: null }));
  };

  const $handleReject = async () => {
    const payload = {
      type: type,
      reason: reason
    };

    const result = await Dispatch(Report.$reject(data?.id, payload));
    if (result?.message === 'success') {
      $handleClose();
      onClose();
    }
  };

  if (rejected) {
    return null
  }

  return (
    <>
      {(Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2') && (
        <button
          className="btn btn-sm btn-danger rounded-2 mt-2"
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              isReject: true,
            }))
          }
        >
          <Fi.FiX size={15} className={accepted? 'me-1': ''}  />
          {accepted ? 'Tolak Data': ''}
        </button>
      )}

      {/* modal reject */}
      <Modal
        show={isReject}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Akan menkonfirmasi bahwa Anda menolak Dokumen {docType[type]}
          </p>

          <div className="row text-start">
            <div className="col-md-12">
              <Form.Textbox
                label="Alasan"
                required
                value={reason}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    reason: e.target.value,
                  }));
                }}
                error={StateForm?.errors?.reason}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleReject}
                disabled={StateForm?.loading}
              >
                Konfirmasi
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Reject;
