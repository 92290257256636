import cn from 'classnames';
import React from 'react';
import * as Fi from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DatePicker } from 'ui/components';

import { formatDate } from 'utilities/common';

const initialState = {
  dispatched_at: null,
};

const Filter = ({ show, onClose, table, setCount }) => {
  const [{ dispatched_at }, setState] = React.useState(initialState);
  const StateTable = useSelector(
    (state) => state?.Table?.data['delivery_line']
  );

  const $onLoad = async () => {
    if (table.State?.filter?.dispatched_at) {
      const x = table.State?.filter?.dispatched_at;
      setState((prevState) => ({
        ...prevState,
        dispatched_at: new Date(x),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        dispatched_at: null,
      }));
    }
  };

  const handleFilter = async (e) => {
    if (table?.State?.filter) {
      let x = [];
      Object.keys(table?.State?.filter).forEach((k) => {
        if (table?.State?.filter[k] && k !== 'bansos_id') {
          x.push(k);
        }
      });
      setCount(x);
    }
  };

  React.useEffect(() => {
    $onLoad();
  }, []);

  React.useEffect(() => {
    handleFilter();
  }, []);

  return (
    <>
      <div
        className={cn(
          show
            ? 'col-md-3 d-none d-md-block py-3 px-3 m-0 border-start'
            : 'd-none'
        )}
      >
        <div className="mb-3 border-bottom">
          <div className="d-flex align-items-center justify-content-between">
            <div className="page-pretitle fs-7 text-dark">Filter</div>
            <div className="mt-n1">
              <button className="btn btn-action-close " onClick={onClose}>
                <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
              </button>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-dark mb-2">Jadwal Pengiriman</div>
          <DatePicker
            value={dispatched_at}
            onChange={(date) => {
              setState((prevState) => ({
                ...prevState,
                dispatched_at: date,
              }));

              if (date) {
                let fm = formatDate(date, 'YYYY-MM-DD');

                table.filter('dispatched_at', fm);
                handleFilter();
              } else {
                table.filter('dispatched_at', null);
                handleFilter();
              }
            }}
          />
        </div>

        {dispatched_at && StateTable?.total !== 0 ? (
          <Link
            className="btn btn-md btn-secondary btn-action rounded-2 text-uppercase w-100"
            role="button"
            to={`/print/whreport`}
            target="_blank"
          >
            <Fi.FiPrinter size={15} color="#202020" className="me-2" />
            print laporan harian
          </Link>
        ) : null}
      </div>

      <>
        <div
          className={cn('filter-overlay', show ? 'd-block d-md-none' : '')}
          onClick={onClose}
        />
        <div
          className={cn(
            'filter-modal d-block d-md-none py-3',
            show ? 'filter-show' : ''
          )}
        >
          <div className="mb-3 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-pretitle fs-7 text-dark">Filter</div>
              <div className="mt-n1">
                <button className="btn btn-action-close " onClick={onClose}>
                  <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
                </button>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-dark mb-2">
              Jadwal Pengiriman
            </div>
            <DatePicker
              value={dispatched_at}
              onChange={(date) => {
                setState((prevState) => ({
                  ...prevState,
                  dispatched_at: date,
                }));

                if (date) {
                  let fm = formatDate(date, 'YYYY-MM-DD');

                  table.filter('dispatched_at', fm);
                  handleFilter();
                } else {
                  table.filter('dispatched_at', null);
                  handleFilter();
                }
              }}
            />

            {dispatched_at && StateTable?.total !== 0 ? (
              <Link
                className="btn btn-md btn-secondary btn-action rounded-2 text-uppercase w-100"
                role="button"
                to={`/print/whreport`}
                target="_blank"
              >
                <Fi.FiPrinter size={15} color="#202020" className="me-2" />
                print laporan harian
              </Link>
            ) : null}
          </div>
        </div>
      </>
    </>
  );
};

export default Filter;
