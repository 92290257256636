import React from 'react';
import * as Fi from 'react-icons/fi';
import cn from 'classnames';

const Checkbox = ({
  label,
  description,
  value,
  onChange,
  disabled,
  iconSize,
  required,
  labelClass,
}) => {
  return (
    <div
      className={cn(
        'd-flex form-label',
        description ? '' : 'align-items-center mb-0',
        disabled ? 'cursor-disable' : 'cursor-pointer',
        required ? 'required' : ''
      )}
      onClick={disabled ? null : onChange}
    >
      <div>
        {!value ? (
          <Fi.FiSquare color={'#bbb'} size={iconSize} className="me-2" />
        ) : (
          <Fi.FiCheckSquare
            color={'#2f83ba'}
            size={iconSize}
            className="me-2"
          />
        )}
      </div>
      <div>
        <div className={labelClass}>{label}</div>
        {description && (
          <span className="desctiption-checkbox">{description}</span>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
