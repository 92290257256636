/* eslint-disable no-undef */
/* eslint-disable indent */
/**
 * Setup redux stores and state store definition
 *
 * @format
 * @flow strict-local
 */

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import persistState, { mergePersistedState } from 'redux-localstorage';
import persistAdapter from 'redux-localstorage/lib/adapters/localStorage';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { $config } from 'utilities/config';
import $state from './state';

/**
 * Defined all stores
 */
let $store;

/**
 * Get all stores defined
 */
export function getStore() {
  return $store;
}

/**
 * Initial store with redux and thunk
 * and using localstorages
 */
export function setStore() {
  const reducer = setupReducer();
  const persistEnhancer = setupPresister();
  const enhancerMiddleware = [thunk];
  const enhancer = setupEnhancer(enhancerMiddleware, persistEnhancer);

  $store = createStore(reducer, enhancer);

  /**
   * Debugging purpose
   */
  if (process.env.NODE_ENV === 'development') {
    global.$state = $state;
    global.$store = $store;

    Object.entries($state).forEach(([module, state]) => {
      if (!state) {
        console.warn(`$state.${module}: invalid descriptor`);
        return;
      }
      if (!state.reducer || typeof state.reducer !== 'function') {
        console.warn(`$state.${module}: missing or invalid 'reducer'`);
      }
      if (state.persister && typeof state.persister === 'function') {
        console.info(`$state.${module}: found 'persister'`);
      }
    });
  }

  return $store;
}

function setupReducer() {
  let reducer = combineReducers(
    Object.entries($state).reduce((result, [name, substate]) => {
      return substate.reducer
        ? {
            ...result,
            [name]: substate.reducer,
          }
        : result;
    }, {})
  );

  return compose(mergePersistedState(mergeDefinedState))(reducer);
}

function mergeDefinedState(target, source) {
  const result = { ...target };

  Object.entries(source).forEach(([key, value]) => {
    if (
      value &&
      Object.prototype.toString.call(value) === '[object Object]' &&
      result[key]
    ) {
      result[key] = mergeDefinedState(result[key], value);
    } else {
      result[key] = value;
    }
  });

  return result;
}

function setupPresister() {
  const persistSelector = (state) =>
    Object.entries($state).reduce((result, [name, substate]) => {
      return substate.persister
        ? {
            ...result,
            [name]: substate.persister(state[name]),
          }
        : result;
    }, {});

  const persistStorage = compose((storage) => {
    storage._put = storage.put;
    storage.put = function (key, state, callback) {
      storage._put(key, persistSelector(state), callback);
    };

    return storage;
  })(persistAdapter(window.localStorage));

  return persistState(persistStorage, $config.APP_VERSION);
}

function setupEnhancer(enhancerMiddleware, persistEnhancer) {
  if (process.env.NODE_ENV === 'development') {
    enhancerMiddleware.push(createLogger());
  }

  const composeEnhancers =
    global && global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  return composeEnhancers(
    applyMiddleware(...enhancerMiddleware),
    persistEnhancer
  );
}
