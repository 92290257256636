import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from 'services/action';
import ChartBarComponent from './bar';
import { Skeletons } from 'ui/components/skeleton';
import classNames from 'classnames';
import CountUpComponent from '../../dashboard/component/countup';

const ChartBar = () => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state.Activity.services['Report.Chart']
  );
  const [data, setData] = React.useState(null);

  const $onLoad = async () => {
    const result = await Dispatch(Report.$chart());
    if (result?.message === 'success') {
      setData(result?.data);
    }
  };

  const bg = {
    'Target / PBP': 'bg-primary-lt border-primary ',
    Realisasi: 'bg-secondary-lt  border-secondary',
    Disetujui: 'bg-success-lt  border-success',
    RTT: 'bg-pink-lt  border-danger',
  };

  React.useEffect(() => {
    $onLoad();
  }, []);

  return StateLoading || !data ? (
    <div className="card p-3 rounded-2 bg-white">
      <Skeletons height="400px" />
    </div>
  ) : (
    <div className="card p-3 rounded-2 bg-white">
      <h3 className="card-title dx-card-title border-bottom pb-2">
        CHART REKAPITULASI
      </h3>
      <div className="row align-items-center">
        <div className="col-9">
          <ChartBarComponent data={data} />
        </div>
        <div className="col-3 mt-n4">
          {data?.statistic?.map((d, i) => (
            <div
              className={classNames(
                'text-center mb-2 p-2 rounded border',
                bg[d?.name]
              )}
              key={i}
            >
              <div className="page-pretitle fs-7 text-dark">{d?.name}</div>
              <div className="title fs-5 ">
                <CountUpComponent
                  starting={0}
                  ending={d?.data.reduce((a, b) => a + b, 0)}
                  fs={20}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChartBar;
