import * as Pin from 'react-icons/ri';
import config from 'services/table/const';
import { formatDate, currencyFormat } from 'utilities/common';
import Action from './action';

let table = { ...config };
table.url = '/delivery/line';
table.columns = {
  dispatched_at: {
    title: 'Tanggal',
    sortable: false,
    width: 70,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 text-uppercase">
            {formatDate(row?.delivery?.dispatched_at, 'DD/MM/YYYY')}
          </div>
        </div>
      );
    },
  },
  doc_outs: {
    title: 'Dokumen out',
    sortable: false,
    width: 130,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8">
            {row?.delivery?.doc_outs?.join(',\n') || '-'}
          </div>
        </div>
      );
    },
    url: (row) => {
      return `/spm/${row?.delivery_id}`;
    },
  },
  doc_transporter: {
    title: 'Dokumen DO',
    sortable: false,
    width: 80,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8">{row?.delivery?.code}</div>
        </div>
      );
    },
    url: (row) => {
      return `/spm/${row?.delivery_id}`;
    },
  },

  doc_bast_transporter: {
    title: 'Dokumen BAST',
    sortable: false,
    width: 130,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8">{row?.manifest}</div>
        </div>
      );
    },
    url: (row) => {
      return `/spm/line/${row?.id}`;
    },
  },
  iconss: {
    title: '',
    sortable: false,
    width: 20,
    class: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          <Pin.RiMapPin2Fill color="gray" size={25} />
        </div>
      );
    },
  },
  origin: {
    title: 'Tujuan',
    sortable: false,
    alias: 'origin.name',
    width: 180,
    component: (row) => {
      return (
        <div className="table-col ps-2">
          <div className="row">
            <div className="text-ellipsis fs-8 text-uppercase">
              {row?.area?.village?.name}
            </div>
            <small className="info text-ellipsis">
              {row?.area?.village?.district?.name},{' '}
              {row?.area?.village?.district?.regency?.name},{' '}
              {row?.area?.village?.district?.regency?.kanwil?.name}
            </small>
          </div>
        </div>
      );
    },
  },

  total_package: {
    title: 'Total PBP',
    format_number: true,
    sortable: false,
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col ps-2">
          <div className="text-ellipsis fs-8 text-uppercase">
            {row?.total_package}
          </div>
        </div>
      );
    },
  },

  total_received: {
    title: 'Kuantum',
    format_number: true,
    sortable: false,
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col ps-2">
          <div className="text-ellipsis fs-8 text-uppercase">
            {currencyFormat(row?.total_package * 10)} Kg
          </div>
        </div>
      );
    },
  },

  // total_received: {
  //   title: 'Total Received',
  //   format_number: true,
  //   sortable: false,
  //   width: 70,
  //   class: 'text-end',
  //   classHeader: 'text-end',
  // },

  status: {
    title: 'Status',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 120,
    component: (row) => {
      return (
        <span className={`badge status-${row?.status}-lt ms-auto`}>
          {row?.status?.replace('new', 'Baru')}
        </span>
      );
    },
  },

  action: {
    title: '',
    width: 50,
    class: 'text-end',
    component: (row) => {
      return <Action data={row} />;
    },
  },
};
export default table;
