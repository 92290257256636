import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Auth, User } from 'services/action';

import { Button, Form, Page } from 'ui/components';
// import Tutorial from '../customer/component/tutorial';

const initialState = {
  name: '',
  phone: '',
  email: '',
  password: '',
  confirm_password: '',
};

const ProfileScreen = () => {
  const History = useHistory();
  const Dispatch = useDispatch();

  const Session = useSelector((state) => state?.Auth?.session);
  const StateForm = useSelector((state) => state.Form);
  const [data, setData] = React.useState(null);
  const [{ confirm_password, name, password, phone, email }, setState] =
    React.useState(initialState);

  const $onLoad = async () => {
    const req = await Dispatch(User.$show(Session?.user?.id));
    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        name: req?.data?.name,
        phone: req?.data?.phone,
        email: req?.data?.email,
      }));
      setData(req?.data);
    }
  };

  const $handleSubmit = async (e) => {
    const payload = {
      name,
      phone,
      email,
      password,
      confirm_password,
    };

    // ini biarin aja soalnya di backendnya butuh data ini jadi pake data dari shownya aja
    payload.company_name = data?.company_name;
    payload.company_type = data?.company_type;
    payload.bio = data?.bio;
    payload.avatar = data?.avatar;
    payload.address = data?.address;
    payload.village_id = data?.region?.village_id;
    payload.username = data?.username;

    const update = await Dispatch(Auth.$update(payload));

    if (update?.message === 'success') {
      Dispatch(Auth.$fetchProfile());
    }
  };

  React.useEffect(() => {
    $onLoad();
  }, []);

  return (
    <Page type="form" className="col-md-6 bordered" onSubmit={$handleSubmit}>
      <Page.Header title="Profile Pengguna" />
      {/* <Page.Navigation>
        <Tutorial />
      </Page.Navigation> */}
      <Page.Body className="px-3 py-3">
        <div className="row ">
          <div className="col-12 col-md-6">
            <Form.Input
              value={name || ''}
              required
              type="text"
              label="Nama"
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  name: e?.target?.value,
                }))
              }
              error={StateForm?.errors?.name}
            />
            <Form.Input
              value={email || ''}
              required
              type="text"
              label="Email"
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  email: e?.target?.value,
                }))
              }
              error={StateForm?.errors?.email}
            />
            <Form.Input
              value={phone || ''}
              type="phone"
              label="Nomor Handphone"
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  phone: e?.target?.value,
                }))
              }
              error={StateForm?.errors?.phone}
            />
          </div>
          <div className="col-12 col-md-6">
            <Form.Input
              value={password}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  password: e?.target?.value,
                }))
              }
              label="Password"
              type="text"
              error={StateForm?.errors?.password}
            />
            <Form.Input
              value={confirm_password}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  confirm_password: e?.target?.value,
                }))
              }
              label="Konfirmasi Password"
              type="text"
              error={StateForm?.errors?.confirm_password}
            />
          </div>
        </div>
      </Page.Body>
      <Page.Footer className="justify-content-center gap-2 pt-3 border-top  d-md-flex d-block mb-3 flex-wrap">
        <Button
          type="button"
          className="btn-danger px-4 py-3"
          onClick={() => {
            History.replace(`/`);
          }}
        >
          Batal &amp; Kembali
        </Button>
        <Button
          type="submit"
          loading={StateForm?.loading}
          className="btn-primary px-4 py-3"
        >
          Simpan
        </Button>
      </Page.Footer>
    </Page>
  );
};

export default ProfileScreen;
