import MenuSm from './menu.sm';
import MenuProfile from './menu.profile';
import MenuConsended from './menu.consended';
import MenuDefault from './menu';
import MenuMaster from './menu.master';

const Menu = () => {
  return;
};

Menu.Default = MenuDefault;
Menu.Consended = MenuConsended;
Menu.Mobile = MenuSm;
Menu.Profile = MenuProfile;
Menu.Right = MenuMaster;

export default Menu;
