import React from 'react';
import { useSelector } from 'react-redux';

const LoadingBar = () => {
  const StateLoading = useSelector((state) => state?.Activity?.processing);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(async () => {
    if (!StateLoading) {
      setTimeout(() => {
        setLoading(false);
      }, 8000);
    } else {
      setLoading(true);
    }
  }, [StateLoading]);

  return loading ? (
    <div className="progress-bar bg-red progress-bar-indeterminate m-0 p-0"></div>
  ) : null;
};

const Body = ({ children }) => {
  return (
    <div className="page-wrapper">
      <div className="progress progress-sm m-0 p-0">
        <LoadingBar />
      </div>

      {children}
    </div>
  );
};

export default Body;
