import { useSelector } from 'react-redux';
import Datagrid from 'ui/components/data/grid';
import { formatDate, waFormat } from 'utilities/common';

export const SectionInfo = ({ data, loading }) => {
  const Session = useSelector((state) => state?.Auth?.session);

  const LandFreight = ({ data }) => {
    if (!data) return null;

    const getDriver = (data) => {
      return data ? `${data?.name} (${waFormat(data?.phone)})` : '-';
    };

    return (
      <>
        <Datagrid
          loading={loading}
          title="Driver/Kurir"
          value={getDriver(data)}
        />
        <Datagrid
          loading={loading}
          title="Kendaraan"
          value={`${data?.vehicle_number} - ${data?.vehicle_type}`}
        />
      </>
    );
  };

  return (
    <div className="p-0 p-md-3 mb-3 mb-md-0">
      <div className="card rounded-2 bg-white">
        <div className="card-header-gray p-3 border-bottom">
          <h3 className="card-title dx-card-title m-0 p-0">Informsi SPM</h3>
        </div>
        <div className="card-body p-3 pt-3">
          <div className="row">
            <div className="col-4">
              <div className="datagrid">
                <Datagrid
                  loading={loading}
                  title="Kode SPM"
                  value={data?.code}
                />
                {(data?.ref_code && (Session?.usergroup?.id === '6c5128cc-e5e8-4dbe-8204-cd532a7e229c' || Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' || Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' || Session?.usergroup?.id === '302d772c-77db-47ea-ab5a-83b6be4d3787' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2')) && (
                  <Datagrid
                    loading={loading}
                    title="Ref Code"
                    value={data?.ref_code}
                  />
                )}
                <Datagrid
                  loading={loading}
                  title="Bansos"
                  value={data?.bansos?.name}
                />
                <Datagrid
                  loading={loading}
                  title="Tanggal Pengiriman"
                  value={formatDate(data?.dispatched_at, 'DD/MM/YYYY')}
                />
                <Datagrid
                  loading={loading}
                  title="Dibuat Oleh"
                  value={data?.dispatched_by}
                />
                <Datagrid
                  loading={loading}
                  title="total dikirim"
                  value={data?.total_kpm}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="datagrid">
                <Datagrid
                  loading={loading}
                  title="Warehouse"
                  value={`${data?.warehouse?.code} - ${data?.warehouse?.name}`}
                />
                <Datagrid
                  loading={loading}
                  title="Alamat Warehouse"
                  value={data?.warehouse?.address || '-'}
                />
                <LandFreight data={data?.driver} />
              </div>
            </div>
            <div className="col-4">
              <div className="datagrid">
                {data?.doc_outs ? (
                  data?.doc_outs?.map((e, i) => (
                    <Datagrid
                      loading={loading}
                      title={`Doc-Out - ${i+1}`}
                      value={e}
                    />
                  ))
                ): null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
