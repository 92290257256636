import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Skeletons } from '../skeleton';

import {
  Button,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import {
  HiOutlineCloudDownload,
  HiOutlineSearch,
  HiOutlineX,
} from 'react-icons/hi';

const TableTool = ({
  name,
  onSearch,
  children,
  downloadable,
  onDownload,
  className,
  dropdown,
  isLoading,
  isFilter,
}) => {
  const StateSearch = useSelector(
    (state) => state?.Table?.data[name]?.textSearch
  );

  const [searchTerm, setSearchTerm] = React.useState('');

  const Download = () => {
    if (!downloadable) return null;

    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-top`}>Download XLS</Tooltip>}
      >
        <Button
          variant="dark"
          className="ms-2"
          onClick={onDownload}
          style={{ height: 35 }}
        >
          <HiOutlineCloudDownload size={18} color="white" />
        </Button>
      </OverlayTrigger>
    );
  };

  React.useEffect(() => {
    if (StateSearch === searchTerm) return;

    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  React.useEffect(() => {
    if (StateSearch === '') return null;

    setSearchTerm(StateSearch);
  }, [StateSearch]);

  return (
    <div
      className={cn(
        className,
        'd-flex table-tools align-items-center',
        isFilter ? 'justify-content-between' : 'justify-content-end'
      )}
    >
      {isLoading ? (
        <>
          <Skeletons height="35px" width="200px" />
          <div className="ms-2">
            <Skeletons height="35px" width="50px" />
          </div>
        </>
      ) : (
        <>
          {isFilter}
          <div className="d-flex align-items-center">
            <InputGroup className="search flex-nowrap input-group-flat">
              <FormControl
                placeholder="Search ..."
                value={searchTerm}
                onChange={(input) => setSearchTerm(input.target.value)}
                className="bg-white"
              />
              <div className="input-group-text">
                {searchTerm ? (
                  <HiOutlineX onClick={() => setSearchTerm('')} />
                ) : (
                  <HiOutlineSearch />
                )}
              </div>
            </InputGroup>
            <Download />
          </div>
        </>
      )}
      {children}
    </div>
  );
};

TableTool.defaultProps = {
  downloadable: false,
};

export default TableTool;
