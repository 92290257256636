import * as Fa from 'react-icons/fa';

const MenuRoute = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <Fa.FaHome size={18} />,
    isActive: '',
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      '302d772c-77db-47ea-ab5a-83b6be4d3787',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6',
      '8465c143-a4e3-478e-b019-cb4e1035e886',
      '004850c5-ebee-2f68-5cd9-4eb2405cfb5e',
    ],
    isMaster: false,
    isDropdown: false,
  },
  // {
  //   title: 'Dashboard',
  //   path: '/monitoring',
  //   icon: <Fa.FaHome size={18} />,
  //   isActive: 'monitoring',
  //   usergroup: [
  //     '00e22f61-b7f4-4d03-806e-98b987c8c8e6',
  //     '8465c143-a4e3-478e-b019-cb4e1035e886',
  //   ],
  //   isMaster: false,
  //   isDropdown: false,
  // },
  {
    title: 'SPM',
    isActive: 'spm',
    icon: <Fa.FaListUl size={18} />,
    usergroup: [
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '7ce651f7-5df9-424a-8a82-7d87ffd45107',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
    isMaster: false,
    isDropdown: true,
    child: [
      {
        title: 'Berdasarkan Kendaraan',
        path: '/spm',
        isActive: 'spm',
        icon: <Fa.FaTruckMoving size={17} />,
        usergroup: [
          '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          '4df4c118-ed27-40a4-865e-270930637e82',
        ],
      },
      {
        title: 'Berdasarkan Tujuan',
        path: '/spm/line',
        isActive: 'line',
        icon: <Fa.FaMapMarkerAlt size={17} />,
        usergroup: [
          '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          '7ce651f7-5df9-424a-8a82-7d87ffd45107',
          '4df4c118-ed27-40a4-865e-270930637e82',
        ],
      },
      // {
      //   title: 'Bermasalah',
      //   path: '/spm/trouble',
      //   isActive: 'trouble',
      //   icon: <Fa.FaExclamationCircle size={17} />,
      //   usergroup: ['9cd330e3-e5d9-47d3-8faa-375139f4daa3', 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805'],
      // },
    ],
  },
  {
    title: 'Laporan',
    isActive: 'report',
    icon: <Fa.FaFileContract size={18} />,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '302d772c-77db-47ea-ab5a-83b6be4d3787',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '8465c143-a4e3-478e-b019-cb4e1035e886',
      '004850c5-ebee-2f68-5cd9-4eb2405cfb5e',
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
    ],
    isMaster: false,
    isDropdown: true,
    child: [
      {
        title: 'Laporan Distribusi',
        path: '/report/distribusi',
        isActive: 'distribusi',
        icon: <Fa.FaTruckLoading size={17} />,
        usergroup: [
          '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
          '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
          '4df4c118-ed27-40a4-865e-270930637e82',
          '302d772c-77db-47ea-ab5a-83b6be4d3787',
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          '004850c5-ebee-2f68-5cd9-4eb2405cfb5e',
          '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
        ],
      },
      {
        title: 'Laporan SPM',
        path: '/report/spm',
        isActive: 'spm',
        icon: <Fa.FaTruckMoving size={17} />,
        usergroup: [
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          '4df4c118-ed27-40a4-865e-270930637e82',
          '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
          '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
        ],
      },
      {
        title: 'Laporan Warehouse',
        path: '/report/warehouse',
        isActive: 'warehouse',
        icon: <Fa.FaWarehouse size={17} />,
        usergroup: [
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          '4df4c118-ed27-40a4-865e-270930637e82',
          '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
          '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
        ],
      },
      {
        title: 'Laporan Dokumen',
        path: '/report/document',
        isActive: 'document',
        icon: <Fa.FaRegFileAlt size={17} />,
        usergroup: [
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          '4df4c118-ed27-40a4-865e-270930637e82',
          '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
        ],
      },
      {
        title: 'Laporan Recon',
        path: '/report/recon',
        isActive: 'recon',
        icon: <Fa.FaRegFileAlt size={17} />,
        usergroup: [
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
          '4df4c118-ed27-40a4-865e-270930637e82',
          '8465c143-a4e3-478e-b019-cb4e1035e886',
          '302d772c-77db-47ea-ab5a-83b6be4d3787',
        ],
      },
      // {
      //   title: 'Bermasalah',
      //   path: '/spm/trouble',
      //   isActive: 'trouble',
      //   icon: <Fa.FaExclamationCircle size={17} />,
      //   usergroup: ['9cd330e3-e5d9-47d3-8faa-375139f4daa3', 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805'],
      // },
    ],
  },
  {
    title: 'Laporan Daily',
    isActive: 'daily',
    icon: <Fa.FaFileContract size={18} />,
    usergroup: [
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
    isMaster: false,
    isDropdown: true,
    child: [
      {
        title: 'Daily Uploaded',
        path: '/daily/uploaded',
        isActive: 'uploaded',
        icon: <Fa.FaUpload size={17} />,
        usergroup: [
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '4df4c118-ed27-40a4-865e-270930637e82',
        ],
      },
      {
        title: 'Daily Salur',
        path: '/daily/salur',
        isActive: 'salur',
        icon: <Fa.FaTruckMoving size={17} />,
        usergroup: [
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '4df4c118-ed27-40a4-865e-270930637e82',
        ],
      },
    ],
  },
  {
    title: 'Recon',
    path: '/recon',
    icon: <Fa.FaUpload size={18} />,
    isActive: 'recon',
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
    ],
    isMaster: false,
    isDropdown: false,
  },
  {
    title: 'RTT',
    path: '/rtt',
    icon: <Fa.FaRegFileAlt size={18} />,
    isActive: 'rtt',
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
    ],
    isMaster: false,
    isDropdown: false,
  },
  {
    title: 'Master Data',
    path: '',
    icon: <Fa.FaLayerGroup size={18} />,
    isActive: 'master',
    isDropdown: true,
    isMaster: true,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '302d772c-77db-47ea-ab5a-83b6be4d3787',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
    ],
    child: [
      {
        title: 'User',
        path: '/master/user',
        isActive: 'user',
        usergroup: [
          '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
          '4df4c118-ed27-40a4-865e-270930637e82',
          '302d772c-77db-47ea-ab5a-83b6be4d3787',
          'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
          '6ca4fba3-b0a2-4266-909e-2471987025f2',
        ],
      },
      {
        title: 'Usergroup',
        path: '/master/usergroup',
        isActive: 'usergroup',
        usergroup: ['6ca4fba3-b0a2-4266-909e-2471987025f2'],
      },
      {
        title: 'Warehouse',
        path: '/master/warehouse',
        isActive: 'warehouse',
        usergroup: ['6ca4fba3-b0a2-4266-909e-2471987025f2'],
      },
    ],
  },
];

export default MenuRoute;
