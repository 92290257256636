import config from 'services/table/const';
import { currencyFormat } from 'utilities/common';

let table = { ...config };
table.url = '/report/document/province';
table.columns = {
  code: {
    title: 'Provinsi',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.province?.name}</div>
        </div>
      );
    },
    url: (row) => {
      return `/report/document/regency?province_id=${row?.province_id}`;
    },
  },

  total_document: {
    title: 'Dokumen Keluar',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return <div className="table-col">{currencyFormat(row?.total_document)}</div>;
    },
    url: (row) => {
      return `/report/document/regency?kanwil_id=${row?.kanwil_id}`;
    },
  },

  total_approved: {
    title: 'Dokumen Diterima',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_approved)}</span>
        </div>
      );
    },
    url: (row) => {
      return `/report/document/regency?kanwil_id=${row?.kanwil_id}`;
    },
  },

  total_uploaded: {
    title: 'Dokumen POD',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_uploaded)}</span>
        </div>
      );
    },
    url: (row) => {
      return `/report/document/regency?kanwil_id=${row?.kanwil_id}`;
    },
  },
};

export default table;
