import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import cn from 'classnames';

import { Uploader, POD } from 'services/action';


const ImageCrop = ({ data, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const [image, setImage] = React.useState();
  const [rotation, setRotation] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });


  const $updateImg = async () => {
    const payload = {
      url: data?.image?.replace('_thumb', ''),
      rotate: -1 * parseFloat(rotation)
    }
    const result = await Dispatch(Uploader.$rotate(payload))
    if (result?.message === 'success') {
      const resPOD = await Dispatch(POD.$update(data?.id, {action: 'url', url: result?.data}))
      if (resPOD?.message === 'success') {
        onClose();
      }
    }
  }

  React.useEffect(() => {
    setImage(data?.image?.replace('_thumb', ''));
  }, [data]);

  return (
    <>
      <Modal.Body >
        <div className="btn-close" onClick={onClose} />
        <div
            className="position-relative"
            style={{ height: 500, width: '100%' }}
        >
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onZoomChange={setZoom}
            cropSize={{
              width: '100%',
              height: '100%'
            }}
            showGrid={false}
            restrictPosition={false}
          />
        </div>

        <div className="mb-2 pb-3 border-bottom">
          <label className="page-pretitle">Rotasi</label>
          <input
            type="range"
            value={rotation}
            min={0}
            max={270}
            step={90}
            aria-labelledby="Rotate"
            onChange={(e) => {
              setRotation(e.target.value);
            }}
            className="zoom-range"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button
              type="button"
              className='btn btn-light w-100'
              onClick={() => onClose()}
            >
               Batal
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className={cn(
                StateForm?.loading ? 'btn-loading' : '',
                  'btn btn-primary w-100'
                )}
              disabled={StateForm?.loading}
              onClick={$updateImg}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>

  )
}

export default ImageCrop;