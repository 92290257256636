import React from 'react';
import cn from 'classnames';

const ListHorizontal = ({
  title,
  content,
  className,
  colTitle,
  colContent,
  nopad,
}) => {
  const c = cn(
    className ? className : '',
    nopad ? '' : 'py-1 mb-1',
    'row flex-wrap list-item m-0 align-items-center'
  );

  return (
    <div className={c}>
      <div className={cn(colTitle ? colTitle : 'col-4', 'p-0 fs-8')}>
        {title}
      </div>
      <div className="col-1 fs-8 text-center p-0">:</div>
      <div
        className={cn(
          colContent ? colContent : 'col-7 text-uppercase',
          'p-0 text-start title fs-8 fw-semibold text-uppercase'
        )}
      >
        {content}
      </div>
    </div>
  );
};

export default ListHorizontal;
