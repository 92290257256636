import { $done, $processing } from 'services/activity/action';
import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';

export const MODULE = 'Dashboard';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $geometryPolygon = ActionCreator.Async(
  MODULE,
  'GeometryPolygon',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($geometryPolygon.OPERATION));

      return await Rest.GET(`${API_URL}/maps/polygon`, payload)
        .then((response) => {
          dispatch($geometryPolygon.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($geometryPolygon.OPERATION)));
    };
  }
);

export const $geometryPoint = ActionCreator.Async(
  MODULE,
  'GeometryPoint',
  (payload) => {
    return async (dispatch) => {
      dispatch($processing($geometryPoint.OPERATION));

      return await Rest.GET(`${API_URL}/maps/point`, payload)
        .then((response) => {
          dispatch($geometryPoint.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($geometryPoint.OPERATION)));
    };
  }
);
