const tableState = {
  url: '',
  columns: [],
  filter: {},
  total: 0,
  currentPage: 1,
  limitPerPage: 25,
  sorting: '',
  queryString: '',
  textSearch: '',
  isEmpty: false,
  showFilter: false,
  data: null,
  loading: false,
};

export default tableState;
