import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bansos, Report } from 'services/action';
import { Page } from 'ui/components';
import Datagrid from 'ui/components/data/grid';
import ActionUpload from './component/table/upload';

import QRCode from 'react-qr-code';
import ActionDocument from './component/table/document';

const POD_URL = `${process.env.REACT_APP_POD_SYSTEM_URL}`;

const initialState = {
  report: null,
  kpm: null
};

const DetailScreen = ({ match }) => {
  const Dispatch = useDispatch();

  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Report.Show.Village']
  );

  const [{ report, kpm }, setState] = React.useState(initialState);

  const $onLoad = async () => {
    const res = await Dispatch(Report.$showVillage(match?.params?.id));

    if (res?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        report: res?.data,
      }));
    }
  };

  const $showKPM = async (data) => {
    const payload = {sorting_dusun: data?.village?.district?.regency?.sorting_dusun || data?.village?.sorting_dusun}
    const res = await Dispatch(Bansos.$kpm(data?.bansos_id, data?.village_id, payload));
    if (res?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        kpm: res?.data,
      }));
    }
  };

  React.useEffect(() => {
    $onLoad();
  }, [match]);

  React.useEffect(() => {
    if (!report) return

    $showKPM(report);
  }, [report])

  return (
    <Page className="">
      <Page.Header
        title="Penerima Bantuan Pangan"
        isLoading={StateLoading}
        backTo="/report/village"
      />
      <div className="row bg-white px-4 py-3">
        <div className="col-8">
          <div className="row">
            <div className="col-4">
              <Datagrid
                loading={StateLoading}
                title="Kanwil"
                value={report?.village?.district?.regency?.kanwil?.name}
              />
              <Datagrid
                loading={StateLoading}
                title="Kab./ Kota"
                value={report?.village?.district?.regency?.name}
              />
            </div>
            <div className="col-4">
              <Datagrid
                loading={StateLoading}
                title="Kecamatan"
                value={report?.village?.district?.name}
              />
              <Datagrid
                loading={StateLoading}
                title="Kelurahan"
                value={report?.village?.name}
              />
            </div>
            <div className="col-4">
              <Datagrid
                loading={StateLoading}
                title="total pbp"
                value={report?.total_kpm}
              />
            </div>
          </div>
          {/* <ActionArcon data={report} onClose={() => $refreshVillageLine()} /> */}
        </div>
      </div>
      <Page.Body className="row m-0 p-0">
        {StateLoading ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
            </div>
          </div>
        ) : kpm?.length === 0 || !kpm ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
              <p className="empty-title">Penerima Bantuan Pangan tidak tersedia</p>
            </div>
          </div>
        ) : (
          <div className="row m-0">
            {kpm?.map((d, i) => (
              <div className='col-4 p-2 border-1 border d-flex flex-column'>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <QRCode
                      size={120}
                      value={`${POD_URL}/pod?type=kpm&bansos_id=${report?.bansos_id}&village_id=${report?.village_id}&kpm_id=${d?.kpm_id}&report_id=${report?.id}`}
                    />
                  </div>
                  <div className='col-md-4 col-sm-12'>
                    <Datagrid
                      loading={StateLoading}
                      title="Nama"
                      value={d?.kpm?.name}
                    />
                    <Datagrid
                      loading={StateLoading}
                      title="Alamat"
                      value={d?.kpm?.address}
                    />
                  </div>
                  <div className='col-md-4 col-sm-12'>
                    <ActionUpload
                      data={report}
                      kpm={d?.kpm}
                      type="kpm"
                      onClose={() => $onLoad()}
                    />
                  </div>
                </div>
                <div className='row'>
                  <ActionDocument
                    data={report}
                    kpm={d?.kpm}
                    type="kpm"
                    onClose={() => $onLoad()}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </Page.Body>
    </Page>
  );
};

export default DetailScreen;
