import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Rtt } from 'services/action';
import { Button, Page } from 'ui/components';
import { FormRttLines, FormGeneral } from './component/form';

const initialState = {
  general: null,
  lines: null,
  isLoading: true,
};

const CreateScreen = () => {
  const History = useHistory();
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state?.Form);
  const Session = useSelector((state) => state?.Auth);

  const [{ general, isLoading, lines }, setState] =
    React.useState(initialState);

  const $handleSubmit = async () => {
    const payload = {
      region: general?.region,
      lines,
      dispatch_at: general?.dispatch_at?.formatted,
    };

    if (Session?.project) {
      payload.bansos_id = Session?.project?.id;
    }

    const req = await Dispatch(Rtt.$create(payload));

    if (req?.message === 'success') {
      History.push(`/rtt/${req?.data?.id}`);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  return (
    <Page
      type="form"
      className="detail-fixed bg-white"
      onSubmit={$handleSubmit}
    >
      <Page.Header title="Buat RTT" className="bg-light" backTo="/spm" />
      <Page.Body className="row px-3 py-3">
        <div
          className="col-12 col-md-4 position-md-fixed "
          style={{ paddingRight: 10 }}
        >
          <FormGeneral
            onInputChange={(e) =>
              setState((prevState) => ({ ...prevState, general: e }))
            }
            isLoading={isLoading}
            lines={lines}
          />
        </div>
        <div className="col-md-8 col-12 offset-md-4 border-start border-sm-none ps-0 pe-0 position-relative mb-5 mb-md-0 ">
          <div className="py-md-3 ps-md-3 ">
            <FormRttLines
              bansos={general?.bansos}
              region={general?.region}
              onFormChange={(e) => {
                setState((prevState) => ({ ...prevState, lines: e }));
              }}
            />
          </div>
        </div>
      </Page.Body>
      <div className="d-flex justify-content-center gap-0 p-3 border-top">
        <Button
          type="button"
          loading={StateForm?.loading}
          className="btn-primary px-2 py-2 w-50"
          onClick={$handleSubmit}
        >
          Simpan
        </Button>
      </div>
    </Page>
  );
};

export default CreateScreen;
