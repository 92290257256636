import RegionScreen from './index';

const routes = [
  {
    path: '/region',
    component: RegionScreen,
    usergroup: [
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
  },
  {
    path: '/region/*',
    component: RegionScreen,
    usergroup: [
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '4df4c118-ed27-40a4-865e-270930637e82',
    ],
  },
];

export default routes;
