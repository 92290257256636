import cn from 'classnames';
import React from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Delivery } from 'services/action';
import { FormPartial, FormWarehouse } from '../form';

import { Skeletons } from 'ui/components/skeleton';

const initialState = {
  showDelete: false,
  showPublish: false,
  showUpdate: false,
  showWarehouse: false,
  showReceive: false,
  showUnreceive: false
};

export const Action = ({ data, onSubmit }) => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const Session = useSelector((state) => state?.Auth?.session);
  const StateForm = useSelector((state) => state.Form);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Delivery.Show']
  );

  const [{ showDelete, showPublish, showUpdate, showWarehouse, showReceive, showUnreceive }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      showDelete: false,
      showPublish: false,
      showUpdate: false,
      showWarehouse: false,
      showReceive: false,
      showUnreceive: false
    }));
  };

  const $handleDelete = async () => {
    const del = await Dispatch(Delivery.$delete(data?.id));
    if (del?.message === 'success') {
      $handleClose();

      History.replace('/spm');
    }
  };

  const $handlePublish = async () => {
    const del = await Dispatch(Delivery.$publish(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      onSubmit();
    }
  };

  const $handleReceive = async () => {
    const del = await Dispatch(Delivery.$receive(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      onSubmit();
    }
  };

  const $handleUnreceive = async () => {
    const del = await Dispatch(Delivery.$unreceive(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      onSubmit();
    }
  };

  const dummy = Array.from({ length: 6 }, (v, i) => i);

  if (Session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' || Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' || Session?.usergroup?.id === '302d772c-77db-47ea-ab5a-83b6be4d3787') return null

  return (
    <div className="d-flex align-items-center gap-1 px-3 px-md-0 pb-2 pb-md-0">
      {StateLoading ? (
        dummy?.map((d, i) => <Skeletons key={i} height="35px" width="35px" />)
      ) : (
        <>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-top`}>Print BAST / STT</Tooltip>}
          >
            <Link
              to={`/print/bast/${data?.id}`}
              className="btn btn-primary px-2 py-2"
              target="_blank"
            >
              <Fi.FiPrinter size={15} />
            </Link>
          </OverlayTrigger>

          {Session?.usergroup?.id !== '6c5128cc-e5e8-4dbe-8204-cd532a7e229c' && (
            <>
              {data?.document_count?.received === 0 ? (
                <>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-top`}>Perbaharui</Tooltip>}
                  >
                    <Link
                      to={`/spm/${data?.id}/update`}
                      className="btn btn-secondary px-2 py-2"
                    >
                      <Fi.FiEdit size={15} />
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-top`}>Hapus</Tooltip>}
                  >
                    <button
                      className={cn('btn btn-danger px-2')}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          showDelete: true,
                        }))
                      }
                    >
                      <Fi.FiTrash2 size="17" />
                    </button>
                  </OverlayTrigger>
                  {/* <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-top`}>Publish</Tooltip>}
                  >
                    <button
                      className={cn('btn btn-primary px-2')}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          showPublish: true,
                        }))
                      }
                    >
                      <Fi.FiCheckCircle size="17" />
                    </button>
                  </OverlayTrigger> */}
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-top`}>Perbaharui Informasi</Tooltip>}
                  >
                    <button
                      className={cn('btn btn-secondary px-2')}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          showUpdate: true,
                        }))
                      }
                    >
                      <Fi.FiInfo size="17" />
                    </button>
                  </OverlayTrigger>

                  {(Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2') && (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id={`tooltip-top`}>Perbaharui Gudang</Tooltip>}
                      >
                        <button
                          className={cn('btn btn-secondary px-2')}
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              showWarehouse: true,
                            }))
                          }
                        >
                          <Fi.FiEdit size="17" />
                        </button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id={`tooltip-top`}>Terima</Tooltip>}
                      >
                        <button
                          className={cn('btn btn-primary px-2')}
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              showReceive: true,
                            }))
                          }
                        >
                          <Fi.FiCheckCircle size="17" />
                        </button>
                      </OverlayTrigger>
                    </>
                  )}
                </>
              ): (
                <>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-top`}>Perbaharui Informasi</Tooltip>}
                  >
                    <button
                      className={cn('btn btn-secondary px-2')}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          showUpdate: true,
                        }))
                      }
                    >
                      <Fi.FiEdit size="17" />
                    </button>
                  </OverlayTrigger>

                  {(Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2') && (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id={`tooltip-top`}>Perbaharui Gudang</Tooltip>}
                      >
                        <button
                          className={cn('btn btn-secondary px-2')}
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              showWarehouse: true,
                            }))
                          }
                        >
                          <Fi.FiEdit size="17" />
                        </button>
                      </OverlayTrigger>
                      {data?.document_count?.received > 0 && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id={`tooltip-top`}>Batalkan Penerimaan</Tooltip>}
                        >
                          <button
                            className={cn('btn btn-danger px-2')}
                            onClick={() =>
                              setState((prevState) => ({
                                ...prevState,
                                showUnreceive: true,
                              }))
                            }
                          >
                            <Fi.FiXCircle size="17" />
                          </button>
                        </OverlayTrigger>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {/* modal delete */}
      <Modal
        show={showDelete}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang sudah dihapus tidak bisa dipulihkan
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleDelete}
                disabled={StateForm?.loading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal publish */}
      <Modal
        show={showPublish}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-primary" />
          <Fi.FiAlertTriangle className="mb-2 text-primary" size={90} />
          <h3>Anda yakin?</h3>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="primary"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handlePublish}
                disabled={StateForm?.loading}
              >
                Publish
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal receive */}
      <Modal
        show={showReceive}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-primary" />
          <Fi.FiAlertTriangle className="mb-2 text-primary" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-primary fw-semibold">
            Data yang sudah diterima tidak bisa dipulihkan
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="primary"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleReceive}
                disabled={StateForm?.loading}
              >
                Terima
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal unreceive */}
      <Modal
        show={showUnreceive}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Anda akan membatalkan penerimaan KPM
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleUnreceive}
                disabled={StateForm?.loading}
              >
                Batalkan Terima
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal update */}
      <Modal
        show={showUpdate}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Perbaharui Informasi SPM</Modal.Title>
        </Modal.Header>
        <FormPartial onClose={() => {
          $handleClose()
          onSubmit()
        }} data={data} />
      </Modal>

      {/* modal gudang */}
      <Modal
        show={showWarehouse}
        onHide={$handleClose}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Perbaharui Gudang SPM</Modal.Title>
        </Modal.Header>
        <FormWarehouse onClose={() => {
          $handleClose()
          onSubmit()
        }} data={data} />
      </Modal>
    </div>
  );
};
