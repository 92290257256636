import config from 'services/table/const';
import Action from './action';

let table = { ...config };
table.url = '/region/regency';
table.columns = {
  kanwil: {
    title: 'Kanwil',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.kanwil?.name}</div>
        </div>
      );
    },
    url: (row) => {
      return `/region/village?kanwil_id=${row?.kanwil?.id}&regency_id=${row?.id}`;
    },
  },

  regency: {
    title: 'Kota/Kab',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.name}</div>
        </div>
      );
    },
    url: (row) => {
      return `/region/village?kanwil_id=${row?.kanwil?.id}&regency_id=${row?.id}`;
    },
  },

  sorting_dusun: {
    title: 'Sorting Dusun',
    class: 'text-center',
    classHeader: 'text-center',
    width: 70,
    component: (row) => {
      return <Action data={row} />;
    },
  },
};

export default table;
