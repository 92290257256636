import React from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZW52aW9pZCIsImEiOiJja3g0bWx5NjgwN3MwMm9wYXB0cnpxdm43In0.F4GEDaOI0_o6fwWkL2r0lQ';

const MapBox = ({ locations, className, centerDynamic }) => {
  const mapContainer = React.useRef(null);
  const map = React.useRef(null);

  const [center, setCenter] = React.useState(null);

  React.useEffect(() => {
    if (centerDynamic) {
      if (locations?.length === 1) {
        const start = [locations[0]?.origin?.lng, locations[0]?.origin?.lat]; // LA
        const end = [
          locations[0]?.destination?.lng,
          locations[0]?.destination?.lat,
        ]; // Hawaii

        const midpoint = turf?.midpoint(start, end);

        setCenter(midpoint?.geometry?.coordinates);
      }
    }
  }, [locations]);

  React.useEffect(() => {
    const bounds = [
      [94.36405868758938, -11.650356606220004], // Southwest coordinates
      [141.95026998017508, 6.763051702954545], // Northeast coordinates
    ];

    map.current = new mapboxgl.Map({
      container: mapContainer?.current,
      style: 'mapbox://styles/envioid/ckx5jzwyo23s714ob0srde3yk',
      center: center ? [center[0], center[1]] : [118.005351, -2.325613],
      zoom: 4,
      interactive: true,
      maxBounds: bounds,
    });
    // return () => map.current.remove();
  }, [center]);

  React.useEffect(() => {
    if (!map?.current || !locations) return; // initialize map only once
    map?.current.on('load', () => {
      locations?.forEach((data, i) => {
        // if (i > 0) return;
        new mapboxgl.Marker({ scale: 0.4 })
          .setLngLat([data?.origin?.lng, data?.origin?.lat])
          .addTo(map?.current);

        new mapboxgl.Marker({ scale: 0.4, color: '#aa0000' })
          .setLngLat([data?.destination?.lng, data?.destination?.lat])
          .addTo(map?.current);

        const start = [data?.origin?.lng, data?.origin?.lat]; // LA
        const end = [data?.destination?.lng, data?.destination?.lat]; // Hawaii

        // rhumb functions
        const radius = turf?.rhumbDistance(start, end);
        const midpoint = turf?.midpoint(start, end);
        const bearing = turf?.rhumbBearing(start, end) - 89; // <-- not 90˚
        const origin = turf?.rhumbDestination(midpoint, radius, bearing);

        const curvedLine = turf?.lineArc(
          origin,
          turf?.distance(origin, start),
          turf?.bearing(origin, end),
          turf?.bearing(origin, start),
          { steps: 128 }
        );

        // route.features[0].geometry.coordinates = arc;
        if (centerDynamic) {
          map?.current?.fitBounds([start, end], { padding: 20 });
        }

        map?.current?.addSource(`route-${i}`, {
          type: 'geojson',
          data: curvedLine,
        });

        map?.current?.addLayer({
          id: `route-${i}`,
          source: `route-${i}`,
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-width': 1,
            'line-color': '#007cbf',
          },
        });
      });
    });
  }, [locations]);

  return (
    <div>
      <div
        ref={mapContainer}
        className={className ? className : 'map-container rounded-2'}
      />
    </div>
  );
};

export default MapBox;
