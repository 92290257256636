import React from 'react';
import { Modal } from 'react-bootstrap';
import ImageViewer from 'react-simple-image-viewer';
import pdf from 'ui/assets/pdf.png';
import * as Fi from 'react-icons/fi';
import ImageCrop from './crop';

const initialState = {
  isPdfopen: false,
  pdfFile: null,
  isOpen: false,
  isRotate: false,
  imgFile: null,
  current: 0,
  data: null,
};

const PODViewer = ({
  items,
  imgStyle,
  imgClass,
  wrapperClass,
  imgWrapperClass,
  showAction,
  deleteButton,
  updateButton,
  onLoad
}) => {
  const [{ isOpen, current, data, isPdfopen, pdfFile, isRotate, imgFile }, setState] =
    React.useState(initialState);

  const openImageViewer = React.useCallback((index) => {
    setState((prevState) => ({ ...prevState, current: index, isOpen: true }));
  }, []);

  const closeImageViewer = () => {
    setState((prevState) => ({ ...prevState, current: 0, isOpen: false }));
  };

  const closeRotate = () => {
    onLoad();
    setState((prevState) => ({ ...prevState, current: 0, isRotate: false }));
  }

  React.useEffect(() => {
    if (items) {
      let im = [];

      items?.forEach((d) => {
        const payload = d?.image?.replace('_thumb', '');
        im?.push(payload);
      });
      setState((prevState) => ({ ...prevState, data: im }));
    }
  }, [items]);

  if (!data) return null;

  return (
    <>
      <div className={wrapperClass}>
        {items?.map((d, i) => (
          <div className={imgWrapperClass}>
            <img
              key={i}
              className={imgClass}
              src={`${d?.image?.includes('.pdf') ? pdf : d?.image}`}
              alt=""
              style={imgStyle}
              onClick={() => {
                d?.image?.includes('.pdf')
                  ? setState((prevState) => ({
                      ...prevState,
                      isPdfopen: true,
                      pdfFile: d,
                    }))
                  : openImageViewer(i);
              }}
            />
            {showAction && (
              <>
                <div className="row">
                  {deleteButton(d)}
                  {updateButton && updateButton(d)}
                </div>

                {!d?.image?.includes('.pdf') && (
                  <div className='row justify-content-center'>
                    <div
                      className={`btn btn-info btn-sm px-2 py-0 mt-2 mx-3 ${!updateButton ? 'col-6' : ''}`}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isRotate: true,
                          imgFile: d,
                        }))
                      }
                    >
                      <Fi.FiRotateCw size="17" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>

      {isOpen && (
        <ImageViewer
          src={data}
          currentIndex={current}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}

      {/* pdf open */}
      <Modal
        show={isPdfopen}
        onHide={() =>
          setState((prevState) => ({ ...prevState, isPdfopen: false }))
        }
        className="text-center bg-transparent"
        contentClassName="bg-transparent "
        centered
        size="xl"
      >
        <Modal.Body className="bg-transparent" style={{ height: '95vh' }}>
          <div
            className="btn-close"
            onClick={() =>
              setState((prevState) => ({ ...prevState, isPdfopen: false }))
            }
          />

          <iframe
            title=" "
            src={pdfFile?.image}
            style={{
              width: '100%',
              height: '100%',
            }}
            frameBorder="0"
          ></iframe>
        </Modal.Body>
      </Modal>

      {/* rotate open */}
      <Modal
        show={isRotate}
        onHide={() =>
          setState((prevState) => ({ ...prevState, isRotate: false }))
        }
        backdrop="static"
        centered
        className="text-center"
        size="lg"
      >
        <ImageCrop data={imgFile} onClose={() => closeRotate()}/>
      </Modal>
    </>
  );
};

export default PODViewer;
