import React from 'react';
import cn from 'classnames';

const Navigation = ({ children, toolbar }) => {
  const cls = cn(
    'page-navigation d-flex flex-column flex-lg-row border-bottom',
    toolbar ? 'justify-content-end' : 'justify-content-between'
  );
  return <div className={cls}>{children}</div>;
};

export default Navigation;
