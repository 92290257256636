import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Delivery } from 'services/action';
import { currencyFormat, formatDate } from 'utilities/common';

const initialState = {
  spm: null,
};

export const FormSpm = ({ warehouseId, data }) => {
  const Dispatch = useDispatch();
  const Project = useSelector((state) => state?.Auth?.project);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Delivery.Get']
  );

  const [{ spm }, setState] = React.useState(initialState);

  const querySpm = () => {
    let query = {
      page: 1,
      limit: 10000000,
      bansos_id: Project?.id,
      warehouse_id: warehouseId
    };

    return query;
  };

  const $setupSpm = async () => {
    const res = await Dispatch(Delivery.$get(querySpm()));

    if (res && res?.message === 'success') {
      setState((prevState) => ({ ...prevState, spm: res?.data }));
    }
  };

  React.useEffect(() => {
    $setupSpm()
  }, [warehouseId, Project]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
            Informasi SPM
          </div>
        </div>
        {/* <div className="col-3">
          <div className="d-flex flex-row-reverse pt-1">
            <button
              type="button"
              className="btn btn-xs btn- "
              onClick={() => $addMoreFields()}
            >
              <Fi.FiPlus /> Tambah Item
            </button>
          </div>
        </div> */}
      </div>

      <div className="col-md-12 col-12 border-top">
        {StateLoading ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
            </div>
          </div>
        ) : !spm ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
              <p className="empty-title">Hasil tidak ditemukan</p>
              <p className="empty-subtitle text-muted">
                Coba sesuaikan pencarian atau filter Anda untuk menemukan apa
                yang Anda cari.
                <br /> Atau mungkin belum ada datanya!
              </p>
            </div>
          </div>
        ) : (
          <div className="table-responsive m-0">
            <table
              width="100%"
              className="table table-hover table-vcenter card-table datatable border"
            >
              <thead className="bg-light">
                <tr className="th">
                  <td style={{ width: '20px' }}></td>
                  <td style={{ width: '100px' }}>Kode</td>
                  <td style={{ width: '100px' }}>DOC-OUT</td>
                  <td style={{ width: '80px' }}>Rencana Pengiriman</td>
                  <td style={{ width: '130px' }}>Gudang</td>
                  <td style={{ width: '50px' }}>Total Dikirim</td>
                </tr>
              </thead>
              <tbody>
                {spm?.map((b, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{b?.code}</td>
                    <td>{b?.doc_outs?.join(',\n') || '-'}</td>
                    <td>{formatDate(b?.dispatched_at, 'DD/MM/YYYY')}</td>
                    <td>{`${b?.warehouse?.code} - ${b?.warehouse?.name}`}</td>
                    <td>{currencyFormat(b?.total_kpm)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
