import { Rest } from 'utilities/rest';
import * as ActionCreator from 'utilities/action.creator';
import { $processing } from 'services/activity/action';
import { $done } from 'services/activity/action';
import { $failure, $requesting, $success } from '../form/action';

export const MODULE = 'Region';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $get = ActionCreator.Async(MODULE, 'Get', (payload) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}/region`, payload)
      .then((response) => {
        dispatch($get.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($get.OPERATION)));
  };
});

export const $sortingDusun = ActionCreator.Async(MODULE, 'SortingDusun', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/region/sorting-dusun`, payload)
      .then((response) => {
        dispatch($sortingDusun.success({ response: response?.data }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});