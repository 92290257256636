import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { POD, Uploader } from 'services/action';
import pdf from 'ui/assets/pdf.png';
import { logo64 } from 'utilities/common';
import Resizer from "react-image-file-resizer";

var watermark = require('watermarkjs');

const initialState = {
  dragActive: false,
  docs: [],
  isUpload: false,
};

const ActionUpload = ({ data, kpm, type, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const Session = useSelector((state) => state.Auth?.session);

  const [
    {
      isUpload,
      dragActive,
      docs,
    },
    setState,
  ] = React.useState(initialState);
  const [imgDraft, setImgDraft] = React.useState(null);
  const inputRef = React.useRef(null);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      isUpload: false,
    }));
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setState((prevState) => ({ ...prevState, dragActive: true }));
    } else if (e.type === 'dragleave') {
      setState((prevState) => ({ ...prevState, dragActive: false }));
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({ ...prevState, dragActive: false }));
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files) {
      // handleFiles(e.target.files);
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleFiles = async (e) => {
    Array.from(e).forEach(async function (item) {
      const fileName = item?.name;
      let fileReader = new FileReader();
      fileReader?.readAsDataURL(item);
      fileReader.onload = async function () {
        let file = fileReader?.result;
        if (item?.type !== 'application/pdf') {
          setImgDraft(file);
          Resizer.imageFileResizer(
            item,
            2000,
            2000,
            "PNG",
            100,
            0,
            (uri) => {
              watermark([uri, logo64()])
                .image(watermark.image.upperLeft())
                .then(async function (img) {
                  let y = document
                    .querySelector('.demo-image-preview')
                    .appendChild(img);
                  y.id = 'foto';
                  file = document.querySelector('img#foto').src;
                  var image_x = document.getElementById('foto');

                  image_x.parentNode.removeChild(image_x);

                  if (type !== 'receive') {
                    file = uri
                  }

                  const fi = {
                    file,
                    fileName,
                  };

                  $upload(fi, e);
            });
          });
        } else {
          const fi = {
            file,
            fileName,
          };

          $upload(fi, e);
        }
      };
    });
  };

  const $upload = async (e) => {
    const payload = {
      file: e?.file,
      document: {
        village_id: data?.village_id,
        bansos_id: data?.bansos_id,
        type: type,
      },
    };

    const req = await Dispatch(Uploader.$upload(payload));
    if (req?.message === 'success') {
      let x = {
        data: req?.data,
        filename: e?.fileName,
      };
      setState((prevState) => ({ ...prevState, docs: [...prevState.docs, x] }));
    }
  };

  const $removeDoc = async (i) => {
    let newImg = [...docs];
    newImg.splice(i, 1);
    setState((prevState) => ({ ...prevState, docs: newImg }));
  };

  const $saveDoc = async () => {
    const payload = {
      document: {
        type: type,
        kpm_id: kpm?.id,
        bansos_id: data?.bansos_id,
        village_id: data?.village_id,
      },
    };

    if (docs) {
      const str = [];

      docs?.forEach((doc) => str?.push(doc?.data));

      payload.images = str;
    }

    const req = await Dispatch(POD.$upload(payload));

    if (req?.message === 'success') {
      $handleClose();
      onClose();
    }
  };

  const ImagePreview = ({ dataUri }) => {
    return (
      <div className="demo-image-preview d-none" id="lower-left">
        <img src={dataUri} alt="" />
      </div>
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column align-items-start"
        style={{ borderWidth: 1, borderColor: '#ddd' }}
      >
        <ImagePreview dataUri={imgDraft} />
        {(Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && Session?.usergroup?.id !== '302d772c-77db-47ea-ab5a-83b6be4d3787') && (
          <div>
            <div className="row align-items-center  py-2 ">
              <div className="col-12">
                <button
                  className="btn btn-sm btn-light rounded-2"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isUpload: true,
                      docs: [],
                    }))
                  }
                >
                  <Fi.FiUpload size={15} className="me-2" />
                  Upload File
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* modal upload */}
      <Modal
        show={isUpload}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="lg"
      >
        <Modal.Body>
          <div className="modal-status bg-primary" />
          <div className="row">
            <div className="col-5">
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                  accept="image/*,.pdf"
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? 'drag-active' : ''}
                >
                  <div>
                    <p>Drag and drop your file here or</p>
                    <button className="upload-button" onClick={onButtonClick}>
                      Upload a file
                    </button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </div>
            <div className="col-7">
              {docs?.map((doc, i) => (
                <div className="row border-bottom py-2 align-items-end" key={i}>
                  <div className="col-2 text-start ">
                    <img
                      src={`${doc?.data?.includes('.pdf') ? pdf : doc?.data}`}
                      alt=""
                      style={{ width: 40, height: 40 }}
                      className="rounded-3"
                    />
                  </div>
                  <div className="col-9 text-start overflow-hidden">
                    {doc?.filename}
                  </div>
                  <div className="col-1 text-start">
                    <div
                      className=" btn-action btn-sm cursor-pointer"
                      onClick={() => $removeDoc(i)}
                    >
                      <Fi.FiTrash color="red" size={14} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="success"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$saveDoc}
                disabled={StateForm?.loading}
              >
                Simpan Dokumen
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionUpload;
