import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { POD, Uploader, Download } from 'services/action';
import { toast } from 'react-toastify';

import pdf from 'ui/assets/pdf.png';
import PODViewer from 'ui/components/imageViewer';
import { logo64 } from 'utilities/common';
import Resizer from "react-image-file-resizer";
import UpdatePOD from './update_pod';
import { aliasBansosName } from 'utilities/common';

var watermark = require('watermarkjs');

const initialState = {
  url: '',
  isRemove: false,
  dragActive: false,
  docs: [],
  uploaded: [],
  isUpload: false,
  isDelete: false,
  isUpdate: false,
  zoomImg: null,
};

const ActionUpload = ({ data, type, received, onClose, isLine, status }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const Session = useSelector((state) => state.Auth?.session);

  const [
    {
      url,
      isRemove,
      isUpload,
      isDelete,
      isUpdate,
      dragActive,
      docs,
      uploaded,
      zoomImg,
    },
    setState,
  ] = React.useState(initialState);
  const [imgDraft, setImgDraft] = React.useState(null);
  const inputRef = React.useRef(null);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      isUpload: false,
      isDelete: false,
      isUpdate: false,
    }));
  };

  const $handleDownload = async() => {
    const params = {
      bansos: aliasBansosName(data?.bansos_id),
      type: type,
      village: data?.village?.name,
      district: data?.village?.district?.name,
      regency: data?.village?.district?.regency?.name,
      kanwil: data?.village?.district?.regency?.kanwil?.name,
    }

    const result = await Dispatch(Download.$imagePdf(params));
    if (result?.message === 'success') {
      setState((prevState) => ({ ...prevState, isRemove: true, url: result?.data }));
      window.open(result?.data, '_blank');
    } else {
      const message = 'Dokumen tidak tersedia.';
      toast.error(message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const $handleRemoveDownload = async() => {
    const payload = {
      url
    }

    const result = await Dispatch(Download.$remove(payload));
    if (result?.message === 'success') {
      setState((prevState) => ({ ...prevState, isRemove: false, url: null }));
    }
  }

  React.useEffect(() => {
    if (isRemove) {
      setTimeout(function () {
        $handleRemoveDownload()
      }, 5000)
    }
  }, [isRemove, url])

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setState((prevState) => ({ ...prevState, dragActive: true }));
    } else if (e.type === 'dragleave') {
      setState((prevState) => ({ ...prevState, dragActive: false }));
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({ ...prevState, dragActive: false }));
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files) {
      // handleFiles(e.target.files);
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleFiles = async (e) => {
    Array.from(e).forEach(async function (item) {
      const fileName = item?.name;
      let fileReader = new FileReader();
      fileReader?.readAsDataURL(item);
      fileReader.onload = async function () {
        let file = fileReader?.result;
        if (item?.type !== 'application/pdf') {
          setImgDraft(file);
          Resizer.imageFileResizer(
            item,
            2000,
            2000,
            "PNG",
            100,
            0,
            (uri) => {
              watermark([uri, logo64()])
                .image(watermark.image.upperLeft())
                .then(async function (img) {
                  let y = document
                    .querySelector('.demo-image-preview')
                    .appendChild(img);
                  y.id = 'foto';
                  file = document.querySelector('img#foto').src;
                  var image_x = document.getElementById('foto');

                  image_x.parentNode.removeChild(image_x);

                  if (type !== 'receive') {
                    file = uri
                  }

                  const fi = {
                    file,
                    fileName,
                  };

                  $upload(fi, e);
            });
          });
        } else {
          const fi = {
            file,
            fileName,
          };

          $upload(fi, e);
        }
      };
    });
  };

  const $upload = async (e) => {
    const payload = {
      file: e?.file,
      document: {
        village_id: data?.village_id,
        bansos_id: data?.bansos_id,
        type: type,
      },
    };

    const req = await Dispatch(Uploader.$upload(payload));
    if (req?.message === 'success') {
      let x = {
        data: req?.data,
        filename: e?.fileName,
      };
      setState((prevState) => ({ ...prevState, docs: [...prevState.docs, x] }));
    }
  };

  const $removeDoc = async (i) => {
    let newImg = [...docs];
    newImg.splice(i, 1);
    setState((prevState) => ({ ...prevState, docs: newImg }));
  };

  const $saveDoc = async () => {
    const payload = {
      document: {
        type: type,
        bansos_id: data?.bansos_id,
        village_id: data?.village_id,
      },
    };

    if (isLine) {
      payload.document.delivery_id = data?.line?.delivery_id;
      payload.document.line_id = data?.line_id;
    }

    if (docs) {
      const str = [];

      docs?.forEach((doc) => str?.push(doc?.data));

      payload.images = str;
    }

    const req = await Dispatch(POD.$upload(payload));

    if (req?.message === 'success') {
      $handleClose();
      $onLoad();
      onClose();
    }
  };

  const $onLoad = async () => {
    if (isLine) {
      $getByLine();
    } else {
      $getByVillage();
    }
  };

  const $getByLine = async () => {
    const req = await Dispatch(
      POD.$line(data?.line_id, {
        type: type,
        bansos_id: data?.bansos_id,
        village_id: data?.village_id,
      })
    );

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        uploaded: req?.data,
      }));
    }
  };

  const $getByVillage = async () => {
    const req = await Dispatch(
      POD.$recon(data?.bansos_id, data?.village_id, { type: type })
    );

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        uploaded: req?.data,
      }));
    }
  };

  const $deleteImage = async () => {
    const payload = {
      deleted_by: Session?.user?.name,
    };
    const del = await Dispatch(POD.$delete(zoomImg?.id, payload));
    if (del?.message === 'success') {
      $handleClose();
      onClose();
    }
  };



  React.useEffect(() => {
    if (!data) return null;

    $onLoad();
  }, [data]);

  const ImagePreview = ({ dataUri }) => {
    return (
      <div className="demo-image-preview d-none" id="lower-left">
        <img src={dataUri} alt="" />
      </div>
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column align-items-start"
        style={{ borderWidth: 1, borderColor: '#ddd' }}
      >
        <ImagePreview dataUri={imgDraft} />
        {(Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && Session?.usergroup?.id !== '302d772c-77db-47ea-ab5a-83b6be4d3787') && (
          <div>
            <div className="row align-items-center py-2 ">
              <div className="col-12">Dokumen </div>
              <div className='row'>
                <div className={`${uploaded ? 'col-6': 'col-12'}`}>
                  <button
                    className="btn btn-sm btn-light rounded-2"
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isUpload: true,
                        docs: [],
                      }))
                    }
                  >
                    <Fi.FiUpload size={15} className="me-2" />
                    Upload
                  </button>
                </div>
                {uploaded && (
                  <div className="col-6">
                    <button
                      className="btn btn-sm btn-light rounded-2"
                      onClick={$handleDownload}
                    >
                      <Fi.FiDownload size={15} className="me-2" />
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <PODViewer
          items={uploaded}
          imgStyle={{ height: 60, width: 60, objectFit: 'cover' }}
          imgClass="img-hover img-responsive rounded-3"
          wrapperClass="row mt-2 w-100"
          // imgWrapperClass="col-4 p-1 justify-content-center align-items-center d-flex flex-column border border-1 "
          imgWrapperClass="col-6 p-2 border-1 border justify-content-center align-items-center d-flex flex-column"
          showAction={
            (!received &&
            (Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && Session?.usergroup?.id !== '302d772c-77db-47ea-ab5a-83b6be4d3787')) ||
            (received && (Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805')) ||
            (status === 'rejected' && Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82')
          }
          deleteButton={(e) => {
            return (
              <div
                className={
                  StateForm?.loading
                    ? 'btn btn-danger btn-loading btn-sm px-2 py-0 mt-2'
                    : 'btn btn-danger btn-sm px-2 py-0 mt-2'
                }
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isDelete: true,
                    zoomImg: e,
                  }))
                }
              >
                <Fi.FiTrash2 size="17" />
              </div>
            );
          }}
          updateButton={(e) => {
            return (
              <div
                className={
                  StateForm?.loading
                    ? 'btn btn-secondary btn-loading btn-sm px-2 py-0 mt-2'
                    : 'btn btn-secondary btn-sm px-2 py-0 mt-2'
                }
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isUpdate: true,
                    zoomImg: e,
                  }))
                }
              >
                <Fi.FiEdit size="17" />
              </div>
            );
          }}
          onLoad={() => $onLoad()}
        />
      </div>

      {/* modal upload */}
      <Modal
        show={isUpload}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="lg"
      >
        <Modal.Body>
          <div className="modal-status bg-primary" />
          <div className="row">
            <div className="col-5">
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                  accept="image/*,.pdf"
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? 'drag-active' : ''}
                >
                  <div>
                    <p>Drag and drop your file here or</p>
                    <button className="upload-button" onClick={onButtonClick}>
                      Upload a file
                    </button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </div>
            <div className="col-7">
              {docs?.map((doc, i) => (
                <div className="row border-bottom py-2 align-items-end" key={i}>
                  <div className="col-2 text-start ">
                    <img
                      src={`${doc?.data?.includes('.pdf') ? pdf : doc?.data}`}
                      alt=""
                      style={{ width: 40, height: 40 }}
                      className="rounded-3"
                    />
                  </div>
                  <div className="col-9 text-start overflow-hidden">
                    {doc?.filename}
                  </div>
                  <div className="col-1 text-start">
                    <div
                      className=" btn-action btn-sm cursor-pointer"
                      onClick={() => $removeDoc(i)}
                    >
                      <Fi.FiTrash color="red" size={14} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="success"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$saveDoc}
                disabled={StateForm?.loading}
              >
                Simpan Dokumen
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal delete */}
      <Modal
        show={isDelete}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang anda hapus tidak dapat dipulihkan
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$deleteImage}
                disabled={StateForm?.loading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal update */}
      <Modal
        show={isUpdate}
        onHide={$handleClose}
        backdrop="static"
        centered
        size="small"
      >
        <Modal.Header closeButton>
          <Modal.Title>Perbaharui Tipe</Modal.Title>
        </Modal.Header>
        <UpdatePOD data={data} type={type} img={zoomImg} onClose={() => {
          $handleClose()
          onClose()
        }}/>

      </Modal>
    </>
  );
};

export default ActionUpload;
