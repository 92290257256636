import React from 'react';
import { renderToString } from 'react-dom/server';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { DeliveryLine } from 'services/action';

import bapang from 'ui/assets/badanpangan.png';
import bulog from 'ui/assets/logo_bulog.png';
import logoBlack from 'ui/assets/logo_jpl.png';

import { Print } from 'ui/components';
import { currencyFormat, formatDate } from 'utilities/common';

const POD_URL = `${process.env.REACT_APP_POD_SYSTEM_URL}`;

const PrintdataBast = ({ match }) => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['DeliveryLine.Show']
  );

  const [data, setData] = React.useState(null);
  const Project = useSelector((state) => state?.Auth?.project);

  const $onLoad = async (id) => {
    const detail = await Dispatch(DeliveryLine.$show(id));
    if (detail?.message === 'success') {
      setData(detail?.data);
    }
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, [match]);

  if (StateLoading || data === null) return null;

  const alokasi = {
    'aa93be9f-5841-4fb2-93f4-1e2ba8dba54a': 'Maret',
    '2acec690-a2a2-4257-b44a-9fb7b4e37fcf': 'April',
    'a7330f07-1505-4e0a-bf9f-4af347bc8389': 'Mei',
  };

  return (
    <div>
      {!data || data === null ? null : (
        <Print
          title={`BAST - ${data?.manifest}`}
          size="A4"
          content={renderToString(
            <>
              <section
                className=" sheet page-break"
                style={{ padding: '50px', position: 'relative' }}
              >
                <div
                  className="d-flex flex-row justify-content-between"
                  style={{ paddingBottom: 50 }}
                >
                  <div className="" style={{ width: '100%' }}>
                    <div
                      className="d-flex align-items-center "
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '20%',
                          justifyContent: 'start',
                          alignItems: 'start',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${data?.id}`}
                          value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                        />
                      </div>
                      <div style={{ alignSelf: 'initial', width: '60%' }}>
                        <h2 className="mb-0 text-center uppercase ">
                          laporan penyaluran dan penyerahan
                        </h2>
                        <h2 className="mb-0 text-center uppercase ">
                          beras bantuan pangan - CBP 2023
                        </h2>
                        <h2 className="mb-0 text-center uppercase ">
                          ALOKASI: {alokasi[data?.delivery?.bansos_id]} 2023
                        </h2>
                      </div>
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          alignItems: 'end',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${data?.id}`}
                          value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <p
                  className="mb-0"
                  style={{
                    fontStyle: 'italic',
                    fontSize: 9,
                    textAlign: 'end',
                  }}
                >
                  dicetak: {data?.delivery?.dispatched_by}{' '}
                  {formatDate(data?.delivery?.dispatched_at, 'DD-MM-YYYY')}{' '}
                </p>

                <div className="d-flex">
                  <div style={{ width: '50%', paddingRight: 5 }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Provinsi</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {
                              data?.area?.village?.district?.regency?.kanwil
                                ?.name
                            }
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kab. / Kota</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.district?.regency?.name}
                          </p>
                        </td>
                      </tr>

                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kecamatan</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.district?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kelurahan</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">GDG</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.warehouse?.code} -{' '}
                            {data?.delivery?.warehouse?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">DOC-OUT</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.doc_outs?.join(',\n') || '-'}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ width: '50%' }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">BAST GUDANG/ WO</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.code}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Total Paket</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {currencyFormat(data?.total_package)} Paket
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Total Berat</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {currencyFormat(
                              data?.total_package * Project?.weight_packages
                            )}{' '}
                            Kg
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">No Truck</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.vehicle_number}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Driver</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">No. HP</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.phone}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <table className="body" style={{ marginTop: 50 }}>
                  <tr className="">
                    <td className="" colSpan={3}>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: 10,
                          fontStyle: 'italic',
                          textAlign: 'end',
                        }}
                      >
                        *kolom Tanda Tangan
                      </p>
                    </td>
                  </tr>
                  <tr className="border">
                    <td
                      className="border"
                      style={{ height: 80, padding: 10, width: '33%' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          ADMIN GUDANG BULOG*
                        </p>
                      </div>
                    </td>
                    <td className="border" style={{ width: '33%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          ADMIN GUDANG TRANSPORTER*
                        </p>
                      </div>
                    </td>
                    <td className="border" style={{ width: '33%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          DRIVER*
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}></p>
                    </td>
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}>
                        {data?.delivery?.warehouse?.name}
                      </p>
                    </td>
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}>
                        {data?.delivery?.driver?.name}
                        <br />
                        {data?.delivery?.driver?.phone}
                      </p>
                    </td>
                  </tr>
                </table>

                <table className="body" style={{ marginTop: 50 }}>
                  <tr className="border">
                    <td className="border">
                      <h4>KETERANGAN : </h4>
                      <ul>
                        <li>
                          <p className="mb-0">
                            Sudah diterima paket sebanyak{' '}
                            {currencyFormat(data?.total_package)} Paket dengan
                            berat{' '}
                            {currencyFormat(
                              data?.total_package * Project?.weight_packages
                            )}{' '}
                            KG
                          </p>
                        </li>
                        <li className="mb-0">
                          <p>
                            {' '}
                            Sudah menerima dokumen Berita Acara Serah Terima
                            (BAST)
                          </p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>

                <table className="body">
                  <tr className="">
                    <td className="left" style={{ width: '33%' }}>
                      <img
                        src={bapang}
                        alt=""
                        style={{ maxHeight: 70, maxWidth: '100%' }}
                      />
                    </td>
                    <td className="center" style={{ width: '33%' }}>
                      <img
                        src={bulog}
                        alt=""
                        style={{ maxHeight: 50, maxWidth: '100%' }}
                      />
                    </td>
                    <td className="right" style={{ width: '33%' }}>
                      <img
                        src={logoBlack}
                        alt=""
                        style={{ maxHeight: 50, maxWidth: '100%' }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="center">
                      <QRCode
                        size={60}
                        // value={`${POD_URL}/pod/${data?.id}`}
                        value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                      />
                    </td>
                    <td className="right"></td>
                  </tr>
                </table>
              </section>
              <section
                className=" sheet page-break"
                style={{ padding: '50px', position: 'relative' }}
              >
                <div
                  className="d-flex flex-row justify-content-between"
                  style={{ paddingBottom: 50 }}
                >
                  <div className="" style={{ width: '100%' }}>
                    <div
                      className="d-flex align-items-center "
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '20%',
                          justifyContent: 'start',
                          alignItems: 'start',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${data?.id}`}
                          value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                        />
                      </div>
                      <div style={{ alignSelf: 'initial', width: '60%' }}>
                        <h2 className="mb-0 text-center uppercase ">
                          laporan penyaluran dan penyerahan
                        </h2>
                        <h2 className="mb-0 text-center uppercase ">
                          beras bantuan pangan - CBP 2023
                        </h2>
                        <h2 className="mb-0 text-center uppercase ">
                          ALOKASI: {alokasi[data?.delivery?.bansos_id]} 2023
                        </h2>
                      </div>
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          alignItems: 'end',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${data?.id}`}
                          value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <p
                  className="mb-0"
                  style={{
                    fontStyle: 'italic',
                    fontSize: 9,
                    textAlign: 'end',
                  }}
                >
                  dicetak: {data?.delivery?.dispatched_by}{' '}
                  {formatDate(data?.delivery?.dispatched_at, 'DD-MM-YYYY')}{' '}
                </p>

                <div className="d-flex">
                  <div style={{ width: '50%', paddingRight: 5 }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Provinsi</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {
                              data?.area?.village?.district?.regency?.kanwil
                                ?.name
                            }
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kab. / Kota</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.district?.regency?.name}
                          </p>
                        </td>
                      </tr>

                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kecamatan</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.district?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kelurahan</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">GDG</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.warehouse?.code} -{' '}
                            {data?.delivery?.warehouse?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">DOC-OUT</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.doc_outs?.join(',\n') || '-'}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ width: '50%' }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">BAST PENGIRIMAN/ WO</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.code}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Total Paket</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {currencyFormat(data?.total_package)} Paket
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Total Berat</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {currencyFormat(
                              data?.total_package * Project?.weight_packages
                            )}{' '}
                            Kg
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">No Truck</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.vehicle_number}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Driver</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">No. HP</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.phone}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <table className="body" style={{ marginTop: 50 }}>
                  <tr className="">
                    <td className="" colSpan={3}>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: 10,
                          fontStyle: 'italic',
                          textAlign: 'end',
                        }}
                      >
                        *kolom Tanda Tangan
                      </p>
                    </td>
                  </tr>
                  <tr className="border">
                    <td
                      className="border"
                      style={{ height: 80, padding: 10, width: '33%' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          DRIVER*
                        </p>
                      </div>
                    </td>
                    <td className="border" style={{ width: '33%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          ARKO/Korlap*
                        </p>
                      </div>
                    </td>
                    <td className="border" style={{ width: '33%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          PENDAMPING PBP*
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}>
                        {data?.delivery?.driver?.name}
                        <br />
                        {data?.delivery?.driver?.phone}
                      </p>
                    </td>
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}>
                        (Nama Jelas & TTD)
                      </p>
                    </td>
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}>
                        (Nama Jelas & TTD)
                      </p>
                    </td>
                  </tr>
                </table>

                <table className="body" style={{ marginTop: 50 }}>
                  <tr className="border">
                    <td className="border">
                      <h4>KETERANGAN : </h4>
                      <ul>
                        <li>
                          <p className="mb-0">
                            Sudah diterima paket sebanyak{' '}
                            {currencyFormat(data?.total_package)} Paket dengan
                            berat{' '}
                            {currencyFormat(
                              data?.total_package * Project?.weight_packages
                            )}{' '}
                            KG
                          </p>
                        </li>
                        <li className="mb-0">
                          <p>
                            {' '}
                            Sudah menerima dokumen Berita Acara Serah Terima
                            (BAST)
                          </p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>

                <table className="body">
                  <tr className="">
                    <td className="left" style={{ width: '33%' }}>
                      <img
                        src={bapang}
                        alt=""
                        style={{ maxHeight: 70, maxWidth: '100%' }}
                      />
                    </td>
                    <td className="center" style={{ width: '33%' }}>
                      <img
                        src={bulog}
                        alt=""
                        style={{ maxHeight: 50, maxWidth: '100%' }}
                      />
                    </td>
                    <td className="right" style={{ width: '33%' }}>
                      <img
                        src={logoBlack}
                        alt=""
                        style={{ maxHeight: 50, maxWidth: '100%' }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="center">
                      <QRCode
                        size={60}
                        // value={`${POD_URL}/pod/${data?.id}`}
                        value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                      />
                    </td>
                    <td className="right"></td>
                  </tr>
                </table>
              </section>

              <section
                className=" sheet page-break"
                style={{ padding: '50px', position: 'relative' }}
              >
                <div
                  className="d-flex flex-row justify-content-between"
                  style={{ paddingBottom: 50 }}
                >
                  <div className="" style={{ width: '100%' }}>
                    <div
                      className="d-flex align-items-center "
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '20%',
                          justifyContent: 'start',
                          alignItems: 'start',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${data?.id}`}
                          value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                        />
                      </div>
                      <div style={{ alignSelf: 'initial', width: '60%' }}>
                        <h2 className="mb-0 text-center uppercase ">
                          laporan penyaluran dan penyerahan
                        </h2>
                        <h2 className="mb-0 text-center uppercase ">
                          beras bantuan pangan - CBP 2023
                        </h2>
                        <h2 className="mb-0 text-center uppercase ">
                          ALOKASI: {alokasi[data?.delivery?.bansos_id]} 2023
                        </h2>
                      </div>
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          alignItems: 'end',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${data?.id}`}
                          value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <p
                  className="mb-0"
                  style={{
                    fontStyle: 'italic',
                    fontSize: 9,
                    textAlign: 'end',
                  }}
                >
                  dicetak: {data?.delivery?.dispatched_by}{' '}
                  {formatDate(data?.delivery?.dispatched_at, 'DD-MM-YYYY')}{' '}
                </p>

                <div className="d-flex">
                  <div style={{ width: '50%', paddingRight: 5 }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Provinsi</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {
                              data?.area?.village?.district?.regency?.kanwil
                                ?.name
                            }
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kab. / Kota</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.district?.regency?.name}
                          </p>
                        </td>
                      </tr>

                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kecamatan</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.district?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">Kelurahan</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.area?.village?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">GDG</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.warehouse?.code} -{' '}
                            {data?.delivery?.warehouse?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="border" style={{ width: '30%' }}>
                          <p className="mb-0 text-left">DOC-OUT</p>
                        </td>
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.doc_outs?.join(',\n') || '-'}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ width: '50%' }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">BAST SURAT JALAN/ WO</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.code}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Total Paket</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {currencyFormat(data?.total_package)} Paket
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Total Berat</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {currencyFormat(
                              data?.total_package * Project?.weight_packages
                            )}{' '}
                            Kg
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">No Truck</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.vehicle_number}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">Driver</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '45%' }}>
                          <p className="mb-0 text-left">No. HP</p>
                        </td>
                        <td className="border" style={{ width: '55%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.delivery?.driver?.phone}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <table className="body" style={{ marginTop: 50 }}>
                  <tr className="">
                    <td className="" colSpan={3}>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: 10,
                          fontStyle: 'italic',
                          textAlign: 'end',
                        }}
                      >
                        *kolom Tanda Tangan
                      </p>
                    </td>
                  </tr>
                  <tr className="border">
                    <td
                      className="border"
                      style={{ height: 80, padding: 10, width: '33%' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          DRIVER*
                        </p>
                      </div>
                    </td>
                    <td className="border" style={{ width: '33%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          ARKO/Korlap*
                        </p>
                      </div>
                    </td>
                    <td className="border" style={{ width: '33%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 150,
                            fontSize: 10,
                          }}
                        >
                          PENDAMPING PBP*
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}>
                        {data?.delivery?.driver?.name}
                        <br />
                        {data?.delivery?.driver?.phone}
                      </p>
                    </td>
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}>
                        (Nama Jelas & TTD)
                      </p>
                    </td>
                    <td className="border center">
                      <p className="mb-0 bold" style={{ fontSize: 10 }}>
                        (Nama Jelas & TTD)
                      </p>
                    </td>
                  </tr>
                </table>

                <table className="body" style={{ marginTop: 50 }}>
                  <tr className="border">
                    <td className="border">
                      <h4>KETERANGAN : </h4>
                      <ul>
                        <li>
                          <p className="mb-0">
                            Sudah diterima paket sebanyak{' '}
                            {currencyFormat(data?.total_package)} Paket dengan
                            berat{' '}
                            {currencyFormat(
                              data?.total_package * Project?.weight_packages
                            )}{' '}
                            KG
                          </p>
                        </li>
                        <li className="mb-0">
                          <p>
                            {' '}
                            Sudah menerima dokumen Berita Acara Serah Terima
                            (BAST)
                          </p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>

                <table className="body">
                  <tr className="">
                    <td className="left" style={{ width: '33%' }}>
                      <img
                        src={bapang}
                        alt=""
                        style={{ maxHeight: 70, maxWidth: '100%' }}
                      />
                    </td>
                    <td className="center" style={{ width: '33%' }}>
                      <img
                        src={bulog}
                        alt=""
                        style={{ maxHeight: 50, maxWidth: '100%' }}
                      />
                    </td>
                    <td className="right" style={{ width: '33%' }}>
                      <img
                        src={logoBlack}
                        alt=""
                        style={{ maxHeight: 50, maxWidth: '100%' }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="center">
                      <QRCode
                        size={60}
                        // value={`${POD_URL}/pod/${data?.id}`}
                        value={`${POD_URL}/pod?type=receive&line_id=${data?.id}&delivery_id=${data?.delivery_id}&bansos_id=${data?.delivery?.bansos_id}&village_id=${data?.area?.village_id}`}
                      />
                    </td>
                    <td className="right"></td>
                  </tr>
                </table>
              </section>
            </>
          )}
        />
      )}
    </div>
  );
};

export default PrintdataBast;
