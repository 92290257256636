import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import cn from 'classnames';

const FormInputAddon = ({
  label,
  icon,
  placeholder,
  error,
  required,
  hint,
  horizontal,
  value,
  onChange,
  type,
  lg,
  md,
}) => {
  const [secured, setSecured] = React.useState('password');

  const classes = cn(
    'form-control',
    error ? 'is-invalid' : '',
    lg ? 'py-3' : md ? 'py-2' : ''
  );

  return (
    <div className={horizontal ? 'row mb-3' : 'mb-3'}>
      {label ? (
        <label
          className={
            required
              ? 'form-label required'
              : horizontal
              ? 'form-label col-3 col-form-label'
              : 'form-label'
          }
        >
          {label}
        </label>
      ) : null}
      <div className="input-icon d-flex align-items-center">
        {icon ? <span className="input-icon-addon px-3">{icon}</span> : null}
        <input
          type={type === 'password' || type === 'new-password' ? secured : type}
          className={classes}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {type === 'password' ? (
          <span
            className="position-absolute end-0 "
            style={{
              marginRight: error ? 20 : 0,
              cursor: 'pointer',
            }}
          >
            {secured === 'password' ? (
              <span
                className={
                  icon ? 'input-group-link px-3' : 'input-group-link px-3'
                }
                onClick={() => setSecured('text')}
              >
                <FaEye size={18} color="#656d77" />
              </span>
            ) : (
              <span
                className="input-group-link px-3"
                onClick={() => setSecured('password')}
              >
                <FaEyeSlash size={18} color="#656d77" />
              </span>
            )}
          </span>
        ) : null}
      </div>
      {hint ? <small className="form-hint">{hint}</small> : null}
      {error ? <div className="d-block invalid-feedback">{error}</div> : null}
    </div>
  );
};

export default FormInputAddon;
