import React from "react";
import Header from "./header";
import Body from "./body";

const Layout = ({ children }) => {
  return <div className="wrapper">{children}</div>;
};

Layout.Header = Header;
Layout.Body = Body;
export default Layout;
