import CreateScreen from './create';
import DetailScreen from './detail';
import RttScreen from './index';
import UpdateScreen from './update';

const routes = [
  {
    path: '/rtt',
    component: RttScreen,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
    ],
  },
  {
    path: '/rtt/create',
    component: CreateScreen,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
    ],
  },
  {
    path: '/rtt/:id',
    component: DetailScreen,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6'
    ],
  },
  {
    path: '/rtt/:id/update',
    component: UpdateScreen,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
    ],
  },
];

export default routes;
