import ListHorizontal from '../list/list.hrz';
import ListItem from '../list/ListItem';
import ListRoute from '../list/route';

export const SkeletonLoading = () => {
  return (
    <>
      <div className="mt-3">
        <div className="row align-items-center justify-content-center">
          <div className="skeleton-avatar" style={{ height: 80, width: 80 }} />
        </div>
      </div>
      <div className="mt-3">
        <div className="skeleton-line pb-1 pt-0 skeleton-line-full" />
        <div className="skeleton-line pb-1 pt-0 skeleton-line-full" />
      </div>
    </>
  );
};

export const SkeletonText = ({ count }) => {
  const render = () => {
    var list = [];
    for (let i = 1; i <= count; i++) {
      list.push(
        <div key={i} className="skeleton-line pb-1 pt-0 skeleton-line-full" />
      );
    }
    return list;
  };
  return <div className="text-left py-1">{render()}</div>;
};

export const Skeletons = ({ height, width }) => {
  return (
    <div
      className="skeleton-image"
      style={{
        height: height ? height : '100%',
        width: width ? width : '100%',
      }}
    />
  );
};

export const SkeletonsForm = ({ count }) => {
  const render = () => {
    var list = [];
    for (let i = 1; i <= count; i++) {
      list.push(
        <div key={i} className="mb-3">
          <div style={{ width: 50 }}>
            <div className="skeleton-line pb-1 pt-0 skeleton-line-full" />
          </div>
          <div
            className="skeleton-image rounded-3"
            style={{
              height: 30,
              width: '100%',
            }}
          />
        </div>
      );
    }
    return list;
  };
  return <div>{render()}</div>;
};

export const SkeletonTable = ({ headerCount, bodyCount }) => {
  const renderHeader = () => {
    var list = [];
    for (let i = 1; i <= headerCount; i++) {
      list.push(
        <td key={i} className="py-1">
          <div className="py-1">
            <div className="skeleton-line pb-1 pt-0 skeleton-line-full py-2" />
          </div>
        </td>
      );
    }
    return list;
  };

  const renderBody = () => {
    var list = [];
    for (let i = 1; i <= bodyCount; i++) {
      list.push(<tr key={i}>{renderHeader()}</tr>);
    }
    return list;
  };

  return (
    <div className="table-responsive m-0" style={{ maxHeight: 700 }}>
      <table
        width="100%"
        className="table table-hover table-vcenter card-table datatable border"
      >
        <thead className="bg-light">
          <tr className="th">{renderHeader()}</tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
};

export const SkeletonPageFooter = () => {
  return (
    <div className="row align-items-center w-100">
      <div className="col-6 d-flex justify-content-start">
        <Skeletons height="20px" width="200px" />
      </div>
      <div className="col-6 d-flex justify-content-end">
        <Skeletons height="35px" width="200px" />
        <div className="ms-2">
          <Skeletons height="35px" width="100px" />
        </div>
      </div>
    </div>
  );
};

export const SkeletonJobCard = () => {
  return (
    <div className="mb-3 border rounded-2 ">
      <div className="row p-3 pt-2">
        <div className="col-md-6 ps-0">
          <ListHorizontal
            title={<SkeletonText count={1} />}
            content={<SkeletonText count={1} />}
          />
          <ListHorizontal
            title={<SkeletonText count={1} />}
            content={<SkeletonText count={1} />}
          />
          <ListHorizontal
            title={<SkeletonText count={1} />}
            content={<SkeletonText count={1} />}
          />
        </div>
        <div className="col-md-6 border-start">
          <ListRoute
            iconOrigin={<Skeletons height="18px" width="18px" />}
            origin={
              <ListItem
                className="border-0"
                title={<SkeletonText count={1} />}
                content={<SkeletonText count={1} />}
              />
            }
            iconDestination={<Skeletons height="18px" width="18px" />}
            destination={
              <ListItem
                className="border-0"
                title={<SkeletonText count={1} />}
                content={<SkeletonText count={1} />}
              />
            }
          />
          <ListItem
            className="border-bottom border-top py-2"
            title={<SkeletonText count={1} />}
            content={<SkeletonText count={1} />}
          />
          <ListItem
            className="py-2"
            title={<SkeletonText count={1} />}
            content={<SkeletonText count={1} />}
          />
        </div>
      </div>
    </div>
  );
};
