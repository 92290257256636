import { useHistory } from 'react-router-dom';
import { currencyFormat } from 'utilities/common';

export const SectionLine = ({ data }) => {
  const History = useHistory();

  return (
    <div className="p-0 p-md-3 mb-3 mb-md-0">
      <div className="card rounded-2 bg-white">
        <div className="card-header-gray p-3 border-bottom">
          <h3 className="card-title dx-card-title m-0 p-0">Daftar SPM Tujuan</h3>
        </div>
        <div className="card-body p-3 pt-3">
          <div className="table-responsive bg-white">
            <table
              width="100%"
              className="table table-hover table-vcenter card-table datatable border"
            >
              <thead className="bg-light">
                <tr className="th">
                  <td style={{ whiteSpace: 'nowrap', width: '5%' }}>No</td>
                  <td style={{ whiteSpace: 'nowrap', width: '15%' }}>Area</td>
                  <td
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                    className="text-center"
                  >
                    Total Package
                  </td>
                  <td
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                    className="text-center"
                  >
                    Total Received
                  </td>
                  <td
                    style={{ whiteSpace: 'nowrap', width: '15%' }}
                    className="text-center"
                  >
                    Status
                  </td>
                </tr>
              </thead>

              <tbody>
                {data?.map((d, i) => (
                  <tr
                    key={i}
                    onClick={() => History.push(`/spm/line/${d?.id}`)}
                  >
                    <td>{i + 1}</td>
                    <td>
                      <span className="d-block fs-8 fw-semibold">
                        {d?.area?.village?.name}
                      </span>
                      <span className="info fs-9">
                        {d?.area?.village?.district?.name},{' '}
                        {d?.area?.village?.district?.regency?.name},{' '}
                        {d?.area?.village?.district?.regency?.kanwil?.name}{' '}
                      </span>
                    </td>
                    <td className="text-center">
                      {currencyFormat(d?.total_package)}
                    </td>
                    <td className="text-center">
                      {currencyFormat(d?.total_received)}
                    </td>
                    <td className="text-center">
                      <span className={`badge status-${d?.status}-lt ms-auto`}>
                        {d?.status?.replace('new', 'Baru')}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
