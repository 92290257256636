import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from 'services/action';
import KanwilCarousel from './kanwil.carousel';

const CardCounter = () => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.project);

  const [data, setData] = React.useState(null);

  const $get = async () => {
    const req = await Dispatch(
      Report.$kanwil({ bansos_id: Session?.id, order_by: 'percentage' })
    );
    setData(req?.data);
  };

  React.useEffect(() => {
    $get();
  }, [Session]);

  return (
    <div className="card p-3 rounded-2 bg-white">
      <h3 className="card-title dx-card-title border-bottom pb-2">
        REALISASI Berdasarkan Kanwil
      </h3>
      <div>
        <KanwilCarousel data={data} />
      </div>
    </div>
  );
};

export default CardCounter;
