import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { Delivery } from 'services/action';
import { Select } from 'ui/components';
import { SkeletonsForm } from 'ui/components/skeleton';

const initialState = {
  isLoading: true,
  warehouse: null,
};

export const FormWarehouse = ({ data, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);

  const [{ warehouse, isLoading }, setState ] = React.useState(initialState);

  const dummy = Array.from({ length: 5 }, (v, i) => i);

  const $handleSubmit = async (c) => {
    const payload = {
      warehouse_id: warehouse?.id,
    };

    const submit = await Dispatch(Delivery.$updateWarehouse(data?.id, payload));
      if (submit?.message === 'success') {
        onClose();
      }
  }

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  React.useEffect(() => {
    if (!data) return;

    setState((prevState) => ({
      ...prevState,
      warehouse: data?.warehouse,
    }));
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <div className="row m-0">
          {dummy?.map((d, i) => (
            <div className="col-md-6 col-12" key={i}>
              <SkeletonsForm count={1} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal.Body>
        <div className="row">
          <div className="mb-3">
            <Select.Remote
              required
              label="Pilih Gudang"
              placeholder=""
              url="/warehouse"
              initialValue={warehouse?.id}
              keys={warehouse?.id}
              row={(d) => {
                return { value: d.id, label: d.name };
              }}
              onSelected={(c) => {
                setState((prevState) => ({
                  ...prevState,
                  warehouse: c,
                }));
              }}
              errors={StateForm?.errors?.warehouse_id}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button
              type="button"
              className='btn btn-light w-100'
              onClick={() => onClose()}
            >
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className={cn(
                StateForm?.loading ? 'btn-loading' : '',
                  'btn btn-primary w-100'
                )}
              disabled={StateForm?.loading}
              onClick={$handleSubmit}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};
