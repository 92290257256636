import React from 'react';
import { useSelector } from 'react-redux';

import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';

import tableConfig from './table.config';

const ReportProvince = () => {
  const Bansos = useSelector((state) => state?.Auth?.project);
  const ServiceTable = useTable('report_recon_province', tableConfig, false);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );

  React.useEffect(() => {
    const filters = {
      bansos_id: Bansos?.id,
      order_by: 'percentage'
    };

    ServiceTable.boot(filters);
  }, []);

  return (
    <div className="sub-page">
      <div className="px-3">
        <ServiceTable.Tools downloadable />
      </div>
      <div className="sub-page-body">
        {StateLoading ? (
          <SkeletonTable headerCount={6} bodyCount={10} />
        ) : (
          <ServiceTable.Render />
        )}
      </div>
      <div className="sub-page-footer">
        {StateLoading ? <SkeletonPageFooter /> : <ServiceTable.Pagination />}
      </div>
    </div>
  );
};

export default ReportProvince;
