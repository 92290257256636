import React from 'react';
import { Form } from 'ui/components';
import * as Fi from 'react-icons/fi';
import _ from 'underscore';


const initialState = {
  datas: [
    {value: ''}
  ]
};

export const DocOut = ({ data, onInputChange }) => {

  const [{ datas }, setState] =
    React.useState(initialState);

  React.useEffect(() => {
    let doc_outs = []

    if (datas?.length > 0) {
      _.each(datas, function(i) {
        if (i?.value) {
          doc_outs.push(i.value);
        }
      })
    }

    onInputChange(doc_outs);
  }, [datas]);

  const $handleChange = async (i, e) => {
    let newItems = [...datas];
    newItems[i]['value'] = e.target.value;

    setState((prevState) => ({
      ...prevState,
      datas: newItems,
    }));
  }

  const $removeDocOut = (key) => {
    let newDocOut = [...datas];
    newDocOut.splice(key, 1);
    setState((prevState) => ({
      ...prevState,
      datas: newDocOut,
    }));
  }

  const $addDocOut = () => {
    setState((prevState) => ({
      ...prevState,
      datas: [
        ...datas,
      {
        value: '',
      },
      ],
    }));
  }

  React.useEffect(() => {
    if (!data) return

    let datas = []
    if (data?.doc_outs?.length > 0) {
      _.each(data?.doc_outs, function(i){
        datas.push({value: i})
      })
    }else {
      datas.push({value: ''})
    }

    setState((prevState) => ({
      ...prevState,
      datas: datas,
    }));
  }, [data]);

  return (
    <>
      {datas?.map((d, i) => (
        <div key={i}>
          <div className='row'>
            <div className='col-10'>
              <Form.Input
                label="DOC-OUT"
                type="text"
                value={d?.value}
                onChange={(e) => $handleChange(i, e)}
              />
            </div>

            {datas?.length > 1 && (
              <div className='col-2'>
                <div onClick={() => $removeDocOut(i)}>
                  <Fi.FiX color="red" />
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <div className='col-12 text-center'>
        <button
          type="button"
          className="btn btn-secondary btn-xs rounded"
          onClick={() => $addDocOut()}
        >
          <Fi.FiPlus />
          Tambah Doc-Out
        </button>
      </div>
    </>
  )
}
