import { Rest } from 'utilities/rest';
import { $processing, $done } from '../activity/action';
import * as ActionCreator from 'utilities/action.creator';
import _ from 'underscore';

const MODULE = 'Table';
const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $initialized = ActionCreator.Default(
  MODULE,
  'Initialized',
  (name, config, state) => {
    return async (dispatch) => {
      let table = state;

      if (typeof state === 'undefined') {
        table = config;
      }

      dispatch($request(name, table));
    };
  }
);

export const $filter = ActionCreator.Default(
  MODULE,
  'Filter',
  (name, field, value, config) => {
    return async (dispatch) => {
      if (config !== undefined) {
        let table = config;

        if (field === 'date_range') {
          table.filter.start_at = value[0] ? value[0] : '';
          table.filter.end_at = value[1] ? value[1] : '';
        } else if (Array.isArray(field)) {
          _.each(field, function(k, v) {
            table.filter[k?.label] = k?.value
          })
        } else {
          table.filter[field] = value;
        }

        table.currentPage = 1;

        dispatch($request(name, table));
      }
    };
  }
);

export const $search = ActionCreator.Default(
  MODULE,
  'Search',
  (name, text, config) => {
    return async (dispatch) => {
      let table = config;
      table.textSearch = text;
      table.currentPage = 1;

      dispatch($request(name, table));
    };
  }
);

export const $pagination = ActionCreator.Default(
  MODULE,
  'Pagination',
  (name, page, config) => {
    return async (dispatch) => {
      let table = config;

      table.currentPage = page;

      dispatch($request(name, table));
    };
  }
);

export const $limit = ActionCreator.Default(
  MODULE,
  'Limit',
  (name, limit, config) => {
    return async (dispatch) => {
      let table = config;
      table.limitPerPage = limit;
      table.currentPage = 1;

      dispatch($request(name, table));
    };
  }
);

export const $sorting = ActionCreator.Default(
  MODULE,
  'Sorting',
  (name, sort, config) => {
    return async (dispatch) => {
      let table = config;
      table.sorting = sort;

      dispatch($request(name, table));
    };
  }
);

export const $download = ActionCreator.Default(
  MODULE,
  'Download',
  (name, table) => {
    return async (dispatch) => {
      let p = params(table);
      p.downloadable = true;

      return await Rest.GET(`${API_URL}${table.url}`, p)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          return false;
        });
    };
  }
);

export const $reload = ActionCreator.Default(
  MODULE,
  'Reload',
  (name, table) => {
    return async (dispatch) => {
      dispatch($request(name, table));
    };
  }
);

const params = (table) => {
  let params = {
    page: table.currentPage,
    limit: table.limitPerPage,
    search: table.textSearch,
    order_by: table.sorting,
    downloadable: table.downloadable,
  };

  Object.keys(params).map(function (key) {
    return (
      (params[key] === '' || params[key] === 0 || params[key] === null) &&
      delete params[key]
    );
  });

  // apply filter into params
  if (table.filter !== null) {
    const filters = table.filter;

    Object.keys(filters).map(function (key) {
      if (filters[key] !== '' && filters[key] !== null) {
        params[key] = filters[key];

        if (typeof filters[key] === 'object') {
          params[key] = filters[key].join('.');
        }
      }

      return filters;
    });
  }

  return params;
};

export const $request = ActionCreator.Async(
  MODULE,
  'Request',
  (name, table) => {
    return async (dispatch) => {
      dispatch($processing($request.OPERATION));

      return await Rest.GET(`${API_URL}${table.url}`, params(table))
        .then((response) => {
          table.total = response?.total;
          table.data = response?.data;
          table.isEmpty = false;

          if (
            !response?.data ||
            response?.data?.length === 0 ||
            response?.total === 0
          ) {
            table.isEmpty = true;
            table.data = [];
            table.total = 0;
          }

          dispatch($request.success({ name, table }));

          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => {
          dispatch($done($request.OPERATION));
        });
    };
  }
);
