import React from 'react';
import { useSelector } from 'react-redux';

import {  Page, Select } from 'ui/components';
import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';

import tableConfig from './table.config';

const initialState = {
  area: null,
};

const ReportPOD = () => {
  const Bansos = useSelector((state) => state?.Auth?.project);
  const ServiceTable = useTable('report_pod', tableConfig, false);

  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );
  const [{ area }, setState] =
    React.useState(initialState);

  const $handleKanwil = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        kanwil: e,
        regency: null,
        district: null,
      },
    }));

    ServiceTable.filter('kanwil_id', e?.kanwil?.id);
  };

  const $handleRegency = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        ...prevState?.area,
        regency: e,
        district: null,
      },
    }));

    ServiceTable.filter('regency_id', e?.regency?.id);
  };

  const $handleDistrict = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        ...prevState?.area,
        district: e,
      },
    }));

    ServiceTable.filter('district_id', e?.district?.id);
  };

  React.useEffect(() => {
    const filters = {
      bansos_id: Bansos?.id,
    };

    ServiceTable.boot(filters);
  }, []);

  return (
    <Page className="fixed-sm bordered">
      <Page.Header title="Laporan POD" isLoading={StateLoading} />

      <div className="row bg-white px-3 py-2 m-0 align-items-center">
        <div className="col-md-8 col-12 row">
          <div className="col-md-6 col-12 mb-2">
            <Select.Remote
              label="Pilih Kanwil"
              placeholder=""
              url="/report/kanwil"
              params={{
                bansos_id: Bansos?.id,
              }}
              row={(d) => {
                return { value: d.kanwil, label: d?.kanwil?.name };
              }}
              onSelected={(c) => {
                $handleKanwil(c);
              }}
            />
          </div>
          <div className="col-md-6 col-12 mb-2">
            <Select.Remote
              label="Pilih Kota/Kab"
              placeholder=""
              url={`/report/regency`}
              params={{
                kanwil_id: area?.kanwil?.kanwil?.id,
                bansos_id: Bansos?.id,
              }}
              row={(d) => {
                return { value: d?.regency, label: d?.regency?.name };
              }}
              onSelected={(c) => {
                $handleRegency(c);
              }}
              keys={area?.kanwil?.kanwil?.id}
              disabled={!area?.kanwil}
            />
          </div>
          <div className="col-md-6 col-12 mb-2">
            <Select.Remote
              label="Pilih Kecamatan"
              placeholder=""
              url={`/report/district`}
              row={(d) => {
                return { value: d?.district, label: d?.district?.name };
              }}
              params={{
                regency_id: area?.regency?.regency?.id,
                bansos_id: Bansos?.id,
              }}
              onSelected={(c) => {
                $handleDistrict(c);
              }}
              keys={area?.regency?.regency?.id}
              disabled={!area?.regency}
            />
          </div>
        </div>
        <div className='col-12'>
          <div className="px-3">
            <ServiceTable.Tools downloadable />
          </div>
        </div>
      </div>

      <Page.Body className="bordered">
        {StateLoading ? (
          <SkeletonTable headerCount={6} bodyCount={10} />
        ) : (
          <ServiceTable.Render />
        )}
      </Page.Body>
      <Page.Footer>
        {StateLoading ? <SkeletonPageFooter /> : <ServiceTable.Pagination />}
      </Page.Footer>
    </Page>
  );
};

export default ReportPOD;
