import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DateRangePicker, Page, Select } from 'ui/components';
import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';
import { formatDate } from 'utilities/common';

import { Report } from 'services/action';

import { currencyFormat, decimalFormat } from 'utilities/common';
import tableConfig from './table.config';

const initialState = {
  area: null,
  dispatch: null,
  count: null,
};

const ReportSPM = () => {
  const Dispatch = useDispatch();
  const Bansos = useSelector((state) => state?.Auth?.project);
  const StateTable = useSelector((state) => state?.Table?.data['report_spm']);
  const ServiceTable = useTable('report_spm', tableConfig, false);

  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );
  const [{ area, dispatch, count }, setState] =
    React.useState(initialState);

  const $handleKanwil = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        kanwil: e,
        regency: null,
        district: null,
      },
    }));

    let filters = [
      {
        label: 'district_id',
        value: null
      },
      {
        label: 'regency_id',
        value: null
      },
      {
        label: 'kanwil_id',
        value: e?.kanwil?.id
      }
    ]

    ServiceTable.filter(filters);
  };

  const $handleRegency = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        ...prevState?.area,
        regency: e,
        district: null,
      },
    }));

    let filters = [
      {
        label: 'district_id',
        value: null
      },
      {
        label: 'regency_id',
        value: e?.regency?.id
      },
    ]

    ServiceTable.filter(filters);
  };

  const $handleDistrict = async (e) => {
    setState((prevState) => ({
      ...prevState,
      area: {
        ...prevState?.area,
        district: e,
      },
    }));

    ServiceTable.filter('district_id', e?.district?.id);
  };

  const $onLoadCount = async (e) => {
    const result = await Dispatch(Report.$getSPMCount(StateTable?.filter));
    if (result?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        count: result?.data
        // total_kpm: result?.data?.total_kpm || 0,
        // total_received: result?.data?.total_received || 0,
        // percent: (result?.data?.total_received / result?.data?.total_kpm) * 100,
      }));
    }
  };

  React.useEffect(() => {
    const filters = {
      bansos_id: Bansos?.id,
      report: 'spm'
    };

    ServiceTable.boot(filters);
  }, []);

  React.useEffect(() => {
    $onLoadCount();
  }, [StateTable, area, dispatch]);

  return (
    <Page className="fixed-sm bordered">
      <Page.Header title="Laporan SPM" isLoading={StateLoading} />

      <div className="row bg-white px-3 py-2 m-0 align-items-center">
        <div className="col-md-8 col-12 row">
          <div className="col-md-6 col-12 mb-2">
            <Select.Remote
              label="Pilih Kanwil"
              placeholder=""
              url="/report/kanwil"
              params={{
                bansos_id: Bansos?.id,
              }}
              row={(d) => {
                return { value: d.kanwil, label: d?.kanwil?.name };
              }}
              onSelected={(c) => {
                $handleKanwil(c);
              }}
            />
          </div>
          <div className="col-md-6 col-12 mb-2">
            <Select.Remote
              label="Pilih Kota/Kab"
              placeholder=""
              url={`/report/regency`}
              params={{
                kanwil_id: area?.kanwil?.kanwil?.id,
                bansos_id: Bansos?.id,
              }}
              row={(d) => {
                return { value: d?.regency, label: d?.regency?.name };
              }}
              onSelected={(c) => {
                $handleRegency(c);
              }}
              keys={area?.kanwil?.kanwil?.id}
              disabled={!area?.kanwil}
            />
          </div>
          <div className="col-md-6 col-12 mb-2">
            <Select.Remote
              label="Pilih Kecamatan"
              placeholder=""
              url={`/report/district`}
              row={(d) => {
                return { value: d?.district, label: d?.district?.name };
              }}
              params={{
                regency_id: area?.regency?.regency?.id,
                bansos_id: Bansos?.id,
              }}
              onSelected={(c) => {
                $handleDistrict(c);
              }}
              keys={area?.regency?.regency?.id}
              disabled={!area?.regency}
            />
          </div>
          <div className="col-md-6 col-12 mb-2">
            <div className="mb-3">
              <label class="form-label fs-8">Pilih Tanggal</label>
              <DateRangePicker
                value={dispatch}
                placeholder=""
                onChange={(date) => {
                  setState((prevState) => ({
                    ...prevState,
                    dispatch: date,
                  }));
                  let fm = [];
                  date?.forEach((data) =>
                    fm?.push(formatDate(data, 'YYYY-MM-DD'))
                  );
                  ServiceTable.filter('date_range', fm);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="row p-2 mb-3 mb-md-0  justify-content-center">
            <div
              className="rounded-2 border text-center p-2 col-12 col-md-12 mb-3 mb-md-0"
              style={{
                background: `linear-gradient(90deg,  ${
                  count?.percentage > 70 ? 'rgba(47,179,68,1)' : 'rgba(246,103,7,1)'
                } 0%, ${
                  count?.percentage > 70
                    ? count?.percentage === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(152,139,36,1)'
                    : 'rgba(231,81,31,1)'
                } ${count?.percentage}%, ${
                  count?.percentage > 70
                    ? count?.percentage === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(238,106,9,1)'
                    : 'rgba(216,59,55,1)'
                } ${(count?.percentage + 6).toFixed(0)}%,  ${
                  count?.percentage > 70
                    ? count?.percentage === 100
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(246,103,7,1)'
                    : 'rgba(214,57,57,1)'
                } 100%)`,
              }}
            >
              <div className="fs-4 fw-semibold text-left text-white">
                {decimalFormat(count?.percentage || 0)} %
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="fs-5 fw-semibold text-left text-white">
                    {currencyFormat(count?.total_kpm || 0)}
                  </div>
                  <div className="page-pretitle text-left text-white">
                    TOTAL DIKIRIM
                  </div>
                </div>
                <div className="col-6">
                  <div className="fs-5 fw-semibold text-left text-white">
                    {currencyFormat(count?.total_received || 0)}
                  </div>
                  <div className="page-pretitle text-left text-white">
                    TOTAL DITERIMA
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div className="px-3">
            <ServiceTable.Tools downloadable />
          </div>
        </div>
      </div>

      <Page.Body className="bordered">
        {StateLoading ? (
          <SkeletonTable headerCount={6} bodyCount={10} />
        ) : (
          <ServiceTable.Render />
        )}
      </Page.Body>
      <Page.Footer>
        {StateLoading ? <SkeletonPageFooter /> : <ServiceTable.Pagination />}
      </Page.Footer>
    </Page>
  );
};

export default ReportSPM;
