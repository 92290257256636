import React from 'react';
import cn from 'classnames';

const ListItem = ({ title, content, className, onClick }) => {
  const c = cn(className ? className : 'list-item py-2');

  return (
    <div className={c} onClick={onClick}>
      <div className="page-pretitle">{title}</div>
      <div className="title fw-semibold">{content}</div>
    </div>
  );
};

export default ListItem;
