import React from 'react';
import cn from 'classnames';

const Footer = ({ children, className }) => {
  return (
    <div
      className={cn('page-footer d-flex align-items-center w-100', className)}
    >
      {children}
    </div>
  );
};

export default Footer;
