import * as Fi from 'react-icons/fi';
import config from 'services/table/const';
import { currencyFormat } from 'utilities/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

let table = { ...config };
table.url = '/report/village';
table.filter = {report_type: 'recon'}
table.columns = {
  code: {
    title: 'Kelurahan',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.village?.name}</div>
          <small className="info text-ellipsis">
            {row?.village?.district?.name},{' '}
            {row?.village?.district?.regency?.name},{' '}
            {row?.village?.district?.regency?.kanwil?.name}
          </small>
        </div>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  total_kpm: {
    title: 'PBP',
    width: 100,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return <div className="table-col">{currencyFormat(row?.total_kpm)}</div>;
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  bast: {
    title: 'BAST',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.bast_status === '' ? '' :
      row?.bast_status === 'accepted' ? <Fi.FiCheck size={20} color="#28ba00" /> : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Alasan: <br/>{row?.bast_reason}</Tooltip>}
        >
          <div>
            <Fi.FiX size={20} color="#d63939"/>
          </div>
        </OverlayTrigger>
      )
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  sptjm: {
    title: 'SPTJM',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.sptjm_status === '' ? '' :
      row?.sptjm_status === 'accepted' ? <Fi.FiCheck size={20} color="#28ba00" /> : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Alasan: <br/>{row?.sptjm_reason}</Tooltip>}
        >
          <div>
            <Fi.FiX size={20} color="#d63939"/>
          </div>
        </OverlayTrigger>
      )
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  rtt: {
    title: 'BNBA / DTT',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.rtt_status === '' ? '' :
      row?.rtt_status === 'accepted' ? <Fi.FiCheck size={20} color="#28ba00" /> : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Alasan: <br/>{row?.rtt_reason}</Tooltip>}
        >
          <div>
            <Fi.FiX size={20} color="#d63939"/>
          </div>
        </OverlayTrigger>
      )
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  stt: {
    title: 'SPM / STT',
    sortable: false,
    width: 150,
    class: 'text-center',
    classHeader: 'text-center',
    component: (row) => {
      return row?.stt_status === '' ? '' :
      row?.stt_status === 'accepted' ? <Fi.FiCheck size={20} color="#28ba00" /> : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Alasan: <br/>{row?.stt_reason}</Tooltip>}
        >
          <div>
            <Fi.FiX size={20} color="#d63939"/>
          </div>
        </OverlayTrigger>
      )
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },

  status: {
    title: 'Status',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 120,
    component: (row) => {
      return (
        <span className={`badge status-${row?.status}-lt ms-auto`}>
          {row?.status?.replace('_', ' ')?.replace('new', 'Baru')}
        </span>
      );
    },
    url: (row) => {
      return `/recon/${row?.id}`;
    },
  },
};

export default table;
