import ReconScreen from '.';
import ReconDetailScreen from './detail';

const routes = [
  {
    path: '/recon',
    component: ReconScreen,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805'
    ],
  },

  {
    path: '/recon/:id',
    component: ReconDetailScreen,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '302d772c-77db-47ea-ab5a-83b6be4d3787',
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '8465c143-a4e3-478e-b019-cb4e1035e886',
    ],
  },
];

export default routes;
