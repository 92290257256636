import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from 'services/action';
import { Form } from 'ui/components';

import _ from 'underscore';

const initialState = {
  districts: null,
  checkedAll: false
};

export const FormRttLines = ({ errors, onFormChange, data, region }) => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Report.Regency']
  );

  const Project = useSelector((state) => state?.Auth?.project);

  const [{ districts, checkedAll }, setState] = React.useState(initialState);

  const $handleCheckedAll = async (e) => {
    let newItems = [...districts];

    _.each(newItems, function(i) {
      if (i.isInput) {
        i.checked = checkedAll ? false : true
      }
    })

    setState((prevState) => ({ ...prevState, districts: newItems, checkedAll: checkedAll ? false: true }));
  }

  const $handleItemsChange = async (i, e) => {
    let newItems = [...districts];

    newItems[i]['checked'] = districts[i].checked ? false : true;

    setState((prevState) => ({ ...prevState, districts: newItems }));
  };

  const queryArea = () => {
    let query = {
      page: 1,
      limit: 100,
      bansos_id: Project?.id
    };

    if (region?.kanwil) {
      query.kanwil_id = region?.kanwil?.kanwil_id;
    }

    if (region?.regency) {
      query.regency_id = region?.regency?.regency_id;
    }

    return query;
  };

  const $setupDistirct = async () => {
    const res = await Dispatch(Report.$district(queryArea()));

    if (res && res?.message === 'success') {
      let newData = [];

      let x = data?.lines;

      await res?.data?.forEach((data) => {
        const payload = {
          report_district_id: data?.id,
          report_district: data,
          checked: false,
          isInput: false
        };

        if (data?.status === '') {
          payload.isInput = true
        }

        x?.map((y) => {
          if (data?.id === y?.report_district_id) {
            payload.id = y?.id
            payload.checked = true
            payload.isInput = true
          }
          return true;
        });

        newData?.push(payload);
      });
      setState((prevState) => ({ ...prevState, districts: newData }));
    }
  };

  React.useEffect(() => {
    if (region?.kanwil && region?.regency) {
      $setupDistirct();
    } else {
      setState((prevState) => ({ ...prevState, districts: null }));
    }
  }, [region]);

  React.useEffect(() => {
    let newLines = [];

    if (districts) {
      districts?.forEach((line) => {
        if (line?.checked) {
          newLines.push({
            id: line?.id,
            report_district_id: line?.report_district_id,
          });
        }
      });
    }

    onFormChange(newLines);
  }, [districts]);

  React.useEffect(() => {
    if (data) {
      let region = {kanwil: null, regency: null}

      if (data?.region?.kanwil_id) {
        region.kanwil = {kanwil: {id: data?.region?.kanwil_id, name: data?.region?.kanwil_name}}
      }

      if (data?.region?.regency_id) {
        region.regency = {regency: {id: data?.region?.regency_id, name: data?.region?.regency_name}}
      }

      setState((prevState) => ({
        ...prevState,
        region: region,
      }));
    }
  }, [data]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="hr-text hr-text-left text-primary mb-3 mt-md-3 mt-3">
            Informasi Kecamatan
          </div>
        </div>
      </div>

      <div className="col-md-12 col-12 border-top">
        {StateLoading ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
            </div>
          </div>
        ) : !districts ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
              <p className="empty-title">Hasil tidak ditemukan</p>
              <p className="empty-subtitle text-muted">
                Silahkan pilih kanwil dan kota / Kabupaten
                yang Anda cari.
              </p>
            </div>
          </div>
        ) : (
          <div className="table-responsive m-0">
            <table
              width="100%"
              className="table table-hover table-vcenter card-table datatable border"
            >
              <thead className="bg-light">
                <tr className="th">
                  <td style={{ width: '20px' }}></td>
                  <td
                    style={{
                      whiteSpace: 'initial',
                      width: '200px',
                    }}
                  >
                    Kecamatan
                  </td>
                  <td
                    style={{
                      whiteSpace: 'initial',
                      width: '200px',
                    }}
                  >
                    PBP
                  </td>
                  <td style={{ width: '20px' }}>
                    {districts && (
                      <Form.Checkbox
                        value={checkedAll}
                        onChange={(e) => $handleCheckedAll(e)}
                        iconSize={18}
                      />
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {districts?.map((b, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{b?.report_district?.district?.name}</td>
                    {b?.isInput && (
                      <>
                        <td>{b?.report_district?.total_kpm}</td>
                        <td>
                          <Form.Checkbox
                            value={b?.checked}
                            onChange={(e) => $handleItemsChange(i, e)}
                            iconSize={18}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {errors ? (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {errors}
          </div>
        ) : null}
      </div>
    </div>
  );
};
