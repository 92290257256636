import PrintBast from './bast';
import PrintBastKota from './bast.kab';
import PrintRecap from './recap';
import PrintRTT from './kpm';
import PrintRTTOld from './kpm_old';
import PrintLineBast from './line.bast';
import PrintReportDailyWH from './wh.daily.report';

const routes = [
  {
    path: '/print/bast/:id',
    component: PrintBast,
  },
  {
    path: '/print/bast/kota/:id',
    component: PrintBastKota,
  },
  {
    path: '/print/recap/:id',
    component: PrintRecap,
  },
  {
    path: '/print/linebast/:id',
    component: PrintLineBast,
  },
  {
    path: '/print/rtt/:id',
    component: PrintRTT,
  },
  {
    path: '/print/rtt/old/:id',
    component: PrintRTTOld,
  },
  {
    path: '/print/whreport',
    component: PrintReportDailyWH,
  },
];

export default routes;
