import * as Action from './action';

const defineInitialState = () => ({
  isAuthenticated: false,
  session: null,
  token: '',
  project: null,
});

export function reducer(state = defineInitialState(), action) {
  switch (action.type) {
    case Action.$reset.ACTION:
      return defineInitialState();
    case Action.$signin.REQUEST:
      return {
        ...state,
        session: null,
        token: '',
      };
    case Action.$signin.SUCCESS:
      return {
        isAuthenticated: true,
        session: {
          user: action.response.user,
          usergroup: action.response.usergroup,
          user_area: action.response.user_area,
          client: action.response.client || action.response.user,
          warehouse: action.response.warehouse,
        },
        token: action.response.token,
      };
    case Action.$fetchProfile.SUCCESS:
      return {
        ...state,
        session: {
          user: action.response.user,
          usergroup: action.response.usergroup,
          client: action.response.client || action.response.user,
          user_area: action.response.user_area,
          warehouse: action.response.warehouse,
        },
      };
    case Action.$project.ACTION:
      return {
        ...state,
        project: action?.payload,
      };
    case Action.$logout.ACTION:
      return {
        isAuthenticated: false,
        session: null,
        token: '',
        project: null,
      };
    default:
      return state;
  }
}

export function persister({ isAuthenticated, session, token, project }) {
  return {
    isAuthenticated,
    session,
    token,
    project,
  };
}
