import React from 'react';
import { Button, ButtonGroup, DropdownButton, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Delivery, Table } from 'services/action';

const initialState = {
  isShow: false,
};

const Action = ({ data }) => {
  const Dispatch = useDispatch();
  const StateTable = useSelector(
    (state) => state?.Table?.data['delivery_line']
  );
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Delivery.Delete']
  );
  const [{ isShow }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({ ...prevState, isShow: false }));
  };

  const $handleDelete = async () => {
    const del = await Dispatch(Delivery.$delete(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      Dispatch(Table.$reload('delivery_line', StateTable));
    }
  };

  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        variant="transparent"
        size="md"
        className="no-carret tbl-action"
        title={<Fi.FiMoreVertical size={13} color="gray" />}
      >
        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={`/spm/${data?.id}`}
        >
          <Fi.FiClipboard size={15} color="#202020" className="me-2" />
          Detil
        </Link>
        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={`/spm/${data?.id}/update`}
        >
          <Fi.FiEdit size={15} color="#202020" className="me-2" />
          Perbaharui
        </Link>

        <button
          className="dropdown-item d-flex align-items-center"
          onClick={() =>
            setState((prevState) => ({ ...prevState, isShow: true }))
          }
        >
          <Fi.FiTrash2 size={15} className="me-2" />
          Hapus
        </button>
      </DropdownButton>

      {/* modal delete */}
      <Modal
        show={isShow}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang sudah dihapus tidak bisa dipulihkan
          </p>

          {/* {isError ? (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {isError?.id}
            </div>
          ) : null} */}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateLoading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleDelete}
                disabled={StateLoading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Action;
