import Select from 'react-select';
import Options from './options';
import Remote from './remote';
import RemoteCreateable from './remote.createable';
import CreatableSelect from 'react-select/creatable';

Select.Remote = Remote;
Select.Remote.Createable = RemoteCreateable;
Select.Options = Options;
Select.Createable = CreatableSelect;
export default Select;
