import React from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, Form, Select } from 'ui/components';

import { SkeletonsForm } from 'ui/components/skeleton';
import { formatDate } from 'utilities/common';
import { DocOut } from './doc_out'

import _ from 'underscore';

const initialState = {
  ref_code: '',
  doc_outs: null,
  driver: null,
  warehouse: null,
  dispatch_at: {
    default: new Date(),
    formatted: formatDate(new Date(), 'YYYY-MM-DD'),
  },
  totalKpm: 0,
};

export const FormGeneral = ({ isLoading, onInputChange, data, lines }) => {
  const Session = useSelector((state) => state?.Auth?.session);
  const StateForm = useSelector((state) => state?.Form);
  const Project = useSelector((state) => state?.Auth?.project);
  const [{ ref_code, doc_outs, driver, warehouse, dispatch_at, totalKpm }, setState] =
    React.useState(initialState);

  React.useEffect(() => {
    const state = {
      ref_code,
      doc_outs,
      driver,
      dispatch_at,
      warehouse,
    };

    onInputChange(state);
  }, [ref_code, doc_outs, driver, warehouse, dispatch_at]);

  React.useEffect(() => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        ref_code: data?.ref_code,
        driver: data?.driver,
        warehouse: data?.warehouse,
        dispatch_at: {
          default: new Date(data?.dispatched_at),
          formatted: formatDate(data?.dispatched_at, 'YYYY-MM-DD'),
        },
      }));
    }
  }, [data]);

  React.useEffect(() => {
    if (!data) {
      if (Session?.warehouse) {
        setState((prevState) => ({
          ...prevState,
          warehouse: Session?.warehouse,
        }));
      }
    }
  }, []);

  React.useEffect(() => {
    let tot = 0;
    _.each(lines, function (x) {
      tot += x?.total_package;
    });

    setState((prevState) => ({
      ...prevState,
      totalKpm: tot,
    }));
  }, [lines]);

  return (
    <div className="sidebar h-100 ">
      <div
        className="form-group sidebar-body-create py-3 pe-0 pe-md-3"
        style={{ overflow: 'auto', maxHeight: 'calc(100vh - 20vh)' }}
      >
        {isLoading ? (
          <SkeletonsForm count={7} />
        ) : (
          <div>
            <div className="mb-4">
              <div className="hr-text text-primary mb-3 mt-md-3 mt-3">
                Informasi General
              </div>

              <div className="mb-3 disabled">
                <label className="form-label fs-8 required">Tanggal Pengiriman</label>
                <div
                  className={
                    StateForm?.errors?.etd_at
                      ? 'border border-danger rounded'
                      : ''
                  }
                >
                  <DatePicker
                    type="normal"
                    value={dispatch_at?.default || null}
                    format="dd/MM/yyyy"
                    onChange={(date) =>
                      setState((prevState) => ({
                        ...prevState,
                        dispatch_at: {
                          default: date,
                          formatted: formatDate(date, 'YYYY-MM-DD'),
                        },
                      }))
                    }
                    isShowTrigger
                  />
                </div>
                {StateForm?.errors?.dispatch_at && (
                  <div
                    className="invalid-feedback"
                    style={{ display: 'block' }}
                  >
                    {StateForm?.errors?.dispatch_at}
                  </div>
                )}
              </div>

              {(Project?.id === 'aa93be9f-5841-4fb2-93f4-1e2ba8dba54a' && (Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805')) && (
                <div className="mb-3">
                  <Form.Input
                    label="Ref Code"
                    type="text"
                    value={ref_code || ''}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        ref_code: e.target.value,
                      }))
                    }
                    error={StateForm?.errors?.ref_code}
                  />
                </div>
              )}

              {Session?.warehouse ? (
                <div className="mb-3">
                  <Form.Input
                    label="Gudang"
                    type="text"
                    value={Session?.warehouse?.name || ''}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        warehouse: e.target.value,
                      }))
                    }
                    error={StateForm?.errors?.warehouse_id}
                    disabled
                  />
                </div>
              ) : (
                <div className="mb-3">
                  <Select.Remote
                    required
                    label="Pilih Gudang"
                    placeholder=""
                    url="/warehouse"
                    value={warehouse}
                    initialValue={warehouse?.id}
                    keys={warehouse?.id}
                    row={(d) => {
                      return { value: d.id, label: d.name };
                    }}
                    onSelected={(c) => {
                      setState((prevState) => ({
                        ...prevState,
                        warehouse: c,
                      }));
                    }}
                    errors={StateForm?.errors?.warehouse_id}
                  />
                </div>
              )}
            </div>

            <div className="mb-3">
              <div className="hr-text text-primary mb-3 mt-md-3 mt-3">
                Informasi Driver
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <Form.Input
                      label="Nama"
                      required
                      type="text"
                      value={driver?.name || ''}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          driver: {
                            ...prevState?.driver,
                            name: e.target.value,
                          },
                        }))
                      }
                      error={
                        StateForm?.errors?.driver ||
                        StateForm?.errors['driver.name']
                      }
                    />
                  </div>

                  <Form.Input
                    label="No. Handphone"
                    required
                    type="text"
                    value={driver?.phone || ''}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        driver: {
                          ...prevState?.driver,
                          phone: e.target.value,
                        },
                      }))
                    }
                    error={
                      StateForm?.errors?.driver ||
                      StateForm?.errors['driver.phone']
                    }
                  />
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <Form.Input
                      label="Jenis Kendaraan"
                      required
                      type="text"
                      value={driver?.vehicle_type || ''}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          driver: {
                            ...prevState?.driver,
                            vehicle_type: e.target.value,
                          },
                        }))
                      }
                      error={
                        StateForm?.errors?.driver ||
                        StateForm?.errors['driver.vehicle_type']
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Input
                      label="No. Kendaraan"
                      required
                      type="text"
                      value={driver?.vehicle_number || ''}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          driver: {
                            ...prevState?.driver,
                            vehicle_number: e.target.value,
                          },
                        }))
                      }
                      error={
                        StateForm?.errors?.driver ||
                        StateForm?.errors['driver.vehicle_number']
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <div className="rounded-2 border bg-light-lt text-center p-2 mb-3">
                <div className="fs-5 fw-semibold text-left text-secondary">
                  {totalKpm}
                </div>
                <div className="page-pretitle text-left">total pbp</div>
              </div>
            </div>

            <div className="mb-3">
              <DocOut data={data} onInputChange={(e) => setState((prevState) => ({ ...prevState, doc_outs: e }))} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
