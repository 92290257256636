import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

const TableWrapper = ({ children }) => {
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );

  const cls = cn(StateLoading ? 'card-loading' : null);

  return <div className={cls}>{children}</div>;
};

export default TableWrapper;
