import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';
import { $failure, $requesting, $success } from '../form/action';
import { $done, $processing } from 'services/activity/action';

export const MODULE = 'Download';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $imageZip = ActionCreator.Async(MODULE, 'ImageZip', (payload) => {
  return async (dispatch) => {
    dispatch($processing($imageZip.OPERATION));

    return await Rest.GET(`${API_URL}/download/zip`, payload)
      .then((response) => {
        dispatch($imageZip.success({ response: response }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($imageZip.OPERATION)));
  };
});

export const $imagePdf = ActionCreator.Async(MODULE, 'ImagePdf', (payload) => {
  return async (dispatch) => {
    dispatch($processing($imagePdf.OPERATION));

    return await Rest.GET(`${API_URL}/download/pdf`, payload)
      .then((response) => {
        dispatch($imagePdf.success({ response: response }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($imagePdf.OPERATION)));
  };
});

export const $remove = ActionCreator.Async(MODULE, 'Remove', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.DELETE(`${API_URL}/download`, payload)
      .then((response) => {
        dispatch($remove.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});
