import React from 'react';
import Slider from 'react-slick';
import _ from 'underscore';
import CardPie from './card.pie';

const RttCarousel = ({ data }) => {
  const [render, setRender] = React.useState(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    speed: 500,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const renderPeriodic = () => {
    if (data) {
      let page = _.chunk(data, 4);
      if (data?.length > 4) {
        let fp = page[0];
        let lp = _.rest(data, fp?.length);
        page = _.chunk(lp, 4);
        page.unshift(fp);
      }

      setRender(page);
    }
  };

  React.useEffect(() => {
    renderPeriodic();
  }, [data]);

  return (
    <div className="pb-4">
      <Slider {...settings}>
        {render?.map((page, i) => (
          <div className="" key={i}>
            <div className="row mt-0">
              {page?.map((d, j) => (
                <div className="col-6 p-2 pt-0" key={j}>
                  <div className="card border-0 rtt-card rounded-2 text-dark shadow-sm">
                    <div className="card-body pb-0">
                      <h5 className="card-title fw-semibold fs-7">
                        {d?.kanwil?.name}
                      </h5>
                    </div>
                    <CardPie data={d} />

                    <div className="px-3">
                      <div className="d-flex justify-content-between py-1  align-items-center border-bottom">
                        <div className="rtt-label">PROSES</div>
                        <div className="rtt-label text-dark fw-bold">
                          {d?.rtt_count?.total_process}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between py-1  align-items-center border-bottom">
                        <div className="rtt-label">APPROVED</div>
                        <div className="rtt-label text-dark fw-bold">
                          {d?.rtt_count?.total_completed}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between py-1  align-items-center">
                        <div className="rtt-label">TOTAL</div>
                        <div className="rtt-label text-dark fw-bold">
                          {d?.rtt_count?.total_regency}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RttCarousel;
