import cn from 'classnames';
import React from 'react';
import * as Bs from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Report } from 'services/action';
import _ from 'underscore';
import { currencyFormat, decimalFormat } from 'utilities/common';
import CountUpComponent from './countup';

const CardCounter = () => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const Session = useSelector((state) => state?.Auth?.project);
  const [data, setData] = React.useState(null);
  const [recap, setRecap] = React.useState(null);
  const [newData, setNewData] = React.useState(null);
  const [newRecap, setNewRecap] = React.useState(null);

  const $get = async () => {
    const req = await Dispatch(
      Report.$kanwil({ bansos_id: Session?.id, order_by: 'percentage' })
    );

    var total_kpm = _.reduce(
      req?.data,
      function (num, i) {
        return i?.total_kpm + num;
      },
      0
    );

    var total_shipped = _.reduce(
      req?.data,
      function (num, i) {
        return i?.total_shipped + num;
      },
      0
    );

    setRecap({
      total_kpm,
      total_shipped,
      percent: (total_shipped / total_kpm) * 100,
    });
    setData(req?.data);
  };

  const $getNext = async () => {
    const req = await Dispatch(
      Report.$kanwil({ bansos_id: Session?.id, order_by: 'percentage' })
    );

    var total_kpm = _.reduce(
      req?.data,
      function (num, i) {
        return i?.total_kpm + num;
      },
      0
    );

    var total_shipped = _.reduce(
      req?.data,
      function (num, i) {
        return i?.total_shipped + num;
      },
      0
    );

    setNewRecap({
      total_kpm,
      total_shipped,
      percent: (total_shipped / total_kpm) * 100,
    });
    setNewData(req?.data);

    setTimeout(() => {
      setData(req?.data);
      setRecap({
        total_kpm,
        total_shipped,
        percent: (total_shipped / total_kpm) * 100,
      });
    }, 10000);
  };

  React.useEffect(() => {
    $get();

    const interval = setInterval(() => {
      $getNext();
    }, 300000);

    return () => {
      clearInterval(interval);
    };
  }, [Session]);

  return (
    <div className="row bg-red">
      <div className="col-md-9 order-2 order-md-1">
        <div className="row ">
          {data?.map((row, i) => {
            let y = _.findWhere(newData, { id: row?.id });
            let up = y?.total_shipped - row?.total_shipped;

            return (
              <div
                className={cn(
                  'col-md-3 recap-region cursor-pointer '
                  // (row?.total_shipped / row?.total_kpm) * 100 > 30
                  //   ? (row?.total_shipped / row?.total_kpm) * 100 > 80
                  //     ? 'bg-success'
                  //     : 'bg-warning'
                  //   : 'bg-danger'
                )}
                key={i}
                style={{
                  background: `linear-gradient(90deg,  ${
                    (row?.total_shipped / row?.total_kpm) * 100 > 70
                      ? 'rgba(47,179,68,1)'
                      : 'rgba(246,103,7,1)'
                  } 0%, ${
                    (row?.total_shipped / row?.total_kpm) * 100 > 70
                      ? (row?.total_shipped / row?.total_kpm) * 100 === 100
                        ? 'rgba(47,179,68,1)'
                        : 'rgba(152,139,36,1)'
                      : 'rgba(231,81,31,1)'
                  } ${((row?.total_shipped / row?.total_kpm) * 100).toFixed(
                    0
                  )}%, ${
                    (row?.total_shipped / row?.total_kpm) * 100 > 70
                      ? (row?.total_shipped / row?.total_kpm) * 100 === 100
                        ? 'rgba(47,179,68,1)'
                        : 'rgba(238,106,9,1)'
                      : 'rgba(216,59,55,1)'
                  } ${((row?.total_shipped / row?.total_kpm) * 100 + 6).toFixed(
                    0
                  )}%,  ${
                    (row?.total_shipped / row?.total_kpm) * 100 > 70
                      ? 'rgba(246,103,7,1)'
                      : 'rgba(214,57,57,1)'
                  } 100%)`,
                }}
                onClick={() =>
                  History.push({
                    pathname: '/report/distribusi/regency',
                    search: `?kanwil_id=${row?.kanwil_id}`,
                  })
                }
              >
                <div className="row align-items-center">
                  <div className="col-12">
                    <h3>{row?.kanwil?.name}</h3>
                  </div>
                  <div className="col-7">
                    {up > 0 && (
                      <div
                        className="position-relative"
                        style={{ marginBottom: -10 }}
                      >
                        <Bs.BsCaretUpFill style={{ marginTop: -5 }} />
                        <span
                          style={{
                            position: 'absolute',
                            top: 0,
                            fontSize: 13,
                          }}
                        >
                          {currencyFormat(up)}
                        </span>
                      </div>
                    )}
                    <CountUpComponent
                      starting={0}
                      ending={row?.total_shipped}
                      fs={18}
                      surfix=" PBP"
                    />

                    <CountUpComponent
                      starting={0}
                      ending={row?.total_shipped * Session?.weight_packages}
                      fs={18}
                      surfix=" Kg"
                    />
                  </div>
                  <div className="col-5 text-end ps-0">
                    <CountUpComponent
                      starting={0}
                      ending={decimalFormat(
                        (row?.total_shipped / row?.total_kpm) * 100
                      )}
                      fs={16}
                      surfix=" %"
                      decimals={2}
                    />
                    <div>
                      <p className="mb-0 lh-sm">
                        /&nbsp;{currencyFormat(row?.total_kpm)} PBP,{' '}
                        {currencyFormat(
                          row?.total_kpm * Session?.weight_packages
                        )}{' '}
                        Kg
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="col-md-3 p-0 order-1 order-md-2"
        style={{
          background: `linear-gradient(90deg,  ${
            recap?.percent > 70 ? 'rgba(47,179,68,1)' : 'rgba(246,103,7,1)'
          } 0%, ${
            recap?.percent > 70 ? 'rgba(152,139,36,1)' : 'rgba(231,81,31,1)'
          } ${recap?.percent}%, ${
            recap?.percent > 70 ? 'rgba(238,106,9,1)' : 'rgba(216,59,55,1)'
          } ${(recap?.percent + 6).toFixed(0)}%,  ${
            recap?.percent > 70 ? 'rgba(246,103,7,1)' : 'rgba(214,57,57,1)'
          } 100%)`,
        }}
      >
        <div className="recap-sum">
          <div className="text-center">
            <CountUpComponent
              starting={0}
              ending={decimalFormat(recap?.percent || 0)}
              fs={80}
              surfix=" %"
              decimals={2}
              className="percentage mt-n3"
            />
            {/* <h1 className="percentage">
              {decimalFormat(recap?.percent || 0)}
              <span>%</span>
            </h1> */}
            <h3 className="mt-n2">Total Realisasi Distribusi</h3>
            {/* <h1>{currencyFormat(recap?.total_shipped)} / </h1> */}

            {newRecap?.total_shipped - recap?.total_shipped > 0 && (
              <div
                className="d-flex align-items-baseline"
                style={{ marginBottom: -10 }}
              >
                <Bs.BsCaretUpFill />
                <span
                  style={{
                    fontSize: 15,
                    fontWeight: 'bold',
                  }}
                >
                  {currencyFormat(
                    newRecap?.total_shipped - recap?.total_shipped
                  )}
                </span>
              </div>
            )}
            <div>
              <span className="d-flex align-items-baseline justify-content-center">
                <CountUpComponent
                  starting={0}
                  ending={recap?.total_shipped}
                  fs={28}
                />{' '}
                <span
                  style={{
                    fontSize: 18,
                    lineHeight: '20px',
                    fontWeight: 'bold',
                    paddingLeft: 0,
                  }}
                >
                  {' '}
                  / {currencyFormat(recap?.total_kpm)} PBP
                </span>
              </span>
            </div>

            <div>
              <span className="d-flex align-items-baseline justify-content-center">
                <CountUpComponent
                  starting={0}
                  ending={recap?.total_shipped * Session?.weight_packages}
                  fs={28}
                />{' '}
                <span
                  style={{
                    fontSize: 18,
                    lineHeight: '20px',
                    fontWeight: 'bold',
                    paddingLeft: 0,
                  }}
                >
                  {' '}
                  /{' '}
                  {currencyFormat(
                    recap?.total_kpm * Session?.weight_packages
                  )}{' '}
                  Kg
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCounter;
