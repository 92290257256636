import cn from 'classnames';
import React from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Rtt } from 'services/action';

import { Skeletons } from 'ui/components/skeleton';
import { ActionRTT } from './rtt';

const initialState = {
  isDelete: false,
  isStatus: false,
};

export const Action = ({ data, onSubmit }) => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const Session = useSelector((state) => state?.Auth?.session);
  const StateForm = useSelector((state) => state.Form);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Delivery.Show']
  );

  const [{ isDelete, isStatus }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      isDelete: false,
      isStatus: false,
    }));
  };

  const $handleDelete = async () => {
    const del = await Dispatch(Rtt.$delete(data?.id));
    if (del?.message === 'success') {
      $handleClose();

      History.replace('/rtt');
    }
  };

  const dummy = Array.from({ length: 6 }, (v, i) => i);

  if (Session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' || Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '302d772c-77db-47ea-ab5a-83b6be4d3787') return null

  return (
    <div className="d-flex align-items-center gap-1 px-3 px-md-0 pb-2 pb-md-0">
      {StateLoading ? (
        dummy?.map((d, i) => <Skeletons key={i} height="35px" width="35px" />)
      ) : (
        <>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-top`}>Print Rekap BAST</Tooltip>}
          >
            <Link
              to={`/print/recap/${data?.id}`}
              className="btn btn-primary px-2 py-2"
              target="_blank"
            >
              <Fi.FiPrinter size={15} />
            </Link>
          </OverlayTrigger>
          {(data?.document_status === 'draft' || data?.document_status === 'process') && (
            <>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-top`}>Perbaharui</Tooltip>}
              >
                <Link
                  to={`/rtt/${data?.id}/update`}
                  className="btn btn-secondary px-2 py-2"
                >
                  <Fi.FiEdit size={15} />
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-top`}>Hapus</Tooltip>}
              >
                <button
                  className={cn('btn btn-danger px-2')}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isDelete: true,
                    }))
                  }
                >
                  <Fi.FiTrash2 size="17" />
                </button>
              </OverlayTrigger>

            </>
          )}
          {data?.document_status === 'draft' && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-top`}>Proses / Maju RTT</Tooltip>}
            >
              <button
                className={cn('btn btn-success px-2')}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isStatus: true,
                  }))
                }
              >
                <Fi.FiArrowRightCircle size="17" />
              </button>
            </OverlayTrigger>
          )}
          {data?.document_status === 'process' && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-top`}>Setujui RTT</Tooltip>}
            >
              <button
                className={cn('btn btn-success px-2')}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isStatus: true,
                  }))
                }
              >
                <Fi.FiCheckCircle size="17" />
              </button>
            </OverlayTrigger>
          )}
        </>
      )}
      {/* modal delete */}
      <Modal
        show={isDelete}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang sudah dihapus tidak bisa dipulihkan
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleDelete}
                disabled={StateForm?.loading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal status */}
      <Modal
        show={isStatus}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="xl"
      >
        <ActionRTT
          data={data}
          onClose={() => {
            $handleClose();
            onSubmit();
          }}
        />
      </Modal>
    </div>
  );
};
