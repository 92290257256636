import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { DeliveryLine } from 'services/action';
import { Form } from 'ui/components';
import { SkeletonsForm } from 'ui/components/skeleton';

const initialState = {
  isLoading: true,
  total_package: 0,
  kpm_left: 0,
  total_kpm: 0,
};

export const FormUpdate = ({ data, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);

  const [{ total_package, kpm_left, total_kpm, isLoading }, setState ] = React.useState(initialState);

  const dummy = Array.from({ length: 2 }, (v, i) => i);

  const $changeKpm = async (e) => {
    setState((prevState) => ({
      ...prevState,
      kpm_left: total_kpm - e?.target?.valueNumber,
      total_package: e?.target?.valueNumber
    }));

  }

  const $handleSubmit = async (c) => {
    const payload = {
      total_package,
    };

    const submit = await Dispatch(DeliveryLine.$update(data?.id, payload));
      if (submit?.message === 'success') {
        onClose();
      }
  }

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  React.useEffect(() => {
    if (!data) return;

    const m = parseFloat(data?.area?.total_kpm) - parseFloat(data?.area?.total_shipped)

    setState((prevState) => ({
      ...prevState,
      kpm_left: m,
      total_kpm: m + data?.total_package,
      total_package: data?.total_package,
    }));
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <div className="row m-0">
          {dummy?.map((d, i) => (
            <div className="col-12" key={i}>
              <SkeletonsForm count={1} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal.Body>
        <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <div class="form-group mb-3 text-center">
                  <label className="form-label">Sisa KPM</label>
                  <span className="bg-white form-control py-3 fw-semibold">{`${kpm_left}`}</span>
                </div>
                </div>
                <div className="mb-3 text-start">
                  <Form.Input
                    label="QTY Pengiriman"
                    required
                    value={total_package}
                    name="qty"
                    type="number"
                    onChange={(e) => $changeKpm(e)}
                    min={0}
                    max={total_kpm}
                  />
                </div>
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button
              type="button"
              className='btn btn-light w-100'
              onClick={() => onClose()}
            >
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className={cn(
                StateForm?.loading ? 'btn-loading' : '',
                  'btn btn-primary w-100'
                )}
              disabled={StateForm?.loading}
              onClick={$handleSubmit}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};
