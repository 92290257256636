/* eslint-disable no-undef */
/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { App } from 'services/action';
import { Layout } from 'ui/components';
import _ from 'underscore';

// Read subrouter in each subfolder
let routes = [];
const rf = require.context('./', true, /^.*_subrouter.js$/);
rf.keys().forEach((fileName) => {
  routes = routes.concat(rf(fileName).default);
});

const ListenPathChanged = () => {
  const { pathname } = useLocation();
  const Dispatch = useDispatch();

  React.useEffect(() => {
    Dispatch(App.$routeChanged(pathname));
  }, [pathname]);

  return null;
};

const AuthorizedRouter = () => {
  const session = useSelector((state) => state?.Auth?.session);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const [showLayout, setShowLayout] = React.useState(true);

  const $getRedirect = () => {
    let redirect = <Redirect exact from="/*" to="/" />;

    if (session?.usergroup?.id === '7ce651f7-5df9-424a-8a82-7d87ffd45107') {
      redirect = <Redirect exact from="/*" to="/spm/line" />;
    }

    // if (
    //   session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' ||
    //   session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886'
    // ) {
    //   redirect = <Redirect exact from="/*" to="/monitoring" />;
    // }

    return redirect;
  };

  React.useEffect(() => {
    if (splitLocation[1] === 'print') {
      setShowLayout(false);
    } else {
      setShowLayout(true);
    }
  }, [splitLocation]);

  return (
    <BrowserRouter>
      <Layout>
        {!showLayout ? null : <Layout.Header sticky fluid consended />}
        <Layout.Body>
          <ListenPathChanged />
          <Switch>
            {routes.map((d, i) => {
              let comp = null;

              if (
                session?.usergroup &&
                session?.usergroup?.id !==
                  '6ca4fba3-b0a2-4266-909e-2471987025f2'
              ) {
                let newRoute = [];

                let x = _.contains(d?.usergroup, session?.usergroup?.id);

                if (x || !d?.usergroup) {
                  newRoute?.push(d);
                }

                if (d?.path === '/profile/setting') {
                  comp = (
                    <Route
                      key={i}
                      exact
                      path={d.path}
                      component={d.component}
                    />
                  );
                }

                if (newRoute?.length > 0) {
                  newRoute?.forEach((r) => {
                    comp = (
                      <Route
                        key={i}
                        exact
                        path={r.path}
                        component={r.component}
                      />
                    );
                  });
                }
              } else {
                comp = (
                  <Route key={i} exact path={d.path} component={d.component} />
                );
              }

              return comp;
            })}

            {$getRedirect()}
          </Switch>
        </Layout.Body>
      </Layout>
    </BrowserRouter>
  );
};

export default AuthorizedRouter;
