/* eslint-disable jsx-a11y/anchor-is-valid */
import { Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import _ from 'underscore';

import React from 'react';
import { User } from 'services/action';
import MenuRoute from './menu.route';

const MenuConsended = ({ fluid }) => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const session = useSelector((state) => state?.Auth?.session);
  const location = useLocation();
  const [area, setArea] = React.useState(null);

  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const $show = async () => {
    const req = await Dispatch(User.$getArea(session?.user?.id));

    if (req?.message === 'success') {
      setArea(req?.data);
    }
  };

  React.useEffect(() => {
    $show();
  }, [session]);

  return (
    <div className="navbar-collapse collapse" id="navbar-menu">
      <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
        <div className={fluid ? 'container-fluid' : 'container-xl'}>
          <ul className="navbar-nav">
            {MenuRoute.map((menu, index) => {
              let link = null;

              if (menu?.isActive === 'monitoring' && session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2') {
                return null
              }

              if (!menu?.isMaster) {
                if (
                  session?.usergroup &&
                  session?.usergroup?.id !==
                    '6ca4fba3-b0a2-4266-909e-2471987025f2'
                ) {
                  let child = [];

                  menu?.usergroup?.forEach((g) => {
                    if (session?.usergroup?.id?.includes(g)) {
                      child?.push(g);
                    }
                  });

                  if (child?.length > 0) {
                    link = !menu?.isDropdown ? (
                      menu?.title === 'Laporan' && area ? null : (
                        <li key={index} className="nav-item">
                          <NavLink
                            to={menu.path}
                            className="nav-link"
                            activeClassName="active"
                            isActive={(match, location) => {
                              const splitLocation =
                                location.pathname.split('/');

                              if (splitLocation[1] === menu.isActive) {
                                return true;
                              }
                            }}
                          >
                            <div className="d-flex justify-content-center">
                              <span className="nav-link-icon d-md-none d-lg-inline-block">
                                {menu.icon}
                              </span>
                              <span className="nav-link-title">
                                {menu?.title}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      )
                    ) : (
                      <li key={index} className="nav-item">
                        <Nav>
                          <NavDropdown
                            active={splitLocation[1] === menu?.isActive}
                            menuVariant="secondary"
                            // className="no-carret"
                            title={
                              <div className="d-flex justify-content-center align-items-center m-0">
                                <span
                                  className="nav-link-icon d-inline-block m-0 pe-2"
                                  style={{
                                    width: 'auto',
                                    height: 'auto',
                                  }}
                                >
                                  {menu.icon}
                                </span>
                                {menu?.title}
                              </div>
                            }
                          >
                            {menu?.child.map((c, index) => {
                              let menu = null;

                              if (session?.usergroup) {
                                let x = _.contains(
                                  c?.usergroup,
                                  session?.usergroup?.id
                                );

                                if (x) {
                                  menu = (
                                    <NavDropdown.Item
                                      key={index}
                                      onClick={() => History.push(c?.path)}
                                      active={
                                        splitLocation[2] === c?.isActive ||
                                        (splitLocation?.length === 2 &&
                                          splitLocation[1] === c?.isActive)
                                      }
                                    >
                                      <div className="d-flex justify-content-center">
                                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                                          {c?.icon}
                                        </span>
                                        <span className="nav-link-title">
                                          {c?.title}
                                        </span>
                                      </div>
                                    </NavDropdown.Item>
                                  );
                                }
                              }
                              return menu;
                            })}
                          </NavDropdown>
                        </Nav>
                      </li>
                    );
                  }
                } else {
                  link = !menu.isDropdown ? (
                    <li key={index} className="nav-item">
                      <NavLink
                        to={menu.path}
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) => {
                          const splitLocation = location.pathname.split('/');

                          if (splitLocation[1] === menu.isActive) {
                            return true;
                          }
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            {menu.icon}
                          </span>
                          <span className="nav-link-title">{menu?.title}</span>
                        </div>
                      </NavLink>
                    </li>
                  ) : (
                    <li key={index} className="nav-item">
                      <Nav>
                        <NavDropdown
                          active={splitLocation[1] === menu?.isActive}
                          menuVariant="secondary"
                          // className="no-carret"
                          title={
                            <div className="d-flex justify-content-center align-items-center m-0">
                              <span
                                className="nav-link-icon d-inline-block m-0 pe-2"
                                style={{
                                  width: 'auto',
                                  height: 'auto',
                                }}
                              >
                                {menu.icon}
                              </span>
                              {menu?.title}
                            </div>
                          }
                        >
                          {menu?.child.map((c, index) => {
                            let menu = (
                              <NavDropdown.Item
                                key={index}
                                onClick={() => History.push(c?.path)}
                                active={
                                  splitLocation[2] === c?.isActive ||
                                  (splitLocation?.length === 2 &&
                                    splitLocation[1] === c?.isActive)
                                }
                              >
                                <div className="d-flex justify-content-center">
                                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    {c?.icon}
                                  </span>
                                  <span className="nav-link-title">
                                    {c?.title}
                                  </span>
                                </div>
                              </NavDropdown.Item>
                            );

                            return menu;
                          })}
                        </NavDropdown>
                      </Nav>
                    </li>
                  );
                }
              }

              return link;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MenuConsended;
