import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const NavigationBack = ({ to }) => {
  const history = useSelector((state) => state?.App?.routeHistory);

  const goTo = (to) => {
    return history?.from ? history?.from : to;
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={`tooltip-top`}>Kembali</Tooltip>}
    >
      <Link
        to={goTo(to)}
        className="btn btn-dark p-1"
        style={{ height: 34, width: 33 }}
      >
        <Fi.FiChevronsLeft size="17" />
      </Link>
    </OverlayTrigger>
  );
};

export default NavigationBack;
