import React from "react";

const LeftBar = ({ children }) => {
  return (
    <div
      className={`col-lg-2 navbar navbar-vertical navbar-expand-md navbar-light`}
    >
      {children}
    </div>
  );
};

export default LeftBar;
