import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { POD } from 'services/action';
import PODViewer from 'ui/components/imageViewer';

const initialState = {
  uploaded: [],
  isDelete: false,
  zoomImg: null,
};

const ActionDocument = ({ data, kpm, type, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const Session = useSelector((state) => state.Auth?.session);

  const [
    {
      isDelete,
      uploaded,
      zoomImg,
    },
    setState,
  ] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      isDelete: false,
    }));
  };

  const $onLoad = async () => {
    const req = await Dispatch(
      POD.$getKpm(kpm?.id, { type: type, bansos_id: data?.bansos_id })
    );

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        uploaded: req?.data,
      }));
    }
  };

  const $deleteImage = async () => {
    const payload = {
      deleted_by: Session?.user?.name,
    };
    const del = await Dispatch(POD.$delete(zoomImg?.id, payload));
    if (del?.message === 'success') {
      $handleClose();
      onClose();
    }
  };

  React.useEffect(() => {
    if (!data) return null;

    $onLoad();
  }, [data]);

  return (
    <>
      <div
        className="d-flex flex-column align-items-start"
        style={{ borderWidth: 1, borderColor: '#ddd' }}
      >
        <PODViewer
          items={uploaded}
          imgStyle={{ height: 60, width: 60, objectFit: 'cover' }}
          imgClass="img-hover img-responsive rounded-3"
          wrapperClass="row mt-2 w-100"
          // imgWrapperClass="col-4 p-1 justify-content-center align-items-center d-flex flex-column border border-1 "
          imgWrapperClass="col-6 p-2 border-1 border justify-content-center align-items-center d-flex flex-column"
          showAction={true}
          deleteButton={(e) => {
            return (
              <div
                className={
                  StateForm?.loading
                    ? 'btn btn-danger btn-loading btn-sm px-2 py-0 mt-2'
                    : 'btn btn-danger btn-sm px-2 py-0 mt-2'
                }
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isDelete: true,
                    zoomImg: e,
                  }))
                }
              >
                <Fi.FiTrash2 size="17" />
              </div>
            );
          }}
          onLoad={() => $onLoad()}
        />
      </div>

      {/* modal delete */}
      <Modal
        show={isDelete}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang anda hapus tidak dapat dipulihkan
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$deleteImage}
                disabled={StateForm?.loading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionDocument;
