import { $done } from 'services/activity/action';
import { $requesting, $success } from 'services/form/action';
import _ from 'underscore';
import * as ActionCreator from 'utilities/action.creator';

export const MODULE = 'Maps';
const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const qs = require('qs');

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $setOrigin = ActionCreator.Default(MODULE, 'setOrigin', (origin) =>
  $setOrigin.action({ origin })
);

export const $setDestination = ActionCreator.Default(
  MODULE,
  'setDestination',
  (destination) => $setDestination.action({ destination })
);

export const $setDestinations = ActionCreator.Default(
  MODULE,
  'setDestinations',
  (destinations) => $setDestinations.action({ destinations })
);

// google place
export const $googlePlace = ActionCreator.Async(
  MODULE,
  'googlePlace',
  (payload) => {
    return async (dispatch) => {
      dispatch($requesting.action());

      const body = {
        result_type: 'street_address',
        latlng: `${payload.latitude},${payload.longitude}`,
        key: GOOGLE_KEY,
      };

      const query = qs.stringify(body);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?${query}`;

      return await fetch(url, { method: 'GET' })
        .then((response) =>
          response?.json().then((p) => {
            let formatted_address = '';
            let shorted_address = '';
            let postal_code = '';

            if (p?.results[0]?.formatted_address || false) {
              let post = _.last(p?.results[0]?.address_components);

              if (post?.types?.includes('postal_code')) {
                postal_code = post?.long_name;
              }

              formatted_address = p?.results[0]?.formatted_address;
              const strings = formatted_address.match(/([^,]*),(.*)/);
              shorted_address = strings[1];
            }

            dispatch($success.action());

            return {
              longitude: payload.longitude,
              latitude: payload.latitude,
              address: formatted_address,
              format_address: shorted_address,
              postal_code: postal_code,
              note: ' ',
            };
          })
        )
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($googlePlace.OPERATION)));
    };
  }
);
