import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';

import PODViewer from 'ui/components/imageViewer';
import { Uploader } from 'ui/components';
import { POD, Table } from 'services/action';

const initialState = {
  showDocument: false,
  isDelete: false,
  zoomImg: null,
  images: []
};

const Action = ({ data }) => {
  const Dispatch = useDispatch();
  const StateTable = useSelector((state) => state?.Table?.data['report_regency']);
  const Session = useSelector((state) => state?.Auth?.session);
  const Project = useSelector((state) => state.Auth?.project);
  const StateForm = useSelector((state) => state.Form);

  const [{ showDocument, isDelete, zoomImg, images }, setState] = React.useState(initialState);

  const $closeImage = () => {
    setState((prevState) => ({ ...prevState, showDocument: false }));
    Dispatch(Table.$reload('report_regency', StateTable));
  };

  const $closeDelete = () => {
    setState((prevState) => ({ ...prevState, isDelete: false, zoomImg: null }));
  }

  const $postImage = async (e) => {
    let payload = {
      document: {
        regency_id: data?.regency_id,
        bansos_id: data?.bansos_id,
        type: 'document_pendukung',
      },
      images: e,
    };

    const req = await Dispatch(POD.$upload(payload));

    if (req?.message === 'success') {
      $onLoad();
    }
  };

  const $deleteImage = async () => {
    const payload = {
      deleted_by: Session?.user?.name,
    };
    const del = await Dispatch(POD.$delete(zoomImg?.id, payload));
    if (del?.message === 'success') {
      $closeDelete();
      $onLoad();
    }
  };

  const $onLoad = async () => {
    const result = await Dispatch(POD.$getRegency(Project?.id, data?.regency_id, {type: 'document_pendukung'}));
    if (result?.message === 'success') {
      setState((prevState) => ({ ...prevState, images: result?.data, showDocument: true }));
    }
  }

  const ugd = Session?.usergroup?.id !== '4df4c118-ed27-40a4-865e-270930637e82' && Session?.usergroup?.id !== '6c5128cc-e5e8-4dbe-8204-cd532a7e229c' && Session?.usergroup?.id !== 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' && Session?.usergroup?.id !== '6ca4fba3-b0a2-4266-909e-2471987025f2'

  if (Session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' || Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' || Session?.usergroup?.id === '302d772c-77db-47ea-ab5a-83b6be4d3787') return null

  return (
    <>
       <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-top`}>Dokumen Pendukung</Tooltip>}
        >
          <button
            className="btn btn-sm btn-default rounded-2 col-12"
            onClick={() =>
              $onLoad()
            }
          >
            <Fi.FiInfo size={15} className="m-1" />
          </button>
        </OverlayTrigger>

        {/* modal info */}
        <Modal
            show={showDocument}
            onHide={$closeImage}
            backdrop="static"
            centered
            className="text-center"
            size="fullscreen"
        >
            <Modal.Header closeButton>
                <Modal.Title>Dokumen Pendukung</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && Session?.usergroup?.id !== '302d772c-77db-47ea-ab5a-83b6be4d3787') && !ugd && (
                    <Uploader
                    medium
                    setData={(e) => $postImage(e)}
                    type='document_pendukung'
                    regency_id={data?.regency_id}
                    bansos_id={data?.bansos_id}
                    />
                )}

                <PODViewer
                    items={images}
                    imgStyle={{ height: 60, width: 60, objectFit: 'cover' }}
                    imgClass="img-hover img-responsive rounded-3"
                    wrapperClass="row mt-2 w-100"
                    // imgWrapperClass="col-4 p-1 justify-content-center align-items-center d-flex flex-column border border-1 "
                    imgWrapperClass="col-6 p-2 border-1 border justify-content-center align-items-center d-flex flex-column"
                    showAction={(Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && Session?.usergroup?.id !== '302d772c-77db-47ea-ab5a-83b6be4d3787') && !ugd}
                    deleteButton={(e) => {
                        return (
                            <div
                                className={
                                    StateForm?.loading
                                    ? 'btn btn-danger btn-loading btn-sm px-2 py-0 mt-2'
                                    : 'btn btn-danger btn-sm px-2 py-0 mt-2'
                                }
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        isDelete: true,
                                        zoomImg: e,
                                    }))
                                }
                            >
                                <Fi.FiTrash2 size="17" />
                            </div>
                        );
                    }}
                />
            </Modal.Body>
        </Modal>

        {/* modal delete */}
        <Modal
            show={isDelete}
            onHide={$closeDelete}
            backdrop="static"
            centered
            className="text-center"
            size="small"
        >
            <Modal.Body>
                <div className="btn-close" onClick={$closeDelete} />
                <div className="modal-status bg-danger" />
                <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
                <h3>Anda yakin?</h3>
                <p className="text-danger fw-semibold">
                    Data yang anda hapus tidak dapat dipulihkan
                </p>
            </Modal.Body>
            <Modal.Footer>
                <div className="row w-100">
                    <div className="col">
                        <Button variant="white" className="w-100" onClick={$closeDelete}>
                            Batal
                        </Button>
                    </div>
                    <div className="col">
                        <Button
                            variant="danger"
                            className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                            onClick={$deleteImage}
                            disabled={StateForm?.loading}
                        >
                            Hapus
                        </Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    </>
  );
};

export default Action;
