import React from 'react';
import { renderToString } from 'react-dom/server';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';

import { Delivery } from 'services/action';

import { Print } from 'ui/components';
import { currencyFormat, formatDate } from 'utilities/common';

import bapang from 'ui/assets/badanpangan.png';
import bulog from 'ui/assets/logo_bulog.png';
import logoBlack from 'ui/assets/logo_jpl.png';
import _ from 'underscore';

const POD_URL = `${process.env.REACT_APP_POD_SYSTEM_URL}`;

const PrintBast = ({ match }) => {
  const Dispatch = useDispatch();
  const Project = useSelector((state) => state?.Auth?.project);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Delivery.Show']
  );

  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [pages, setPage] = React.useState(null);
  const [firstPage] = React.useState(10);
  const [limit] = React.useState(22);

  const $onLoad = async (id) => {
    const detail = await Dispatch(Delivery.$show(id));
    if (detail?.message === 'success') {
      setData(detail?.data);

      if (detail?.data?.lines) {
        let pages = _.chunk(detail?.data?.lines, firstPage);
        if (detail?.data?.lines?.length > firstPage) {
          let first = pages[0];
          let last = _.rest(detail?.data?.lines, first?.length);
          pages = _.chunk(last, limit);
          pages.unshift(first);
        } else {
          setPage(pages);
        }

        setPage(pages);
        setLoaded(true);
      } else {
        setLoaded(true);
      }
    }
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, [match]);

  if (StateLoading || data === null || !loaded) return null;

  const alokasi = {
    'aa93be9f-5841-4fb2-93f4-1e2ba8dba54a': 'Maret',
    '2acec690-a2a2-4257-b44a-9fb7b4e37fcf': 'April',
    'a7330f07-1505-4e0a-bf9f-4af347bc8389': 'Mei',
  };

  return (
    <div>
      {!data || data === null ? null : (
        <Print
          title={`BAST - ${data?.code}`}
          size="A4"
          content={renderToString(
            <>
              {pages?.map((page, ip) => (
                <section
                  key={ip}
                  className=" sheet page-break"
                  style={{ padding: '50px', position: 'relative' }}
                >
                  {ip === 0 && (
                    <>
                      <div style={{ paddingBottom: 50 }}>
                        <h2 className="mb-0 text-center uppercase ">
                          laporan penyaluran dan penyerahan
                        </h2>
                        <h2 className="mb-0 text-center uppercase ">
                          beras bantuan pangan - CBP 2023
                        </h2>
                        <h2 className="mb-0 text-center uppercase ">
                          ALOKASI: {alokasi[data?.bansos_id]} 2023
                        </h2>
                      </div>

                      <p
                        className="mb-0"
                        style={{
                          fontStyle: 'italic',
                          fontSize: 9,
                          textAlign: 'end',
                        }}
                      >
                        dicetak: {data?.warehouse?.name}{' '}
                        {formatDate(data?.dispatched_at, 'DD-MM-YYYY')}{' '}
                      </p>

                      <div className="d-flex">
                        <div style={{ width: '50%', paddingRight: 5 }}>
                          <table className="body border">
                            <tr className="border">
                              <td className="border" style={{ width: '30%' }}>
                                <p className="mb-0 text-left">DO</p>
                              </td>
                              <td className="border" style={{ width: '70%' }}>
                                <p className="bolder mb-0 text-left">
                                  {data?.code || '-'}
                                </p>
                              </td>
                            </tr>
                            <tr className="">
                              <td className="border" style={{ width: '30%' }}>
                                <p className="mb-0 text-left">DOC-OUT</p>
                              </td>
                              <td className="border" style={{ width: '70%' }}>
                                <p className="bolder mb-0 text-left">
                                  {data?.doc_outs?.join(',\n') || '-'}
                                </p>
                              </td>
                            </tr>
                            <tr className="">
                              <td className="border" style={{ width: '30%' }}>
                                <p className="mb-0 text-left">GDG</p>
                              </td>
                              <td className="border" style={{ width: '70%' }}>
                                <p className="bolder mb-0 text-left">
                                  {data?.warehouse?.code} -{' '}
                                  {data?.warehouse?.name}
                                </p>
                              </td>
                            </tr>

                            <tr className="border">
                              <td className="border" style={{ width: '30%' }}>
                                <p className="mb-0 text-left">Total PBP / KG</p>
                              </td>
                              <td className="border" style={{ width: '70%' }}>
                                <p className="bolder mb-0 text-left">
                                  {currencyFormat(data?.total_kpm)} PBP /{' '}
                                  {currencyFormat(
                                    data?.total_kpm * Project?.weight_packages
                                  )}{' '}
                                  KG
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div style={{ width: '50%' }}>
                          <table className="body border">
                            <tr className="border">
                              <td className="border" style={{ width: '45%' }}>
                                <p className="mb-0 text-left">No Truck</p>
                              </td>
                              <td className="border" style={{ width: '55%' }}>
                                <p className="bolder mb-0 text-left">
                                  {data?.driver?.vehicle_number}
                                </p>
                              </td>
                            </tr>
                            <tr className="border">
                              <td className="border" style={{ width: '45%' }}>
                                <p className="mb-0 text-left">Driver</p>
                              </td>
                              <td className="border" style={{ width: '55%' }}>
                                <p className="bolder mb-0 text-left">
                                  {data?.driver?.name}
                                </p>
                              </td>
                            </tr>
                            <tr className="border">
                              <td className="border" style={{ width: '45%' }}>
                                <p className="mb-0 text-left">No. HP</p>
                              </td>
                              <td className="border" style={{ width: '55%' }}>
                                <p className="bolder mb-0 text-left">
                                  {data?.driver?.phone}
                                </p>
                              </td>
                            </tr>
                            <tr className="border">
                              <td className="border" style={{ width: '45%' }}>
                                <p className="mb-0 text-left">
                                  Total Pengiriman
                                </p>
                              </td>
                              <td className="border" style={{ width: '55%' }}>
                                <p className="bolder mb-0 text-left">
                                  {currencyFormat(data?.document_count?.total)}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </>
                  )}

                  <table className="body" style={{ marginTop: 50 }}>
                    <tr className="border">
                      <td className="border" style={{ width: '5%' }}>
                        <p
                          className="mb-0 bolder"
                          style={{
                            fontSize: 10,
                            textAlign: 'center',
                          }}
                        >
                          No
                        </p>
                      </td>
                      <td className="border" style={{ width: '50%' }}>
                        <p
                          className="mb-0 bolder"
                          style={{
                            fontSize: 10,
                            textAlign: 'start',
                          }}
                        >
                          Tujuan
                        </p>
                      </td>
                      <td className="border" style={{ width: '20%' }}>
                        <p
                          className="mb-0 bolder"
                          style={{
                            fontSize: 10,
                            textAlign: 'end',
                          }}
                        >
                          Total PBP
                        </p>
                      </td>
                      <td className="border" style={{ width: '25%' }}>
                        <p
                          className="mb-0 bolder"
                          style={{
                            fontSize: 10,
                            textAlign: 'end',
                          }}
                        >
                          Total Berat
                        </p>
                      </td>
                    </tr>
                    {page?.map((l, ix) => (
                      <tr className="border" key={ix}>
                        <td className="border" style={{ width: '5%' }}>
                          <p
                            className="mb-0 "
                            style={{
                              fontSize: 10,
                              textAlign: 'center',
                            }}
                          >
                            {ix + 1}
                          </p>
                        </td>
                        <td className="border" style={{ width: '50%' }}>
                          <p
                            className="mb-0 "
                            style={{
                              fontSize: 10,
                              textAlign: 'start',
                            }}
                          >
                            {l?.area?.village?.name},{' '}
                            {l?.area?.village?.district?.name},{' '}
                            {l?.area?.village?.district?.regency?.name},{' '}
                            {
                              l?.area?.village?.district?.regency?.kanwil
                                ?.name
                            }
                          </p>
                        </td>
                        <td className="border" style={{ width: '20%' }}>
                          <p
                            className="mb-0"
                            style={{
                              fontSize: 10,
                              textAlign: 'end',
                            }}
                          >
                            {currencyFormat(l?.total_package)}
                          </p>
                        </td>
                        <td className="border" style={{ width: '25%' }}>
                          <p
                            className="mb-0"
                            style={{
                              fontSize: 10,
                              textAlign: 'end',
                            }}
                          >
                            {currencyFormat(
                              l?.total_package * Project?.weight_packages
                            )}{' '}
                            KG
                          </p>
                        </td>
                      </tr>
                    ))}

                    <tr className="border">
                      <td colSpan={2} className="border">
                        <p
                          className="bolder mb-0 uppercase "
                          style={{ textAlign: 'end' }}
                        >
                          total
                        </p>
                      </td>
                      <td className="border">
                        <p className="bolder mb-0" style={{ textAlign: 'end' }}>
                          {currencyFormat(data?.total_kpm)} PBP
                        </p>
                      </td>
                      <td className="border ">
                        <p className="bolder mb-0" style={{ textAlign: 'end' }}>
                          {currencyFormat(
                            data?.total_kpm * Project?.weight_packages
                          )}{' '}
                          KG
                        </p>
                      </td>
                    </tr>
                  </table>

                  {ip + 1 === pages?.length && (
                    <>
                      <table className="body" style={{ marginTop: 50 }}>
                        <tr className="">
                          <td className="" colSpan={3}>
                            <p
                              className="mb-0"
                              style={{
                                fontSize: 10,
                                fontStyle: 'italic',
                                textAlign: 'end',
                              }}
                            >
                              *kolom Tanda Tangan
                            </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td
                            className="border"
                            style={{ height: 80, padding: 10, width: '33%' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                textAlign: 'center',
                              }}
                            >
                              <p
                                className="bold"
                                style={{
                                  marginBottom: 150,
                                  fontSize: 10,
                                }}
                              >
                                ADMIN GUDANG BULOG*
                              </p>
                            </div>
                          </td>
                          <td className="border" style={{ width: '33%' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                textAlign: 'center',
                              }}
                            >
                              <p
                                className="bold"
                                style={{
                                  marginBottom: 150,
                                  fontSize: 10,
                                }}
                              >
                                ADMIN GUDANG TRANSPORTER*
                              </p>
                            </div>
                          </td>
                          <td className="border" style={{ width: '33%' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                textAlign: 'center',
                              }}
                            >
                              <p
                                className="bold"
                                style={{
                                  marginBottom: 150,
                                  fontSize: 10,
                                }}
                              >
                                DRIVER*
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border center">
                            <p
                              className="mb-0 bold"
                              style={{ fontSize: 10 }}
                            ></p>
                          </td>
                          <td className="border center">
                            <p className="mb-0 bold" style={{ fontSize: 10 }}>
                              {data?.warehouse?.name}
                            </p>
                          </td>
                          <td className="border center">
                            <p className="mb-0 bold" style={{ fontSize: 10 }}>
                              {data?.driver?.name}
                              <br />
                              {data?.driver?.phone}
                            </p>
                          </td>
                        </tr>
                      </table>

                      <table className="body">
                        <tr className="">
                          <td className="left" style={{ width: '33%' }}>
                            <img
                              src={bapang}
                              alt=""
                              style={{ maxHeight: 70, maxWidth: '100%' }}
                            />
                          </td>
                          <td className="center" style={{ width: '33%' }}>
                            <img
                              src={bulog}
                              alt=""
                              style={{ maxHeight: 50, maxWidth: '100%' }}
                            />
                          </td>
                          <td className="right" style={{ width: '33%' }}>
                            <img
                              src={logoBlack}
                              alt=""
                              style={{ maxHeight: 50, maxWidth: '100%' }}
                            />
                          </td>
                        </tr>
                      </table>
                    </>
                  )}
                </section>
              ))}

              {data?.lines?.map((line, i) => (
                <>
                  <section
                    className=" sheet page-break"
                    style={{ padding: '50px', position: 'relative' }}
                  >
                    <div
                      className="d-flex flex-row justify-content-between"
                      style={{ paddingBottom: 50 }}
                    >
                      <div className="" style={{ width: '100%' }}>
                        <div
                          className="d-flex align-items-center "
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <div
                            className="d-flex flex-column  text-center "
                            style={{
                              width: '20%',
                              justifyContent: 'start',
                              alignItems: 'start',
                            }}
                          >
                            <QRCode
                              size={80}
                              // value={`${POD_URL}/pod/${line?.id}`}
                              value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                            />
                          </div>
                          <div style={{ alignSelf: 'initial', width: '60%' }}>
                            <h2 className="mb-0 text-center uppercase ">
                              laporan penyaluran dan penyerahan
                            </h2>
                            <h2 className="mb-0 text-center uppercase ">
                              beras bantuan pangan - CBP 2023
                            </h2>
                            <h2 className="mb-0 text-center uppercase ">
                              ALOKASI: {alokasi[data?.bansos_id]} 2023
                            </h2>
                          </div>
                          <div
                            className="d-flex flex-column  text-center "
                            style={{
                              width: '20%',
                              justifyContent: 'end',
                              alignItems: 'end',
                            }}
                          >
                            <QRCode
                              size={80}
                              // value={`${POD_URL}/pod/${line?.id}`}
                              value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <p
                      className="mb-0"
                      style={{
                        fontStyle: 'italic',
                        fontSize: 9,
                        textAlign: 'end',
                      }}
                    >
                      dicetak: {data?.warehouse?.name}{' '}
                      {formatDate(data?.dispatched_at, 'DD-MM-YYYY')}{' '}
                    </p>

                    <div className="d-flex">
                      <div style={{ width: '50%', paddingRight: 5 }}>
                        <table className="body border">
                          <tr className="border">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Provinsi</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {
                                  line?.area?.village?.district?.regency
                                    ?.kanwil?.name
                                }
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kab. / Kota</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.district?.regency?.name}
                              </p>
                            </td>
                          </tr>

                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kecamatan</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.district?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kelurahan</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">GDG</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.warehouse?.code} -{' '}
                                {data?.warehouse?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">DOC-OUT</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.doc_outs?.join(',\n') || '-'}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div style={{ width: '50%' }}>
                        <table className="body border">
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">BAST GUDANG/ WO</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.code}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Total Paket</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {currencyFormat(line?.total_package)} Paket
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Total Berat</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {currencyFormat(
                                  line?.total_package * Project?.weight_packages
                                )}{' '}
                                Kg
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">No Truck</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.vehicle_number}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Driver</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">No. HP</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.phone}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <table className="body" style={{ marginTop: 50 }}>
                      <tr className="">
                        <td className="" colSpan={3}>
                          <p
                            className="mb-0"
                            style={{
                              fontSize: 10,
                              fontStyle: 'italic',
                              textAlign: 'end',
                            }}
                          >
                            *kolom Tanda Tangan
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td
                          className="border"
                          style={{ height: 80, padding: 10, width: '33%' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              ADMIN GUDANG BULOG*
                            </p>
                          </div>
                        </td>
                        <td className="border" style={{ width: '33%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              ADMIN GUDANG TRANSPORTER*
                            </p>
                          </div>
                        </td>
                        <td className="border" style={{ width: '33%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              DRIVER*
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}></p>
                        </td>
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            {data?.warehouse?.name}
                          </p>
                        </td>
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            {data?.driver?.name}
                            <br />
                            {data?.driver?.phone}
                          </p>
                        </td>
                      </tr>
                    </table>

                    <table className="body" style={{ marginTop: 50 }}>
                      <tr className="border">
                        <td className="border">
                          <h4>KETERANGAN : </h4>
                          <ul>
                            <li>
                              <p className="mb-0">
                                Sudah diterima paket sebanyak{' '}
                                {currencyFormat(line?.total_package)} Paket
                                dengan berat{' '}
                                {currencyFormat(
                                  line?.total_package * Project?.weight_packages
                                )}{' '}
                                KG
                              </p>
                            </li>
                            <li className="mb-0">
                              <p>
                                {' '}
                                Sudah menerima dokumen Berita Acara Serah Terima
                                (BAST)
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </table>

                    <table className="body">
                      <tr className="">
                        <td className="left" style={{ width: '33%' }}>
                          <img
                            src={bapang}
                            alt=""
                            style={{ maxHeight: 70, maxWidth: '100%' }}
                          />
                        </td>
                        <td className="center" style={{ width: '33%' }}>
                          <img
                            src={bulog}
                            alt=""
                            style={{ maxHeight: 50, maxWidth: '100%' }}
                          />
                        </td>
                        <td className="right" style={{ width: '33%' }}>
                          <img
                            src={logoBlack}
                            alt=""
                            style={{ maxHeight: 50, maxWidth: '100%' }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="center">
                          <QRCode
                            size={60}
                            // value={`${POD_URL}/pod/${line?.id}`}
                            value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                          />
                        </td>
                        <td className="right"></td>
                      </tr>
                    </table>
                  </section>

                  <section
                    className=" sheet page-break"
                    style={{ padding: '50px', position: 'relative' }}
                  >
                    <div
                      className="d-flex flex-row justify-content-between"
                      style={{ paddingBottom: 50 }}
                    >
                      <div className="" style={{ width: '100%' }}>
                        <div
                          className="d-flex align-items-center "
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <div
                            className="d-flex flex-column  text-center "
                            style={{
                              width: '20%',
                              justifyContent: 'start',
                              alignItems: 'start',
                            }}
                          >
                            <QRCode
                              size={80}
                              // value={`${POD_URL}/pod/${line?.id}`}
                              value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                            />
                          </div>
                          <div style={{ alignSelf: 'initial', width: '60%' }}>
                            <h2 className="mb-0 text-center uppercase ">
                              laporan penyaluran dan penyerahan
                            </h2>
                            <h2 className="mb-0 text-center uppercase ">
                              beras bantuan pangan - CBP 2023
                            </h2>
                            <h2 className="mb-0 text-center uppercase ">
                              ALOKASI: {alokasi[data?.bansos_id]} 2023
                            </h2>
                          </div>
                          <div
                            className="d-flex flex-column  text-center "
                            style={{
                              width: '20%',
                              justifyContent: 'end',
                              alignItems: 'end',
                            }}
                          >
                            <QRCode
                              size={80}
                              // value={`${POD_URL}/pod/${line?.id}`}
                              value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <p
                      className="mb-0"
                      style={{
                        fontStyle: 'italic',
                        fontSize: 9,
                        textAlign: 'end',
                      }}
                    >
                      dicetak: {data?.warehouse?.name}{' '}
                      {formatDate(data?.dispatched_at, 'DD-MM-YYYY')}{' '}
                    </p>

                    <div className="d-flex">
                      <div style={{ width: '50%', paddingRight: 5 }}>
                        <table className="body border">
                          <tr className="border">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Provinsi</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {
                                  line?.area?.village?.district?.regency
                                    ?.kanwil?.name
                                }
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kab. / Kota</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.district?.regency?.name}
                              </p>
                            </td>
                          </tr>

                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kecamatan</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.district?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kelurahan</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">GDG</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.warehouse?.code} -{' '}
                                {data?.warehouse?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">DOC-OUT</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.doc_outs?.join(',\n') || '-'}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div style={{ width: '50%' }}>
                        <table className="body border">
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">
                                BAST PENGIRIMAN/ WO
                              </p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.code}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Total Paket</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {currencyFormat(line?.total_package)} Paket
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Total Berat</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {currencyFormat(
                                  line?.total_package * Project?.weight_packages
                                )}{' '}
                                Kg
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">No Truck</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.vehicle_number}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Driver</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">No. HP</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.phone}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <table className="body" style={{ marginTop: 50 }}>
                      <tr className="">
                        <td className="" colSpan={3}>
                          <p
                            className="mb-0"
                            style={{
                              fontSize: 10,
                              fontStyle: 'italic',
                              textAlign: 'end',
                            }}
                          >
                            *kolom Tanda Tangan
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td
                          className="border"
                          style={{ height: 80, padding: 10, width: '33%' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              DRIVER*
                            </p>
                          </div>
                        </td>
                        <td className="border" style={{ width: '33%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              ARKO/Korlap*
                            </p>
                          </div>
                        </td>
                        <td className="border" style={{ width: '33%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              PENDAMPING PBP*
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            {data?.driver?.name}
                            <br />
                            {data?.driver?.phone}
                          </p>
                        </td>
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            (Nama Jelas & TTD)
                          </p>
                        </td>
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            (Nama Jelas & TTD)
                          </p>
                        </td>
                      </tr>
                    </table>

                    <table className="body" style={{ marginTop: 50 }}>
                      <tr className="border">
                        <td className="border">
                          <h4>KETERANGAN : </h4>
                          <ul>
                            <li>
                              <p className="mb-0">
                                Sudah diterima paket sebanyak{' '}
                                {currencyFormat(line?.total_package)} Paket
                                dengan berat{' '}
                                {currencyFormat(
                                  line?.total_package * Project?.weight_packages
                                )}{' '}
                                KG
                              </p>
                            </li>
                            <li className="mb-0">
                              <p>
                                {' '}
                                Sudah menerima dokumen Berita Acara Serah Terima
                                (BAST)
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </table>

                    <table className="body">
                      <tr className="">
                        <td className="left" style={{ width: '33%' }}>
                          <img
                            src={bapang}
                            alt=""
                            style={{ maxHeight: 70, maxWidth: '100%' }}
                          />
                        </td>
                        <td className="center" style={{ width: '33%' }}>
                          <img
                            src={bulog}
                            alt=""
                            style={{ maxHeight: 50, maxWidth: '100%' }}
                          />
                        </td>
                        <td className="right" style={{ width: '33%' }}>
                          <img
                            src={logoBlack}
                            alt=""
                            style={{ maxHeight: 50, maxWidth: '100%' }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="center">
                          <QRCode
                            size={60}
                            // value={`${POD_URL}/pod/${line?.id}`}
                            value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                          />
                        </td>
                        <td className="right"></td>
                      </tr>
                    </table>
                  </section>

                  <section
                    className=" sheet page-break"
                    style={{ padding: '50px', position: 'relative' }}
                  >
                    <div
                      className="d-flex flex-row justify-content-between"
                      style={{ paddingBottom: 50 }}
                    >
                      <div className="" style={{ width: '100%' }}>
                        <div
                          className="d-flex align-items-center "
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <div
                            className="d-flex flex-column  text-center "
                            style={{
                              width: '20%',
                              justifyContent: 'start',
                              alignItems: 'start',
                            }}
                          >
                            <QRCode
                              size={80}
                              // value={`${POD_URL}/pod/${line?.id}`}
                              value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                            />
                          </div>
                          <div style={{ alignSelf: 'initial', width: '60%' }}>
                            <h2 className="mb-0 text-center uppercase ">
                              laporan penyaluran dan penyerahan
                            </h2>
                            <h2 className="mb-0 text-center uppercase ">
                              beras bantuan pangan - CBP 2023
                            </h2>
                            <h2 className="mb-0 text-center uppercase ">
                              ALOKASI: {alokasi[data?.bansos_id]} 2023
                            </h2>
                          </div>
                          <div
                            className="d-flex flex-column  text-center "
                            style={{
                              width: '20%',
                              justifyContent: 'end',
                              alignItems: 'end',
                            }}
                          >
                            <QRCode
                              size={80}
                              // value={`${POD_URL}/pod/${line?.id}`}
                              value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <p
                      className="mb-0"
                      style={{
                        fontStyle: 'italic',
                        fontSize: 9,
                        textAlign: 'end',
                      }}
                    >
                      dicetak: {data?.warehouse?.name}{' '}
                      {formatDate(data?.dispatched_at, 'DD-MM-YYYY')}{' '}
                    </p>

                    <div className="d-flex">
                      <div style={{ width: '50%', paddingRight: 5 }}>
                        <table className="body border">
                          <tr className="border">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Provinsi</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {
                                  line?.area?.village?.district?.regency
                                    ?.kanwil?.name
                                }
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kab. / Kota</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.district?.regency?.name}
                              </p>
                            </td>
                          </tr>

                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kecamatan</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.district?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">Kelurahan</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {line?.area?.village?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">GDG</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.warehouse?.code} -{' '}
                                {data?.warehouse?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border" style={{ width: '30%' }}>
                              <p className="mb-0 text-left">DOC-OUT</p>
                            </td>
                            <td className="border" style={{ width: '70%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.doc_outs?.join(',\n') || '-'}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div style={{ width: '50%' }}>
                        <table className="body border">
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">
                                BAST SURAT JALAN/ WO
                              </p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.code}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Total Paket</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {currencyFormat(line?.total_package)} Paket
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Total Berat</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {currencyFormat(
                                  line?.total_package * Project?.weight_packages
                                )}{' '}
                                Kg
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">No Truck</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.vehicle_number}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">Driver</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.name}
                              </p>
                            </td>
                          </tr>
                          <tr className="border">
                            <td className="border" style={{ width: '45%' }}>
                              <p className="mb-0 text-left">No. HP</p>
                            </td>
                            <td className="border" style={{ width: '55%' }}>
                              <p className="bolder mb-0 text-left">
                                {data?.driver?.phone}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <table className="body" style={{ marginTop: 50 }}>
                      <tr className="">
                        <td className="" colSpan={3}>
                          <p
                            className="mb-0"
                            style={{
                              fontSize: 10,
                              fontStyle: 'italic',
                              textAlign: 'end',
                            }}
                          >
                            *kolom Tanda Tangan
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td
                          className="border"
                          style={{ height: 80, padding: 10, width: '33%' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              DRIVER*
                            </p>
                          </div>
                        </td>
                        <td className="border" style={{ width: '33%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              ARKO/Korlap*
                            </p>
                          </div>
                        </td>
                        <td className="border" style={{ width: '33%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              className="bold"
                              style={{
                                marginBottom: 150,
                                fontSize: 10,
                              }}
                            >
                              PENDAMPING PBP*
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            {data?.driver?.name}
                            <br />
                            {data?.driver?.phone}
                          </p>
                        </td>
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            (Nama Jelas & TTD)
                          </p>
                        </td>
                        <td className="border center">
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            (Nama Jelas & TTD)
                          </p>
                        </td>
                      </tr>
                    </table>

                    <table className="body" style={{ marginTop: 50 }}>
                      <tr className="border">
                        <td className="border">
                          <h4>KETERANGAN : </h4>
                          <ul>
                            <li>
                              <p className="mb-0">
                                Sudah diterima paket sebanyak{' '}
                                {currencyFormat(line?.total_package)} Paket
                                dengan berat{' '}
                                {currencyFormat(
                                  line?.total_package * Project?.weight_packages
                                )}{' '}
                                KG
                              </p>
                            </li>
                            <li className="mb-0">
                              <p>
                                {' '}
                                Sudah menerima dokumen Berita Acara Serah Terima
                                (BAST)
                              </p>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </table>

                    <table className="body">
                      <tr className="">
                        <td className="left" style={{ width: '33%' }}>
                          <img
                            src={bapang}
                            alt=""
                            style={{ maxHeight: 70, maxWidth: '100%' }}
                          />
                        </td>
                        <td className="center" style={{ width: '33%' }}>
                          <img
                            src={bulog}
                            alt=""
                            style={{ maxHeight: 50, maxWidth: '100%' }}
                          />
                        </td>
                        <td className="right" style={{ width: '33%' }}>
                          <img
                            src={logoBlack}
                            alt=""
                            style={{ maxHeight: 50, maxWidth: '100%' }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="center">
                          <QRCode
                            size={60}
                            // value={`${POD_URL}/pod/${line?.id}`}
                            value={`${POD_URL}/pod?type=receive&line_id=${line?.id}&delivery_id=${line?.delivery_id}&bansos_id=${data?.bansos_id}&village_id=${line?.area?.village_id}`}
                          />
                        </td>
                        <td className="right"></td>
                      </tr>
                    </table>
                  </section>
                </>
              ))}
            </>
          )}
        />
      )}
    </div>
  );
};

export default PrintBast;
