import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Skeletons } from 'ui/components/skeleton';
import { Report } from 'services/action';

import RttCarousel from './rtt.carousel';

const initialState = {
  datas: null,
};

const CardRtt = ({ isLoading }) => {
  const Dispatch = useDispatch();
  const Project = useSelector((state) => state?.Auth?.project);

  const [{ datas }, setState] = React.useState(initialState);

  const $onLoad = async () => {
    const payload = {
      bansos_id: Project?.id,
    };
    const result = await Dispatch(Report.$reconKanwil(payload));
    if (result?.message === 'success') {
      setState((prevState) => ({ ...prevState, datas: result?.data }));
    }
  };

  React.useEffect(() => {
    $onLoad();
  }, [Project]);

  return isLoading || !datas ? (
    <div className="card p-3 rounded-2 bg-white">
      <Skeletons height="400px" />
    </div>
  ) : (
    <div className="card p-3 rounded-2 bg-white">
      <h3 className="card-title dx-card-title border-bottom pb-2">
        RTT Berdasarkan Kanwil
      </h3>
      <div>
        <RttCarousel data={datas} />
      </div>
    </div>
  );
};

export default CardRtt;
