import UserScreen from './index';

const routes = [
  {
    path: '/master/user',
    component: UserScreen,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '302d772c-77db-47ea-ab5a-83b6be4d3787',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
    ],
  },
];

export default routes;
