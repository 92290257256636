import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Auth, Bansos } from 'services/action';
import _ from 'underscore';
import MenuRoute from './menu.route';

const MenuMaster = () => {
  const Dispatch = useDispatch();
  const History = useHistory();
  const location = useLocation();
  const session = useSelector((state) => state?.Auth?.session);
  const project = useSelector((state) => state?.Auth?.project);
  const [data, setData] = React.useState(null);

  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const $getProject = async () => {
    const req = await Dispatch(Bansos.$get());

    if (req?.message === 'success' && req?.data) {
      if (!project) {
        const p = _.first(req?.data);
        Dispatch(Auth.$project(p));
      }

      setData(req?.data);
    }
  };

  const $handleProjectChange = async (e) => {
    Dispatch(Auth.$project(e, true));

    setTimeout(function(){
      window.location.reload();
    }, 1500);
  };

  React.useEffect(() => {
    $getProject();
  }, []);

  return (
    <div className="nav-item d-none d-md-block">
      <ul className="navbar-nav">
        <li className="nav-item me-2">
          <Nav>
            <NavDropdown
              menuVariant="secondary"
              className="bg-white m-0 p-0 rounded-3 carret-dark"
              style={{
                minWidth: 150,
              }}
              title={
                <div
                  className="text-dark text-start text-uppercase fw-bold"
                  style={{ fontSize: 13, marginBottom: 0 }}
                >
                  {project?.name}
                </div>
              }
            >
              {data?.map((d, i) => (
                <NavDropdown.Item
                  key={i}
                  onClick={() => $handleProjectChange(d)}
                >
                  {d?.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </li>
        {session?.usergroup?.id !== '7ce651f7-5df9-424a-8a82-7d87ffd45107' &&
          session?.usergroup?.id !== '9cd330e3-e5d9-47d3-8faa-375139f4daa3' &&
          session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' &&
          session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' &&
          session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' &&
          MenuRoute.map((menu, index) => {
            let link = null;

            if (menu?.isMaster) {
              link = (
                <li key={index} className="nav-item">
                  <Nav>
                    <NavDropdown
                      active={splitLocation[1] === menu?.isActive}
                      menuVariant="secondary"
                      className="no-carret"
                      title={
                        <div className="d-flex justify-content-center align-items-center m-0">
                          <span
                            className="nav-link-icon d-inline-block m-0"
                            style={{
                              width: 'auto',
                              height: 'auto',
                            }}
                          >
                            {menu.icon}
                          </span>
                        </div>
                      }
                    >
                      {menu?.child.map((c, index) => {
                        if (
                          session?.usergroup &&
                          session?.usergroup?.id !==
                            '6ca4fba3-b0a2-4266-909e-2471987025f2'
                        ) {
                          let x = _.contains(
                            c?.usergroup,
                            session?.usergroup?.id
                          );

                          if (x) {
                            return (
                              <NavDropdown.Item
                                key={index}
                                onClick={() => History.push(c?.path)}
                                active={splitLocation[1] === c?.isActive}
                              >
                                {c?.title}
                              </NavDropdown.Item>
                            );
                          }
                        } else {
                          return (
                            <NavDropdown.Item
                              key={index}
                              onClick={() => History.push(c?.path)}
                              active={splitLocation[1] === c?.isActive}
                            >
                              {c?.title}
                            </NavDropdown.Item>
                          );
                        }
                        return null;
                      })}
                    </NavDropdown>
                  </Nav>
                </li>
              );
            }

            return link;
          })}
      </ul>
    </div>
  );
};

export default MenuMaster;
