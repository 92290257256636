import * as Pin from 'react-icons/ri';
import * as Fi from 'react-icons/fi';
import config from 'services/table/const';
import { currencyFormat, formatDate } from 'utilities/common';

let table = { ...config };
table.url = '/delivery/line';
table.columns = {
  dispatched_at: {
    title: 'Tanggal Pengiriman',
    sortable: false,
    width: 80,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {formatDate(row?.delivery?.dispatched_at, 'DD/MM/YYYY')}
          </div>
        </div>
      );
    },
  },

  alokasi: {
    title: 'Bulan Alokasi',
    sortable: false,
    width: 60,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold">
            {row?.delivery?.bansos_id === 'aa93be9f-5841-4fb2-93f4-1e2ba8dba54a'
              ? 'Maret'
              : row?.delivery?.bansos_id ===
                '2acec690-a2a2-4257-b44a-9fb7b4e37fcf'
              ? 'April'
              : row?.delivery?.bansos_id ===
                'a7330f07-1505-4e0a-bf9f-4af347bc8389'
              ? 'Mei'
              : '-'}
          </div>
        </div>
      );
    },
  },

  doc_outs: {
    title: 'Dokumen out',
    sortable: false,
    width: 100,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold">
            {row?.delivery?.doc_outs?.join(', \n') || '-'}
          </div>
        </div>
      );
    },
    url: (row) => {
      return `/spm/${row?.delivery_id}`;
    },
  },

  doc_transporter: {
    title: 'Dokumen Transporter',
    sortable: false,
    width: 70,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold">
            {row?.delivery?.code}
          </div>
        </div>
      );
    },
    url: (row) => {
      return `/spm/${row?.delivery_id}`;
    },
  },

  doc_bast_transporter: {
    title: 'Dokumen bast Transporter',
    sortable: false,
    width: 100,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold">{row?.manifest}</div>
        </div>
      );
    },
    url: (row) => {
      return `/spm/line/${row?.id}`;
    },
  },

  iconss: {
    title: '',
    sortable: false,
    width: 20,
    class: 'text-center',
    component: (row) => {
      return (
        <div className="table-col">
          <Pin.RiMapPin2Fill color="gray" size={25} />
        </div>
      );
    },
  },
  origin: {
    title: 'Tujuan',
    sortable: false,
    alias: 'origin.name',
    width: 150,
    component: (row) => {
      return (
        <div className="table-col ps-2">
          <div className="row">
            <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
              {row?.area?.village?.name}
            </div>
            <small className="info text-ellipsis">
              {row?.area?.village?.district?.name},{' '}
              {row?.area?.village?.district?.regency?.name},{' '}
              {row?.area?.village?.district?.regency?.kanwil?.name}
            </small>
          </div>
        </div>
      );
    },
  },

  total_package: {
    title: 'Jumlah PBP',
    format_number: true,
    sortable: false,
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
  },

  kuantum: {
    title: 'Kuantum/kg',
    sortable: false,
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col ps-2">
          <div className="text-ellipsis fs-8 text-uppercase">
            {currencyFormat(
              row?.total_package * row?.delivery?.bansos?.weight_packages
            )}{' '}
            KG
          </div>
        </div>
      );
    },
  },

  qty_delivery: {
    title: 'QTY Dikirim',
    sortable: false,
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col ps-2">
          <div className="text-ellipsis fs-8 text-uppercase">
            {currencyFormat(row?.total_package)}
          </div>
        </div>
      );
    },
  },

  total_received: {
    title: 'QTY Diterima',
    format_number: true,
    sortable: false,
    width: 70,
    class: 'text-end',
    classHeader: 'text-end',
  },

  status: {
    title: 'Status',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 120,
    component: (row) => {
      return (
        <span className={`badge status-${row?.status}-lt ms-auto`}>
          {row?.status?.replace('new', 'Baru')}
        </span>
      );
    },
  },

  is_pod: {
    title: 'POD',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 60,
    component: (row) => {
      return (
        row?.is_pod ? (
          <Fi.FiCheck size={15} className="me-2 text-success" />
        ): (
          <Fi.FiX size={15} className="me-2 text-danger" />
        )
      );
    },
  },

  is_docout: {
    title: 'POD Doc-Out',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 80,
    component: (row) => {
      return (
        row?.is_docout ? (
          <Fi.FiCheck size={15} className="me-2 text-success" />
        ): (
          <Fi.FiX size={15} className="me-2 text-danger" />
        )
      );
    },
  },
};
export default table;
