import React from 'react';
import { Skeletons } from 'ui/components/skeleton';
import Chart from 'react-apexcharts';

const ChartBarComponent = ({ data }) => {
  const [series, setSeries] = React.useState([]);
  const [options, setOptions] = React.useState({});

  React.useEffect(() => {
    if (!data) return null;

    const opt = {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 30,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: data?.labels,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      fill: {
        opacity: 1,
      },
      colors: ['#f16821', '#0a0e4d', '#2fb344', '#d6336c']
    };

    setOptions(opt);
    setSeries(data?.statistic);
  }, [data]);

  console.log([1, 2, 3, 4].reduce((a, b) => a + b, 0));

  return !data ? (
    <Skeletons height="150px" />
  ) : (
    <div id="chart">
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default ChartBarComponent;
