import * as Action from './action';

const defineInitialState = () => ({
  data: {},
});

export function reducer(state = defineInitialState(), action) {
  switch (action.type) {
    case Action.$reset.ACTION:
      return defineInitialState();
    case Action.$initialized.ACTION:
      state.data[action.name] = action.config;
      return {
        ...state,
      };
    case Action.$request.SUCCESS:
      state.data[action.name] = action.table;
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function persister({ data }) {
  return { data };
}
