import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'ui/components';

import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';

import tableConfig from './table.config';

const initialState = {
  kanwil: null,
  regency: null,
};

const ReportDistrict = ({ location }) => {
  const Bansos = useSelector((state) => state?.Auth?.project);
  const ServiceTable = useTable('report_document_district', tableConfig, false);
  const StateTable = useSelector((state) => state?.Table?.data['report_document_district']);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );
  const [{ kanwil, regency }, setState] = React.useState(initialState);
  const paramKanwil = new URLSearchParams(location?.search).get('kanwil_id');
  const paramRegency = new URLSearchParams(location?.search).get('regency_id');

  const $handleKanwil = async (e) => {
    setState((prevState) => ({
      ...prevState,
      kanwil: e,
      regency: null
    }));

    let filters = [
      {
        label: 'regency_id',
        value: null
      },
      {
        label: 'kanwil_id',
        value: e?.kanwil?.id
      }
    ]

    ServiceTable.filter(filters);
  };

  const $handleRegency = async (e) => {
    setState((prevState) => ({
      ...prevState,
      regency: e
    }));

    ServiceTable.filter('regency_id', e?.regency?.id);
  };

  const $onLoad = async() => {
    const filters = {
      bansos_id: Bansos?.id,
    };

    if (paramKanwil && paramRegency) {
      filters.kanwil_id = paramKanwil
      filters.regency_id = paramRegency;

      setState((prevState) => ({
        ...prevState,
        kanwil: {kanwil_id: paramKanwil},
        regency: {regency_id: paramRegency}
      }));
    } else if (StateTable?.filter?.regency_id) {
      filters.kanwil_id = StateTable?.filter?.kanwil_id;
      filters.regency_id = StateTable?.filter?.regency_id;

      setState((prevState) => ({
        ...prevState,
        kanwil: {kanwil_id: StateTable?.filter?.kanwil_id},
        regency: {regency_id: StateTable?.filter?.regency_id},
      }));
    }

    ServiceTable.boot(filters);
  }

  React.useEffect(() => {
    $onLoad();
  }, []);

  return (
    <div className="sub-page">
      <div className="p-3">
        <div className="row">
          <div className="col-md-4 col-12 order-2 order-md-1 mb-2 mb-md-0">
            <Select.Remote
              required
              label="Pilih Kanwil"
              placeholder=""
              url="/report/document/kanwil"
              initialValue={kanwil?.kanwil_id}
              keys={kanwil?.kanwil_id}
              params={{
                bansos_id: Bansos?.id,
              }}
              row={(d) => {
                return { value: d.kanwil, label: d?.kanwil?.name };
              }}
              onSelected={(c) => {
                $handleKanwil(c);
              }}
            />
          </div>
          <div className="col-md-4 col-12 order-3 order-md-2 mb-2 mb-md-0">
            <Select.Remote
              required
              label="Pilih Kota/Kab"
              placeholder=""
              url={`/report/document/regency`}
              initialValue={regency?.regency_id}
              params={{
                kanwil_id: kanwil?.kanwil_id,
                bansos_id: Bansos?.id,
              }}
              row={(d) => {
                return { value: d?.regency?.id, label: d?.regency?.name };
              }}
              onSelected={(c) => {
                $handleRegency(c);
              }}
              keys={kanwil?.kanwil_id}
              disabled={!kanwil}
            />
          </div>
          <div className="col-md-4 order-1 order-md-3 col-12">
            <ServiceTable.Tools downloadable />
          </div>
        </div>
      </div>
      <div className="sub-page-body">
        {StateLoading ? (
          <SkeletonTable headerCount={6} bodyCount={10} />
        ) : (
          <ServiceTable.Render />
        )}
      </div>
      <div className="sub-page-footer">
        {StateLoading ? <SkeletonPageFooter /> : <ServiceTable.Pagination />}
      </div>
    </div>
  );
};

export default ReportDistrict;
