import React from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, Select } from 'ui/components';

import { SkeletonsForm } from 'ui/components/skeleton';
import { formatDate } from 'utilities/common';

const initialState = {
  dispatch_at: {
    default: new Date(),
    formatted: formatDate(new Date(), 'YYYY-MM-DD'),
  },
  region: null,
};

export const FormGeneral = ({ isLoading, onInputChange, data }) => {
  const StateForm = useSelector((state) => state?.Form);
  const Project = useSelector((state) => state?.Auth?.project);
  const [{ dispatch_at, region }, setState] =
    React.useState(initialState);

  React.useEffect(() => {
    const state = {
      dispatch_at,
      region,
    };

    onInputChange(state);
  }, [region, dispatch_at]);

  const $handleKanwil = async (e) => {
    setState((prevState) => ({
      ...prevState,
      region: {
        kanwil: e,
        kanwil_id: e?.kanwil_id,
        regency: null
      },
    }));
  };

  const $handleRegency = async (e) => {
    setState((prevState) => ({
      ...prevState,
      region: {
        ...prevState?.region,
        regency_id: e?.regency_id,
        regency: e,
      },
    }));
  };

  React.useEffect(() => {
    if (data) {
      let region = {kanwil: null, regency: null}

      if (data?.region?.kanwil_id) {
        region.kanwil = {kanwil: {id: data?.region?.kanwil_id, name: data?.region?.kanwil_name}, kanwil_id: data?.region?.kanwil_id}
        region.kanwil_id = data?.region?.kanwil_id
      }

      if (data?.region?.regency_id) {
        region.regency = {regency: {id: data?.region?.regency_id, name: data?.region?.regency_name}, regency_id: data?.region?.regency_id}
        region.regency_id = data?.region?.regency_id
      }

      setState((prevState) => ({
        ...prevState,
        dispatch_at: {
          default: new Date(data?.dispatched_at),
          formatted: formatDate(data?.dispatched_at, 'YYYY-MM-DD'),
        },
        region: region
      }));
    }
  }, [data]);

  return (
    <div className="sidebar h-100 ">
      <div
        className="form-group sidebar-body-create py-3 pe-0 pe-md-3"
        style={{ overflow: 'auto', maxHeight: 'calc(100vh - 20vh)' }}
      >
        {isLoading ? (
          <SkeletonsForm count={3} />
        ) : (
          <div>
            <div className="mb-4">
              <div className="hr-text text-primary mb-3 mt-md-3 mt-3">
                Informasi General
              </div>

              <div className="mb-3">
                <label className="form-label fs-8 required">Tanggal RTT</label>
                <DatePicker
                  type="normal"
                  value={dispatch_at?.default || null}
                  format="dd/MM/yyyy"
                  onChange={(date) =>
                    setState((prevState) => ({
                      ...prevState,
                      dispatch_at: {
                        default: date,
                        formatted: formatDate(date, 'YYYY-MM-DD'),
                      },
                    }))
                  }
                  isShowTrigger
                />
                {StateForm?.errors?.dispatch_at && (
                  <div
                    className="invalid-feedback"
                    style={{ display: 'block' }}
                  >
                    {StateForm?.errors?.dispatch_at}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <Select.Remote
                  required
                  label="Pilih Kanwil"
                  placeholder=""
                  url="/report/kanwil"
                  initialValue={region?.kanwil?.kanwil_id}
                  row={(d) => {
                    return { value: d?.kanwil?.id, label: d?.kanwil?.name };
                  }}
                  params={{
                    bansos_id: Project?.id,
                  }}
                  onSelected={(c) => {
                    $handleKanwil(c);
                  }}
                  keys={region?.kanwil?.kanwil_id}
                />
                {StateForm?.errors?.kanwil_id && (
                  <div
                    className="invalid-feedback"
                    style={{ display: 'block' }}
                  >
                    {StateForm?.errors?.kanwil_id}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <Select.Remote
                  required
                  label="Pilih Kota/Kab"
                  placeholder=""
                  url={`/report/regency`}
                  initialValue={region?.regency?.regency_id}
                  params={{
                    kanwil_id: region?.kanwil?.kanwil_id,
                    bansos_id: Project?.id,
                  }}
                  row={(d) => {
                    return { value: d?.regency?.id, label: d?.regency?.name };
                  }}
                  onSelected={(c) => {
                    $handleRegency(c);
                  }}
                  keys={region?.kanwil?.kanwil_id}
                  disabled={!region?.kanwil}
                />
                {StateForm?.errors?.regency_id && (
                  <div
                    className="invalid-feedback"
                    style={{ display: 'block' }}
                  >
                    {StateForm?.errors?.regency_id}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
