import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';
import { $failure, $requesting, $success } from '../form/action';

export const MODULE = 'Uploader';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $upload = ActionCreator.Async(MODULE, 'Upload', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/uploader`, payload)
      .then((response) => {
        dispatch($upload.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});

export const $rotate = ActionCreator.Async(MODULE, 'Rotate', (payload) => {
  return async (dispatch) => {
    dispatch($requesting.action());

    return await Rest.POST(`${API_URL}/uploader/rotate`, payload)
      .then((response) => {
        dispatch($rotate.success({ response: response }));
        dispatch($success.action());
        return response;
      })
      .catch((error) => {
        dispatch($failure({ error }));
        return false;
      });
  };
});