import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Recon, Report } from 'services/action';
import { Page, Select } from 'ui/components';
import { currencyFormat } from 'utilities/common';
import ActionApprove from './component/table/accept';
import ActionUpload from './component/table/upload';
import ActionReport from './component/table/report';
import ActionSorting from './component/table/sorting';
import ActionDeletePOD from './component/table/delete_pod';

const initialState = {
  kanwil: null,
  regency: null,
  district: null,
  village: null,
  data: [],
  report: null,
};
const ReconScreen = () => {
  const Dispatch = useDispatch();

  const Bansos = useSelector((state) => state?.Auth?.project);
  const Session = useSelector((state) => state.Auth?.session);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );

  const [{ kanwil, regency, district, village, data, report }, setState] = React.useState(initialState);

  const $handleKanwil = async (e) => {
    setState((prevState) => ({
      ...prevState,
      kanwil: e,
      regency: null,
      district: null,
      village: null,
    }));
  };

  const $handleRegency = async (e) => {
    setState((prevState) => ({
      ...prevState,
      regency: e,
      district: null,
      village: null,
    }));
  };

  const $handleDistrict = async (e) => {
    setState((prevState) => ({
      ...prevState,
      district: e,
      village: null,
    }));
  };

  const $handleVillage = async (e) => {
    setState((prevState) => ({
      ...prevState,
      village: e,
    }));
  };

  const queryArea = () => {
    let query = {
      bansos_id: Bansos?.id,
    };

    if (kanwil) {
      query.kanwil_id = kanwil?.kanwil_id;
    }

    if (regency) {
      query.regency_id = regency?.regency_id;
    }

    if (district) {
      query.district_id = district?.district_id;
    }

    if (village) {
      query.village_id = village?.village_id;
    }

    return query;
  };

  const $onLoad = async () => {
    const req = await Dispatch(Recon.$get(queryArea()));

    if (req?.message === 'success') {
      setState((prevState) => ({ ...prevState, data: req?.data }));
    }
  };

  const $refresh = async (e) => {
    const res = await Dispatch(Report.$showVillage(e));

    if (res?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        report: res?.data,
      }));
    }
  };

  const $refreshVillageLine = async () => {
    $refresh(village?.id);
    $onLoad();
  };

  React.useEffect(() => {
    if (village) {
      $refreshVillageLine()
    } else {
      setState((prevState) => ({
        ...prevState,
        data: null,
        report: null
      }));
    }
  }, [village])

  return (
    <Page className="">
      <Page.Header title="Recon" isLoading={StateLoading} />
      <div className="row bg-white px-4 py-3">
        <div className="col-8">
          <div className="row">
            <div className="col-md-6 col-6">
              <div className="mb-3">
                <Select.Remote
                  required
                  label="Pilih Kanwil"
                  placeholder=""
                  url="/report/kanwil"
                  row={(d) => {
                    return { value: d, label: d?.kanwil?.name };
                  }}
                  params={{
                    bansos_id: Bansos?.id,
                  }}
                  onSelected={(c) => {
                    $handleKanwil(c);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 col-6">
              <div className="mb-3">
                <Select.Remote
                  required
                  label="Pilih Kota/Kab"
                  placeholder=""
                  url={`/report/regency`}
                  params={{
                    kanwil_id: kanwil?.kanwil_id,
                    bansos_id: Bansos?.id,
                  }}
                  row={(d) => {
                    return { value: d, label: d?.regency?.name };
                  }}
                  onSelected={(c) => {
                    $handleRegency(c);
                  }}
                  keys={kanwil?.kanwil_id}
                  disabled={!kanwil}
                />
              </div>
            </div>
            <div className="col-md-6 col-6">
              <div className="mb-3">
                <Select.Remote
                  required
                  label="Pilih Kecamatan"
                  placeholder=""
                  url={`/report/district`}
                  row={(d) => {
                    return { value: d, label: d?.district?.name };
                  }}
                  params={{
                    regency_id: regency?.regency_id,
                    bansos_id: Bansos?.id,
                  }}
                  onSelected={(c) => {
                    $handleDistrict(c);
                  }}
                  keys={regency?.regency_id}
                  disabled={!regency}
                />
              </div>
            </div>
            <div className="col-md-6 col-6">
              <div className="mb-3">
                <Select.Remote
                  required
                  label="Pilih Kelurahan"
                  placeholder=""
                  url={`/report/village`}
                  row={(d) => {
                    return { value: d, label: d?.village?.name };
                  }}
                  params={{
                    district_id: district?.district_id,
                    bansos_id: Bansos?.id,
                  }}
                  onSelected={(c) => {
                    $handleVillage(c);
                  }}
                  keys={district?.district_id}
                  disabled={!district}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row p-2 mb-3 mb-md-0  justify-content-center">
            <div className="rounded-2 border bg-success-lt text-center p-2 col-6 col-md-12 mb-3">
              <div className="fs-5 fw-semibold text-left text-secondary">
                {report?.total_kpm || 0}
              </div>
              <div className="page-pretitle text-left">total pbp</div>
            </div>
            <div className="rounded-2 border bg-light-lt text-center p-2 col-6 col-md-6 mb-3">
              <div className="fs-5 fw-semibold text-left text-secondary">
                {report?.total_shipped || 0}
              </div>
              <div className="page-pretitle text-left">TOTAL DIKIRIM</div>
            </div>
            <div className="rounded-2 border bg-light-lt text-center p-2 col-6 col-md-6 mb-3">
              <div className="fs-5 fw-semibold text-left text-secondary">
                {report?.total_delivered || 0}
              </div>
              <div className="page-pretitle text-left">TOTAL TERKIRIM</div>
            </div>
          </div>
        </div>
      </div>
      <Page.Body className="row m-0 p-0">
        {StateLoading ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
            </div>
          </div>
        ) : data?.length === 0 || !data ? (
          <div className="row">
            <div className="empty col-lg-12 truck">
              <div className="truck-wrapper">
                <div className="truck">
                  <div className="truck-container"></div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
                </div>
              </div>
              <p className="empty-title">Belum ada pengiriman</p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-6">
              <div className="table-responsive bg-white">
                <table
                  width="100%"
                  className="table table-hover table-vcenter card-table datatable border"
                >
                  <thead className="bg-light">
                    <tr className="th">
                      <td style={{ whiteSpace: 'nowrap', width: '30%' }}></td>
                      <td
                        style={{ whiteSpace: 'nowrap', width: '30%' }}
                        className="text-center"
                      >
                        Total Paket
                      </td>
                      <td
                        style={{ whiteSpace: 'nowrap', width: '30%' }}
                        className="text-center"
                      >
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((d, i) => (
                      <>
                        <tr key={i} className="border-bottom-0 border-white">
                          <td>
                            <div className="table-col ps-2">
                              <div className="row">
                                <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
                                  {d?.line?.manifest}
                                </div>
                                <small className="info text-ellipsis">
                                  {d?.village?.name},{' '}
                                  {d?.village?.district?.name},{' '}
                                  {d?.village?.district?.regency?.name},{' '}
                                  {d?.village?.district?.regency?.kanwil?.name}
                                </small>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Lihat SPM Berdasarkan Tujuan
                                </Tooltip>
                              }
                            >
                              <Link
                                className={`btn ${d?.line?.status === 'new' ? 'btn-default' : 'btn-success'} px-5`}
                                role="button"
                                to={`/spm/line/${d?.line?.id}`}
                              >
                                <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
                                  {currencyFormat(d?.line?.total_package)}
                                </div>
                              </Link>
                            </OverlayTrigger>
                          </td>
                          {i === 0 && (
                            <td className="text-center" >
                              <ActionReport
                                data={report}
                                type="receive"
                                accepted={report?.stt_status === 'accepted'}
                                rejected={report?.stt_status === 'rejected'}
                                uploaded={report?.upload_stt}
                                onClose={() => $refreshVillageLine()}
                              />

                              {report?.stt_reason && (
                                <div className='border-top mt-2 text-start'>
                                  <span className='info fs-9'>
                                    Alasan: <p className='mb-0'>{report?.stt_reason}</p>
                                  </span>
                                </div>
                              )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td className="justify-content-end" colSpan={3}>
                            <ActionUpload
                              data={d}
                              type="receive"
                              isLine
                              received={report?.stt_status !== ''}
                              status={report?.stt_status}
                              onClose={() => $refreshVillageLine()}
                            />
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6">
              <div className="table-responsive bg-white">
                <table
                  width="100%"
                  className="table table-hover card-table datatable border"
                >
                  <thead className="bg-light">
                    <tr className="th">
                      <td
                        style={{ whiteSpace: 'nowrap', width: '20%' }}
                        className="text-center"
                      >
                        BAST
                      </td>
                      <td
                        style={{ whiteSpace: 'nowrap', width: '20%' }}
                        className="text-center"
                      >
                        SPTJM
                      </td>
                      <td
                        style={{ whiteSpace: 'nowrap', width: '20%' }}
                        className="text-center"
                      >
                        BNBA
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <ActionReport
                          data={report}
                          type="bast"
                          accepted={report?.bast_status === 'accepted'}
                          rejected={report?.bast_status === 'rejected'}
                          uploaded={report?.upload_bast}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                      <td className="text-center">
                        <ActionReport
                          data={report}
                          type="sptjm"
                          accepted={report?.sptjm_status === 'accepted'}
                          rejected={report?.sptjm_status === 'rejected'}
                          uploaded={report?.upload_sptjm}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                      <td className="text-center">
                        <ActionReport
                          data={report}
                          type="rtt"
                          accepted={report?.rtt_status === 'accepted'}
                          rejected={report?.rtt_status === 'rejected'}
                          uploaded={report?.upload_rtt}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <ActionUpload
                          data={report}
                          type="bast"
                          received={report?.bast_status !== ''}
                          status={report?.bast_status}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                      <td className="text-center">
                        <ActionUpload
                          data={report}
                          type="sptjm"
                          received={report?.sptjm_status !== ''}
                          status={report?.sptjm_status}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                      <td className="text-center">
                        <ActionUpload
                          data={report}
                          type="rtt"
                          received={report?.rtt_status !== ''}
                          status={report?.rtt_status}
                          onClose={() => $refreshVillageLine()}
                        />
                      </td>
                    </tr>
                    {report?.upload_rtt && (
                      <>
                        <tr className='border-bottom-0 border-white'>
                          <td colSpan={3}>
                            <ActionSorting type="rtt" data={report} onClose={$refreshVillageLine} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <ActionDeletePOD
                              type="rtt"
                              data={report}
                              received={report?.rtt_status !== ''}
                              status={report?.rtt_status}
                              onClose={$refreshVillageLine}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                    {(report?.bast_reason || report?.sptjm_reason || report?.rtt_reason) && (
                      <tr>
                        <td className="text-left">
                          {report?.bast_reason && (
                            <span className='info fs-9'>
                              Alasan: <p className='mb-0'>{report?.bast_reason}</p>
                            </span>
                          )}
                        </td>
                        <td className="text-left">
                          {report?.sptjm_reason && (
                            <span className='info fs-9'>
                              Alasan: <p className='mb-0'>{report?.sptjm_reason}</p>
                            </span>
                          )}
                        </td>
                        <td className="text-left">
                          {report?.rtt_reason && (
                            <span className='info fs-9'>
                              Alasan: <p className='mb-0'>{report?.rtt_reason}</p>
                            </span>
                          )}
                        </td>
                      </tr>
                    )}

                    {Session?.usergroup?.id ===
                      '302d772c-77db-47ea-ab5a-83b6be4d3787' && (
                      <tr>
                        <td colspan="3">
                          <ActionApprove
                            data={report}
                            type="arcon"
                            accepted={Session?.usergroup?.id !==
                              '302d772c-77db-47ea-ab5a-83b6be4d3787'}
                            onClose={() => $refreshVillageLine()}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Page.Body>
    </Page>
  );
};

export default ReconScreen;
