import React from 'react';
import _ from 'underscore';
import Slider from 'react-slick';
import ProgressBar from '@ramonak/react-progress-bar';
import CountUpComponent from '../../dashboard/component/countup';
import { decimalFormat } from 'utilities/common';

const KanwilCarousel = ({ data }) => {
  const [render, setRender] = React.useState(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    speed: 500,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const renderPeriodic = () => {
    if (data) {
      let page = _.chunk(data, 8);
      if (data?.length > 8) {
        let fp = page[0];
        let lp = _.rest(data, fp?.length);
        page = _.chunk(lp, 8);
        page.unshift(fp);
      }

      setRender(page);
    }
  };

  React.useEffect(() => {
    renderPeriodic();
  }, [data]);

  return (
    <div className="pb-4">
      <Slider {...settings}>
        {render?.map((page, i) => (
          <div key={i}>
            <div className="row">
              {page?.map((data, j) => (
                <div className="col-12 col-md-4 p-2 " key={j}>
                  <div className="bg-light p-2 rounded-3 shadow-sm">
                    <h5>{data?.kanwil?.name}</h5>

                    <div className="row justify-content-center align-items-center mb-2">
                      <div className="col-7 pe-0">
                        <ProgressBar
                          completed={
                            (data?.total_shipped / data?.total_kpm) * 100
                          }
                          bgColor="rgba(47,179,68,1)"
                          isLabelVisible={false}
                          transitionDuration={5}
                          animateOnRender={true}
                          height="10px"
                        />
                      </div>
                      <div className="col ps-0 text-end">
                        {(
                          (data?.total_shipped / data?.total_kpm) *
                          100
                        ).toFixed(2)}{' '}
                        %
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="bg-primary-lt p-2 text-center">
                          <div className="page-pretitle">target</div>
                          <div className="title text-dark">
                            <CountUpComponent
                              starting={0}
                              ending={decimalFormat(data?.total_kpm)}
                              fs={12}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="bg-success-lt p-2 text-center">
                          <div className="page-pretitle">diterima</div>
                          <div className="title text-dark">
                            <CountUpComponent
                              starting={0}
                              ending={decimalFormat(data?.total_shipped)}
                              fs={12}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default KanwilCarousel;
