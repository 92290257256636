import DailyUploadedcreen from './index';

const routes = [
  {
    path: '/daily/uploaded',
    component: DailyUploadedcreen,
    usergroup: [
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '4df4c118-ed27-40a4-865e-270930637e82'
    ],
  },
];

export default routes;
