import config from 'services/table/const';
import { currencyFormat } from 'utilities/common';

let table = { ...config };
table.url = '/report/recon/district';
table.filter = {'order_by': 'percentage'}
table.columns = {
  code: {
    title: 'Kecamatan',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.district?.name}</div>
          <small className="info text-ellipsis">
            {row?.district?.regency?.name},{' '}
            {row?.district?.regency?.kanwil?.name}
          </small>
        </div>
      );
    },
    url: (row) => {
      return `/report/recon/village?kanwil_id=${row?.district?.regency?.kanwil?.id}&regency_id=${row?.district?.regency?.id}&district_id=${row?.district_id}`;
    },
  },

  total_kpm: {
    title: 'PBP',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return <div className="table-col">{currencyFormat(row?.total_kpm)}</div>;
    },
    url: (row) => {
      return `/report/recon/village?kanwil_id=${row?.district?.regency?.kanwil?.id}&regency_id=${row?.district?.regency?.id}&district_id=${row?.district_id}`;
    },
  },

  total_uploaded: {
    title: 'Uploaded',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_uploaded)}</span>
          {` (${((row?.total_uploaded / row?.total_kpm) * 100).toFixed(2)}%)`}
        </div>
      );
    },
    url: (row) => {
      return `/report/recon/village?kanwil_id=${row?.district?.regency?.kanwil?.id}&regency_id=${row?.district?.regency?.id}&district_id=${row?.district_id}`;
    },
  },

  total_approved: {
    title: 'Disetujui',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_approved)}</span>
          {` (${((row?.total_approved / row?.total_kpm) * 100).toFixed(2)}%)`}
        </div>
      );
    },
    url: (row) => {
      return `/report/recon/village?kanwil_id=${row?.district?.regency?.kanwil?.id}&regency_id=${row?.district?.regency?.id}&district_id=${row?.district_id}`;
    },
  },

  total_rejected: {
    title: 'Ditolak',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_rejected)}</span>
          {` (${((row?.total_rejected / row?.total_kpm) * 100).toFixed(2)}%)`}
        </div>
      );
    },
    url: (row) => {
      return `/report/recon/village?kanwil_id=${row?.district?.regency?.kanwil?.id}&regency_id=${row?.district?.regency?.id}&district_id=${row?.district_id}`;
    },
  },

  status: {
    title: 'Status',
    sortable: true,
    class: 'text-center',
    classHeader: 'text-center',
    width: 120,
    component: (row) => {
      return (
        <span className={`badge status-${row?.status}-lt ms-auto`}>
          {row?.status === '' ? 'Draft': row?.status?.replace('_', ' ')}
        </span>
      );
    },
    url: (row) => {
      return `/report/recon/village?kanwil_id=${row?.district?.regency?.kanwil?.id}&regency_id=${row?.district?.regency?.id}&district_id=${row?.district_id}`;
    },
  },
};

export default table;
