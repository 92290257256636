import SigninScreen from './signin';

// import CreateScreen from 'ui/screens/authorized/spm/create';

const routes = [
  {
    path: '/signin',
    // component: CreateScreen,
    component: SigninScreen,
  },
];

export default routes;
