import React from "react";

const FormInputFile = ({
  label,
  value,
  onChange,
  error,
  horizontal,
  required,
  hint,
}) => {
  return (
    <div className={horizontal ? "row mb-3" : "mb-3"}>
      {label ? (
        <label
          className={
            required
              ? "form-label required"
              : horizontal
              ? "form-label col-3 col-form-label"
              : "form-label"
          }
        >
          {label}
        </label>
      ) : null}
      <div className={horizontal ? "col" : null}>
        <input
          type="file"
          className={error ? "form-control is-invalid" : "form-control"}
          value={value}
          onChange={onChange}
        />
        {hint ? <small className="form-hint">{hint}</small> : null}
        {error ? <div className="invalid-feedback">{error}</div> : null}
      </div>
    </div>
  );
};

export default FormInputFile;
