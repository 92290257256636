import Datagrid from 'ui/components/data/grid';
import { currencyFormat } from 'utilities/common';

export const SectionInfo = ({ data, loading }) => {
  return (
    <div className="p-0 p-md-3 mb-3 mb-md-0">
      <div className="card rounded-2 bg-white">
        <div className="card-header-gray p-3 border-bottom">
          <h3 className="card-title dx-card-title m-0 p-0">
            Informsi Delivery Line
          </h3>
        </div>
        <div className="card-body p-3 pt-3">
          <div className="row">
            <div className="col-6">
              <div className="datagrid">
                <Datagrid
                  loading={loading}
                  title="Kode Manifest"
                  value={data?.manifest}
                />
                <Datagrid
                  loading={loading}
                  title="Area"
                  value={
                    `${data?.area?.village?.name}, ${data?.area?.village?.district?.name}, ${data?.area?.village?.district?.regency?.name}, ${data?.area?.village?.district?.regency?.kanwil?.name}` ||
                    '-'
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="datagrid">
                <Datagrid
                  loading={loading}
                  title="total pbp"
                  value={currencyFormat(data?.area?.total_kpm)}
                />
                <Datagrid
                  loading={loading}
                  title="Total Dikirim"
                  value={currencyFormat(data?.total_package)}
                />
                <Datagrid
                  loading={loading}
                  title="Total Diterima"
                  value={currencyFormat(data?.total_received)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
