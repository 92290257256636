import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { Delivery } from 'services/action';
import { DatePicker, Form } from 'ui/components';
import { SkeletonsForm } from 'ui/components/skeleton';
import { formatDate } from 'utilities/common';
import { DocOut } from './doc_out';

const initialState = {
  isLoading: true,
  driver: null,
  doc_outs: null,
  dispatch_at: {
    default: new Date(),
    formatted: formatDate(new Date(), 'YYYY-MM-DD'),
  },
};

export const FormPartial = ({ data, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);

  const [{ driver, doc_outs, dispatch_at, isLoading }, setState ] = React.useState(initialState);

  const dummy = Array.from({ length: 5 }, (v, i) => i);

  const $handleSubmit = async (c) => {
    const payload = {
      driver,
      doc_outs,
      dispatch_at: dispatch_at?.formatted,
    };

    const submit = await Dispatch(Delivery.$updatePartial(data?.id, payload));
      if (submit?.message === 'success') {
        onClose();
      }
  }

  React.useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 2000);
  }, []);

  React.useEffect(() => {
    if (!data) return;

    setState((prevState) => ({
      ...prevState,
      dispatch_at: {
        default: new Date(data?.dispatched_at),
        formatted: formatDate(data?.dispatched_at, 'YYYY-MM-DD'),
      },
      driver: data?.driver,
    }));
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <div className="row m-0">
          {dummy?.map((d, i) => (
            <div className="col-md-6 col-12" key={i}>
              <SkeletonsForm count={1} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal.Body>
        <div className="row">
            <div className="col-6">
                <div className="hr-text text-primary mb-3 mt-md-3 mt-3">
                  Informasi Driver
                </div>

                <div className="mb-3">
                    <Form.Input
                      label="Nama"
                      required
                      type="text"
                      value={driver?.name || ''}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          driver: {
                            ...prevState?.driver,
                            name: e.target.value,
                          },
                        }))
                      }
                      error={
                        StateForm?.errors?.driver ||
                        StateForm?.errors['driver.name']
                      }
                    />
                </div>

                <div className="mb-3">
                  <Form.Input
                      label="No. Handphone"
                      required
                      type="text"
                      value={driver?.phone || ''}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          driver: {
                            ...prevState?.driver,
                            phone: e.target.value,
                          },
                        }))
                      }
                      error={
                        StateForm?.errors?.driver ||
                        StateForm?.errors['driver.phone']
                      }
                  />
                </div>
                <div className="mb-3">
                  <Form.Input
                    label="Jenis Kendaraan"
                    required
                    type="text"
                    value={driver?.vehicle_type || ''}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        driver: {
                          ...prevState?.driver,
                          vehicle_type: e.target.value,
                        },
                      }))
                    }
                    error={
                      StateForm?.errors?.driver ||
                      StateForm?.errors['driver.vehicle_type']
                    }
                  />
                </div>
                <div className="mb-3">
                  <Form.Input
                    label="No. Kendaraan"
                    required
                    type="text"
                    value={driver?.vehicle_number || ''}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        driver: {
                          ...prevState?.driver,
                          vehicle_number: e.target.value,
                        },
                      }))
                    }
                    error={
                      StateForm?.errors?.driver ||
                      StateForm?.errors['driver.vehicle_number']
                    }
                  />
                </div>
            </div>
            <div className='col-6'>
              <div className="hr-text text-primary mb-3 mt-md-3 mt-3">
                Informasi General
              </div>

              <div className="mb-3">
                <label className="form-label fs-8">Tanggal</label>
                <div
                  className={
                    StateForm?.errors?.dispatch_at
                      ? 'border border-danger rounded'
                      : ''
                  }
                >
                  <DatePicker
                    type="normal"
                    value={dispatch_at?.default || null}
                    format="dd/MM/yyyy"
                    onChange={(date) =>
                      setState((prevState) => ({
                        ...prevState,
                        dispatch_at: {
                          default: date,
                          formatted: formatDate(date, 'YYYY-MM-DD'),
                        },
                      }))
                    }
                    isShowTrigger
                  />
                </div>
                {StateForm?.errors?.dispatch_at && (
                  <div
                    className="invalid-feedback"
                    style={{ display: 'block' }}
                  >
                    {StateForm?.errors?.dispatch_at}
                  </div>
                )}
              </div>

              <DocOut data={data} onInputChange={(e) => setState((prevState) => ({ ...prevState, doc_outs: e }))} />
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button
              type="button"
              className='btn btn-light w-100'
              onClick={() => onClose()}
            >
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className={cn(
                StateForm?.loading ? 'btn-loading' : '',
                  'btn btn-primary w-100'
                )}
              disabled={StateForm?.loading}
              onClick={$handleSubmit}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};
