import ProfileScreen from './index';

const routes = [
  {
    path: '/profile/setting',
    component: ProfileScreen,
  },
];

export default routes;
