import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import * as Fi from 'react-icons/fi';
import Resizer from "react-image-file-resizer";
import pdf from 'ui/assets/pdf.png';

import { logo64 } from 'utilities/common';
import { Uploader } from 'services/action';
var watermark = require('watermarkjs');


const initialState = {
  isShow: false,
  draft: null,
  error: null,
};

const UploadComp = ({ setData, small, errors, postImage, type, village_id, regency_id, bansos_id }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state?.Form);
  const refspod = React.useRef(null);

  const [{ isShow, draft, error }, setState] = React.useState(initialState);
  const [imgUpload, setImgUpload] = React.useState([]);

  const clearState = () => {
    setState({ ...initialState });
  };

  const $handleClose = () => {
    clearState();
  };

  const $handleMultiImg = async (blob) => {
    Array.from(blob).forEach(async function (item) {
      let reader = new FileReader();
      reader?.readAsDataURL(item);
      reader.onload = async function () {
        setState((prevState) => ({ ...prevState, draft: reader?.result }));
        if (item?.type !== 'application/pdf') {
          Resizer.imageFileResizer(
            item,
            2000,
            2000,
            "PNG",
            100,
            0,
            (uri) => {
              watermark([uri, logo64()])
                .image(watermark.image.upperLeft())
                .then(async function (img) {
                  let y = document
                    .querySelector('.demo-image-preview')
                    .appendChild(img);
                  y.id = 'foto';
                  let x = document.querySelector('img#foto').src;
                  var image_x = document.getElementById('foto');

                  image_x.parentNode.removeChild(image_x);

                  if (type !== 'receive') {
                    x = uri
                  }

                  const payloads = {
                    file: x,
                    document: {
                      type: type,
                      bansos_id: bansos_id,
                      village_id: village_id,
                      regency_id: regency_id
                    }
                  };

                  const upload = await Dispatch(Uploader.$upload(payloads));
                  if (upload?.message === 'success') {
                    setImgUpload((imgUpload) => [...imgUpload, upload?.data]);
                  }
                });
            }
          );
        } else {
          const payloads = {
            file: reader?.result,
            document: {
              type: type,
              bansos_id: bansos_id,
              village_id: village_id,
              regency_id: regency_id
            }
          };

          const upload = await Dispatch(Uploader.$upload(payloads));
          if (upload?.message === 'success') {
            setImgUpload((imgUpload) => [...imgUpload, upload?.data]);
          }
        }
      };
    });
  };

  const $removeImg = async (i) => {
    let newImg = [...imgUpload];
    newImg.splice(i, 1);
    setImgUpload(newImg);
  };

  const $saveImage = () => {
    if (imgUpload?.length > 0) {
      setData(imgUpload);
      if (postImage) {
        postImage();
      }
      setImgUpload([]);
      clearState();
    } else {
      setState((prevState) => ({
        ...prevState,
        error: 'Gambar harus diisi',
      }));

      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          error: null,
        }));
      }, 8000);
    }
  };

  const ImagePreview = ({ dataUri }) => {
    return (
      <div className="demo-image-preview d-none" id="lower-left">
        <img src={dataUri} alt="" />
      </div>
    );
  };

  const ImageUploader = () => {
    return (
      <div>
        <input
          ref={refspod}
          type="file"
          multiple={true}
          style={{ display: 'none' }}
          onChange={(e) => {
            $handleMultiImg(e?.target?.files);
          }}
          accept="image/png, image/jpeg, .pdf"
        />
        <div
          className={cn(
            'uploader d-flex flex-column align-items-center justify-content-center cursor-pointer',
            error ? 'border border-danger' : ''
          )}
          onClick={() => refspod.current.click()}
        >
          <Fi.FiUpload size={20} />
          <div className=" fw-semibold" style={{ fontSize: 12 }}>
            Upload
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className={cn(
          'uploader d-flex flex-column align-items-center justify-content-center cursor-pointer',
          small ? 'small' : 'medium',
          errors ? 'border border-danger' : ' '
        )}
        onClick={() => {
          setState((prevState) => ({ ...prevState, isShow: true }));
        }}
      >
        <Fi.FiUpload size={small ? 15 : 20} />
        <div className=" fw-semibold" style={{ fontSize: small ? 10 : 12 }}>
          Upload
        </div>
      </div>

      <Modal
        show={isShow}
        onHide={$handleClose}
        backdrop="static"
        centered
        size="small"
        enforceFocus={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>POD Uploader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImagePreview dataUri={draft} />
          <div className="row justify-content-center align-items-center">
            <div className="col-3 p-0 mb-2 justify-content-center align-content-center d-flex">
              <ImageUploader />
            </div>
            {imgUpload?.map((e, i) => (
              <div className="col-3 p-0 mb-2" key={i}>
                <div className="position-relative d-flex justify-content-center align-items-center">
                  <span
                    className="position-absolute bg-danger rounded-circle top-0 end-0 zIndex-up d-flex justify-content-center align-items-center cursor-pointer me-1 mt-n1"
                    style={{ height: 20, width: 20 }}
                    onClick={() => $removeImg(i)}
                  >
                    <Fi.FiX color="white" size={10} />
                  </span>
                  <img
                    src={`${e?.includes('.pdf') ? pdf : e}`}
                    alt=""
                    style={{ width: 100, height: 100 }}
                    className="rounded-3"
                  />
                </div>
              </div>
            ))}
          </div>

          {error ? (
            <div
              className="invalid-feedback mb-3 text-center"
              style={{ display: 'block' }}
            >
              {error}
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={cn(
              'btn btn-primary',
              StateForm?.loading ? 'btn-loading' : ''
            )}
            disabled={StateForm?.loading}
            onClick={$saveImage}
          >
            <Fi.FiUpload className="me-2" /> Upload
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadComp;
