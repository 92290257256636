import React from 'react';

const FormTextbox = ({
  label,
  name,
  placeholder,
  required,
  rows,
  hint,
  horizontal,
  value,
  onChange,
  error,
  defaultValue,
  readOnly,
  disabled,
}) => {
  return (
    <div className={horizontal ? 'row mb-3' : 'mb-3'}>
      {label ? (
        <label
          className={
            required
              ? 'form-label fs-8 required'
              : horizontal
              ? 'form-label fs-8 col-3 col-form-label'
              : 'form-label fs-8'
          }
        >
          {label}
        </label>
      ) : null}
      <div className={horizontal ? 'col' : null}>
        <textarea
          className={error ? 'form-control is-invalid' : 'form-control'}
          name={name}
          rows={rows}
          placeholder={placeholder}
          value={value == null ? '' : value}
          onChange={onChange}
          defaultValue={defaultValue}
          readOnly={readOnly}
          disabled={disabled}
        />
        {hint ? <small className="form-hint">{hint}</small> : null}
        {error ? <div className="invalid-feedback">{error}</div> : null}
      </div>
    </div>
  );
};

export default FormTextbox;
