import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from 'services/action';

const BreadcrumbWrapper = ({ children }) => {
  return (
    <ul className="d-flex align-items-center breadcrumbs flex-wrap">
      {children}
    </ul>
  );
};

const BreadcrumbItem = ({ data, type, onValueChange, initialValue }) => {
  const [selected, setSelected] = React.useState(null);
  React.useEffect(() => {
    if (!data) return;

    if (!selected || selected === null) {
      if (initialValue) {
        setSelected(initialValue);
      } else {
        setSelected(data[0]);
      }
    }
  }, [data, initialValue]);

  if (data?.length > 1) {
    return (
      <li>
        <Dropdown>
          <Dropdown.Toggle
            id="nav-dropdown-breadcrumb"
            variant="light"
            className="border-0 py-0 ps-0 px-3 bg-transparent"
          >
            <div
              className="text-dark text-start text-uppercase fw-bold"
              style={{ fontSize: 15, marginBottom: 0, marginTop: -1 }}
            >
              {type === 'kanwil'
                ? selected?.kanwil?.name || selected?.kanwil_name
                : type === 'regency'
                ? selected?.regency?.name
                : type === 'district'
                ? selected?.district?.name
                : null}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {data?.map((d, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => {
                  setSelected(d);
                  onValueChange(d);
                }}
              >
                {type === 'kanwil'
                  ? d?.kanwil?.name
                  : type === 'regency'
                  ? d?.regency?.name
                  : type === 'district'
                  ? d?.district?.name
                  : null}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </li>
    );
  } else {
    return (
      <li>
        <div
          className="px-3 ps-0 cursor-pointer"
          onClick={() => {
            setSelected(selected);
            onValueChange(selected);
          }}
        >
          <div
            className="text-dark text-start text-uppercase fw-bold"
            style={{ fontSize: 15, marginBottom: 0 }}
          >
            {type === 'kanwil'
              ? selected?.kanwil?.name || selected?.kanwil_name
              : type === 'regency'
              ? selected?.regency?.name
              : type === 'district'
              ? selected?.district?.name
              : null}
          </div>
        </div>
      </li>
    );
  }
};

const Breadcrumb = ({
  kanwil,
  regency,
  district,
  onKanwilChange,
  onRegencyChange,
  onDistrictChange,
}) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.project);
  const [dataKanwil, setDK] = React.useState(null);
  const [dataRegency, setRG] = React.useState(null);
  const [dataDistrict, setDS] = React.useState(null);

  const reportKanwil = async () => {
    const req = await Dispatch(Report.$kanwil({ bansos_id: Session?.id }));

    if (req?.message === 'success') {
      setDK(req?.data);
    }
  };

  const reportRegency = async () => {
    const req = await Dispatch(
      Report.$regency({
        bansos_id: Session?.id,
        kanwil_id: kanwil?.kanwil_id,
      })
    );

    if (req?.message === 'success') {
      setRG(req?.data);
    }
  };

  const reportDistrict = async () => {
    const req = await Dispatch(
      Report.$district({
        bansos_id: Session?.id,
        regency_id: regency?.regency_id,
      })
    );

    if (req?.message === 'success') {
      setDS(req?.data);
    }
  };

  React.useEffect(() => {
    reportKanwil();
  }, [Session]);

  React.useEffect(() => {
    if (kanwil) {
      reportRegency();
    }
  }, [kanwil]);

  React.useEffect(() => {
    if (regency) {
      reportDistrict();
    }
  }, [regency]);

  return (
    <div className="page-header flex-column">
      <div>
        <div className="page-pretitle">area </div>
        <BreadcrumbWrapper>
          <BreadcrumbItem
            data={dataKanwil}
            type="kanwil"
            onValueChange={onKanwilChange}
            initialValue={kanwil}
          />

          {regency && (
            <BreadcrumbItem
              data={dataRegency}
              type="regency"
              onValueChange={onRegencyChange}
              initialValue={regency}
            />
          )}

          {district && (
            <BreadcrumbItem
              data={dataDistrict}
              type="district"
              onValueChange={onDistrictChange}
              initialValue={district}
            />
          )}
        </BreadcrumbWrapper>
      </div>
    </div>
  );
};

export default Breadcrumb;
