import WarehouseScreen from './index';

const routes = [
  {
    path: '/master/warehouse',
    component: WarehouseScreen,
    usergroup: ['6ca4fba3-b0a2-4266-909e-2471987025f2'],
  },
];

export default routes;
