import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Delivery } from 'services/action';
import { Page } from 'ui/components';
import { PODImage, SectionInfo, SectionLine } from './component/detail';
import { Action } from './component/detail/action';

const initialState = {
  data: null,
};

const DetailScreen = ({ match }) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state.Auth?.session);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Delivery.Show']
  );
  const [{ data }, setState] = React.useState(initialState);

  const $onLoad = async (id) => {
    const req = await Dispatch(Delivery.$show(id));

    if (req?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        data: req?.data,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        data: null,
      }));
    }
  };

  React.useEffect(() => {
    $onLoad(match?.params?.id);
  }, [match]);

  return (
    <Page className="col-md-10 col-sm-12 bordered bg-white">
      <Page.Header
        pretitle="SPM"
        title={data?.code}
        status={data?.status}
        backTo="/spm"
        className="bg-white"
      >
        <Action data={data} onSubmit={() => $onLoad(match?.params?.id)} />
      </Page.Header>
      <Page.Body className="bg-light mt-3 mt-md-0 bordered">
        <div className="p3">
          <div className="row">
            <div className="col-md-8">
              <SectionInfo data={data} loading={StateLoading} />
              <SectionLine data={data?.lines} loading={StateLoading} />
            </div>
            <div className="col-md-4">
              <PODImage data={data} type='receive' />
              <PODImage
                data={data}
                type='doc_out'
                showAction={Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' || Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2' || Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' || Session?.usergroup?.id === '9cd330e3-e5d9-47d3-8faa-375139f4daa3' }
              />
            </div>
          </div>
        </div>
      </Page.Body>
    </Page>
  );
};

export default DetailScreen;
