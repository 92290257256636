import { $done, $processing } from 'services/activity/action';
import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';

export const MODULE = 'Recon';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $get = ActionCreator.Async(MODULE, 'Get', (payload) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}/recon`, payload)
      .then((response) => {
        dispatch($get.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($get.OPERATION)));
  };
});
