import React from 'react';
import { FaLock, FaUserAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Form } from 'ui/components';

import { Auth } from 'services/action';
// import bpn from 'ui/assets/badanpangan.png';
// import bulog from 'ui/assets/logo_bulog.png';
import { formatDate } from 'utilities/common';

const SigninScreen = () => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const $handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      username: username,
      password: password,
    };

    await Dispatch(Auth.$signin(payload));
  };

  return (
    <div className="markdown row p-0">
      <div className="col-lg-3 signin d-none d-lg-block"></div>
      <div
        className="col-lg-9 d-flex flex-column justify-content-center align-items-center mt-md-n5"
        style={{ minHeight: '100vh' }}
      >
        <div className="mb-3 text-center">
          <h1
            className="text-center m-0 tracking-wide "
            style={{ fontSize: 40, fontWeight: 'bolder' }}
          >
            ASTRID - JPL
          </h1>
          <div className="page-pretitle" style={{ fontSize: 15 }}>
            Bansos Bagi Negeri
          </div>
        </div>
        <Card className="p-2 login">
          <div className="login-form">
            <form onSubmit={$handleSubmit}>
              <div className="form-group mb-3 ">
                <Form.InputAddon
                  icon={<FaUserAlt />}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  placeholder="Username"
                  label="Username"
                  error={StateForm?.errors?.username}
                  lg
                />
              </div>
              <div className="form-group mb-3 ">
                <Form.InputAddon
                  icon={<FaLock />}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  type="password"
                  placeholder="Password"
                  error={StateForm?.errors?.password}
                  lg
                />
              </div>
              <div className="form-footer">
                <button
                  type="submit"
                  disabled={StateForm?.loading}
                  className="btn btn-primary w-100 py-3"
                >
                  {!StateForm?.loading ? (
                    'Submit'
                  ) : (
                    <div className="spinner-border" role="status"></div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Card>

        {/* <div className="row justify-content-center align-items-center ">
          <div className="col-6">
            <img src={bpn} alt="" style={{ maxHeight: 80, maxWidth: '100%' }} />
          </div>
          <div className="col-6">
            <img
              src={bulog}
              alt=""
              style={{ maxHeight: 50, maxWidth: '100%' }}
            />
          </div>
        </div> */}

        <small className="text-muted mt-3">
          &copy;{formatDate(new Date(), 'YYYY')} PT Jasa Prima Logistik BULOG
        </small>
      </div>
    </div>
  );
};

export default SigninScreen;
