/* eslint-disable no-undef */
import { createLogger } from './logger';
import { EventEmitter } from './event';
import { getStore } from 'services/store';
import { toast } from 'react-toastify';
import { FaTimesCircle } from 'react-icons/fa';

const Logger = createLogger('REST');
const qs = require('qs');
const saveAs = require('file-saver');

class restLibrary {
  events = new EventEmitter();
  notify = true;

  constructor() {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  disableNotify() {
    this.notify = false;

    return this;
  }

  _jwtHeader() {
    const { getState } = getStore();

    let jwtHeader = {};
    const jwt = getState().Auth.token;

    if (jwt !== '') {
      jwtHeader = {
        Authorization: 'Bearer ' + jwt,
      };
    }

    return jwtHeader;
  }

  async request(url, method, body, isQuery = false) {
    if (!url) {
      toast.error('path is undefined', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        icon: <FaTimesCircle color="#E74C3C" size={50} />,
      });
      throw new Error('path is undefined');
    }

    const jwtHeader = this._jwtHeader();
    Object.assign(this.headers, jwtHeader);

    if (isQuery && body) {
      url = `${url}?${qs.stringify(body)}`;
    }

    const option = {
      method,
      headers: this.headers,
    };

    if (method !== 'GET' && body) {
      Object.assign(option, { body: JSON.stringify(body) });
    }

    this.events.emit('requesting', { url, body, option });

    return await fetch(url, option)
      .then((res) => {
        if (method !== 'GET' && this.notify) {
          if (res?.status === 200) {
            toast.success('Success', {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
            });
          }
        }

        return this.responseHandler(res);
      })
      .catch((error) => {
        this.events.emit('failure', error);

        if (method !== 'GET') {
          toast.error(error?.message, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: undefined,
            icon: <FaTimesCircle color="#E74C3C" size={50} />,
          });
        }

        if (typeof error.validations !== 'undefined') {
          throw error.validations;
        }

        if (error?.status === 401) {
          toast.error('unauthorized', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: undefined,
            icon: <FaTimesCircle color="#E74C3C" size={50} />,
          });
          this.events.emit('unauthorized', error);
        }

        throw error;
      });
  }

  responseHandler(response) {
    if (response.status === 401 || response.status === 404) {
      throw response;
    }

    const contentType = response.headers.get('content-type');

    if (contentType === 'application/octet-stream' || contentType === 'application/zip') {
      const contentDisp = response.headers.get('Content-Disposition');
      const fileName = contentDisp.split('filename=')[1];

      const ctx = response.blob();
      return ctx.then((payload) => {
        saveAs(payload, fileName);
      });
    }

    return response.json().then((payload) => {
      if (typeof payload.error !== 'undefined') {
        const error = {
          validations: payload.error,
          status: response.status,
          message: payload.message,
        };
        throw error;
      }

      // events.emit('success', payload, response);

      if (typeof payload.total !== 'undefined') {
        return payload;
      }

      return payload;
    });
  }

  GET(path, query) {
    return this.request(path, 'GET', query, true);
  }

  POST(path, body) {
    return this.request(path, 'POST', body);
  }

  PUT(path, body) {
    return this.request(path, 'PUT', body);
  }

  DELETE(path, query) {
    return this.request(path, 'DELETE', query, true);
  }
}

export const Rest = new restLibrary();

if (process.env.NODE_ENV === 'development') {
  Rest.events.on('requesting', (payload) => {
    console.group('REST: @requesting');
    console.log(`URI: ${payload.url}`);
    console.log(`METHOD: ${payload.option.method}`);

    if (payload.body) {
      console.groupCollapsed('PARAMS');
      console.log(payload.body);
      console.groupEnd();
    }

    console.groupCollapsed('HEADERS');
    console.table(payload.option.headers);
    console.groupEnd();

    console.groupEnd();
  });

  Rest.events.on('success', (payload, response) => {
    Logger.info('@success', response.url, response.status, payload);
  });

  Rest.events.on('failure', (errors) => {
    Logger.info('@failure', errors);
  });
}
