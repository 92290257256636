import React from 'react';
import { useCountUp } from 'react-countup';

const CountUpComponent = ({
  starting,
  ending,
  fs,
  surfix,
  decimals,
  className,
}) => {
  const countUpRef = React.useRef(null);
  const { start, update } = useCountUp({
    ref: countUpRef,
    start: starting,
    end: ending,
    delay: 0,
    duration: 5,
    separator: '.',
    suffix: surfix,
    decimals: decimals,
    decimal: ',',
  });

  React.useEffect(() => start(), []);
  React.useEffect(() => update(ending), [ending]);

  return (
    <div
      ref={countUpRef}
      style={{ fontSize: fs, fontWeight: 'bold' }}
      className={className}
    />
  );
};

export default CountUpComponent;
