import DashboardScreen from './index';

const routes = [
  {
    path: '/',
    component: DashboardScreen,
    usergroup: [
      '6c5128cc-e5e8-4dbe-8204-cd532a7e229c',
      '9cd330e3-e5d9-47d3-8faa-375139f4daa3',
      '4df4c118-ed27-40a4-865e-270930637e82',
      '302d772c-77db-47ea-ab5a-83b6be4d3787',
      'd2b8a77c-dbf0-4cd8-b92d-e19f15270805',
      '6ca4fba3-b0a2-4266-909e-2471987025f2',
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6',
      '004850c5-ebee-2f68-5cd9-4eb2405cfb5e',
      '8465c143-a4e3-478e-b019-cb4e1035e886'
    ],
  },
];

export default routes;
