import React from 'react';
// import { Link } from 'react-router-dom';
// import { Button, Modal, ButtonGroup, DropdownButton } from 'react-bootstrap';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DatePicker } from 'ui/components';
import * as Fi from 'react-icons/fi';
import { useSelector } from 'react-redux';

import { formatDate } from 'utilities/common';

const initialState = {
  showPrint: false,
  schedule_at: {
    default: new Date(),
    formatted: formatDate(new Date(), 'DD-MM-YYYY'),
  },
  withQr: false,
  errors: ''
};

const Action = ({ data }) => {
  const Session = useSelector((state) => state?.Auth?.session);
  const [{ showPrint, schedule_at, withQr, errors }, setState] =
    React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      showPrint: false,
      schedule_at: '',
    }));
  };

  const $handlePrint = () => {
    let url = `/print/rtt/${data?.id}?date=${schedule_at?.formatted}`
    if (withQr) {
      url = `/print/rtt/${data?.id}?date=${schedule_at?.formatted}&with_qr=${withQr}`
    }

    window.open(url, '_blank')
    $handleClose();
  }

  if (Session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' || Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' || Session?.usergroup?.id === '302d772c-77db-47ea-ab5a-83b6be4d3787') return null

  return (
    <div className="d-flex gap-2">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-top`}>Print BAST & SPTJM & BNBA</Tooltip>}
      >
        <button
          className='btn btn-danger px-2'
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              showPrint: true,
            }))
          }
        >
          <Fi.FiPrinter size="17" />
        </button>
      </OverlayTrigger>

      {/* modal print */}
      <Modal
        show={showPrint}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className='row'>
            <div className="mb-3">
              <label className="form-label fs-8">Tanggal</label>
              <div
                className='border border-danger rounded'
              >
                <DatePicker
                  type="normal"
                  value={schedule_at?.default || null}
                  format="dd/MM/yyyy"
                  onChange={(date) =>
                    setState((prevState) => ({
                      ...prevState,
                      schedule_at: {
                        default: date,
                        formatted: formatDate(date, 'DD-MM-YYYY'),
                      },
                    }))
                  }
                  isShowTrigger
                />
              </div>
              {errors && (
                <div
                  className="invalid-feedback"
                  style={{ display: 'block' }}
                >
                  {errors}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="primary"
                className='w-100'
                onClick={$handlePrint}
                disabled={!schedule_at?.default}
              >
                Print
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

    </div>
  )

  // return (
  //   <>
  //     <DropdownButton
  //       as={ButtonGroup}
  //       variant="transparent"
  //       size="md"
  //       className="no-carret tbl-action"
  //       title={<Fi.FiMoreVertical size={13} color="gray" />}
  //     >
  //       <button
  //         className="dropdown-item d-flex align-items-center"
  //         onClick={() =>
  //           setState((prevState) => ({
  //             ...prevState,
  //             showPrint: true,
  //           }))
  //         }
  //       >
  //         <Fi.FiPrinter size={15} color="#202020" className="me-2" />
  //         Print BAST & SPTJM & BNBA
  //       </button>

  //       <Link
  //         className="dropdown-item d-flex align-items-center"
  //         role="button"
  //         to={`/kpm/${data?.id}`}
  //       >
  //         <Fi.FiClipboard size={15} color="#202020" className="me-2" />
  //         Detail PBP
  //       </Link>

  //       <button
  //         className="dropdown-item d-flex align-items-center"
  //         onClick={() =>
  //           setState((prevState) => ({
  //             ...prevState,
  //             showPrint: true,
  //             withQr: true
  //           }))
  //         }
  //       >
  //         <Fi.FiPrinter size={15} color="#202020" className="me-2" />
  //         Print BAST & SPTJM & QR BNBA
  //       </button>
  //     </DropdownButton>

  //     {/* modal print */}
  //     <Modal
  //       show={showPrint}
  //       onHide={$handleClose}
  //       backdrop="static"
  //       centered
  //       className="text-center"
  //       size="small"
  //     >
  //       <Modal.Body>
  //         <div className="btn-close" onClick={$handleClose} />
  //         <div className='row'>
  //           <div className="mb-3">
  //             <label className="form-label fs-8">Tanggal</label>
  //             <div
  //               className='border border-danger rounded'
  //             >
  //               <DatePicker
  //                 type="normal"
  //                 value={schedule_at?.default || null}
  //                 format="dd/MM/yyyy"
  //                 onChange={(date) =>
  //                   setState((prevState) => ({
  //                     ...prevState,
  //                     schedule_at: {
  //                       default: date,
  //                       formatted: formatDate(date, 'DD-MM-YYYY'),
  //                     },
  //                   }))
  //                 }
  //                 isShowTrigger
  //               />
  //             </div>
  //             {errors && (
  //               <div
  //                 className="invalid-feedback"
  //                 style={{ display: 'block' }}
  //               >
  //                 {errors}
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <div className="row w-100">
  //           <div className="col">
  //             <Button variant="white" className="w-100" onClick={$handleClose}>
  //               Batal
  //             </Button>
  //           </div>
  //           <div className="col">
  //             <Button
  //               variant="primary"
  //               className='w-100'
  //               onClick={$handlePrint}
  //               disabled={!schedule_at?.default}
  //             >
  //               Print
  //             </Button>
  //           </div>
  //         </div>
  //       </Modal.Footer>
  //     </Modal>
  //   </>
  // );
};

export default Action;
