import config from 'services/table/const';

let table = { ...config };
table.url = '/report/pod';
table.columns = {
  kanwil_id: {
    title: 'Kanwil',
    sortable: false,
    width: 80,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {`${row?.village?.district?.regency?.kanwil?.name}`}
          </div>
        </div>
      );
    },
  },
  regency_id: {
    title: 'Kota / Kab',
    sortable: false,
    width: 80,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {` ${row?.village?.district?.regency?.name}`}
          </div>
        </div>
      );
    },
  },
  district_id: {
    title: 'Kecamatan',
    sortable: false,
    width: 80,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {`${row?.village?.district?.name}`}
          </div>
        </div>
      );
    },
  },
  village_id: {
    title: 'Kelurahan',
    sortable: false,
    width: 80,
    class: 'text-start',
    classHeader: 'text-start',
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold text-uppercase">
            {`${row?.village?.name}`}
          </div>
        </div>
      );
    },
  },

  is_upload: {
    title: 'Sudah Upload',
    sortable: true,
    width: 60,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis fs-8 fw-semibold">
            {row?.is_upload ? 'Ya': 'Belum'}
          </div>
        </div>
      );
    },
  },
};
export default table;
