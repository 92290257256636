import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Region, Table } from 'services/action';

const Action = ({ data }) => {
  const Dispatch = useDispatch();
  const StateTable = useSelector((state) => state?.Table?.data['region_village']);

  const $handleToogle = async (data) => {
    const payload = {
      village_id: data?.id
    }
    await Dispatch(Region.$sortingDusun(payload));
    Dispatch(Table.$reload('region_village', StateTable));
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <label className="mb-1 form-switch text-center">
        <input
          defaultChecked={data?.sorting_dusun}
          className="form-check-input"
          type="checkbox"
          onChange={() => $handleToogle(data)}
        />
      </label>
      <div className="text-center page-pretitle">
        {data?.sorting_dusun === true ? 'Aktif' : 'Tidak Aktif'}
      </div>
    </div>
  );
};

export default Action;