import MonitoringScreen from './index';

const routes = [
  {
    path: '/monitoring',
    component: MonitoringScreen,
    usergroup: [
      '00e22f61-b7f4-4d03-806e-98b987c8c8e6',
      '8465c143-a4e3-478e-b019-cb4e1035e886',
      '004850c5-ebee-2f68-5cd9-4eb2405cfb5e',
    ],
  },
];

export default routes;
