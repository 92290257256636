import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { POD } from 'services/action';

import { Select } from 'ui/components';

const imgTypeOptions = [
  { value: 'bast', label: 'BAST' },
  { value: 'sptjm', label: 'SPTJM' },
  { value: 'rtt', label: 'BNBA / DTT' },
  { value: 'receive', label: 'STT / SPM' },
];

const initialState = {
  lineSelected: null,
  typeSelected: null,
};

const UpdatePOD = ({ data, img, type, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state.Form);
  const [
    {
      typeSelected,
      lineSelected
    },
    setState,
  ] = React.useState(initialState);

  const $updateImage = async () => {
    const payload = {
      action: 'type',
      type: typeSelected?.value,
    };

    if (lineSelected) {
      payload.delivery_id = lineSelected?.line?.delivery_id
      payload.line_id = lineSelected?.line_id
    }

    const result = await Dispatch(POD.$update(img?.id, payload));
    if (result?.message === 'success') {
      onClose();
    }
  };

  const $handleTypeSelected = async (e) => {
    setState((prevState) => ({ ...prevState, typeSelected: e}));
  }

  React.useEffect(() => {
    if (!data) return

    let line = null;
    if (data?.line_id) {
      line = data
    }

    const ts = type === 'bast'
      ? imgTypeOptions[0]
      : type === 'sptjm'
      ? imgTypeOptions[1]
      : type === 'rtt'
      ? imgTypeOptions[2]
      : type === 'receive'
      ? imgTypeOptions[3]
      : null

    setState((prevState) => ({ ...prevState, typeSelected: ts, lineSelected: line}));
  }, [data])

  return (
    <>
      <Modal.Body>
        <div className="row">
          <div className="mb-3">
            <label className='form-label required'>
              Pilih Tipe
            </label>
            <Select
              placeholder=""
              label="Pilih Tipe"
              classNamePrefix="select"
              isClearable
              value={typeSelected}
              initialValue={typeSelected}
              options={imgTypeOptions}
              onChange={$handleTypeSelected}
            />
            {StateForm?.errors?.type ?
              <div className="invalid-feedback d-block">
                {StateForm?.errors?.type}
              </div>
            : null}
          </div>

          {typeSelected?.value === 'receive' && (
            <div className="mb-3">
              <Select.Remote
                required
                label="Pilih Kanwil"
                placeholder=""
                url="/recon"
                initialValue={lineSelected?.id}
                params={{
                  bansos_id: data?.bansos_id,
                  village_id: data?.village_id,
                }}
                row={(d) => {
                  return { value: d, label: d?.line?.manifest };
                }}
                onSelected={(c) => {
                  setState((prevState) => ({
                    ...prevState,
                    lineSelected: c,
                  }));
                }}
                errors={StateForm?.errors?.line_id}
                oneSelected
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button variant="white" className="w-100" onClick={onClose}>
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              variant="secondary"
              className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
              onClick={$updateImage}
              disabled={StateForm?.loading}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

export default UpdatePOD;
