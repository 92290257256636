import React from 'react';
import { Button, ButtonGroup, DropdownButton, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Rtt, Table } from 'services/action';
import { ActionRTT } from '../detail/rtt';

const initialState = {
  isDelete: false,
  isStatus: false,
};

const Action = ({ data }) => {
  const Dispatch = useDispatch();
  const StateTable = useSelector((state) => state?.Table?.data['rtt']);
  const StateForm = useSelector((state) => state.Form);
  const Session = useSelector((state) => state?.Auth?.session);
  const [{ isStatus, isDelete }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({ ...prevState, isDelete: false, isProcess: false, isComplete: false }));
  };

  const $handleDelete = async () => {
    const del = await Dispatch(Rtt.$delete(data?.id));
    if (del?.message === 'success') {
      $handleClose();
      Dispatch(Table.$reload('rtt', StateTable));
    }
  };

  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        variant="transparent"
        size="md"
        className="no-carret tbl-action"
        title={<Fi.FiMoreVertical size={13} color="gray" />}
      >
        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={`/rtt/${data?.id}`}
        >
          <Fi.FiClipboard size={15} color="#202020" className="me-2" />
          Detil
        </Link>
        {Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && (
          <>
            <Link
              className="dropdown-item d-flex align-items-center"
              role="button"
              to={`/print/recap/${data?.id}`}
              target="_blank"
            >
              <Fi.FiPrinter size={15} color="#202020" className="me-2" />
              Print Rekap BAST
            </Link>
            {(data?.document_status === 'draft' || data?.document_status === 'process') && (
              <>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  role="button"
                  to={`/rtt/${data?.id}/update`}
                >
                  <Fi.FiEdit size={15} color="#202020" className="me-2" />
                  Perbaharui
                </Link>

                <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, isDelete: true }))
                  }
                >
                  <Fi.FiTrash2 size={15} className="me-2" />
                  Hapus
                </button>
              </>
            )}
            {data?.document_status === 'draft' && (
              <button
                className="dropdown-item d-flex align-items-center"
                onClick={() =>
                  setState((prevState) => ({ ...prevState, isStatus: true }))
                }
              >
                <Fi.FiArrowRightCircle size={15} className="me-2" />
                Proses / Maju RTT
              </button>
            )}
            {data?.document_status === 'process' && (
              <button
                className="dropdown-item d-flex align-items-center"
                onClick={() =>
                  setState((prevState) => ({ ...prevState, isStatus: true }))
                }
              >
                <Fi.FiCheckCircle size={15} className="me-2" />
                Setujui RTT
              </button>
            )}
          </>
        )}

      </DropdownButton>

      {/* modal delete */}
      <Modal
        show={isDelete}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang sudah dihapus tidak bisa dipulihkan
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$handleDelete}
                disabled={StateForm?.loading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* modal status */}
      <Modal
        show={isStatus}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="xl"
      >
        <ActionRTT
          data={data}
          onClose={() => {
            $handleClose();
            Dispatch(Table.$reload('rtt', StateTable));
          }}
        />
      </Modal>
    </>
  );
};

export default Action;
