import { $done, $processing } from 'services/activity/action';
import * as ActionCreator from 'utilities/action.creator';
import { Rest } from 'utilities/rest';

export const MODULE = 'Bansos';

const API_URL = `${process.env.REACT_APP_SERVICE_SYSTEM_URL}`;

export const $reset = ActionCreator.Default(MODULE, 'reset', () =>
  $reset.action()
);

export const $get = ActionCreator.Async(MODULE, 'Get', (payload) => {
  return async (dispatch) => {
    dispatch($processing($get.OPERATION));

    return await Rest.GET(`${API_URL}/bansos`, payload)
      .then((response) => {
        dispatch($get.success({ response: response?.data }));
        return response;
      })
      .catch((error) => {
        return false;
      })
      .finally(() => dispatch($done($get.OPERATION)));
  };
});

export const $area = ActionCreator.Async(
  MODULE,
  'Area',
  (bansos_id, payload) => {
    return async (dispatch) => {
      dispatch($processing($area.OPERATION));

      return await Rest.GET(`${API_URL}/bansos/${bansos_id}/area`, payload)
        .then((response) => {
          dispatch($area.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($area.OPERATION)));
    };
  }
);

export const $kpm = ActionCreator.Async(
  MODULE,
  'KPM',
  (bansos_id, village_id, payload) => {
    return async (dispatch) => {
      dispatch($processing($kpm.OPERATION));

      return await Rest.GET(`${API_URL}/bansos/${bansos_id}/kpm/${village_id}`, payload)
        .then((response) => {
          dispatch($kpm.success({ response: response?.data }));
          return response;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => dispatch($done($kpm.OPERATION)));
    };
  }
);
