import React from "react";

const RightBar = ({ className, children }) => {
  return (
    <div
      style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 25 }}
      className={
        className
          ? `${className} bg-white right-sidebar`
          : `col-lg-2 bg-white right-sidebar`
      }
    >
      {children}
    </div>
  );
};
export default RightBar;
