import React from 'react';
import cn from 'classnames';
import { FaCheckSquare } from 'react-icons/fa';

const CustomCheckbox = ({ label, value, onChange, required, error }) => {
  return (
    <div>
      <span
        className={cn(
          'd-flex align-items-center cursor-pointer form-label',
          required ? 'required' : '',
          error ? 'text-danger is-invalid' : ''
        )}
        onClick={onChange}
      >
        <div
          className={cn('me-2', error ? 'border border-danger rounded' : '')}
        >
          <FaCheckSquare
            color={value ? '#2f83ba' : '#bbb'}
            size={18}
            style={{ marginTop: -3 }}
          />
        </div>
        <div>{label}</div>
      </span>
      {error ? <div className="invalid-feedback">{error}</div> : null}
    </div>
  );
};

export default CustomCheckbox;
