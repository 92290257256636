import React from 'react';
import { BiAddToQueue } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';

import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';

import { Modal } from 'react-bootstrap';
import { Table } from 'services/action';
import { Page } from 'ui/components';
import { FormUser } from '../../_shared';
import tableConfig from './table.config';

const initialState = {
  privilege: null,
  isShow: false,
};

const UserScreen = () => {
  const Dispatch = useDispatch();
  const UserTable = useTable('users', tableConfig);
  const StateTable = useSelector((state) => state?.Table?.data['users']);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );

  const [{ isShow }, setState] = React.useState(initialState);

  return (
    <Page className="col-12 col-md-8 bordered">
      <Page.Header pretitle="Daftar" title="Pengguna" isLoading={StateLoading}>
        <button
          onClick={() =>
            setState((prevState) => ({ ...prevState, isShow: true }))
          }
          className="btn btn-primary py-1 ms-1"
        >
          <BiAddToQueue color="white" size="14" />
          <span className="d-none d-md-block ms-2">Tambah Pengguna</span>
        </button>
      </Page.Header>

      <Page.Navigation toolbar>
        <UserTable.Tools isLoading={StateLoading} downloadable />
      </Page.Navigation>
      <Page.Body className="row m-0 p-0">
        <div className="col-md-12 m-0 p-0">
          {StateLoading ? (
            <SkeletonTable headerCount={6} bodyCount={10} />
          ) : (
            <UserTable.Render />
          )}
        </div>

        <Modal
          show={isShow}
          onHide={() =>
            setState((prevState) => ({ ...prevState, isShow: false }))
          }
          backdrop="static"
          centered
          size="xl"
          enforceFocus={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Tambah Pengguna</Modal.Title>
          </Modal.Header>
          <FormUser
            onClose={() => {
              setState((prevState) => ({ ...prevState, isShow: false }));
            }}
            onSuccess={() => {
              setState((prevState) => ({ ...prevState, isShow: false }));
              Dispatch(Table.$reload('users', StateTable));
            }}
          />
        </Modal>
      </Page.Body>

      <Page.Footer>
        {StateLoading ? <SkeletonPageFooter /> : <UserTable.Pagination />}
      </Page.Footer>
    </Page>
  );
};

export default UserScreen;
