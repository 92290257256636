import React, { useState, useEffect, useRef } from 'react';
import { isValid } from 'utilities/common';
import * as Fi from 'react-icons/fi';

const TimeInput = ({
  value,
  disabled,
  mountFocus,
  onChange,
  type,
  onFocusHandler,
  placeholder,
  error,
  name,
  onBlurHandler,
  required,
  horizontal,
  mb0,
  label,
  hint,
}) => {
  const [time, setTime] = useState(value || '');

  const _input = useRef(null);

  useEffect(() => {
    if (!disabled && mountFocus) {
      setTimeout(() => {
        _input.current.focus();
      }, 0);
    }
  });

  let lastVal = '';

  const onChangeHandler = (val) => {
    if (val === time) {
      return;
    }

    if (isValid(val)) {
      if (val.length === 2 && lastVal.length !== 3 && val.indexOf(':') === -1) {
        val = val + ':';
      }

      if (val.length === 2 && lastVal.length === 3) {
        val = val.slice(0, 1);
      }

      if (val.length > 5) {
        return false;
      }

      lastVal = val;

      setTime(val);

      if (val.length === 5) {
        onChange(val);
      }
    }
  };

  const getCurrentTime = () => {
    let d = new Date(); // for now
    let hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
    let min = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

    let c = `${hour}:${min}`;
    onChangeHandler(c);
  };

  const getType = () => {
    if (type) {
      return type;
    }
    return 'tel';
  };

  return (
    <div className={horizontal ? 'row mb-3' : mb0 ? 'mb-0' : 'mb-3'}>
      {label ? (
        <label
          className={
            required
              ? 'form-label fs-8 required'
              : horizontal
              ? 'form-label fs-8 col-3 col-form-label text-ellipsis'
              : 'form-label fs-8'
          }
        >
          {label}
        </label>
      ) : null}
      <div className={horizontal ? 'col' : null}>
        <div className="input-icon d-flex align-items-center">
          <input
            name={name ? name : undefined}
            type={getType()}
            className={error ? 'form-control is-invalid' : 'form-control'}
            disabled={disabled}
            placeholder={placeholder}
            value={time}
            onChange={(e) => onChangeHandler(e.target.value)}
            onFocus={onFocusHandler ? (e) => onFocusHandler(e) : undefined}
            onBlur={onBlurHandler ? (e) => onBlurHandler(e) : undefined}
            ref={_input}
          />
          <span
            className="position-absolute end-0  mt-n1"
            style={{
              marginRight: error ? 20 : 0,
              cursor: 'pointer',
            }}
          >
            <span
              className="input-group-link pe-2"
              onClick={() => !disabled && getCurrentTime()}
            >
              <Fi.FiClock size={15} color="#656d77" />
            </span>
          </span>
        </div>
        {hint ? <small className="form-hint">{hint}</small> : null}
        {error ? <div className="invalid-feedback">{error}</div> : null}
      </div>
    </div>
  );
};

TimeInput.defaultProps = {
  placeholder: '00:00',
};

export default TimeInput;
