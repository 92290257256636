import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Page } from 'ui/components';
import { SkeletonPageFooter, SkeletonTable } from 'ui/components/skeleton';
import useTable from 'ui/components/table';
import tableConfig from './component/table/config';
import Filter from './component/table/filter';

const initialState = {
  displayFilter: false,
  count: [],
};
const DeliveryTroubleScreen = () => {
  const DLTable = useTable('delivery_trouble', tableConfig);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Table.Request']
  );

  const [{ displayFilter }, setState] = React.useState(initialState);

  React.useEffect(() => {}, []);
  return (
    <Page className="fixed ">
      <Page.Header title="Daftar SPM" isLoading={StateLoading} />
      <Page.Navigation toolbar>
        <DLTable.Tools isLoading={StateLoading}>
          {/* {StateLoading ? (
            <div className="ms-2">
              <Skeletons height="35px" width="50px" />
            </div>
          ) : (
            <Button
              variant={displayFilter ? 'danger' : 'dark'}
              className="ms-2 position-relative"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  displayFilter: !displayFilter,
                }))
              }
              style={{ height: 35 }}
            >
              <Fi.FiFilter size={20} color="white" />
              {count?.length > 0 ? (
                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger">
                  {count?.length}
                </span>
              ) : null}
            </Button>
          )} */}
        </DLTable.Tools>
      </Page.Navigation>
      <Page.Body className="row m-0 p-0">
        <div
          className={cn(
            displayFilter ? 'col-md-9  m-0 p-0' : 'col-md-12   m-0 p-0'
          )}
        >
          {StateLoading ? (
            <SkeletonTable headerCount={9} bodyCount={9} />
          ) : (
            <DLTable.Render />
          )}
        </div>

        <Filter
          table={DLTable}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              displayFilter: false,
            }))
          }
          show={displayFilter}
          setCount={(x) =>
            setState((prevState) => ({
              ...prevState,
              count: x,
            }))
          }
        />
      </Page.Body>
      <Page.Footer>
        {StateLoading ? <SkeletonPageFooter /> : <DLTable.Pagination />}
      </Page.Footer>
    </Page>
  );
};

export default DeliveryTroubleScreen;
