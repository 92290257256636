import cn from 'classnames';
import React from 'react';
import * as Fi from 'react-icons/fi';
import { DateRangePicker, Select } from 'ui/components';

import { formatDate } from 'utilities/common';

const typeOptions = [
  { value: 'pickup', label: 'First Mile' },
  { value: 'console', label: 'Mid Mile' },
  { value: 'last_mile', label: 'Last Mile' },
  { value: 'return', label: 'Return' },
  { value: 'forwarder', label: 'Forwarder' },
];

const statusOptions = [
  { value: 'new', label: 'Draft' },
  { value: 'process', label: 'Process' },
  { value: 'completed', label: 'Completed' },
];

const modaOptions = [
  { value: 'land_freight', label: 'Darat' },
  { value: 'sea_freight', label: 'Laut' },
  { value: 'air_freight', label: 'Udara' },
];

const initialState = {
  status: null,
  schedule: null,
  type: null,
  moda_type: null,
};

const Filter = ({ show, onClose, table, setCount }) => {
  const [{ status, schedule, type, moda_type }, setState] =
    React.useState(initialState);

  const $onLoad = async () => {
    let date = [];

    if (table.State?.filter?.start_at && table.State?.filter?.end_at) {
      const x = table.State?.filter?.start_at;

      date.push(new Date(x));

      const y = table.State?.filter?.end_at;
      date.push(new Date(y));
      setState((prevState) => ({
        ...prevState,
        schedule: date,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        schedule: null,
      }));
    }

    setState((prevState) => ({
      ...prevState,
      type:
        table?.State?.filter?.type === 'pickup'
          ? typeOptions[0]
          : table?.State?.filter?.type === 'console'
          ? typeOptions[1]
          : table?.State?.filter?.type === 'last_mile'
          ? typeOptions[2]
          : table?.State?.filter?.type === 'return'
          ? typeOptions[3]
          : null,
      status:
        table?.State?.filter?.status === 'new'
          ? statusOptions[0]
          : table?.State?.filter?.status === 'process'
          ? statusOptions[1]
          : table?.State?.filter?.status === 'completed'
          ? statusOptions[2]
          : null,
      moda_type:
        table?.State?.filter?.moda_type === 'land_freight'
          ? modaOptions[0]
          : table?.State?.filter?.moda_type === 'sea_freight'
          ? modaOptions[1]
          : table?.State?.filter?.moda_type === 'air_freight'
          ? modaOptions[2]
          : null,
    }));
  };

  const handleFilter = async (e) => {
    if (table?.State?.filter) {
      let x = [];
      Object.keys(table?.State?.filter).forEach((k) => {
        if (table?.State?.filter[k] && k !== 'task' && k !== 'start_at') {
          x.push(k);
        }
      });
      setCount(x);
    }
  };

  const handleStatus = (e) => {
    setState((prevState) => ({
      ...prevState,
      status: e,
    }));
    table.filter('status', e?.value);
    handleFilter();
  };

  const handleType = (e) => {
    setState((prevState) => ({
      ...prevState,
      type: e,
    }));
    table.filter('type', e?.value);
    handleFilter();
  };

  const handleModa = (e) => {
    setState((prevState) => ({
      ...prevState,
      moda_type: e,
    }));
    table.filter('moda_type', e?.value);
    handleFilter();
  };

  React.useEffect(() => {
    $onLoad();
  }, []);

  React.useEffect(() => {
    handleFilter();
  }, []);

  return (
    <>
      <div
        className={cn(
          show
            ? 'col-md-3 d-none d-md-block py-3 px-3 m-0 border-start'
            : 'd-none'
        )}
      >
        <div className="mb-3 border-bottom">
          <div className="d-flex align-items-center justify-content-between">
            <div className="page-pretitle fs-7 text-dark">Filter</div>
            <div className="mt-n1">
              <button className="btn btn-action-close " onClick={onClose}>
                <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
              </button>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-dark mb-2">Tipe</div>
          <Select
            placeholder=""
            classNamePrefix="select"
            isClearable
            options={typeOptions}
            value={type}
            onChange={handleType}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-dark mb-2">Moda</div>
          <Select
            placeholder=""
            classNamePrefix="select"
            isClearable
            options={modaOptions}
            value={moda_type}
            onChange={handleModa}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-dark mb-2">Hub Asal</div>
          <Select.Remote
            placeholder=""
            url="/warehouse"
            params={{ is_active: 1 }}
            row={(d) => {
              return { value: d?.id, label: d?.name };
            }}
            initialValue={table.State?.filter?.origin_id}
            onSelected={(c) => {
              table.filter('origin_id', c?.id);
              handleFilter();
            }}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-dark mb-2">Hub Tujuan</div>
          <Select.Remote
            placeholder=""
            url="/warehouse"
            params={{ is_active: 1 }}
            row={(d) => {
              return { value: d?.id, label: d?.name };
            }}
            initialValue={table.State?.filter?.destination_id}
            onSelected={(c) => {
              table.filter('destination_id', c?.id);
              handleFilter();
            }}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-dark mb-2">Status</div>
          <Select
            placeholder=""
            classNamePrefix="select"
            isClearable
            options={statusOptions}
            value={status}
            onChange={handleStatus}
          />
        </div>

        <div className="mb-3">
          <div className="page-pretitle text-dark mb-2">Jadwal Pengiriman</div>
          <DateRangePicker
            value={schedule}
            placeholder=""
            onChange={(date) => {
              setState((prevState) => ({
                ...prevState,
                schedule: date,
              }));
              let fm = [];
              date?.forEach((data) => fm?.push(formatDate(data, 'YYYY-MM-DD')));
              if (fm?.length > 0) {
                table.filter('start_at', fm[0]);
                table.filter('end_at', fm[1]);
                handleFilter();
              } else {
                table.filter('start_at', null);
                table.filter('end_at', null);
                handleFilter();
              }
            }}
          />
        </div>
      </div>

      <>
        <div
          className={cn('filter-overlay', show ? 'd-block d-md-none' : '')}
          onClick={onClose}
        />
        <div
          className={cn(
            'filter-modal d-block d-md-none py-3',
            show ? 'filter-show' : ''
          )}
        >
          <div className="mb-3 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="page-pretitle fs-7 text-dark">Filter</div>
              <div className="mt-n1">
                <button className="btn btn-action-close " onClick={onClose}>
                  <Fi.FiX color="hsl(0deg 0% 81%)" size={18} />
                </button>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-dark mb-2">Tipe</div>
            <Select
              placeholder=""
              classNamePrefix="select"
              isClearable
              options={typeOptions}
              value={type}
              onChange={handleType}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-dark mb-2">Moda</div>
            <Select
              placeholder=""
              classNamePrefix="select"
              isClearable
              options={modaOptions}
              value={moda_type}
              onChange={handleModa}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-dark mb-2">Hub Asal</div>
            <Select.Remote
              placeholder=""
              url="/warehouse"
              params={{ is_active: 1 }}
              row={(d) => {
                return { value: d?.id, label: d?.name };
              }}
              initialValue={table.State?.filter?.origin_id}
              onSelected={(c) => {
                table.filter('origin_id', c?.id);
                handleFilter();
              }}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-dark mb-2">Hub Tujuan</div>
            <Select.Remote
              placeholder=""
              url="/warehouse"
              params={{ is_active: 1 }}
              row={(d) => {
                return { value: d?.id, label: d?.name };
              }}
              initialValue={table.State?.filter?.destination_id}
              onSelected={(c) => {
                table.filter('destination_id', c?.id);
                handleFilter();
              }}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-dark mb-2">Status</div>
            <Select
              placeholder=""
              classNamePrefix="select"
              isClearable
              options={statusOptions}
              value={status}
              onChange={handleStatus}
            />
          </div>

          <div className="mb-3">
            <div className="page-pretitle text-dark mb-2">
              Jadwal Pengiriman
            </div>
            <DateRangePicker
              value={schedule}
              placeholder=""
              onChange={(date) => {
                setState((prevState) => ({
                  ...prevState,
                  schedule: date,
                }));
                let fm = [];
                date?.forEach((data) =>
                  fm?.push(formatDate(data, 'YYYY-MM-DD'))
                );
                if (fm?.length > 0) {
                  table.filter('start_at', fm[0]);
                  table.filter('end_at', fm[1]);
                  handleFilter();
                } else {
                  table.filter('start_at', null);
                  table.filter('end_at', null);
                  handleFilter();
                }
              }}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default Filter;
