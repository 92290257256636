import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TableDistrict from './table.district';
import TableRegencyRecon from './table.regency.recon';
import TableRegency from './table.regency';
import TableVillage from './table.village';

const Table = ({
  keys,
  kanwil,
  regency,
  district,
  onRegencySelected,
  onDistrictSelected,
}) => {
  const History = useHistory();
  const Session = useSelector((state) => state.Auth?.session);
  const [display, setDisplay] = React.useState('');

  React.useEffect(() => {
    setDisplay(keys);
  }, [keys]);

  if (display === 'district') {
    return <TableDistrict keys={regency} onSelected={onDistrictSelected} />;
  }

  if (display === 'village') {
    return (
      <TableVillage
        keys={district}
        onSelected={(e) =>
          Session?.usergroup?.id === '6c5128cc-e5e8-4dbe-8204-cd532a7e229c' ||
          Session?.usergroup?.id === '4df4c118-ed27-40a4-865e-270930637e82' ||
          Session?.usergroup?.id === 'd2b8a77c-dbf0-4cd8-b92d-e19f15270805' ||
          Session?.usergroup?.id === '6ca4fba3-b0a2-4266-909e-2471987025f2'
            ? History.push(`/recon/${e?.id}`)
            : null
        }
      />
    );
  }

  if (Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '004850c5-ebee-2f68-5cd9-4eb2405cfb5e') {
    return <TableRegencyRecon keys={kanwil} onSelected={onRegencySelected}/>;
  }

  return <TableRegency keys={kanwil} onSelected={onRegencySelected} />;
};

export default Table;
