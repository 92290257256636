import Datagrid from 'ui/components/data/grid';
import { formatDate } from 'utilities/common';

export const SectionInfo = ({ data, loading }) => {
  return (
    <div className="p-0 p-md-3 mb-3 mb-md-0">
      <div className="card rounded-2 bg-white">
        <div className="card-header-gray p-3 border-bottom">
          <h3 className="card-title dx-card-title m-0 p-0">Informsi RTT</h3>
        </div>
        <div className="card-body p-3 pt-3">
          <div className="row">
            <div className="col-4">
              <div className="datagrid">
                <Datagrid
                  loading={loading}
                  title="Kode RTT"
                  value={data?.code}
                />
                <Datagrid
                  loading={loading}
                  title="Bansos"
                  value={data?.bansos?.name}
                />
                <Datagrid
                  loading={loading}
                  title="Tanggal RTT"
                  value={formatDate(data?.dispatched_at, 'DD/MM/YYYY')}
                />
                <Datagrid
                  loading={loading}
                  title="Dibuat Oleh"
                  value={data?.dispatched_by}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="datagrid">
                <Datagrid
                  loading={loading}
                  title="Total PBP"
                  value={`${data?.total_kpm}`}
                />
                <Datagrid
                  loading={loading}
                  title="Kanwil"
                  value={`${data?.region?.kanwil_name}`}
                />
                <Datagrid
                  loading={loading}
                  title="Kota / Kabupaten"
                  value={`${data?.region?.regency_name}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
