import config from 'services/table/const';
import { currencyFormat } from 'utilities/common';

let table = { ...config };
table.url = '/report/document/regency';
table.columns = {
  code: {
    title: 'Kota/Kabupaten',
    width: 300,
    component: (row) => {
      return (
        <div className="table-col">
          <div className="text-ellipsis">{row?.regency?.name}</div>
          <small className="info text-ellipsis">
            {row?.regency?.kanwil?.name}
          </small>
        </div>
      );
    },
    url: (row) => {
      return `/report/document/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },

  total_document: {
    title: 'Dokumen Keluar',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return <div className="table-col">{currencyFormat(row?.total_document)}</div>;
    },
    url: (row) => {
      return `/report/document/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },

  total_approved: {
    title: 'Dokumen Diterima',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_approved)}</span>
        </div>
      );
    },
    url: (row) => {
      return `/report/document/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },

  total_uploaded: {
    title: 'Dokumen POD',
    class: 'text-end',
    classHeader: 'text-end',
    component: (row) => {
      return (
        <div className="table-col">
          <span className='fw-semibold'>{currencyFormat(row?.total_uploaded)}</span>
        </div>
      );
    },
    url: (row) => {
      return `/report/document/district?kanwil_id=${row?.regency?.kanwil?.id}&regency_id=${row?.regency_id}`;
    },
  },
};

export default table;
