import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';

import { Rtt, Uploader, POD } from 'services/action';

import Resizer from "react-image-file-resizer";
import pdf from 'ui/assets/pdf.png';

const initialState = {
  imgDraft: null,
  dragActive: false,
  docs: [],
  errors: ''
};

export const ActionRTT = ({ data, onClose }) => {
  const Dispatch = useDispatch();
  const StateForm = useSelector((state) => state?.Form);

  const [{ imgDraft, dragActive, docs, errors }, setState] = React.useState(initialState);
  const inputRef = React.useRef(null);

  const $upload = async (e) => {
    const payload = {
      file: e?.file,
      document: {
        bansos_id: data?.bansos_id,
        regency_id: data?.region?.regency_id,
        type: 'recap_rtt',
      },
    };

    const req = await Dispatch(Uploader.$upload(payload));
    if (req?.message === 'success') {
      let x = {
        data: req?.data,
        filename: e?.fileName,
      };
      setState((prevState) => ({ ...prevState, docs: [...prevState.docs, x] }));
    }
  };

  const $handleUpdateRTT = async() => {
    if (data?.document_status === 'process' && docs?.length === 0) {
      setState((prevState) => ({ ...prevState, errors: 'Gambar harus diisi.' }))

      return
    }

    let status = 'process'
    if (data?.document_status === 'process') {
      status = 'completed'
    }

    const result = await Dispatch(Rtt.$updateStatus(data?.id, {status}));
    if (result?.message === 'success') {
      if (docs?.length > 0) {
        $saveImage()
      } else {
        onClose();
      }
    }
  }

  const $saveImage = async() => {
    const payload = {
      document: {
        rtt_id: data?.id,
        regency_id: data?.region?.regency_id,
        bansos_id: data?.bansos_id,
        type: 'recap_rtt',
      },
    };

    if (docs) {
      const str = [];

      docs?.forEach((doc) => str?.push(doc?.data));

      payload.images = str;
    }

    const req = await Dispatch(POD.$upload(payload));
    if (req?.message === 'success') {
      onClose();
    }
  }


  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setState((prevState) => ({ ...prevState, dragActive: true }));
    } else if (e.type === 'dragleave') {
      setState((prevState) => ({ ...prevState, dragActive: false }));
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({ ...prevState, dragActive: false }));
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files) {
      // handleFiles(e.target.files);
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleFiles = async (e) => {
    Array.from(e).forEach(async function (item) {
      const fileName = item?.name;
      let fileReader = new FileReader();
      fileReader?.readAsDataURL(item);
      fileReader.onload = async function () {
        let file = fileReader?.result;
        if (item?.type !== 'application/pdf') {
          setState((prevState) => ({ ...prevState, imgDraft: file }));
          Resizer.imageFileResizer(
            item,
            2000,
            2000,
            "PNG",
            100,
            0,
            (uri) => {
              const fi = {
                file: uri,
                fileName,
              };

              $upload(fi, e);
            }
          );
        } else {
          const fi = {
            file,
            fileName,
          };

          $upload(fi, e);
        }
      };
    });
  };

  const $removeDoc = async (i) => {
    let newImg = [...docs];
    newImg.splice(i, 1);
    setState((prevState) => ({ ...prevState, docs: newImg }));
  };

  const ImagePreview = ({ dataUri }) => {
    return (
      <div className="demo-image-preview d-none" id="lower-left">
        <img src={dataUri} alt="" />
      </div>
    );
  };

  return (
    <>
      <Modal.Body>
        <div className="btn-close" onClick={onClose} />
        <div className="modal-status bg-primary" />
        <Fi.FiAlertTriangle className="mb-2 text-primary" size={90} />
        <h3>Anda yakin?</h3>
        <p className="text-danger fw-semibold">
          {data?.document_status === '' ? `Akan menkonfirmasi bahwa akan maju / proses Dokumen Rekap BAST (RTT)` : `Akan menkonfirmasi bahwa Dokumen Rekap BAST (RTT) sudah selesai`}
        </p>

        {StateForm?.errors?.id || StateForm?.errors?.status ? (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {StateForm?.errors?.id || StateForm?.errors?.status}
          </div>
        ) : null}

        {data?.document_status === 'process' && (
          <>
            <ImagePreview dataUri={imgDraft} />

            <div className="row">
              <div className="col-5">
                <form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={true}
                    onChange={handleChange}
                    accept="image/*,.pdf"
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActive ? 'drag-active' : ''}
                  >
                    <div>
                      <p>Drag and drop your file here or</p>
                      <button className="upload-button" onClick={onButtonClick}>
                        Upload a file
                      </button>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              </div>
              <div className="col-7">
                {docs?.map((doc, i) => (
                  <div className="row border-bottom py-2 align-items-end" key={i}>
                    <div className="col-2 text-start ">
                      <img
                        src={`${doc?.data?.includes('.pdf') ? pdf : doc?.data}`}
                        alt=""
                        style={{ width: 40, height: 40 }}
                        className="rounded-3"
                      />
                    </div>
                    <div className="col-9 text-start overflow-hidden">
                      {doc?.filename}
                    </div>
                    <div className="col-1 text-start">
                      <div
                        className=" btn-action btn-sm cursor-pointer"
                        onClick={() => $removeDoc(i)}
                      >
                        <Fi.FiTrash color="red" size={14} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="invalid-feedback" style={{ display: 'block', textAlign: 'left' }}>
                {errors}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="row w-100">
          <div className="col">
            <Button variant="white" className="w-100" onClick={onClose}>
              Batal
            </Button>
          </div>
          <div className="col">
            <Button
              variant="primary"
              className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
              onClick={$handleUpdateRTT}
              disabled={StateForm?.loading}
            >
              Simpan
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>

  )
}