import * as StateHelper from "utilities/action.creator";

export const MODULE = "Form";

export const $failure = StateHelper.Default(
  MODULE,
  "Failure",
  ({ error, message }) => {
    return async (dispatch) => {
      // delay 10 second to reset the errors
      var tid = setInterval(function () {
        dispatch($reset.action());
      }, 10000);

      setTimeout(function () {
        clearInterval(tid);
      }, 10001);

      return dispatch($failure.action({ errors: error }));
    };
  }
);

export const $success = StateHelper.Default(
  MODULE,
  "Success",
  ({ message }) => {
    return async (dispatch) => {
      return dispatch($success.action({ message }));
    };
  }
);

export const $requesting = StateHelper.Default(MODULE, "Requesting", () =>
  $requesting.action()
);

export const $reset = StateHelper.Default(MODULE, "Reset", () =>
  $reset.action()
);
