import React from 'react';
import cn from 'classnames';

const FormInput = ({
  label,
  placeholder,
  required,
  type,
  hint,
  horizontal,
  value,
  onChange,
  error,
  defaultValue,
  disabled,
  className,
}) => {
  return (
    <div className={horizontal ? 'row mb-3' : 'mb-3'}>
      {label ? (
        <label
          className={
            required
              ? 'form-label required'
              : horizontal
              ? 'form-label col-3 col-form-label'
              : 'form-label'
          }
        >
          {label}
        </label>
      ) : null}
      <div className={horizontal ? 'col' : null}>
        <input
          type={type}
          className={cn('form-control', error ? 'is-invalid' : '', className)}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {error ? <div className="invalid-feedback">{error}</div> : null}
        {hint ? <small className="form-hint">{hint}</small> : null}
      </div>
    </div>
  );
};

export default FormInput;
