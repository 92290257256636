import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { DropdownButton, ButtonGroup } from 'react-bootstrap';
// import { toast } from 'react-toastify';
import * as Fi from 'react-icons/fi';
import { Link } from 'react-router-dom';

// import { Download } from 'services/action';
// import { aliasBansosName } from 'utilities/common';

// const initialState = {
//   isRemove: false,
//   url: null
// };

const Action = ({ data }) => {
  // const Dispatch = useDispatch();
  const Session = useSelector((state) => state?.Auth?.session);
  // const [{ isRemove, url }, setState] = React.useState(initialState);

  // const $handleDownload = async() => {
  //   const params = {
  //     bansos: aliasBansosName(data?.bansos_id),
  //     regency: data?.regency?.name,
  //     kanwil: data?.regency?.kanwil?.name
  //   }

  //   const result = await Dispatch(Download.$imageZip(params));
  //   if (result?.message === 'success') {
  //     setState((prevState) => ({ ...prevState, isRemove: true, url: result?.data }));
  //     window.open(result?.data, '_blank');
  //   } else {
  //     const message = 'Dokumen tidak tersedia.';
  //     toast.error(message, {
  //       position: 'bottom-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // }

  // const $handleRemoveDownload = async() => {
  //   const payload = {
  //     url
  //   }

  //   const result = await Dispatch(Download.$remove(payload));
  //   if (result?.message === 'success') {
  //     setState((prevState) => ({ ...prevState, isRemove: false, url: null }));
  //   }
  // }

  // React.useEffect(() => {
  //   if (isRemove) {
  //     setTimeout(function () {
  //       $handleRemoveDownload()
  //     }, 5000)
  //   }
  // }, [isRemove, url])

  if (Session?.usergroup?.id === '00e22f61-b7f4-4d03-806e-98b987c8c8e6' || Session?.usergroup?.id === '8465c143-a4e3-478e-b019-cb4e1035e886' || Session?.usergroup?.id === '302d772c-77db-47ea-ab5a-83b6be4d3787') return null

  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        variant="transparent"
        size="md"
        className="no-carret tbl-action"
        title={<Fi.FiMoreVertical size={13} color="gray" />}
      >
        <Link
          className="dropdown-item d-flex align-items-center"
          role="button"
          to={{
            pathname: `/rtt`,
            search: `?kanwil_id=${data?.regency?.kanwil?.id}&regency_id=${data?.regency_id}`,
          }}
        >
          <Fi.FiArrowRight size={15} color="#202020" className="me-2" />
          Lihat RTT
        </Link>

        {/* <button
          className="dropdown-item d-flex align-items-center"
          onClick={$handleDownload}
        >
          <Fi.FiDownload size={15} className="me-2" />
          Donwload Dokumen
        </button> */}
      </DropdownButton>
    </>
  );
};

export default Action;
