import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import * as Fi from 'react-icons/fi';
import { toast } from 'react-toastify';

import { POD, Download } from 'services/action';
import PODViewer from 'ui/components/imageViewer';
import { Skeletons } from 'ui/components/skeleton';
import { Uploader } from 'ui/components';

import { aliasBansosName } from 'utilities/common';

const initialState = {
  images: [],
  imgShow: 7,
  zoomImg: null,
  isDelete: false,
};

export const PODImage = ({ data, type, showAction, deleted }) => {
  const Dispatch = useDispatch();
  const Session = useSelector((state) => state.Auth?.session);
  const StateForm = useSelector((state) => state.Form);
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['POD.Get.Delivery']
  );

  const dummy = Array.from({ length: 2 }, (v, i) => i);
  const [{ images, zoomImg, isDelete }, setState] = React.useState(initialState);

  const $handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      isDelete: false,
      zoomImg: null,
    }));
  };

  const $getImage = async () => {
    const req = await Dispatch(POD.$delivery(data?.id, {type: type}));

    if (req?.message === 'success' && req?.data?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        images: req?.data,
      }));
    }
  };

  const $postImage = async (e) => {
    let payload = {
      document: {
        bansos_id: data?.bansos_id,
        delivery_id: data?.id,
        type: 'doc_out',
      },
      images: e,
    };

    const req = await Dispatch(POD.$upload(payload));

    if (req?.message === 'success') {
      $getImage();
    }
  };

  const $deleteImage = async () => {
    const payload = {
      deleted_by: Session?.user?.name,
    };
    const del = await Dispatch(POD.$delete(zoomImg?.id, payload));
    if (del?.message === 'success') {
      $getImage();
      setState((prevState) => ({
        ...prevState,
        isDelete: false,
      }));
    }
  };

  const $handleDownload = async() => {
    const params = {
      bansos: aliasBansosName(data?.bansos_id),
      type: type,
      delivery_id: data?.id,
      regency: data?.region?.regency_name,
      kanwil: data?.region?.kanwil_name,
      download_by: type
    }

    const result = await Dispatch(Download.$imagePdf(params));
    if (result?.message === 'success') {
      setState((prevState) => ({ ...prevState, isRemove: true, url: result?.data }));
      window.open(result?.data, '_blank');
    } else {
      const message = 'Dokumen tidak tersedia.';
      toast.error(message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  React.useEffect(() => {
    if (!data) return
    $getImage();
  }, [data]);

  return (
    <div className="p-0 p-md-3 mb-3 mb-md-0">
      <div className="card rounded-2 bg-white">
        <div className="card-header-gray p-3 border-bottom">
          <div className='row'>
            <div className='col-9'>
              <h3 className="card-title dx-card-title m-0 p-0">{type === 'doc_out' ? 'DOC-OUT': 'POD'}</h3>
            </div>
            {(type === 'doc_out' && images?.length > 0) && (
              <div className='col-2'>
                <button
                  className='btn btn-default float-right'
                  onClick={$handleDownload}
                >
                  <Fi.FiDownload size="15" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card-body bg-white p-3 pt-3">
        <div className="mt-2">
          {StateLoading ? (
            <div className="d-flex flex-wrap gap-2 align-items-center justify-content-start mt-n1">
              {dummy?.map((d, i) => (
                <Skeletons width="50px" height="50px" key={i} />
              ))}
              <Skeletons width="50px" height="50px" />
            </div>
          ) : (
            <div className="d-flex flex-wrap pb-2 gap-2 align-items-center justify-content-start mt-2">
              {(Session?.usergroup?.id !== '00e22f61-b7f4-4d03-806e-98b987c8c8e6' && Session?.usergroup?.id !== '8465c143-a4e3-478e-b019-cb4e1035e886' && Session?.usergroup?.id !== '004850c5-ebee-2f68-5cd9-4eb2405cfb5e' && Session?.usergroup?.id !== '302d772c-77db-47ea-ab5a-83b6be4d3787') && type === 'doc_out' && (
                <Uploader
                  medium
                  setData={(e) => $postImage(e)}
                  type='doc_out'
                  regency_id={data?.region?.regency_id}
                  bansos_id={data?.bansos_id}
                />
              )}

              <PODViewer
                items={images}
                imgStyle={{ width: 80, height: 80 }}
                imgClass="img-hover rounded-3 border"
                wrapperClass="d-flex flex-wrap pb-2 gap-2 align-items-center justify-content-start mt-2"
                showAction={showAction}
                deleteButton={(e) => {
                  return (
                    <div
                      className={
                        StateForm?.loading
                          ? 'btn btn-danger btn-loading btn-sm px-2 py-0 mt-2 mx-3 col-8'
                          : 'btn btn-danger btn-sm px-2 py-0 mt-2 mx-3 col-8'
                      }
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isDelete: true,
                          zoomImg: e,
                        }))
                      }
                    >
                      <Fi.FiTrash2 size="17" />
                    </div>
                  );
                }}
                onLoad={() => $getImage()}
              />
            </div>
          )}
        </div>
      </div>

      {/* modal delete */}
      <Modal
        show={isDelete}
        onHide={$handleClose}
        backdrop="static"
        centered
        className="text-center"
        size="small"
      >
        <Modal.Body>
          <div className="btn-close" onClick={$handleClose} />
          <div className="modal-status bg-danger" />
          <Fi.FiAlertTriangle className="mb-2 text-danger" size={90} />
          <h3>Anda yakin?</h3>
          <p className="text-danger fw-semibold">
            Data yang anda hapus tidak dapat dipulihkan
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col">
              <Button variant="white" className="w-100" onClick={$handleClose}>
                Batal
              </Button>
            </div>
            <div className="col">
              <Button
                variant="danger"
                className={StateForm?.loading ? ' btn-loading w-100' : 'w-100'}
                onClick={$deleteImage}
                disabled={StateForm?.loading}
              >
                Hapus
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
