import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import Menu from '../menu';

import LogoWhite from 'ui/assets/logo_jpl.png';

const Header = ({ fluid, consended }) => {
  const History = useHistory();

  return (
    <header className="navbar navbar-expand-md navbar-dark d-print-none ">
      <div className={fluid ? 'container-fluid' : 'container-xl'}>
        <Menu.Mobile />
        <h1
          className={cn(
            'navbar-brand d-none-navbar-horizontal cursor-pointer navbar-brand-autodark'
          )}
          onClick={() => History.push('/')}
        >
          <img
            src={LogoWhite}
            alt=""
            className="img-fluid rounded"
            style={{ maxWidth: 150, maxHeight: 35 }}
            id="logo-operator"
          />
        </h1>
        <div className="navbar-nav flex-row order-md-last">
          <Menu.Right />
          <Menu.Profile />
        </div>
        {consended ? <Menu.Consended fluid={fluid} /> : null}
      </div>
    </header>
  );
};

export default Header;
