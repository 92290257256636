import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import {
  HiOutlineCloudDownload,
} from 'react-icons/hi';
import { Page, Select, DatePicker } from 'ui/components';
import { formatDate } from 'utilities/common';

import { Report } from 'services/action';
import Table from './table';

const periodeOptions = [
  { value: '', label: 'All Periode' },
  { value: 'periode', label: 'Periode' },
];

const initialState = {
  filter: {
    date: new Date(),
    format: formatDate(new Date(), 'YYYY-MM'),
  },
  isLoading: false,
  periode: { value: 'periode', label: 'Periode' },
  data: null
};

const ReportDailySPM = () => {
  const Dispatch = useDispatch();
  const Project = useSelector((state) => state?.Auth?.project);
  const [{ periode, data, filter }, setState, ] = React.useState(initialState);

  const $handleDownload = async() => {
    const params = {
      bansos_id: periode?.value === 'periode' ? Project?.id : '',
      monthly: filter?.format,
      downloadable: true
    }

    await Dispatch(Report.$dailySPM(params));
  }

  const $onLoad = async() => {
    const params = {
      bansos_id: periode?.value === 'periode' ? Project?.id : '',
      monthly: filter?.format
    }
    const result = await Dispatch(Report.$dailySPM(params));
    if (result?.message === 'success') {
      setState((prevState) => ({
        ...prevState,
        data: result?.data
      }));
    }
  }

  React.useEffect(() => {
    $onLoad();
  }, [filter, periode]);

  return (
    <Page className="bordered">
      <Page.Header title="Laporan Daily Salur"  />

      <div className='row bg-white px-2 m-0'>
        <div className='col-12'>
          <div className='px-3'>
            <div className="d-flex table-tools align-items-center justify-content-end">
              <div className="d-flex align-items-center">
                <div className="mb-3">
                  <label class="form-label fs-8">Pilih Bulan</label>
                  <DatePicker
                    selectionMode="month"
                    value={filter?.date}
                    onChange={(date) => {
                      setState((prevState) => ({
                        ...prevState,
                        filter: {
                          date: date,
                          format: formatDate(date, 'YYYY-MM'),
                        },
                      }));
                    }}
                    isShowTrigger={true}
                  />
                </div>
                <div className="mb-3 ms-2 col-5">
                  <div className="form-label">
                    Pilih Periode
                  </div>
                  <Select
                    placeholder=""
                    classNamePrefix="select"
                    isClearable
                    value={periode}
                    initialValue={periode}
                    options={periodeOptions}
                    onChange={(v) => {
                      setState((prevState) => ({
                        ...prevState,
                        periode: v
                      }));
                    }}
                  />
                </div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-top`}>Download XLS</Tooltip>}
                >
                  <Button
                    variant="dark"
                    className="ms-2"
                    onClick={$handleDownload}
                    style={{ height: 30 }}
                  >
                    <HiOutlineCloudDownload size={18} color="white" />
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Page.Body className="bordered">
        <Table data={data}/>
      </Page.Body>
    </Page>
  );
};

export default ReportDailySPM;
