/* eslint-disable no-undef */
/* eslint-disable max-len */
import React from 'react';
import { Redirect, Switch, Route, BrowserRouter } from 'react-router-dom';

// Read subrouter in each subfolder
let routes = [];
const rf = require.context('./', true, /^.*_subrouter.js$/);
rf.keys().forEach((fileName) => {
  routes = routes.concat(rf(fileName).default);
});

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, i) => (
          <Route key={i} exact path={route.path} component={route.component} />
        ))}
        <Redirect exact from="/*" to="/signin" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
