import cn from 'classnames';
import { Skeletons } from 'ui/components/skeleton';

const Datagrid = ({ loading, title, value, className }) => {
  if (loading) {
    return (
      <div className={cn('datagrid-item', className)}>
        <div className="datagrid-title">
          <Skeletons height="14px" width="100%" />
        </div>
        <div className="datagrid-content">
          <Skeletons height="25px" width="100%" />
        </div>
      </div>
    );
  }

  return (
    <div className={cn('datagrid-item', className)}>
      <div className="datagrid-title">{title}</div>
      <div
        className="datagrid-content text-uppercase fs-8"
        style={{ wordBreak: 'break-all', fontWeight: '700' }}
      >
        {value}
      </div>
    </div>
  );
};

export default Datagrid;
