import React from 'react';
import cn from 'classnames';

const Card = ({
  title,
  children,
  footer,
  titleRight,
  loading,
  className,
  style,
}) => {
  const classes = cn('card', className);

  return (
    <div className={classes} style={style}>
      {title ? (
        <div className="card-header row m-0">
          <div className="col-lg-8 col-8 p-0">
            <h3 className="card-title dx-card-title"> {title} </h3>
          </div>
          <div className="col-lg-4 col-4  p-0">{titleRight}</div>
        </div>
      ) : null}

      <div className={loading ? 'card-loading' : null}>
        <div className="card-body">{children}</div>
        {footer ? (
          <div className="card-footer" style={{ backgroundColor: '#f8f9fa' }}>
            {footer}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
