import React from 'react';
import { renderToString } from 'react-dom/server';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from 'services/action';

import { Bansos } from 'services/action';
import bapang from 'ui/assets/badanpangan.png';
import bulog from 'ui/assets/logo_bulog.png';
import logoBlack from 'ui/assets/logo_jpl.png';
import { Print } from 'ui/components';
import _ from 'underscore';
import { currencyFormat, formatDate } from 'utilities/common';

const POD_URL = `${process.env.REACT_APP_POD_SYSTEM_URL}`;

const PrintRTT = ({ match, location }) => {
  const Dispatch = useDispatch();
  const StateLoading = useSelector(
    (state) => state?.Activity?.services['Report.Show.Village']
  );

  const StateLoadingKPM = useSelector(
    (state) => state?.Activity?.services['Bansos.KPM']
  );

  const Project = useSelector((state) => state?.Auth?.project);
  const Session = useSelector((state) => state?.Auth?.session);

  const [data, setData] = React.useState(null);
  const [kpm, setKpm] = React.useState(null);
  const [withQr, setWithQr] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [pages, setPage] = React.useState(null);
  const [firstPage, setFirstPage] = React.useState(40);
  const [limit, setLimit] = React.useState(40);

  const paramDate = new URLSearchParams(location?.search).get('date');

  const $onLoad = async (id) => {
    const detail = await Dispatch(Report.$showVillage(id));
    if (detail?.message === 'success') {
      $showKPM(detail?.data);
    }
  };

  const $showKPM = async (data) => {
    const payload = {sorting_dusun: data?.village?.district?.regency?.sorting_dusun || data?.village?.sorting_dusun}
    const res = await Dispatch(Bansos.$kpm(Project?.id, data?.village_id, payload));
    if (res?.message === 'success') {
      setKpm(res?.data);
      setData(data);
    }
  };

  React.useEffect(() => {
    let with_qr = new URLSearchParams(location?.search).get("with_qr")

    if (with_qr) {
      setFirstPage(24);
      setLimit(24);
      setWithQr(with_qr);
    }

    setLoaded(false);
    $onLoad(match?.params?.id);
  }, [match, Project]);

  React.useEffect(() => {
    if (kpm) {
      let pages = _.chunk(kpm, firstPage);
      if (kpm?.length > firstPage) {
        let first = pages[0];
        let last = _.rest(kpm, first?.length);
        pages = _.chunk(last, limit);
        pages.unshift(first);
      } else {
        setPage(pages);
      }

      setPage(pages);
      setLoaded(true);
    }
  }, [kpm, data]);

  const dummy = Array.from({ length: 8 }, (v, i) => i);
  const dummys = Array.from({ length: 5 }, (v, i) => i);

  if (StateLoading || StateLoadingKPM || data === null || !loaded) return null;

  const alokasi = {
    'aa93be9f-5841-4fb2-93f4-1e2ba8dba54a': 'Maret',
    '2acec690-a2a2-4257-b44a-9fb7b4e37fcf': 'April',
    'a7330f07-1505-4e0a-bf9f-4af347bc8389': 'Mei',
  };

  return (
    <div>
      {!data || data === null ? null : (
        <Print
          title={`BNBA - ${data?.village?.name}`}
          size="A4"
          content={renderToString(
            <>
              <section
                className=" sheet page-break"
                style={{ padding: '20px', position: 'relative' }}
              >
                <div
                  className="d-flex flex-row justify-content-between"
                  style={{ height: 132 }}
                >
                  <div className="" style={{ width: '100%' }}>
                    <div
                      className="d-flex align-items-center "
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderBottomWidth: 3,
                        borderBottomColor: '#202020',
                        borderBottomStyle: 'solid',
                      }}
                    >
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '20%',
                          justifyContent: 'start',
                          alignItems: 'start',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${line?.id}`}
                          value={`${POD_URL}/pod?type=bast&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          width: '60%',
                        }}
                      >
                        <h2 className="bolder uppercase text-center">
                          berita acara serah terima (bast)
                        </h2>
                        <h2 className="bolder uppercase text-center">
                          penerima bantuan pangan - cbp 2023
                        </h2>
                        <table className="body">
                          <tr>
                            <td style={{ width: '20%' }}>
                              <p
                                className="mb-0 text-left"
                                style={{ fontSize: 11 }}
                              >
                                Nomor{' '}
                              </p>
                            </td>
                            <td style={{ width: '80%' }}>
                              <p
                                className="mb-0 text-left bolder"
                                style={{ fontSize: 11 }}
                              >
                                : {data?.code|| ''}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '20%' }}>
                              <p
                                className="mb-0 text-left"
                                style={{ fontSize: 11 }}
                              >
                                Alokasi
                              </p>
                            </td>
                            <td style={{ width: '80%' }}>
                              <p
                                className="mb-0 text-left bolder"
                                style={{ fontSize: 11 }}
                              >
                                : {alokasi[Project?.id]} 2023
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          alignItems: 'end',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${line?.id}`}
                          value={`${POD_URL}/pod?type=bast&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div style={{ width: '50%', paddingRight: 5 }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '20%' }}>
                          <p className="mb-0 text-left">Provinsi</p>
                        </td>
                        <td className="border" style={{ width: '30%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.village?.district?.regency?.kanwil?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '20%' }}>
                          <p className="mb-0 text-left">Kab. / Kota</p>
                        </td>
                        <td className="border" style={{ width: '30%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.village?.district?.regency?.name}
                          </p>
                        </td>
                      </tr>

                      <tr className="">
                        <td className="border" style={{ width: '20%' }}>
                          <p className="mb-0 text-left">Kecamatan</p>
                        </td>
                        <td className="border" style={{ width: '30%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.village?.district?.name}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ width: '50%' }}>
                    <table className="body border">
                      <tr className="">
                        <td className="border" style={{ width: '20%' }}>
                          <p className="mb-0 text-left">Kelurahan</p>
                        </td>
                        <td className="border" style={{ width: '30%' }}>
                          <p className="bolder mb-0 text-left">
                            {data?.village?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '20%' }}>
                          <p className="mb-0 text-left">Total Paket</p>
                        </td>
                        <td className="border" style={{ width: '30%' }}>
                          <p className="bolder mb-0 text-left">
                            {currencyFormat(data?.total_kpm)} Paket
                          </p>
                        </td>
                      </tr>
                      <tr className="border">
                        <td className="border" style={{ width: '20%' }}>
                          <p className="mb-0 text-left">Total Berat</p>
                        </td>
                        <td className="border" style={{ width: '30%' }}>
                          <p className="bolder mb-0 text-left">
                            {currencyFormat(
                              data?.total_kpm * Project?.weight_packages
                            )}{' '}
                            Kg
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <table className="body" style={{ marginTop: 30 }}>
                  <tr className="border">
                    <td className="border">
                      <p
                        className="text-center"
                        style={{ fontSize: 13, lineHeight: 2 }}
                      >
                        Beras Bantuan Pangan - CBP 2023 diterima untuk
                        disalurkan kepada PBP terlampir dibantu oleh pejabat
                        setempat dan pendamping PBP.
                      </p>
                    </td>
                  </tr>
                </table>

                <table className="body" style={{ marginTop: 30 }}>
                  <tr className="">
                    <td className="" style={{ width: '5%' }}></td>
                    <td className="" style={{ width: '40%' }}>
                      <p
                        className="mb-0 text-left"
                        style={{ fontSize: 10, fontStyle: 'italic' }}
                      >
                        * kolom Tanda Tangan
                      </p>
                      <p
                        className="mb-0 text-left"
                        style={{ fontSize: 10, fontStyle: 'italic' }}
                      >
                        ** Stempel bagi yang memiliki
                      </p>
                    </td>
                    <td className="right" style={{ width: '40%' }}>
                      <p
                        className="mb-0 text-end"
                        style={{ fontSize: 12, lineHeight: 2 }}
                      >
                        {data?.village?.district?.regency?.name}
                        ................................ 2023
                      </p>
                    </td>
                    <td className="" style={{ width: '5%' }}></td>
                  </tr>
                  <tr className="border">
                    <td className="" style={{ width: '5%' }}></td>
                    <td className="border" style={{ height: 80, padding: 10 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 120,
                            fontSize: 10,
                          }}
                        >
                          Aparat Setempat*
                        </p>
                        <p className="mb-0 bold" style={{ fontSize: 10 }}>
                          (Nama Jelas, TTD & Stempel)**
                        </p>
                      </div>
                    </td>
                    <td className="border">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 120,
                            fontSize: 10,
                          }}
                        >
                          TRANSPORTER*
                        </p>
                        <p className="mb-0 bold" style={{ fontSize: 10 }}>
                          (Nama Jelas, TTD & Stempel)**
                        </p>
                      </div>
                    </td>
                    <td className="" style={{ width: '5%' }}></td>
                  </tr>
                </table>

                <div className="d-flex" style={{ marginTop: 30 }}>
                  <div style={{ width: '50%', paddingRight: 5 }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-center">
                            Nama & No Pendamping PBP
                          </p>
                        </td>
                        <td className="border" style={{ width: '30%' }}>
                          <p className="bolder mb-0 text-center">
                            Tanda Tangan
                          </p>
                        </td>
                      </tr>

                      {dummy?.map((dm, ix) => (
                        <tr className="border" key={ix}>
                          <td
                            className="border"
                            style={{ width: '70%', height: 20 }}
                          ></td>
                          <td className="border" style={{ width: '30%' }}>
                            <p className="bolder mb-0 text-left"></p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <div style={{ width: '50%' }}>
                    <table className="body border">
                      <tr className="border">
                        <td className="border" style={{ width: '70%' }}>
                          <p className="bolder mb-0 text-center">
                            Nama & No Pendamping PBP
                          </p>
                        </td>
                        <td className="border" style={{ width: '30%' }}>
                          <p className="bolder mb-0 text-center">
                            Tanda Tangan
                          </p>
                        </td>
                      </tr>

                      {dummy?.map((dm, ix) => (
                        <tr className="border" key={ix}>
                          <td
                            className="border"
                            style={{ width: '70%', height: 20 }}
                          ></td>
                          <td className="border" style={{ width: '30%' }}>
                            <p className="bolder mb-0 text-left"></p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>

                <table className="body">
                  <tr className="">
                    <td className="left" style={{ width: '33%' }}>
                      <img
                        src={bapang}
                        alt=""
                        style={{ maxHeight: 70, maxwidth: '100%' }}
                      />
                    </td>
                    <td className="center" style={{ width: '33%' }}>
                      <img
                        src={bulog}
                        alt=""
                        style={{ maxHeight: 50, maxwidth: '100%' }}
                      />
                    </td>
                    <td className="right" style={{ width: '33%' }}>
                      <img
                        src={logoBlack}
                        alt=""
                        style={{ maxHeight: 50, maxwidth: '100%' }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td className="center">
                      <QRCode
                        size={60}
                        // value={`${POD_URL}/pod/${line?.id}`}
                        value={`${POD_URL}/pod?type=bast&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                      />
                    </td>
                    <td className="right">
                      <p className="mb-0" style={{ fontStyle: 'italic' }}>
                        dicetak oleh: {Session?.user?.username}{' '}
                        {paramDate ? paramDate: formatDate(new Date(), 'DD-MM-YYYY')}{' '}
                      </p>
                    </td>
                  </tr>
                </table>
              </section>

              <section
                className=" sheet page-break"
                style={{ padding: '30px 40px', position: 'relative' }}
              >
                <div
                  className="d-flex flex-row justify-content-between"
                  style={{ height: 132 }}
                >
                  <div className="" style={{ width: '100%' }}>
                    <div
                      className="d-flex align-items-center "
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderBottomWidth: 3,
                        borderBottomColor: '#202020',
                        borderBottomStyle: 'solid',
                      }}
                    >
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '10%',
                          justifyContent: 'start',
                          alignItems: 'start',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${line?.id}`}
                          value={`${POD_URL}/pod?type=sptjm&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          width: '80%',
                          textAlign: 'center',
                        }}
                      >
                        <h2 className="bolder uppercase ">
                          surat pernyataan tanggung jawab mutlak
                        </h2>
                        <h2 className="bolder uppercase ">
                          PENGGATIAN PENERIMA BANTUAN PANGAN – CBP 2023
                        </h2>
                        <h2 className="bolder uppercase ">
                          ALOKASI: {alokasi[Project?.id]} 2023
                        </h2>
                      </div>
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '10%',
                          justifyContent: 'end',
                          alignItems: 'end',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${line?.id}`}
                          value={`${POD_URL}/pod?type=sptjm&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                    Yang bertandatangan di bawah ini :
                  </p>

                  <div style={{ marginLeft: 30 }}>
                    <table>
                      <tr className="tb-spacing">
                        <td className="tb-spacing" width="30%">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            Nama
                          </p>
                        </td>
                        <td className="tb-spacing">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            :{' '}
                          </p>
                        </td>
                      </tr>
                      <tr className="tb-spacing">
                        <td className="tb-spacing" width="30%">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            No. HP
                          </p>
                        </td>
                        <td className="tb-spacing">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            :{' '}
                          </p>
                        </td>
                      </tr>
                      <tr className="tb-spacing">
                        <td className="tb-spacing" width="30%">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            Jabatan
                          </p>
                        </td>
                        <td className="tb-spacing">
                          <p
                            className="mb-0 text-left uppercase"
                            style={{ fontSize: 13 }}
                          >
                            :
                          </p>
                        </td>
                      </tr>

                      <tr className="tb-spacing">
                        <td className="tb-spacing" width="30%">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            Instansi
                          </p>
                        </td>
                        <td className="tb-spacing">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            :{' '}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                    Menyatakan dengan sesungguhnya bahwa:
                  </p>
                  <p
                    className="text-left"
                    style={{ fontSize: 13, lineHeight: 2 }}
                  >
                    Saya bertanggungjawab terhadap perubahan/penggantian
                    Penerima Bantuan Pangan dalam Program Bantuan Pangan – CBP
                    2023 dari Badan Pangan Nasional melalui Perum BULOG di
                    wilayah:
                  </p>

                  <div style={{ marginLeft: 30 }}>
                    <table>
                      <tr className="tb-spacing">
                        <td className="tb-spacing" width="30%">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            Kelurahan/Desa
                          </p>
                        </td>
                        <td className="tb-spacing">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            : {data?.village?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="tb-spacing">
                        <td className="tb-spacing" width="30%">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            Kecamatan
                          </p>
                        </td>
                        <td className="tb-spacing">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            : {data?.village?.district?.name}
                          </p>
                        </td>
                      </tr>
                      <tr className="tb-spacing">
                        <td className="tb-spacing" width="30%">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            Kabupaten/Kota
                          </p>
                        </td>
                        <td className="tb-spacing">
                          <p
                            className="mb-0 text-left uppercase"
                            style={{ fontSize: 13 }}
                          >
                            : {data?.village?.district?.regency?.name}
                          </p>
                        </td>
                      </tr>

                      <tr className="tb-spacing">
                        <td className="tb-spacing" width="30%">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            Provinsi
                          </p>
                        </td>
                        <td className="tb-spacing">
                          <p
                            className="mb-0 text-left"
                            style={{ fontSize: 13 }}
                          >
                            : {data?.village?.district?.regency?.kanwil?.name}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <p
                    className="text-left"
                    style={{ fontSize: 13, lineHeight: 2 }}
                  >
                    Dengan perincian sebagai berikut:
                  </p>

                  <table className="body border" style={{ marginBottom: 30 }}>
                    <tr className="border">
                      <td className="border" style={{ width: '5%' }}>
                        <p className="mb-0 text-center">No</p>
                      </td>
                      <td className="border" style={{ width: '30%' }}>
                        <p className="mb-0 text-center">
                          Nama PBP yang tidak ditemukan
                        </p>
                      </td>
                      <td className="border" style={{ width: '20%' }}>
                        <p className="mb-0 text-center">Alamat</p>
                      </td>
                      <td className="border" style={{ width: '25%' }}>
                        <p className="mb-0 text-center">Nama PBP Pengganti</p>
                      </td>
                      <td className="border" style={{ width: '20%' }}>
                        <p className="mb-0 text-center">Alamat</p>
                      </td>
                    </tr>

                    {dummys?.map((dm, ix) => (
                      <tr className="border" key={ix}>
                        <td
                          className="border"
                          style={{ width: '5%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '30%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '20%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '25%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '20%', height: 20 }}
                        ></td>
                      </tr>
                    ))}
                  </table>
                  <p className="text-left" style={{ fontSize: 13 }}>
                    Selanjutnya kepada PBP Pengganti disiapkan Daftar Tanda
                    Terima sebagaimana terlampir.
                  </p>
                  <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                    Demikian pernyataan ini saya buat dengan sesungguhnya.
                  </p>
                </div>

                <table className="body" style={{ marginTop: 30 }}>
                  <tr className="">
                    <td className="" style={{ width: '5%' }}></td>
                    <td className="" style={{ width: '40%' }}></td>
                    <td className="right" style={{ width: '40%' }}>
                      <p
                        className="mb-0 text-end"
                        style={{ fontSize: 12, lineHeight: 2 }}
                      >
                        .............................................. 2023
                      </p>
                    </td>
                    <td className="" style={{ width: '5%' }}></td>
                  </tr>
                  <tr className="border">
                    <td className="" style={{ width: '5%' }}></td>
                    <td className="border" style={{ height: 80, padding: 10 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <div style={{ marginBottom: 100 }}>
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            Mengetahui**,
                          </p>
                          <p className="mb-0" style={{ fontSize: 10 }}>
                            (Nama Jelas, TTD & Stempel)***
                          </p>
                        </div>
                        <p className="mb-0 bold" style={{ fontSize: 10 }}>
                          Kepala Desa/Lurah
                        </p>
                      </div>
                    </td>
                    <td className="border">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <div style={{ marginBottom: 100 }}>
                          <p className="mb-0 bold" style={{ fontSize: 10 }}>
                            Yang membuat pernyataan**,
                          </p>
                          <p className="mb-0" style={{ fontSize: 10 }}>
                            (Nama Jelas, TTD & Stempel)***
                          </p>
                        </div>
                        <p className="mb-0 bold" style={{ fontSize: 10 }}>
                          Aparat Setempat
                        </p>
                      </div>
                    </td>
                    <td className="" style={{ width: '5%' }}></td>
                  </tr>
                </table>
                <p
                  className="mb-0 text-left"
                  style={{ fontSize: 10, fontStyle: 'italic' }}
                >
                  *Pengurus RT/RW atau sebutan nama lainnya atau aparat
                  kelurahan/desa atau perwakilan PBP sasaran
                </p>
                <p
                  className="mb-0 text-left"
                  style={{ fontSize: 10, fontStyle: 'italic' }}
                >
                  **Dapat ditandatangani setelah penyaluran
                </p>
                <p
                  className="mb-0 text-left"
                  style={{ fontSize: 10, fontStyle: 'italic' }}
                >
                  ***Stempel bagi yang memiliki
                </p>
                <table className="body">
                  <tr className="">
                    <td className="left" style={{ width: '33%' }}>
                      <img
                        src={bapang}
                        alt=""
                        style={{ maxHeight: 70, maxwidth: '100%' }}
                      />
                    </td>
                    <td className="center" style={{ width: '33%' }}>
                      <img
                        src={bulog}
                        alt=""
                        style={{ maxHeight: 50, maxwidth: '100%' }}
                      />
                    </td>
                    <td className="right" style={{ width: '33%' }}>
                      <img
                        src={logoBlack}
                        alt=""
                        style={{ maxHeight: 50, maxwidth: '100%' }}
                      />
                    </td>
                  </tr>
                </table>
              </section>

              <section
                className=" sheet page-break"
                style={{ padding: '30px 40px', position: 'relative' }}
              >
                <div
                  className="d-flex flex-row justify-content-between"
                  style={{ height: 132 }}
                >
                  <div className="" style={{ width: '100%' }}>
                    <div
                      className="d-flex align-items-center "
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderBottomWidth: 3,
                        borderBottomColor: '#202020',
                        borderBottomStyle: 'solid',
                      }}
                    >
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '10%',
                          justifyContent: 'start',
                          alignItems: 'start',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${line?.id}`}
                          value={`${POD_URL}/pod?type=sptjm&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          width: '80%',
                          textAlign: 'center',
                        }}
                      >
                        <h2 className="bolder uppercase text-center">
                          berita acara serah terima (bast)
                        </h2>
                        <h2 className="bolder uppercase text-center">
                          penerima bantuan pangan (pbp) pengganti
                        </h2>
                        <h2 className="bolder uppercase text-center ">
                          ALOKASI: {alokasi[Project?.id]} 2023
                        </h2>
                      </div>
                      <div
                        className="d-flex flex-column  text-center "
                        style={{
                          width: '10%',
                          justifyContent: 'end',
                          alignItems: 'end',
                        }}
                      >
                        <QRCode
                          size={80}
                          // value={`${POD_URL}/pod/${line?.id}`}
                          value={`${POD_URL}/pod?type=sptjm&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: 30 }}>
                  <table>
                    <tr className="tb-spacing">
                      <td className="tb-spacing" width="30%">
                        <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                          Provinsi
                        </p>
                      </td>
                      <td className="tb-spacing">
                        <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                          : {data?.village?.district?.regency?.kanwil?.name}
                        </p>
                      </td>
                    </tr>
                    <tr className="tb-spacing">
                      <td className="tb-spacing" width="30%">
                        <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                          Kabupaten/Kota
                        </p>
                      </td>
                      <td className="tb-spacing">
                        <p
                          className="mb-0 text-left uppercase"
                          style={{ fontSize: 13 }}
                        >
                          : {data?.village?.district?.regency?.name}
                        </p>
                      </td>
                    </tr>
                    <tr className="tb-spacing">
                      <td className="tb-spacing" width="30%">
                        <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                          Kecamatan
                        </p>
                      </td>
                      <td className="tb-spacing">
                        <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                          : {data?.village?.district?.name}
                        </p>
                      </td>
                    </tr>

                    <tr className="tb-spacing">
                      <td className="tb-spacing" width="30%">
                        <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                          Kelurahan/Desa
                        </p>
                      </td>
                      <td className="tb-spacing">
                        <p className="mb-0 text-left" style={{ fontSize: 13 }}>
                          : {data?.village?.name}
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
                <p
                  className="text-left"
                  style={{ fontSize: 13, lineHeight: 2, marginTop: 20 }}
                >
                  Kami yang bertandatangan di bawah ini adalah sebagai PBP
                  Pengganti yang ditunjuk oleh aparat setempat menyatakan dengan
                  sebenar-benarnya bahwa kami telah menerima paket beras
                  sejumlah {Project?.weight_packages} KG dengan kondisi kualitas
                  baik sebagai berikut:
                </p>

                <table className="body" style={{ marginTop: 30 }}>
                  <tr className="border">
                    <tr className="border">
                      <td
                        rowSpan="2"
                        className="border"
                        style={{ width: '5%' }}
                      >
                        <p className="mb-0 text-center">No</p>
                      </td>
                      <td
                        rowSpan="2"
                        className="border"
                        style={{ width: '20%' }}
                      >
                        <p className="mb-0 text-center">
                          Nama PBP yang tidak ditemukan
                        </p>
                      </td>
                      <td
                        colSpan="2"
                        className="border"
                        style={{ width: '35%' }}
                      >
                        <p className="mb-0 text-center">PBP Pengganti</p>
                      </td>
                      <td
                        rowSpan="2"
                        className="border"
                        style={{ width: '20%' }}
                      >
                        <p className="mb-0 text-center">Sebab Penggantian*</p>
                      </td>
                      <td
                        rowSpan="2"
                        className="border"
                        style={{ width: '20%' }}
                      >
                        <p className="mb-0 text-center">
                          Tanda Tangan PBP Pengganti
                        </p>
                      </td>
                    </tr>
                    <tr className="border">
                      <td className="border">
                        <p className="mb-0 text-center">Nama</p>
                      </td>
                      <td className="border">
                        <p className="mb-0 text-center">Alamat</p>
                      </td>
                    </tr>

                    {dummys?.map((dm, ix) => (
                      <tr className="border" key={ix}>
                        <td
                          className="border"
                          style={{ width: '5%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '20%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '15%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '15%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '20%', height: 20 }}
                        ></td>
                        <td
                          className="border"
                          style={{ width: '20%', height: 20 }}
                        ></td>
                      </tr>
                    ))}
                  </tr>
                </table>

                <div style={{ width: '50%', marginTop: 30 }}>
                  <table className="body">
                    <tr className="border">
                      <td className="border" style={{ width: '50%' }}>
                        <p
                          className=" mb-0 bolder text-center uppercase"
                          style={{ fontSize: 13 }}
                        >
                          jumlah
                        </p>
                      </td>
                      <td className="border"></td>
                    </tr>
                  </table>
                </div>

                <p
                  className="text-left"
                  style={{ fontSize: 13, lineHeight: 2, marginTop: 20 }}
                >
                  Demikian Berita Acara ini dibuat dengan sebenarnya untuk dapat
                  dipergunakan sebagaimana mestinya.
                </p>

                <table className="body" style={{ marginTop: 30 }}>
                  <tr className="">
                    <td className="" style={{ width: '5%' }}></td>
                    <td className="" style={{ width: '40%' }}></td>
                    <td className="right" style={{ width: '40%' }}>
                      <p
                        className="mb-0 text-end"
                        style={{ fontSize: 12, lineHeight: 2 }}
                      >
                        ................................ 2023
                      </p>
                    </td>
                    <td className="" style={{ width: '5%' }}></td>
                  </tr>
                  <tr className="border">
                    <td className="" style={{ width: '5%' }}></td>
                    <td className="border" style={{ height: 80, padding: 10 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 120,
                            fontSize: 10,
                          }}
                        >
                          Mengetahui,
                          <br />
                          Aparat Setempat**
                        </p>
                        <p className="mb-0 bold" style={{ fontSize: 10 }}>
                          (Nama Jelas, TTD & Stempel)***
                        </p>
                      </div>
                    </td>
                    <td className="border">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          className="bold"
                          style={{
                            marginBottom: 120,
                            fontSize: 10,
                          }}
                        >
                          TRANSPORTER
                        </p>
                        <p className="mb-0 bold" style={{ fontSize: 10 }}>
                          (Nama Jelas & TTD)
                        </p>
                      </div>
                    </td>
                    <td className="" style={{ width: '5%' }}></td>
                  </tr>
                </table>
                <p
                  className="mb-0 text-left"
                  style={{ fontSize: 10, fontStyle: 'italic' }}
                >
                  *Diisi meninggal dunia, pindah domisili, dicatat lebih dari
                  1(satu) kali,tidak ditemukan alamatnya, tidak ditemukan pada
                  alamat terdata, dan/atau telah mampu (tidak memenuhi syarat
                  sebagai PBP)
                </p>
                <p
                  className="mb-0 text-left"
                  style={{ fontSize: 10, fontStyle: 'italic' }}
                >
                  **Pengurus RT/RW atau sebutan nama lainnya atau aparat
                  kelurahan/desa atau perwakilan PBP sasaran
                </p>
                <p
                  className="mb-0 text-left"
                  style={{ fontSize: 10, fontStyle: 'italic' }}
                >
                  ***Stempel bagi yang memiliki
                </p>

                <table className="body">
                  <tr className="">
                    <td className="left" style={{ width: '33%' }}>
                      <img
                        src={bapang}
                        alt=""
                        style={{ maxHeight: 70, maxwidth: '100%' }}
                      />
                    </td>
                    <td className="center" style={{ width: '33%' }}>
                      <img
                        src={bulog}
                        alt=""
                        style={{ maxHeight: 50, maxwidth: '100%' }}
                      />
                    </td>
                    <td className="right" style={{ width: '33%' }}>
                      <img
                        src={logoBlack}
                        alt=""
                        style={{ maxHeight: 50, maxwidth: '100%' }}
                      />
                    </td>
                  </tr>
                </table>
              </section>

              {pages?.map((page, id) => (
                <section
                  className=" sheet page-break"
                  style={{ padding: '20px', position: 'relative' }}
                >
                  <table className="body ">
                    <tr className="border">
                      <td className="border" style={{ width: '15%' }}>
                        <p
                          className="bolder mb-0 text-center"
                          style={{ fontSize: 14 }}
                        >
                          BAST NO
                        </p>
                      </td>
                      <td
                        className="border text-center"
                        rowSpan={3}
                        style={{ width: '15%' }}
                      >
                        <QRCode
                          size={70}
                          // value={`${POD_URL}/pod/${line?.id}`}
                          value={`${POD_URL}/pod?type=rtt&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                        />
                      </td>
                      <td
                        className="border"
                        rowSpan={3}
                        style={{ width: '30%' }}
                      >
                        <p
                          className="mb-0 text-center"
                          style={{ fontSize: 14 }}
                        >
                          KOTA / KAB
                        </p>
                        <p
                          className="bolder mb-0 text-center"
                          style={{ fontSize: 14 }}
                        >
                          {data?.village?.district?.regency?.name}
                        </p>
                      </td>
                      <td className="border" style={{ width: '25%' }}>
                        <p className="mb-0 text-left bolder">
                          KEC. {data?.village?.district?.name}
                        </p>
                      </td>
                      <td
                        className="border text-center"
                        rowSpan={3}
                        style={{ width: '15%' }}
                      >
                        <QRCode
                          size={70}
                          // value={`${POD_URL}/pod/${line?.id}`}
                          value={`${POD_URL}/pod?type=rtt&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&report_id=${data?.id}`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="border"
                        rowSpan={2}
                        style={{ width: '15%' }}
                      >
                        <p
                          className="mb-0 text-center bolder"
                          style={{
                            fontSize: 13,
                            overflowWrap: 'break-word',
                            width: 200,
                          }}
                        >
                          {data?.code || ''}
                        </p>
                      </td>
                      <td className="border" style={{ width: '15%' }}>
                        <p className="mb-0 text-left bolder">
                          KEL. {data?.village?.name}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border" style={{ width: '15%' }}>
                        <p className="mb-0 text-left bolder">
                          Hal. {id + 1} / {pages?.length}
                        </p>
                      </td>
                    </tr>
                  </table>

                  <p className="mb-0" style={{ fontSize: 8 }}>
                    Saya yang bertanda tangan di bawah ini menyatakan dengan
                    sebenar-benarnya bahwa telah menerima{' '}
                    {Project?.weight_packages}Kg beras bantuan pangan - CBP 2023
                    dengan kualitas baik.
                  </p>

                  <div className="d-flex">
                    <div style={{ width: '50%', paddingRight: 5 }}>
                      <table className="body border">
                        <thead>
                          <tr>
                            {withQr && (
                              <td className="border" style={{ width: '20%' }}>&nbsp;</td>
                            )}
                            <td className="border" style={{ width: `${withQr ? '20%': '40%'}` }}>
                              <p
                                className="mb-0 text-center uppercase bolder"
                                style={{ fontSize: 10 }}
                              >
                                Nama PBP
                              </p>
                            </td>
                            <td className="border" style={{ width: '40%' }}>
                              <p
                                className="mb-0 text-center uppercase bolder"
                                style={{ fontSize: 10 }}
                              >
                                Tanda Tangan
                              </p>
                            </td>
                            <td className="border" style={{ width: '20%' }}>
                              <p
                                className="mb-0 text-center uppercase bolder"
                                style={{ fontSize: 10 }}
                              >
                                TANGGAL TERIMA
                              </p>
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {page?.map((d, idx) => {
                            if ((idx < 12 && withQr) || (idx < 20 && !withQr)) {
                              return (
                                <tr className="border" key={idx}>
                                  {withQr && (
                                    <td
                                      className='border'
                                      style={{
                                        width: '20%',
                                        height: 35,
                                        maxHeight: 35,
                                      }}
                                    >
                                      <QRCode
                                        size={60}
                                        value={`${POD_URL}/pod?type=kpm&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&kpm_id=${d?.kpm_id}&report_id=${data?.id}`}
                                      />
                                    </td>
                                  )}
                                  <td
                                    className="border"
                                    style={{
                                      width: `${withQr ? '20%': '40%'}`,
                                      height: 35,
                                      maxHeight: 35,
                                    }}
                                  >
                                    <p
                                      className="text-left bold"
                                      style={{
                                        fontSize: 10,
                                        marginBlockEnd: 0,
                                        width: 180,
                                        overflowWrap: 'break-word',
                                      }}
                                    >
                                      {d?.kpm?.name}
                                    </p>
                                    <p
                                      className="text-left"
                                      style={{
                                        fontSize: 8,
                                        width: 180,
                                        display: 'inline-block',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        marginBlockEnd: 0,
                                      }}
                                    >
                                      {d?.kpm?.address}
                                    </p>
                                  </td>
                                  <td
                                    className="border"
                                    style={{
                                      width: '40%',
                                      verticalAlign: 'top',
                                    }}
                                  >
                                    <p style={{ fontSize: 9 }}>
                                      {id > 1
                                        ? firstPage +
                                          (limit * (id - 1) + idx + 1)
                                        : firstPage * id + idx + 1}
                                    </p>
                                  </td>
                                  <td
                                    className="border"
                                    style={{ width: '20%' }}
                                  ></td>
                                </tr>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div style={{ width: '50%', paddingRight: 5 }}>
                      <table className="body border">
                        <thead>
                          <tr>
                            {withQr && (
                              <td className="border" style={{ width: '20%' }}>&nbsp;</td>
                            )}
                            <td className="border" style={{ width: `${withQr ? '20%': '40%'}` }}>
                              <p
                                className="mb-0 text-center uppercase bolder"
                                style={{ fontSize: 10 }}
                              >
                                Nama PBP
                              </p>
                            </td>
                            <td className="border" style={{ width: '40%' }}>
                              <p
                                className="mb-0 text-center uppercase bolder"
                                style={{ fontSize: 10 }}
                              >
                                Tanda Tangan
                              </p>
                            </td>
                            <td className="border" style={{ width: '20%' }}>
                              <p
                                className="mb-0 text-center uppercase bolder"
                                style={{ fontSize: 10 }}
                              >
                                TANGGAL TERIMA
                              </p>
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {page?.map((d, idx) => {
                            if (((idx > 11 && idx < 24) && withQr) || ((idx > 19 && idx < 40) && !withQr)) {
                              return (
                                <tr className="border" key={idx}>
                                  {withQr && (
                                    <td
                                      className='border'
                                      style={{
                                        width: '20%',
                                        height: 35,
                                        maxHeight: 35,
                                      }}
                                    >
                                      <QRCode
                                        size={60}
                                        value={`${POD_URL}/pod?type=kpm&bansos_id=${data?.bansos_id}&village_id=${data?.village_id}&kpm_id=${d?.kpm_id}&report_id=${data?.id}`}
                                      />
                                    </td>
                                  )}
                                  <td
                                    className="border"
                                    style={{
                                      width: `${withQr ? '20%': '40%'}`,
                                      height: 35,
                                      maxHeight: 35,
                                    }}
                                  >
                                    <p
                                      className="text-left bold"
                                      style={{
                                        fontSize: 10,
                                        marginBlockEnd: 0,
                                        width: 180,
                                        overflowWrap: 'break-word',
                                      }}
                                    >
                                      {d?.kpm?.name}
                                    </p>
                                    <p
                                      className="text-left"
                                      style={{
                                        fontSize: 8,
                                        width: 180,
                                        display: 'inline-block',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        marginBlockEnd: 0,
                                      }}
                                    >
                                      {d?.kpm?.address}
                                    </p>
                                  </td>
                                  <td
                                    className="border"
                                    style={{
                                      width: '40%',
                                      verticalAlign: 'top',
                                    }}
                                  >
                                    <p style={{ fontSize: 9 }}>
                                      {id > 1
                                        ? firstPage +
                                          (limit * (id - 1) + idx + 1)
                                        : firstPage * id + idx + 1}
                                    </p>
                                  </td>
                                  <td
                                    className="border"
                                    style={{ width: '20%' }}
                                  ></td>
                                </tr>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      bottom: 20,
                      right: 20,
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{
                        fontSize: 9,
                      }}
                    >
                      dicetak: {Session?.user?.username}{' '}
                      {paramDate ? paramDate: formatDate(new Date(), 'DD-MM-YYYY')}
                    </p>
                  </div>
                </section>
              ))}
            </>
          )}
        />
      )}
    </div>
  );
};

export default PrintRTT;
